import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from "react-intl";
import { observer } from 'mobx-react-lite';

import { StyledSelect } from '../../../../common/components/input/styled-select';
import { StyledInput } from '../../../../common/components/input/styled-input';
import { useEnterKeyListener } from '../../../../common/components/hooks/use-key-listener';

import { ExtraContractStatusFilter, ProvidersFilter, ProvidersFilterModel } from '../../../model/providers-filter';
import { getTimeFilters, TimePeriodFilter } from '../../../../common/models/filters';
import { ContractStatus } from '../../../model/contract';
import { Country, nameFromCountryId } from '../../../../common/models/country';

import SearchIcon from '../../../../assets/images/icon-search.svg';
import messages from '../../../providers.messages';
import commonMessages from '../../../../common/messages/common.messages';
import filterMessages from '../../../../common/messages/filter.messages';

import './provider-section-header.scss';

export interface ProvidersSectionHeaderProps {
    filters: ProvidersFilter;
    availableCountries: Country[];
    applyFilters: () => void;
    onFiltersChanged: (filters: ProvidersFilter) => void;
    canUserChangeCountryFilter: boolean;
}

const anyStatusFilterItemId = '__any__contract_status__';
const noCountry = 'noCountry';

export const ProvidersSectionHeader = observer((props: ProvidersSectionHeaderProps) => {
    
    const intl = useIntl();
    const { formatMessage } = intl;

    const [oldCpoFilterValue, setOldCpoFilterValue] = useState('');
    
    const availableCountries = useMemo(() => [
        {id: noCountry, label: formatMessage(filterMessages.anyCountryFilter)},
        ...props.availableCountries.map(elem => {
            const nameDescriptor = nameFromCountryId(elem);
            return {
                id: elem, 
                label: nameDescriptor ? formatMessage(nameDescriptor) : elem
            };
        })
        .sort((a,b) => a.label.localeCompare(b.label))
    ]
        
    // to enable rebuild if the available countries change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [formatMessage, props.availableCountries.length]);
    
    const onCountrySelected = useCallback((countryId: string) => {
        if (countryId == noCountry) 
            props.filters.setCountry(undefined);
        else {
            props.onFiltersChanged(ProvidersFilterModel.create({
                cpoName: props.filters.cpoName,
                countryId: countryId,
                contractStatus: props.filters.contractStatus,
                timePeriod: props.filters.timePeriod,
            }));
        }
        props.applyFilters();
    }, [props]);

    
    const contractStatusFilterValues = useMemo(() => [
        ...Object.values(ContractStatus),
        ...Object.values(ExtraContractStatusFilter)
    ], []);
    const contractStatusFilters = useMemo(() => [
        { id: anyStatusFilterItemId, label: formatMessage(messages.anyContractFilter) },
        { id: ContractStatus.ACTIVE, label: formatMessage(messages.onlyActiveContractsFilter) },
        { id: ContractStatus.NOT_ACTIVE, label: formatMessage(messages.onlyNotActiveContractsFilter) },
        { id: ContractStatus.EXPIRED, label: formatMessage(messages.onlyExpiredContractsFilter) },
        { id: ExtraContractStatusFilter.BLOCKED, label: formatMessage(messages.onlyBlockedContractsFilter) },
        { id: ExtraContractStatusFilter.NO_CONTRACTS, label: formatMessage(messages.onlyNoContractsFilter) },
    ], [formatMessage]);
    const onContractStatusSelected = useCallback((contractStatus: string) => {
        props.onFiltersChanged(ProvidersFilterModel.create({
            cpoName: props.filters.cpoName,
            countryId: props.filters.countryId,
            contractStatus: contractStatus === anyStatusFilterItemId 
                ? undefined
                :contractStatusFilterValues.find(elem => elem === contractStatus),
            timePeriod: props.filters.timePeriod,
        }));
        props.applyFilters();
    }, [props, contractStatusFilterValues]);

    const onCpoNameChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
        event => {
            const newValue = event.currentTarget.value;
            props.onFiltersChanged(ProvidersFilterModel.create({
                cpoName: newValue.trim().length === 0 ? undefined : newValue,
                countryId: props.filters.countryId,
                contractStatus: props.filters.contractStatus,
                timePeriod: props.filters.timePeriod,
            }));
        }, 
        [props]
    );
    const onCpoNameBlur = useCallback(() => {
        if((props.filters.cpoName ?? '') !== oldCpoFilterValue){
            setOldCpoFilterValue(props.filters.cpoName ?? '');
            props.applyFilters();
        }
    }, [oldCpoFilterValue, props]);

    const timeFilters =  useMemo(() => [
        { id: TimePeriodFilter.LAST_30_DAYS, label: intl.formatMessage(filterMessages.last30DaysFilter) },
        ...getTimeFilters(intl)
    ], [intl]);
    const onTimePeriodSelected = useCallback((timePeriod: string) => {
        props.onFiltersChanged(ProvidersFilterModel.create({
            cpoName: props.filters.cpoName,
            countryId: props.filters.countryId,
            contractStatus: props.filters.contractStatus,
            timePeriod: Object.values(TimePeriodFilter)
                .find(elem => elem === timePeriod) ?? TimePeriodFilter.LAST_30_DAYS,
        }));
        props.applyFilters();
    }, [props]);

    useEnterKeyListener(onCpoNameBlur);

    return <div className='providers-section-header'>
        
        <div className='providers-filters'>
            <StyledSelect
                containerClassName='country-search-select'
                items={availableCountries}
                selectedItemId={props.filters.countryId ?? noCountry}
                onItemSelected={onCountrySelected}
                disabled={!props.canUserChangeCountryFilter}
            />
            <StyledSelect
                containerClassName='contract-status-search-select'
                items={contractStatusFilters}
                selectedItemId={props.filters.contractStatus ?? anyStatusFilterItemId}
                onItemSelected={onContractStatusSelected}
            />
            <StyledInput
                className='email-search-input'
                onChange={onCpoNameChange}
                value={props.filters.cpoName ?? ''}
                placeholder={formatMessage(commonMessages.searchPlaceholderText)}
                icon={SearchIcon}
                onIconClick={onCpoNameBlur}
                onBlur={onCpoNameBlur} 
            />
        </div>

        <div className='date-time-period-container'>
            <span>{formatMessage(messages.dataFrom)}</span>
            <StyledSelect
                containerClassName='data-time-period-search-select'
                items={timeFilters}
                selectedItemId={props.filters.timePeriod}
                onItemSelected={onTimePeriodSelected} />
        </div>
        
    </div>;
});