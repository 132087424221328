import { Instance, types } from "mobx-state-tree";
import { Country } from "../../common/models/country";
import { UserRole } from "../../common/models/user";

export const AdministrationFilterModel = types.model({
    country: types.maybeNull(types.string),
    role: types.maybeNull(types.enumeration(Object.values(UserRole))),
    backendUserSearched: types.maybeNull(types.string)
})
.actions((self) => ({
    
    setCountry(country?: Country) {
        self.country = country ?? null;
    },

    setRole(role?: UserRole) {
        self.role = role ?? null;
    },

    setBackendUserSearched(value?: string) {
        self.backendUserSearched = value ?? null;
    },

    resetFilter() {
        self.country = null;
        self.role = null;
        self.backendUserSearched = null;
    }

}));


export interface AdministrationFilter extends Instance<typeof AdministrationFilterModel> { }