// import { doLogout } from "../account/account-service";
import logoutManager from "./account/logout-manager";
import { ApiResponseCode, API_TIMEOUT } from "./api-constants";
import ApiError from "./api-error";

export class ApiService {

    private baseUrl = process.env.REACT_APP_BASE_URL;

    private static token?: string = undefined;

    public static updateToken(token: string) {
        this.token = token;
    }

    public static clearToken() {
        this.token = undefined;
    }

    private getHeaders = () => {
        const headers: any = {
            Accept: "application/ld+json",
            'Content-Type': 'application/json'
        };

        const token = this.getToken();
        if (token) {
            headers["Authorization"] = `Bearer ${token}`;
        }

        return headers;
    };

    protected getPath = (path: string): string => this.baseUrl + path;
    protected getToken = () => ApiService.token;

    protected post = (path: string, bodyParams: any, queryParams?: any, logoutOnAnauthorized = true): Promise<Response> => {
        return this.executeRequest(
            path,
            {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify(bodyParams)
            },
            queryParams,
            logoutOnAnauthorized
        );
    };

    protected put = (path: string, bodyParams: any, queryParams?: any, logoutOnAnauthorized = true): Promise<Response> => {
        return this.executeRequest(
            path,
            {
                method: 'PUT',
                headers: this.getHeaders(),
                body: JSON.stringify(bodyParams),
            },
            queryParams,
            logoutOnAnauthorized
        );
    };

    protected get = (path: string, queryParams?: any, logoutOnAnauthorized = true) => {
        return this.executeRequest(
            path,
            {
                method: 'GET',
                headers: this.getHeaders(),
            },
            queryParams,
            logoutOnAnauthorized
        );
    };

    protected downloadFile = (path: string, filename: string, accept: string, queryParams?: any) => {

        let url = path;
        if (queryParams) {
            url +=
                '?' +
                Object.keys(queryParams)
                    .map(key => `${key}=${queryParams[key]}`)
                    .join('&');
        }

        const headers = {
            ...this.getHeaders(),
            'Accept': accept,
        };
        delete headers['Content-Type'];
        return fetch(url, {
            method: 'GET',
            headers
        })
            .then((response) => {
                if (response.ok) {
                    return response;
                } else {
                    throw new Error('');
                }
            })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download', filename
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();
            });
    };

    public getImageBlob(path: string, queryParams?: any) {
        let url = path;
        if (queryParams) {
            url +=
                '?' +
                Object.keys(queryParams)
                    .map(key => `${key}=${queryParams[key]}`)
                    .join('&');
        }

        const headers = {
            ...this.getHeaders(),
            'Accept': 'image/*',
        };
        delete headers['Content-Type'];
        return fetch(url, {
            method: 'GET',
            headers
        })
            .then((response) => {
                if (response.ok) {
                    return response;
                } else {
                    throw new Error('');
                }
            })
            .then((response) => response.blob());
    }

    private async executeRequest(
        path: string,
        requestParams: RequestInit,
        queryParams?: any,
        logoutOnAnauthorized = true,
        timeout: number = API_TIMEOUT
    ): Promise<Response> {
        let url = this.getPath(path);
        if (queryParams) {
            url +=
                '?' +
                Object.keys(queryParams)
                    .map(key => `${key}=${queryParams[key]}`)
                    .join('&');
        }
        const headers = this.getHeaders();
        const request = {
            url,
            headers,
            method: requestParams.method,
            body: requestParams.body,
        };

        const controller =
            typeof AbortController === 'function'
                ? new AbortController()
                : null;
        const signal = controller && controller.signal;

        const params: RequestInit = {
            body: request.body,
            headers: request.headers,
            method: request.method,
        };


        return Promise.race([
            fetch(request.url, { ...params, signal }),
            new Promise<Response>((resolve, reject) => {
                setTimeout(() => {
                    if (controller) {
                        controller.abort();
                    }
                    reject(new ApiError(ApiResponseCode.TIMEOUT));
                }, timeout);
            })
        ]).then(async response => {
            if (response.status === ApiResponseCode.UNAUTHORIZED) {
                if (logoutOnAnauthorized) {
                    logoutManager.logout();
                }
                return Promise.reject();
            } else if (response.ok) {
                return Promise.resolve(response);
            }
            return Promise.reject();
        });
    }

}