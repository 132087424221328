export default class ApiError extends Error {
    private _errorCode: number;
    private _errorTitle: string;

    get title() {
        return this._errorTitle;
    }

    get code() {
        return this._errorCode;
    }

    constructor(code: number, title?: string, message?: string) {
        super(message);

        this._errorCode = code;
        this._errorTitle = title || '';

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ApiError);
        }

        Object.setPrototypeOf(this, ApiError.prototype);
    }
}


export enum ApiErrorDescription {
    USER_FORCE_CHANGE_PASSWORD = '4xx_USER-FORCE-CHANGE-PASSWORD'
}