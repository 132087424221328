import { defineMessages } from 'react-intl';

export default defineMessages({
    "tableHeaderCPO": {
        "id": "common.list.tableHeaderCPO",
        "defaultMessage": "CPO",
        "description": ""
    },
     "tableHeaderID": {
        "id": "common.list.tableHeaderID",
        "defaultMessage": "ID",
        "description": ""
    },
     "tableHeaderContract": {
        "id": "common.list.tableHeaderContract",
        "defaultMessage": "Contratto",
        "description": ""
    },
     "tableHeaderStartValidDate": {
        "id": "common.list.tableHeaderStartValidDate",
        "defaultMessage": "Valido da",
        "description": ""
    },
     "tableHeaderEndValidDate": {
        "id": "common.list.tableHeaderEndValidDate",
        "defaultMessage": "Valido a",
        "description": ""
    },
     "tableHeaderKwhDelivered": {
        "id": "common.list.tableHeaderKwhDelivered",
        "defaultMessage": "Kwh erogati",
        "description": ""
    },
     "tableHeaderNumberOfCharges": {
        "id": "common.list.tableHeaderNumberOfCharges",
        "defaultMessage": "N. Ricariche",
        "description": ""
    },
     "tableHeaderCostsAmount": {
        "id": "common.list.tableHeaderCostsAmount",
        "defaultMessage": "Costi {currency}",
        "description": ""
    },
     "tableHeaderAmoundEarned": {
        "id": "common.list.tableHeaderAmoundEarned",
        "defaultMessage": "Guadagni {currency}",
        "description": ""
    },
    "tableHeaderActions": {
        "id": "common.list.tableHeaderActions",
        "defaultMessage": "Azioni",
        "description": ""
    },
    "tableHeaderPaymentDebit": {
        "id": "common.list.tableHeaderPaymentDebit",
        "defaultMessage": "Importo dovuto ({currency})",
        "description": ""
    },
    "tableHeaderPaymentCollections": {
        "id": "common.list.tableHeaderPaymentCollections",
        "defaultMessage": "Importo riscosso ({currency})",
        "description": ""
    },
    "tableHeaderPaymentEarnings": {
        "id": "common.list.tableHeaderPaymentEarnings",
        "defaultMessage": "Ricavo ({currency})",
        "description": ""
    },
    "tableHeaderDateAndTime": {
        "id": "containers.transactions.tableHeaderDateAndTime",
        "defaultMessage": "Data e ora",
        "description": ""
    },
    "tableHeaderTotal": {
        "id": "containers.transactions.tableHeaderTotal",
        "defaultMessage": "Totale",
        "description": ""
    },
    "tableHeaderName": {
        "id": "containers.transactions.tableHeaderName",
        "defaultMessage": "Nome",
        "description": ""
    },
    "tableHeaderCountry": {
        "id": "containers.transactions.tableHeaderCountry",
        "defaultMessage": "Paese",
        "description": ""
    },
    "tableHeaderRole": {
        "id": "containers.transactions.tableHeaderRole",
        "defaultMessage": "Ruolo",
        "description": ""
    },
    "tableHeaderEmail": {
        "id": "containers.transactions.tableHeaderEmail",
        "defaultMessage": "Email",
        "description": ""
    },
    "tableHeaderContactNumber": {
        "id": "containers.transactions.tableHeaderContactNumber",
        "defaultMessage": "Recapito",
        "description": ""
    },
    "tableHeaderContractType": {
        "id": "containers.transactions.tableHeaderContractType",
        "defaultMessage": "Tipo",
        "description": ""
    },
    "tableHeaderContractStatus": {
        "id": "containers.transactions.tableHeaderContractStatus",
        "defaultMessage": "Stato",
        "description": ""
    },
    "tableHeaderContractPeriod": {
        "id": "containers.transactions.tableHeaderContractPeriod",
        "defaultMessage": "Periodo",
        "description": ""
    },
    "tableHeaderContractFee": {
        "id": "containers.transactions.tableHeaderContractFee",
        "defaultMessage": "Rincaro {currency}",
        "description": ""
    },
    "tableHeaderCurrency": {
        "id": "containers.transactions.tableHeaderCurrency",
        "defaultMessage": "Valuta",
        "description": ""
    },
    "tableHeaderCableConnector": {
        "id": "containers.transactions.tableHeaderCableConnector",
        "defaultMessage": "Connettore",
        "description": ""
    },
    "tableHeaderPower": {
        "id": "containers.transactions.tableHeaderPower",
        "defaultMessage": "Potenza",
        "description": ""
    },
    "tableHeaderRange": {
        "id": "containers.transactions.tableHeaderRange",
        "defaultMessage": "Intervallo",
        "description": ""
    },
    "tableHeaderEvseId": {
        "id": "containers.transactions.tableHeaderPower",
        "defaultMessage": "EVSE IDs",
        "description": ""
    },
    "tableHeaderNotes": {
        "id": "containers.transactions.tableHeaderNotes",
        "defaultMessage": "Note",
        "description": ""
    },
});