import { defineMessages } from 'react-intl';

export default defineMessages({
    faresTableTitle: {
        id: "containers.contractDetails.faresTableTitle",
        defaultMessage: "Tariffe",
        description: "Titolo della tabella nella pagina dei dettagli di un contratto"
    },
    contractDetailsSummaryTitle: {
        id: "containers.contractDetails.contractDetailsSummaryTitle",
        defaultMessage: "Dati contratto",
        description: "Titolo della sezione con le informazioni del contratto"
    },
    AccordionTitle: {
        id: "containers.contractDetails.AccordionTitle",
        defaultMessage: "Gruppo ",
        description: "Titolo delle sezioni delle tabelle delle tariffe per gruppo"
    },
    validityTimeRangeLabel: {
        id: "containers.contractDetails.validityTimeRangeLabel",
        defaultMessage: "Periodo di validità",
        description: "Label per il periodo di validità del contratto"
    },
    baseFeeLabel: {
        id: "containers.contractDetails.baseFeeLabel",
        defaultMessage: "Rincaro base",
        description: "Label per il rincaro base di un contratto"
    },
    contractCreatedOnLabel: {
        id: "containers.contractDetails.contractCreatedOnLabel",
        defaultMessage: "Creato",
        description: "Label per la data di creazione del contratto"
    },
    contractLastUpdateOnLabel: {
        id: "containers.contractDetails.contractLastUpdateOnLabel",
        defaultMessage: "Ultima modifica",
        description: "Label per la data in cui il contratto è stato modificato per l'ultima volta"
    },
    contractTypeLabel: {
        id: "containers.contractDetails.contractTypeLabel",
        defaultMessage: "Tipo di contratto",
        description: "Label per la tipologia del contratto"
    },
    contractRepresentativeLabel: {
        id: "containers.contractDetails.contractRepresentativeLabel",
        defaultMessage: "Referente",
        description: "Label per le info del referente di un contratto"
    },
    contractNotesForTheOperatorLabel: {
        id: "containers.contractDetails.contractNotesForTheOperatorLabel",
        defaultMessage: "Note",
        description: "Label per le note lasciate ad un operatore che sono state salvate nel contratto"
    },
    contractOperationInfo: {
        id: "containers.contractDetails.contractOperationInfo",
        defaultMessage: "{date} ore {time} da {operatorName} ({operatorEmail})",
        description: "Indica quando è stata svolta un'operazione su un contratto e da chi"
    },
    deleteFareDialogTitle: {
        id: "containers.contractDetails.deleteFareDialogTitle",
        defaultMessage: "Rimuovere la tariffa {fareId}?",
        description: "Titolo della dialog che permette di rimuovere una tariffa"
    },
    deleteFareDialogMessage: {
        id: "containers.contractDetails.deleteFareDialogMessage",
        defaultMessage: "L'operazione è irreversibile. Se il contratto è attivo, le ricariche già effettuate o in corso non verranno influenzate da questa operazione. \
        I dati potrebbero richiedere alcuni minuti per essere aggiornati in app.",
        description: "Messaggio mostrato nella dialog che permette di rimuovere una tariffa"
    },
    acSocketType: {
        id: "containers.contractDetails.acSocketType",
        defaultMessage: "AC",
        description: ""
    },
    dcSocketType: {
        id: "containers.contractDetails.dcSocketType",
        defaultMessage: "DC",
        description: ""
    },
    hpcUfcSocketType: {
        id: "containers.contractDetails.hpcUfcSocketType",
        defaultMessage: "HPC - UFC",
        description: ""
    },
    allSockets: {
        id: "containers.contractDetails.allSockets",
        defaultMessage: "Qualsiasi",
        description: "Messaggio mostrato per indicare tutti i tipi di connettori di una colonnina"
    },
    allPowerCharges: {
        id: "containers.contractDetails.allPowerCharges",
        defaultMessage: "Qualsiasi",
        description: "Messaggio mostrato per indicare tutti i range di potenza delle colonnine"
    },
    fareIsInSpecificTimeSlot: {
        id: "containers.contractDetails.fareInSpecificTimeSlot",
        defaultMessage: "Sì",
        description: "Messaggio mostrato per indicare che una tariffa è relativa ad uno slot temporale"
    },
    fareIsNotInSpecificTimeSlot: {
        id: "containers.contractDetails.fareIsNotInSpecificTimeSlot",
        defaultMessage: "No",
        description: "Messaggio mostrato per indicare che una tariffa non è relativa ad uno slot temporale"
    },
    allEvseIds: {
        id: "containers.contractDetails.allEvseIds",
        defaultMessage: "Tutti",
        description: "Messaggio mostrato per indicare che una tariffa si applica a tutti gli evse id del provider"
    },
    fareInflationValue: {
        id: "containers.contractDetails.fareInflationValue",
        defaultMessage: "Rincaro = {fareBaseInflation}{currency}",
        description: "Messaggio che mostra il rincaro di una tariffa"
    },
    powerRangeFromValueMessage: {
        id: "containers.contractDetails.powerRangeFromValueMessage",
        defaultMessage: "Da {value} Kwh",
        description: "Messaggio che mostra da quale potenza è valida una tariffa"
    },
    powerRangeToValueMessage: {
        id: "containers.contractDetails.powerRangeToValueMessage",
        defaultMessage: "Fino a {value} Kwh",
        description: "Messaggio che mostra fino a quale potenza è valida una tariffa"
    },
    manageFareDialogTitle: {
        id: "containers.contractDetails.manageFareDialogTitle",
        defaultMessage: "{providerName} {contractId} - Tariffa {fareId}",
        description: "Titolo della dialog che mostra i dati di una tariffa e permette di modificarli"
    },
    addFareDialogTitle: {
        id: "containers.contractDetails.addFareDialogTitle",
        defaultMessage: "{providerName} {contractId} - Aggiungi tariffa",
        description: "Titolo della dialog che permette di creare una tariffa"
    },
    fareDataSectionTitle: {
        id: "containers.contractDetails.fareDataSectionTitle",
        defaultMessage: "Tariffa",
        description: "Titolo della sezione contenente i dati generali di una tariffa"
    },
    fareInflationLabel: {
        id: "containers.contractDetails.fareInflationLabel",
        defaultMessage: "Rincaro ({currency})",
        description: "Label per il campo contenente il rincaro della tariffa"
    },
    fareIdLabel: {
        id: "containers.contractDetails.fareIdLabel",
        defaultMessage: "ID Tariffa",
        description: "Label per il campo contenente l'id della tariffa"
    },
    fareNameLabel: {
        id: "containers.contractDetails.fareNameLabel",
        defaultMessage: "Nome",
        description: "Label per il campo contenente il nome della tariffa"
    },
    fareCurrencyLabel: {
        id: "containers.contractDetails.fareCurrencyLabel",
        defaultMessage: "Valuta",
        description: "Label per il campo contenente la valuta della tariffa"
    },
    operatorNotesLabel: {
        id: "containers.contractDetails.operatorNotesLabel",
        defaultMessage: "Note per l'operatore",
        description: "Label per il campo contenente le note per l'operatore"
    },
    evseIdListLabel: {
        id: "containers.contractDetails.evseIdListLabel",
        defaultMessage: "EVSE IDs",
        description: "Label per il campo contenente le EVSE ID che si applicano alla tariffa"
    },
    operatorNotesPlaceholder: {
        id: "containers.contractDetails.operatorNotesPlaceholder",
        defaultMessage: "Campo opzionale",
        description: "Placeholder per il campo in cui si possono inserire note per l'operatore"
    },
    fareConditionsSectionTitle: {
        id: "containers.contractDetails.fareConditionsSectionTitle",
        defaultMessage: "Condizioni",
        description: "Titolo della sezione contenente le condizioni di una tariffa"
    },
    setSubCpoLabel: {
        id: "containers.contractDetails.setSubCpoLabel",
        defaultMessage: "Imposta un Sub-CPO",
        description: "Label della checkbox con cui indicare se si vuole impostare il sub cpo di una tariffa"
    },
    subCpoLabel: {
        id: "containers.contractDetails.subCpoLabel",
        defaultMessage: "Sub-CPO ID",
        description: "Label per il campo del sub cpo di una tariffa"
    },
    socketTypeLabel: {
        id: "containers.contractDetails.socketTypeLabel",
        defaultMessage: "Connettore",
        description: "Label per il connettore a cui si riferisce una tariffa"
    },
    minPowerRangeLabel: {
        id: "containers.contractDetails.minPowerRangeLabel",
        defaultMessage: "Potenza Min (Kw)",
        description: "Label per il campo relativo alla potenza minima a cui si riferisce una tariffa"
    },
    maxPowerRangeLabel: {
        id: "containers.contractDetails.maxPowerRangeLabel",
        defaultMessage: "Potenza Max (Kw)",
        description: "Label per il campo relativo alla potenza massima a cui si riferisce una tariffa"
    },
    conditionsNotes: {
        id: "containers.contractDetails.conditionsNotes",
        defaultMessage: '\
            (*) I campi sono facoltativi. Un valore vuoto corrisponde a nessun limite.{br}\
            (**) Per impostare più fascie orarie sullo stesso intervallo aggiungilo più volte: es. Lunedì 09:00 - 13:00; Lunedì 14:00 - 18:00\
        ',
        description: "Messaggio che mostra cosa indicano gli asterischi usati in alcuni dei campi"
    },
    valid24hLabel: {
        id: "containers.contractDetails.valid24hLabel",
        defaultMessage: 'Valido tutti i giorni, tutto il giorno',
        description: "Label della checkbox che indica se le condizioni si applicano 24/7 oppure no"
    },
    dayIntervalLabel: {
        id: "containers.contractDetails.dayIntervalLabel",
        defaultMessage: 'Intervallo',
        description: "Label per i campi che rappresentano i giorni in cui si deve applicare una tariffa"
    },
    timeIntervalLabel: {
        id: "containers.contractDetails.timeIntervalLabel",
        defaultMessage: 'Fascia oraria (da/a)**',
        description: "Label per i campi che rappresentano le fasce orarie in cui si deve applicare una tariffa"
    },
    wrongStartTimeIntervalMessage: {
        id: "containers.contractDetails.wrongStartTimeIntervalMessage",
        defaultMessage: 'La data di inizio validità deve essere precedente a quella di fine validità',
        description: "Messaggio di errore mostrato nel caso in cui gli orari di validità inseriti non sono corretti"
    },
    wrongChargingPowerRangeMessage: {
        id: "containers.contractDetails.wrongChargingPowerRangeMessage",
        defaultMessage: 'I valori di potenza minima e massima sono inconsistenti fra di loro',
        description: "Messaggio di errore mostrato nel caso in cui il range di potenza inserito non è corretto"
    },
    fareSessionCostsSectionTitle: {
        id: "containers.contractDetails.fareSessionCostsSectionTitle",
        defaultMessage: "Costi di sessione ({value} {unitOfMeasure})",
        description: "Titolo della sezione contenente i costi di sessione di una tariffa"
    },
    fareSessionCostsSectionDescription: {
        id: "containers.contractDetails.fareSessionCostsSectionDescription",
        defaultMessage: "Eventuali costi fissi o al minuto legati alla sessione",
        description: "Descrizione della sezione contenente i costi di sessione di una tariffa"
    },
    contractFareInflationType: {
        id: "containers.contractDetails.contractFareInflationType",
        defaultMessage: "Contratto",
        description: ""
    },
    customFareInflationType: {
        id: "containers.contractDetails.customFareInflationType",
        defaultMessage: "Personalizzato",
        description: ""
    },
    dynamicFareCostType: {
        id: "containers.contractDetails.dynamicFareCostType",
        defaultMessage: "Dinamico",
        description: ""
    },
    staticFareCostType: {
        id: "containers.contractDetails.staticFareCostType",
        defaultMessage: "Fisso",
        description: ""
    },
    costTypeLabel: {
        id: "containers.contractDetails.costTypeLabel",
        defaultMessage: "Tipo di costo",
        description: "Label della select che permette di selezionare che tipologia di costo di vuole impostare"
    },
    startCostFromLabel: {
        id: "containers.contractDetails.startCostFromLabel",
        defaultMessage: "Conteggia da",
        description: "Label della select che permette di selezionare quando deve iniziare un costo di una tariffa se dinamica"
    },
    setCostWhenLabel: {
        id: "containers.contractDetails.setCostWhenLabel",
        defaultMessage: "Conteggia a",
        description: "Label della select che permette di selezionare quando deve iniziare un costo di una tariffa se a costo fisso"
    },
    setAdditionalConditionsLabel: {
        id: "containers.contractDetails.setAdditionalConditionsLabel",
        defaultMessage: "Imposta condizioni aggiuntive",
        description: "Label della checkbox che determina se si vogliono impostare condizioni aggiuntive sul costo di una tariffa"
    },
    sessionMomentChargingStart: {
        id: "containers.contractDetails.sessionMomentChargingStart",
        defaultMessage: "Inizio ricarica",
        description: ""
    },
    sessionMomentChargingEnd: {
        id: "containers.contractDetails.sessionMomentChargingEnd",
        defaultMessage: "Fine ricarica",
        description: ""
    },
    sessionMomentSessionStart: {
        id: "containers.contractDetails.sessionMomentSessionStart",
        defaultMessage: "Inizio sessione",
        description: ""
    },
    sessionMomentSessionEnd: {
        id: "containers.contractDetails.sessionMomentSessionEnd",
        defaultMessage: "Fine sessione",
        description: ""
    },
    staticPricingReferenceUnit: {
        id: "containers.contractDetails.staticPricingReferenceUnit",
        defaultMessage: "Costo fisso",
        description: ""
    },
    kwhPricingReferenceUnit: {
        id: "containers.contractDetails.kwhPricingReferenceUnit",
        defaultMessage: "Kw/h",
        description: ""
    },
    minutesPricingReferenceUnit: {
        id: "containers.contractDetails.minutesPricingReferenceUnit",
        defaultMessage: "Minuti",
        description: ""
    },
    hoursPricingReferenceUnit: {
        id: "containers.contractDetails.hoursPricingReferenceUnit",
        defaultMessage: "Ore",
        description: ""
    },
    costPricingReferenceUnitLabel: {
        id: "containers.contractDetails.costPricingReferenceUnitLabel",
        defaultMessage: "Unità di misura",
        description: "Label per la select che permette di selezionare l'unita di misura di uno dei costi della tariffa"
    },
    priceValueLabel: {
        id: "containers.contractDetails.priceValueLabel",
        defaultMessage: "Prezzo unitario",
        description: "Label per la l'edit text nel quale si inserisce il costo"
    },
    minimumReferenceUnitLabel: {
        id: "containers.contractDetails.minimumReferenceUnitLabel",
        defaultMessage: "Unità minime conteggiate",
        description: "Label per l'edit text nel quale si inseriscono le unità minime conteggiate'"
    },
    kwhGracePeriod: {
        id: "containers.contractDetails.kwhGracePeriod",
        defaultMessage: "Kw/h",
        description: ""
    },
    minutesGracePeriod: {
        id: "containers.contractDetails.minutesGracePeriod",
        defaultMessage: "Minuti",
        description: ""
    },
    hoursGracePeriod: {
        id: "containers.contractDetails.hoursGracePeriod",
        defaultMessage: "Ore",
        description: ""
    },
    gracePeriodPricingReferenceUnitLabel: {
        id: "containers.contractDetails.gracePeriodPricingReferenceUnitLabel",
        defaultMessage: "Avvia dopo",
        description: "Label per la select nella quale si seleziona l'unità di misura del periodo non considerato"
    },
    endCostWhenLabel: {
        id: "containers.contractDetails.endCostWhenLabel",
        defaultMessage: "Termina a",
        description: "Label della select che permette di selezionare quando deve terminare il costo di una tariffa se dinamica"
    },
    fareChargingCostsSectionTitle: {
        id: "containers.contractDetails.fareChargingCostsSectionTitle",
        defaultMessage: "Costo dell'energia ({value} {unitOfMeasure})",
        description: "Titolo della sezione contenente i costi di ricarica di una tariffa"
    },
    fareChargingCostsSectionDescription: {
        id: "containers.contractDetails.fareChargingCostsSectionDescription",
        defaultMessage: "Costo fisso o al Kw applicato alla ricarica",
        description: "Descrizione della sezione contenente i costi di ricarica di una tariffa"
    },
    fareParkingCostsSectionTitle: {
        id: "containers.contractDetails.fareParkingCostsSectionTitle",
        defaultMessage: "Costo del parcheggio ({value} {unitOfMeasure})",
        description: "Titolo della sezione contenente i costi di parcheggio di una tariffa"
    },
    fareParkingCostsSectionDescription: {
        id: "containers.contractDetails.fareChargingCostsSectionDescription",
        defaultMessage: "Tariffa fissa o al minuto applicata per il posteggio del veicolo e l'occupazione della stazione di ricarica",
        description: "Descrizione della sezione contenente i costi di parcheggio di una tariffa"
    },
    fareOtherCostsSectionTitle: {
        id: "containers.contractDetails.fareOtherCostsSectionTitle",
        defaultMessage: "Altri costi",
        description: "Titolo della sezione contenente i costi aggiuntivi di una tariffa"
    },
    fareOtherCostsSectionDescription: {
        id: "containers.contractDetails.fareOtherCostsSectionDescription",
        defaultMessage: "Eventuali costi aggiuntivi personalizzabili. NOTA: I costi aggiuntivi non sono visibili in app; appaiono solo nel contratto scaricabile",
        description: "Descrizione della sezione contenente i costi aggiuntivi di una tariffa"
    },
    addOtherFareLabel: {
        id: "containers.contractDetails.addOtherFareLabel",
        defaultMessage: "Aggiungi tariffa di",
        description: "Label della select che permette di aggiungere o rimuovere un costo aggiuntivo"
    },
    costsSummaryText: {
        id: "containers.contractDetails.costsSummaryText",
        defaultMessage: "Costi",
        description: ""
    },
    otherFeeText: {
        id: "containers.contractDetails.otherFeeText",
        defaultMessage: "Altri",
        description: ""
    },
    sessionFareFeeType: {
        id: "containers.contractDetails.sessionFareFeeType",
        defaultMessage: "Sessione",
        description: ""
    },
    chargingFareFeeType: {
        id: "containers.contractDetails.chargingFareFeeType",
        defaultMessage: "Energia",
        description: ""
    },
    parkingFareFeeType: {
        id: "containers.contractDetails.parkingFareFeeType",
        defaultMessage: "Parcheggio",
        description: ""
    },
    emptyFareFeeType: {
        id: "containers.contractDetails.emptyFareFeeType",
        defaultMessage: "--",
        description: ""
    },
    fareUpdatedSuccessMessage: {
        id: "containers.contractDetails.fareUpdatedSuccessMessage",
        defaultMessage: "Tariffa impostata",
        description: ""
    },
    fareCreatedSuccessMessage: {
        id: "containers.contractDetails.fareCreatedSuccessMessage",
        defaultMessage: "Tariffa aggiunta",
        description: ""
    },
    fareWithSameIdAlreadyExistsMessage: {
        id: "containers.contractDetails.fareWithSameIdAlreadyExistsMessage",
        defaultMessage: "Nel sistema è già presente una tariffa con lo stesso ID",
        description: "Messaggio di errore mostrato quando si prova ad inserire un id di una tariffa che già esiste",
    },
    confirmFareUpdateDialogTitle: {
        id: "containers.contractDetails.confirmFareUpdateDialogTitle",
        defaultMessage: "Modificare la tariffa {fareId}?",
        description: "Titolo della dialog che richiede la conferma della modifica di una tariffa di un contratto attivo",
    },
    confirmFareUpdateDialogMessage: {
        id: "containers.contractDetails.confirmFareUpdateDialogMessage",
        defaultMessage: "L'operazione è irreversibile. Le ricariche già effettuate o in corso sulla tariffa non verranno influenzate da questa operazione. I dati potrebbero richiedere alcuni minuti per essere aggiornati in app.",
        description: "Messaggio della dialog che richiede la conferma della modifica di una tariffa di un contratto attivo",
    }
});