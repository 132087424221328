import { AxiosPromise } from "axios";
import apiManager from "../../common/api/api-manager";
import { parseDateFromServer } from "../../common/api/common-parsers";
import { ListApiService } from "../../common/api/items-list/list-api-service";
import { OrderCriteria } from "../../common/models/order-criteria";
import { MobileUser, UpdateMobileUserStatusStatusEnum } from "../../_generated/api/api";
import { AppUser, AppUserModel, MobileUserStatus } from "../model/app-user";
import { UsersFilter } from "../model/users-filters";

class UsersApiService extends ListApiService<AppUser, UsersFilter, any> {

    downloadItemsList(offset: number, limit: number, filters: UsersFilter, orderCriteria?: OrderCriteria, accept?: string): AxiosPromise<any> {
        
        const config = accept === undefined ? undefined : {
            headers: {
                'Accept': accept
            }
        };
        return apiManager.mobileUsersApi.getMobileUsersData(apiManager.idToken,
            filters.businessUser, filters.privateUser, offset, limit, filters.userStatus,
            orderCriteria ? `${orderCriteria.key}:${orderCriteria.sortOrder.toLowerCase()}` : undefined,
            filters.email || undefined, config);
    }

    parseListItem = (apiResponse: MobileUser) => AppUserModel.create({
        id: apiResponse.id,
        email: apiResponse.email,
        lastChargeDate: parseDateFromServer(apiResponse.lastChargeDate),
        registrationDate: parseDateFromServer(apiResponse.registrationDate),
        status: apiResponse.status as MobileUserStatus,
        amountDue: apiResponse.amountDue,
        amountPaid: apiResponse.amountPaid,
        businessUser: apiResponse.businessUser
    });

    parseSummary = () => undefined;



    
    blockUser = (user: AppUser) => {
        return apiManager.mobileUsersApi.updateMobileUserStatus(user.id, apiManager.idToken,
            { status: UpdateMobileUserStatusStatusEnum.Blocked });
    };
    restoreUser = (user: AppUser) => {
        return apiManager.mobileUsersApi.updateMobileUserStatus(user.id, apiManager.idToken,
            { status: UpdateMobileUserStatusStatusEnum.Active });
    };
    suspendUser = (user: AppUser) => {
        return apiManager.mobileUsersApi.updateMobileUserStatus(user.id, apiManager.idToken,
            { status: UpdateMobileUserStatusStatusEnum.Suspended });
    };
    deleteUser = (user: AppUser) => {
        return apiManager.mobileUsersApi.deleteMobileUser(user.id, apiManager.idToken);
    };

}


export const usersApiService = new UsersApiService('users');