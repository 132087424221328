import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { AppButtonVariant } from "../../../../../common/components/button/app-button";
import { BaseDialog } from "../../../../../common/components/dialogs/base-dialog";
import commonMessages from "../../../../../common/messages/common.messages";
import contractsMessages from "../../../../contracts.messages";

export interface ManageBlockContractDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirmButtonClick: () => void;
    isBlocking: boolean;
    isOperationRunning: boolean;
    contractId: string;
}

export const ManageBlockContractDialog = (props: ManageBlockContractDialogProps) => {
    const { formatMessage } = useIntl();
    
    const data = useMemo(() => {
        if(props.isBlocking) {
            return {
                title: contractsMessages.blockContractDialogTitle,
                body: contractsMessages.blockContractDialogMessage,
                confirmButton: commonMessages.blockButton,
                buttonType: AppButtonVariant.Tertiary
            };
        } else {
            return {
                title: contractsMessages.unlockContractDialogTitle,
                body: contractsMessages.unlockContractDialogMessage,
                confirmButton: commonMessages.unlockButton,
                buttonType: AppButtonVariant.Primary
            };
        }
    }, [props.isBlocking]);
    
    return <BaseDialog 
        title={formatMessage(data.title, {contractId: props.contractId})}
        open={props.open}
        onClose={props.onClose}
        containerClassName="manage-block-contract-dialog"
        buttons={[
            {
                variant: AppButtonVariant.Dark,
                label: formatMessage(commonMessages.cancelButton),
                onClick: props.onClose,
                disabled: props.isOperationRunning
            },
            {
                variant: data.buttonType,
                label: formatMessage(data.confirmButton),
                onClick: props.onConfirmButtonClick,
                disabled: props.isOperationRunning
            },
        ]}
    >
        <span>{formatMessage(data.body)}</span>
    </BaseDialog>;
};