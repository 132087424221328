import React from 'react';
import { useIntl } from 'react-intl';
import { StyledTimePicker } from '../../../../../../common/components/input/styled-time-picker';
import contractDetailsMessages from '../../../../../contract-details.messages';

import AddIcon from '../../../../../../assets/images/icon-add.svg';
import DeleteIcon from '../../../../../../assets/images/icon-delete.svg';

interface TimeIntervalSelectorProps {
    startTime: Date;
    endTime: Date;

    onStartTimeChange: (time: Date) => void;
    onEndTimeChange: (time: Date) => void;

    onAddTimeIntervalClick?: () => void;
    onDeleteTimeIntervalClick?: () => void;

    showAddIntervalButton: boolean;
    showDeleteIntervalButton: boolean;
    
    disabled: boolean;
    isTimeIntervalWrong?: boolean;
    showLabel?: boolean;
}

export const TimeIntervalSelector = (props: TimeIntervalSelectorProps) => {
    
    const { formatMessage } = useIntl();
    
    return <div className="cc-input-box time-interval-selector">
        {props.showLabel && <span>{formatMessage(contractDetailsMessages.timeIntervalLabel)}</span>}

        <div  className='input-fields-row'>
            <StyledTimePicker 
                selectedTime={props.startTime}
                onTimeSelected={props.onStartTimeChange}
                disabled={props.disabled}
            />
            <StyledTimePicker 
                selectedTime={props.endTime}
                onTimeSelected={props.onEndTimeChange}
                disabled={props.disabled}
            />

            { 
                props.showDeleteIntervalButton
                && !props.disabled
                && <img src={DeleteIcon} alt="" onClick={props.onDeleteTimeIntervalClick}/> 
            }
            { 
                props.showAddIntervalButton
                && !props.disabled
                && <img src={AddIcon} alt="" onClick={props.onAddTimeIntervalClick}/> 
            }
        </div>

        {
            props.isTimeIntervalWrong && 
                <span className='error-label'>
                    {formatMessage(contractDetailsMessages.wrongStartTimeIntervalMessage)}
                </span>
        }

    </div>;
};