import { Instance, types } from "mobx-state-tree";
import { ResourceType } from "../../common/config/constants";
import { TimePeriodFilter } from "../../common/models/filters";
import { createPagedListStoreModel } from "../../common/stores/paged-list/paged-list-store";
import { TransactionModel } from "../../transaction/model/transaction";
import { userDetailApiService } from "../api/user-detail-api-service";
import { UserDetailFilterModel } from "../model/user-detail-filters";

const UserDetailSummaryModel = types.model({
    totalAmountCollected: types.number,
    totalAmountDue: types.number,
}).views((self) => ({
    get totalAmountEarned() {
        return self.totalAmountCollected - self.totalAmountDue;
    }
}));

const UserDetailModel = types.model({
    userId: types.string,
    userEmail: types.string
});

export const UserDetailStoreModel = types.compose(createPagedListStoreModel<any,any,any>({
    type: TransactionModel,
    apiService: userDetailApiService,
    typeLabel: ResourceType.Users,
    summaryType: UserDetailSummaryModel,
    filterType: UserDetailFilterModel,
    filters: () => UserDetailFilterModel.create({
        timePeriod: TimePeriodFilter.THIS_MONTH
    })

}), UserDetailModel)
    .named('UserDetailStoreModel');

    export interface UserDetail extends Instance<typeof UserDetailModel> { }
    export interface UserDetailStore extends Instance<typeof UserDetailStoreModel> { }