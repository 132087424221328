import React from 'react';
import { IntlProvider } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import makeInspectable from 'mobx-devtools-mst';
import DateFnsUtils from '@date-io/date-fns';

import { onIntlError } from './common/utils/intl';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { PrivateRoute } from './common/components/navigation/private-route';
import { createStore, StoreProvider } from './common/stores/root-store';
import { ProviderPagedList } from './providers/ui/providers/list/provider-paged-list';
import { LoginPage } from './login/ui/login-page';
import { FirstLoginPage } from './login/ui/first-login-page';
import { Routes } from './common/routing/routes';
import { TransactionPagedList } from './transaction/ui/list/transaction-paged-list';
import { NotificationDialog } from './common/components/dialogs/notification-dialog';
import { UsersPagedList } from './users/ui/list/users-paged-list';
import { AdminPagedList } from './administration/ui/list/admin-paged-list';
import { UserDetailPage } from './users/ui/detail/user-detail-page';
import { PageNotFound } from './page-not-found/ui/page-not-found';
import { ChangePasswordPage } from './change-password/ui/change-password-page';
import { ContractsPagedList } from './providers/ui/contracts/list/contracts-paged-list';
import { ContractDetailsPagedList } from './providers/ui/contracts-detail/list/contract-details-paged-list';

import './App.scss';

const rootStore = createStore();
const { notificationStore, appUsersStore, userStore, providerStore } = rootStore;
if (process.env.NODE_ENV === 'development') {
  makeInspectable(rootStore);
}


function App() {

  return (
      <IntlProvider locale='it-IT' defaultLocale='it-IT' onError={onIntlError}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <AppRouter />
        </MuiPickersUtilsProvider>
      </IntlProvider>
  );
}


const AppRouter = observer(() => {

  return <div className="app">
    <StoreProvider value={rootStore}>
      <HashRouter>

        <Switch>
          <Route exact path={Routes.Home}>
            <Redirect to={Routes.Providers} />
          </Route>
          <PrivateRoute path={Routes.Transactions}>
            <TransactionPagedList isAdmin={userStore.isAdminLogged} />
          </PrivateRoute>
          <PrivateRoute path={Routes.ProviderContractDetail}>
            {providerStore.contractsStore?.selectedItem
              ? <ContractDetailsPagedList  
                    isAdmin={userStore.isAdminLogged} 
                />
              : <Redirect to={Routes.ProviderContracts} />}
          </PrivateRoute>
          <PrivateRoute path={Routes.ProviderContracts}>
            {providerStore.selectedItem
              ? <ContractsPagedList  
                    isAdmin={userStore.isAdminLogged} 
                />
              : <Redirect to={Routes.Providers} />}
          </PrivateRoute>
          <PrivateRoute path={Routes.Providers}>
            <ProviderPagedList isAdmin={userStore.isAdminLogged} />
          </PrivateRoute>
          <PrivateRoute path={Routes.Admin}>
            <AdminPagedList isAdmin={userStore.isAdminLogged} />
          </PrivateRoute>
          <PrivateRoute path={Routes.UserDetail}>
            {appUsersStore.selectedItem
              ? <UserDetailPage user={appUsersStore.selectedItem} />
              : <Redirect to={Routes.Users} />}
          </PrivateRoute>
          <PrivateRoute path={Routes.Users}>
            <UsersPagedList isAdmin={userStore.isAdminLogged} />
          </PrivateRoute>
          <Route path={Routes.ChangePassword}>
            <ChangePasswordPage />
          </Route>
          <Route path={Routes.FirstLogin}>
            <FirstLoginPage />
          </Route>
          <Route path={Routes.Login}>
            <LoginPage />
          </Route>
          <Route path='*'>
            <PageNotFound />
          </Route>
        </Switch>
      </HashRouter>

      <NotificationDialog
        notification={notificationStore.activeNotification}
        onClose={notificationStore.clearNotification} />

    </StoreProvider>
  </div>;
});


export default App;

