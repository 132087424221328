import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AppButtonVariant } from '../../../../common/components/button/app-button';
import { BaseDialog } from '../../../../common/components/dialogs/base-dialog';
import commonMessages from '../../../../common/messages/common.messages';

import messages from '../../../providers.messages';

export interface DeleteItemDialogProps {
    open: boolean
    isOperationRunning: boolean
    title: string
    onClose: () => void
    onConfirmButtonClick: () => void
}

export const DeleteItemDialog = (props: DeleteItemDialogProps) => {
    const { formatMessage } = useIntl();
    
    const onClose = useCallback(() => {
        if(!props.isOperationRunning)
            props.onClose();
    }, [props]);
    
    return <BaseDialog
        open={props.open}
        title={props.title}
        buttons={[
            {
                label: formatMessage(commonMessages.cancelButton),
                disabled: props.isOperationRunning,
                buttonClasses: AppButtonVariant.Dark,
                onClick: onClose
            },
            {
                label: formatMessage(commonMessages.deleteButton),
                disabled: props.isOperationRunning,
                buttonClasses: AppButtonVariant.Tertiary,
                onClick: props.onConfirmButtonClick
            },
        ]}
        onClose={onClose}
    >
        <span>{formatMessage(messages.deleteItemOperatorDialogBody)}</span>
    </BaseDialog>;

};