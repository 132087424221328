import React, { useEffect, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { onSnapshot } from 'mobx-state-tree';
import { Redirect } from 'react-router';

import { ProviderTableHeader } from './provider-table-header';
import { ProviderTableItem } from './provider-table-item';
import { SectionPagedList } from '../../common/section-paged-list/section-paged-list';
import { ProvidersSectionHeader } from './provider-section-header';
import { DeleteItemDialog } from '../../common/dialog/delete-item-dialog';
import { ApiRequestState } from '../../../../common/api/api-request';
import { createServerErrorNotification, createSuccessNotification } from '../../../../common/stores/notifications-store';
import { ManageProviderDialog } from '../dialog/manage-provider-dialog';

import { Provider } from '../../../model/provider';
import { OrderManagerProps } from '../../../../common/models/order-criteria';
import { ProvidersFilterModel } from '../../../model/providers-filter';
import { UserRole } from '../../../../common/models/user';
import { useStore } from '../../../../common/stores/root-store';
import { Routes } from '../../../../common/routing/routes';
import { ContractsPagedListRouteState } from '../../contracts/list/contracts-paged-list';
import { supportedCountries } from '../../../../common/models/country';
import { ProviderError } from '../../../store/provider-store';

import messages from '../../../providers.messages';

import './provider-paged-list.scss';

interface ProviderPagedListProps {
    isAdmin: boolean;
}

export const ProviderPagedList = observer((props: ProviderPagedListProps) => {

    /* ---------------- Setup ---------------- */

    const { providerStore, notificationStore, userStore } = useStore();
    const intl = useIntl();
    const { formatMessage } = intl;

    const [hasToCreateNewContract, setHasToCreateNewContract] = useState(false);

    useEffect(() => {

        setHasToCreateNewContract(false);
        
        providerStore.deselectItem();
        providerStore.reload();

    }, [providerStore]);

    const openProviderContracts = useCallback(
        (provider: Provider) => providerStore.openItemDetail(provider), 
        [providerStore]
    );

    /* ---------------- Add new provider ---------------- */

    const [showManageProviderDialog, setShowManageProviderDialog] = useState(false);
    const onAddNewButtonClick = useCallback(() => setShowManageProviderDialog(true), []);
    const onCloseCreateProviderDialog = useCallback(
        () => setShowManageProviderDialog(false),
        []
    );
    const onCreateProviderSubmitted = useCallback((provider: Provider) => {
        const dispose = onSnapshot(providerStore, (snapshot) => {
            let managed = false;

            if (snapshot.manageProviderRequestState === ApiRequestState.COMPLETED) {
                notificationStore.showNotification(createSuccessNotification(
                    intl,
                    messages.addProviderDialogSuccessMessage
                )).then(() => openProviderContracts(provider));
                managed = true;
                setHasToCreateNewContract(true);
            } else if (snapshot.manageProviderRequestState === ApiRequestState.FAILED) {
                notificationStore.showNotification(createServerErrorNotification(
                    intl,
                    providerStore.providerError === ProviderError.PROVIDER_WITH_SAME_ID_ALREADY_EXISTS 
                        ? messages.providerWithSameIdAlreadyExistsMessage
                        : undefined
                ));
                managed = true;
            }

            if (managed) {
                dispose();
                setShowManageProviderDialog(false);
                providerStore.clearManageProviderRequestState();
                providerStore.clearProviderError();
            }
        });
        providerStore.addProvider(provider);
    }, [intl, notificationStore, openProviderContracts, providerStore]);

    /* ---------------- Update provider ---------------- */

    const [providerToUpdate, setProviderToUpdate] = useState<Provider>();
    const onUpdateButtonClick = useCallback((provider: Provider) => {
        setProviderToUpdate(provider);
        setShowManageProviderDialog(true);
    }, []);
    const onCloseUpdateProviderDialog = useCallback(() => {
        setShowManageProviderDialog(false);
        setProviderToUpdate(undefined);
    }, []);
    const onUpdateProviderSubmitted = useCallback((provider: Provider) => {
        const dispose = onSnapshot(providerStore, (snapshot) => {
            let managed = false;

            if (snapshot.manageProviderRequestState === ApiRequestState.COMPLETED) {
                notificationStore.showNotification(createSuccessNotification(intl));
                managed = true;
                setShowManageProviderDialog(false);
                setProviderToUpdate(undefined);
                providerStore.reload();
            } else if (snapshot.manageProviderRequestState === ApiRequestState.FAILED) {
                notificationStore.showNotification(createServerErrorNotification(
                    intl,
                    providerStore.providerError === ProviderError.PROVIDER_WITH_SAME_ID_ALREADY_EXISTS 
                        ? messages.providerWithSameIdAlreadyExistsMessage
                        : undefined
                ));
                managed = true;
            }

            if (managed) {
                dispose();
                providerStore.clearManageProviderRequestState();
                providerStore.clearProviderError();
            }
        });
        providerToUpdate && providerStore.updateProvider(providerToUpdate, provider);
    }, [intl, notificationStore, providerStore, providerToUpdate]);

    /* ---------------- Delete provider dialog ---------------- */

    const [providerToDelete, setProviderToDelete] = useState<Provider>();
    const [showProviderToDeleteDialog, setShowProviderToDeleteDialog] = useState(false);
    
    const onDeleteProviderClicked = useCallback((provider: Provider) => {
        setProviderToDelete(provider);
        setShowProviderToDeleteDialog(true);
    }, []);
    const onCloseDeleteProviderDialog = useCallback(() => 
        setShowProviderToDeleteDialog(false)
    , []);
    const onConfirmDeleteProviderSubmitted = useCallback(() => {
        const dispose = onSnapshot(providerStore, (snapshot) => {
            let managed = false;

            if (snapshot.deleteProviderRequestState === ApiRequestState.COMPLETED) {
                notificationStore.showNotification(createSuccessNotification(intl));
                managed = true;
                providerStore.onItemDeleted();
            } else if (snapshot.deleteProviderRequestState === ApiRequestState.FAILED) {
                notificationStore.showNotification(createServerErrorNotification(
                    intl
                ));
                managed = true;
            }

            if (managed) {
                dispose();
                setShowProviderToDeleteDialog(false);
                providerStore.clearDeleteProviderRequestState();
                providerStore.clearProviderError();
            }
        });
        providerToDelete && providerStore.deleteProvider(providerToDelete);
    }, [intl, notificationStore, providerStore, providerToDelete]);

    /* ---------------- Render page elements ---------------- */

    const applyFilters = useCallback(() => providerStore.applyFilters(), [providerStore]);
    const onFiltersChanged = useCallback(
        (newFilters) => providerStore.filters?.updateFilters(newFilters),
        [providerStore.filters]
    );

    const renderHeader = useCallback(() => <ProvidersSectionHeader
        availableCountries={supportedCountries}
        filters={providerStore.filters ?? ProvidersFilterModel.create()}
        applyFilters={applyFilters}
        onFiltersChanged={onFiltersChanged}
        canUserChangeCountryFilter={userStore.user?.role === UserRole.Admin}
    />, [
        applyFilters, onFiltersChanged, providerStore.filters, 
        userStore.user?.role
    ]);

    const renderTableHeader = useCallback((orderProps: OrderManagerProps) =>
        <ProviderTableHeader {...orderProps} />, []);

    const renderItem = useCallback((item: Provider, index: number) => {
        return <ProviderTableItem
            key={index}
            item={item}
            onItemClick={openProviderContracts}
            onDeleteItemClick={onDeleteProviderClicked}
            onUpdateItemClick={onUpdateButtonClick}
            canUserDeleteItem={item.canBeDeleted && userStore.user?.role !== UserRole.Operator}
            canUserUpdateItem={userStore.user?.role !== UserRole.Operator}
        />;
    }, [onDeleteProviderClicked, onUpdateButtonClick, openProviderContracts, userStore.user?.role]);

    /* ---------------- Layout ---------------- */

    return <>
        <SectionPagedList
            isAdmin={props.isAdmin}
            store={providerStore}
            tableClass='providers-table'

            renderSectionHeader={renderHeader}
            renderTableHeader={renderTableHeader}
            renderItem={renderItem}
            columnsCount={10}

            createButtonEnabled
            onAddNewButtonClick={onAddNewButtonClick}
        />

        <DeleteItemDialog 
            title={formatMessage(messages.deleteProviderOperatorDialogTitle, {providerName: providerToDelete?.cpoName})}
            open={showProviderToDeleteDialog}
            onClose={onCloseDeleteProviderDialog}
            isOperationRunning={providerStore.deleteProviderRequestState === ApiRequestState.RUNNING}
            onConfirmButtonClick={onConfirmDeleteProviderSubmitted}
        />

        <ManageProviderDialog 
            open={showManageProviderDialog}
            onClose={providerToUpdate ? onCloseUpdateProviderDialog : onCloseCreateProviderDialog}
            isOperationRunning={providerStore.manageProviderRequestState === ApiRequestState.RUNNING}
            onSubmitButtonClick={providerToUpdate ? onUpdateProviderSubmitted : onCreateProviderSubmitted}
            selectedCountryId={providerToUpdate?.country ?? undefined}
            provider={providerToUpdate}
        />

        { providerStore.selectedItem && <Redirect to={{
            pathname: Routes.ProviderContracts,
            state: { hasToCreateNewContract: hasToCreateNewContract } as ContractsPagedListRouteState
        }} /> }

    </>;
});