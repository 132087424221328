import React, { useCallback, useMemo, useRef, useState } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { createMuiTheme, Theme, ThemeProvider } from '@material-ui/core';

import { StyledInput } from '../input/styled-input';

import CalendarIcon from '../../../assets/images/icon-calendar.svg';
import { formatDate } from '../../utils/formatters';

interface StyledDatePickerProps {
    selectedDate?: Date
    firstAvailableDate?: Date
    lastAvailableDate?: Date
    onDateSelected?: (date: Date) => void
    label?: string
    dialogTheme?: Theme
    disabled?: boolean
}

const inputStyle: React.CSSProperties = {
    padding: 8
};

export const StyledDatePicker = (props: StyledDatePickerProps) => {

    const inputRef = useRef<HTMLInputElement>(null);
    
    const [opened, setOpened] = useState(false);

    const openMenu = useCallback(() => setOpened(true), []);
    const closeMenu = useCallback(() => {
        setOpened(false);
        inputRef.current?.blur();
    }, []);

    const updateValue = useCallback((date: Date | null) => 
        date && props.onDateSelected && props.onDateSelected(date),
    [props]);

    const muiTheme = useMemo(() => props.dialogTheme ?? createMuiTheme({
        palette: {
            primary: {
                main: '#333',
            }
        },
    }), [props.dialogTheme]);

    const renderInput = useCallback(() => {
        const value = props.selectedDate ? formatDate(props.selectedDate) : undefined;
        return <StyledInput 
            readOnly
            inputRef={inputRef}
            label={props.label} 
            value={value} 
            icon={CalendarIcon} 
            onIconClick={openMenu}
            onClick={openMenu}
            className='styled-date-picker'
            disabled={props.disabled}
        />;
    }, [openMenu, props.disabled, props.label, props.selectedDate]);

    return <ThemeProvider theme={muiTheme}>
        <KeyboardDatePicker
            disabled={props.disabled}
            placeholder={props.label}
            TextFieldComponent={renderInput}
            onClick={openMenu}
            onClose={closeMenu}
            open={opened}
            
            value={props.selectedDate}
            onChange={updateValue}
            minDate={props.firstAvailableDate}
            maxDate={props.lastAvailableDate}
            
            keyboardIcon={null}
            variant='dialog'
            inputVariant='outlined'
            
            inputProps={{
                style: inputStyle
            }}
        />
    </ThemeProvider>;
};