import React, { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { AppButtonVariant } from '../../../../common/components/button/app-button';
import { BaseDialog } from '../../../../common/components/dialogs/base-dialog';
import { StyledInput } from '../../../../common/components/input/styled-input';
import { StyledSelect } from '../../../../common/components/input/styled-select';
import { emptyFunction } from '../../../../common/utils/utils';

import { Provider, ProviderModel, SocketTypePowerRange, SocketTypePowerRangeModel } from '../../../model/provider';
import { nameFromCountryId, supportedCountries } from '../../../../common/models/country';
import { CheckedState, StyledCheckBox } from '../../../../common/components/input/styled-checkbox';

import messages from '../../../providers.messages';
import commonMessages from '../../../../common/messages/common.messages';

import './manage-provider-dialog.scss';
import { ChargingPowerRangeModel, RangeValueCondition, SocketType } from '../../../model/fare';
import { isNumber } from '../../../../common/utils/validators';

export interface ManageProviderDialogProps {
    open: boolean
    isOperationRunning: boolean
    onClose: () => void
    onSubmitButtonClick: (provider: Provider) => void
    selectedCountryId?: string; 
    provider?: Provider;
}

export const ManageProviderDialog = (props: ManageProviderDialogProps) => {
    
    const { formatMessage } = useIntl();

    const countries = useMemo(() => 
        supportedCountries.map(country => {
            const nameDescriptor = nameFromCountryId(country);
            return {
                id: country,
                label: nameDescriptor ? formatMessage(nameDescriptor) : country
            };
        }),
        [formatMessage]
    );

    const [countryId, setCountryId] = useState<string>('');

    const [cpoId, setCpoId] = useState<string>('');
    const onCpoIdChange: ChangeEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            setCpoId(event.currentTarget.value);
            if (event.currentTarget.value.length >= 2) {
                const countryId = event.currentTarget.value.substr(0, 2);
                
                if (supportedCountries.includes(countryId))
                    setCountryId(countryId);
            } 
        }, 
        [setCountryId]
    );

    const [cpoName, setCpoName] = useState<string>('');
    const onCpoNameChange: ChangeEventHandler<HTMLInputElement> = useCallback(
        event => setCpoName(event.currentTarget.value), 
        []
    );

    const [hpcPowerRange, setHpcPowerRange] = useState<SocketTypePowerRange>();
    const [hcpStartingPowerValue, setHcpStartingPowerValue] = useState<string>('');
    const onCheckboxToggle = useCallback(() => {
        if(hpcPowerRange) {
            setHpcPowerRange(undefined);
        } else {
            setHpcPowerRange(SocketTypePowerRangeModel.create({
                newSocketType: SocketType.HPC_UFC,
                socketType: SocketType.DC,
                powerRange: ChargingPowerRangeModel.create({
                    min: {
                        value: Number(hcpStartingPowerValue),
                        condition: RangeValueCondition.INCLUSIVE
                    }
                })
            }));
        }
    }, [hpcPowerRange, hcpStartingPowerValue]);
    const onHcpStartingPowerValueChange: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
        const value = event.target.value;
        if (isNumber(value)) {
            if (value.endsWith('.')) {
                setHcpStartingPowerValue(value);
                setHpcPowerRange(SocketTypePowerRangeModel.create({
                    newSocketType: SocketType.HPC_UFC,
                    socketType: SocketType.DC,
                    powerRange: ChargingPowerRangeModel.create({
                        min: {
                            value: Number(value),
                            condition: RangeValueCondition.INCLUSIVE
                        }
                    })
                }));
                return;
            } else {
                if (value !== undefined && value !== null) {
                    setHcpStartingPowerValue(value);
                    setHpcPowerRange(SocketTypePowerRangeModel.create({
                        newSocketType: SocketType.HPC_UFC,
                        socketType: SocketType.DC,
                        powerRange: ChargingPowerRangeModel.create({
                            min: {
                                value: Number(value),
                                condition: RangeValueCondition.INCLUSIVE
                            }
                        })
                    }));
                    return;
                }
            }
        }
    }, []);

    const isAddButtonEnabled = useMemo(() => 
        cpoId && 
        cpoId.trim().length > 0 && 

        cpoName &&
        cpoName.trim().length > 0 &&

        (   
            // if hpc checkbox is checked (hpcPowerRange has value)
            // form is valid if hcpStartingPowerValue is not empty
            // otherwise don't consider it 
            hpcPowerRange ?
                hcpStartingPowerValue && hcpStartingPowerValue.trim().length > 0 :
                true
        )
    , [cpoId, cpoName, hpcPowerRange, hcpStartingPowerValue]);

    const onClose = useCallback(() => {
        if(!props.isOperationRunning)
            props.onClose();
    }, [props]);

    const onCreateButtonClick = useCallback(() => {
        if (isAddButtonEnabled && !props.isOperationRunning) {
            props.onSubmitButtonClick(ProviderModel.create({
                cpoId: cpoId ?? '',
                cpoName: cpoName ?? '',
                country: props.selectedCountryId ?? countryId,
                socketTypePowerRanges: hpcPowerRange ? [hpcPowerRange] : [],
            }));
        }
    },[cpoId, cpoName, countryId, hpcPowerRange, isAddButtonEnabled, props]);

    // clear fields when closing the dialog
    useEffect(() => {
        if(!props.open){
            setCpoId('');
            setCpoName('');
            setHpcPowerRange(undefined);
            setHcpStartingPowerValue('');
        }
    }, [props.open]);

    useEffect(() => {
        setCpoId(props.provider?.cpoId ?? '');
        setCpoName(props.provider?.cpoName ?? '');
        // TO FIX: socketTypePowerRanges.length always 0
        setHpcPowerRange(props.provider?.socketTypePowerRanges[0] ?? undefined);
        setHcpStartingPowerValue((props.provider?.socketTypePowerRanges[0]?.powerRange.min?.value.toString()) ?? '');
    }, [props.provider?.cpoId, props.provider?.cpoName, props.provider?.socketTypePowerRanges]);
    
    return <BaseDialog
        containerClassName='manage-provider-dialog'
        open={props.open}
        title={props.provider?.cpoName ?? formatMessage(messages.addProviderOperatorDialogTitle)}
        buttons={[
            {
                label: formatMessage(commonMessages.cancelButton),
                disabled: props.isOperationRunning,
                buttonClasses: AppButtonVariant.Dark,
                onClick: onClose
            },
            {
                label: formatMessage(props.provider ? commonMessages.saveButton : commonMessages.addButton),
                disabled: props.isOperationRunning || !isAddButtonEnabled,
                buttonClasses: AppButtonVariant.Primary,
                onClick: onCreateButtonClick
            },
        ]}
        onClose={onClose}
    >
        {!props.provider && <p>{formatMessage(messages.addProviderOperatorDialogBody)}</p>}
        <form>

            <StyledSelect 
                label={formatMessage(messages.cpoCountryLabel)}
                items={countries}
                selectedItemId={props.selectedCountryId ?? countryId}
                onItemSelected={emptyFunction}
                disabled
            />

            <StyledInput
                label={formatMessage(messages.cpoIdLabel)}
                value={cpoId}
                onChange={onCpoIdChange}
                disabled={props.provider && !props.provider.canIdBeUpdated}
            />

            <StyledInput
                label={formatMessage(messages.cpoNameLabel)}
                value={cpoName}
                onChange={onCpoNameChange}
            />

            <StyledCheckBox 
                label={formatMessage(messages.enableHpcCheckboxLabel)}
                checked={hpcPowerRange ? CheckedState.Checked : CheckedState.Unchecked}
                onClick={onCheckboxToggle}
            />

            {
                hpcPowerRange && 
                <StyledInput 
                    label={formatMessage(messages.hpcLabel)}
                    value={hcpStartingPowerValue}
                    onChange={onHcpStartingPowerValueChange}
                />
            }

        </form>
    </BaseDialog>;
};