import { Provider, ProviderModel, ProviderValueStatus, SocketTypePowerRange, SocketTypePowerRangeModel } from '../../model/provider';
import { 
    ChargesPeriodFilter,
    ChargingPowerRange as ApiChargingPowerRange,
    SocketTypePowerRange as ApiSocketTypePowerRange,
    ProvidersSearchResultItem as ApiProvider,
    ProvidersSearchResultItemValueStatus,
    ContractStatus as ApiContractStatus,
    ActiveContractStatusFilter,
    ActiveContractStatus
} from "../../../_generated/api";
import { parseContractAnagraphicFromApiModel } from "../contracts/contract-api-parser";
import { TimePeriodFilter } from "../../../common/models/filters";
import { ContractStatus } from "../../model/contract";
import { ExtraContractStatusFilter } from "../../model/providers-filter";
import { parseSocketTypeFromApiModel, parseRangeValueConditionFromApiModel, parseSocketTypeToApiModel, parseRangeValueConditionToApiModel } from '../fares/fare-api-parser';
import { ChargingPowerRangeModel, RangeValueModel } from '../../model/fare';

function parseProviderValueItemFromApiModel(apiValueItem : ProvidersSearchResultItemValueStatus): ProviderValueStatus {
    switch(apiValueItem){
        case ProvidersSearchResultItemValueStatus.Max:
            return ProviderValueStatus.MAX;
        case ProvidersSearchResultItemValueStatus.Min:
                return ProviderValueStatus.MIN;
        case ProvidersSearchResultItemValueStatus.None:
            return ProviderValueStatus.NONE;
    }
}

function parseProviderSocketTypeRangesFromApiModel(apiSocketTypePowerRanges : Array<ApiSocketTypePowerRange>): Array<SocketTypePowerRange> {
    return apiSocketTypePowerRanges.map(apiSocketTypePowerRange => SocketTypePowerRangeModel.create({
        socketType: parseSocketTypeFromApiModel(apiSocketTypePowerRange.socketType),
        newSocketType: parseSocketTypeFromApiModel(apiSocketTypePowerRange.newSocketType),
        powerRange: ChargingPowerRangeModel.create({
            min: apiSocketTypePowerRange.powerRange.min && RangeValueModel.create({
                value: apiSocketTypePowerRange.powerRange.min.value,
                condition: parseRangeValueConditionFromApiModel(apiSocketTypePowerRange.powerRange.min.condition)
            }),
            max: apiSocketTypePowerRange.powerRange.max && RangeValueModel.create({
                value: apiSocketTypePowerRange.powerRange.max.value,
                condition: parseRangeValueConditionFromApiModel(apiSocketTypePowerRange.powerRange.max.condition)
            }),
        }),
    }));
}

export const parseProviderFromApiModel = (apiProvider: ApiProvider): Provider => {
    return ProviderModel.create({
        cpoId: apiProvider.cpoId,
        cpoName: apiProvider.cpoName,
        country: apiProvider.country ?? 'it',
        chargesCountStatus: parseProviderValueItemFromApiModel(apiProvider.chargesCountStatus),
        deliveredKwhStatus: parseProviderValueItemFromApiModel(apiProvider.deliveredkWhStatus),
        earningsAmountStatus: parseProviderValueItemFromApiModel(apiProvider.earningsAmountStatus),
        chargesCount: apiProvider.chargesCount,
        costsAmount: apiProvider.costsAmount,
        earningsAmount: apiProvider.earningsAmount,
        kwhDelivered: apiProvider.kWhDelivered,
        activeContract: apiProvider.activeContract && parseContractAnagraphicFromApiModel(apiProvider.activeContract),
        socketTypePowerRanges: apiProvider.socketTypePowerRanges && parseProviderSocketTypeRangesFromApiModel(apiProvider.socketTypePowerRanges),
    });
};

export const parseTimePeriodFilterToApiModel = (timePeriod: TimePeriodFilter): ChargesPeriodFilter => {
    switch(timePeriod){
        case TimePeriodFilter.LAST_30_DAYS:
            return ChargesPeriodFilter.Last30Days;
        case TimePeriodFilter.LAST_MONTH:
            return ChargesPeriodFilter.LastMonth;
        case TimePeriodFilter.THIS_MONTH:
            return ChargesPeriodFilter.CurrentMonth;
        case TimePeriodFilter.LAST_3_MONTHS:
            return ChargesPeriodFilter.Last3Months;
        case TimePeriodFilter.LAST_6_MONTHS:
            return ChargesPeriodFilter.Last6Months;
        case TimePeriodFilter.LAST_YEAR:
            return ChargesPeriodFilter.LastYear;
    }
};

export const parseContractStatusFilterToApiModel = (status: ContractStatus | ExtraContractStatusFilter): ActiveContractStatusFilter => {
    switch(status){
        case ContractStatus.ACTIVE:
            return ApiContractStatus.Active;
        case ContractStatus.NOT_ACTIVE:
            return ApiContractStatus.NonActive;
        case ContractStatus.EXPIRED:
            return ApiContractStatus.Expired;
        case ExtraContractStatusFilter.NO_CONTRACTS:
            return ActiveContractStatus.None;
        case ExtraContractStatusFilter.BLOCKED:
            return ActiveContractStatus.Blocked;
    }
};

export const parseProviderSocketTypeRangesToApiModel = (socketTypePowerRanges: Array<SocketTypePowerRange>): Array<ApiSocketTypePowerRange> => {
    return socketTypePowerRanges.map<ApiSocketTypePowerRange>(socketTypePowerRange => ({
        socketType: parseSocketTypeToApiModel(socketTypePowerRange.socketType),
        newSocketType: parseSocketTypeToApiModel(socketTypePowerRange.newSocketType),
        powerRange: <ApiChargingPowerRange>{
            min: socketTypePowerRange.powerRange.min && {
                value: socketTypePowerRange.powerRange.min.value,
                condition: parseRangeValueConditionToApiModel(socketTypePowerRange.powerRange.min.condition)
            },
            max: socketTypePowerRange.powerRange.max && {
                value: socketTypePowerRange.powerRange.max.value,
                condition: parseRangeValueConditionToApiModel(socketTypePowerRange.powerRange.max.condition)
            },
        },
    }));
};