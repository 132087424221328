import { defineMessages } from 'react-intl';

export default defineMessages({
    "goToUserDetail": {
        "id": "commons.action.goToUserDetail",
        "defaultMessage": "Vai a utente",
    },
    "optionsIconAlt": {
        "id": "commons.action.optionsIconAlt",
        "defaultMessage": "Azioni disponibili",
        "description": "Testo alternativo dell'immagine per la selezione di un'azione su un elemento della lista"
    },
    "customizeFeeActionLabel": {
        "id": "commons.action.customizeFeeActionLabel",
        "defaultMessage": "Personalizza rincaro",
        "description": ""
    },
    "resetFeeActionLabel": {
        "id": "commons.action.resetFeeActionLabel",
        "defaultMessage": "Resetta rincaro",
        "description": ""
    },
    "updateBackofficeUserLabel": {
        "id": "commons.action.updateBackofficeUserLabel",
        "defaultMessage": "Modifica",
        "description": ""
    },
    "deleteBackofficeUserLabel": {
        "id": "commons.action.deleteBackofficeUserLabel",
        "defaultMessage": "Rimuovi",
        "description": ""
    },
    "changePasswordLabel": {
        "id": "commons.action.changePasswordLabel",
        "defaultMessage": "Cambia password",
        "description": ""
    },
    "logoutLabel": {
        "id": "commons.action.logoutLabel",
        "defaultMessage": "Logout",
        "description": ""
    },
    "changePricingActionLabel": {
        "id": "commons.action.changePricingActionLabel",
        "defaultMessage": "Cambia unità di misura",
        "description": ""
    },
    "changeBaseFeeActionLabel": {
        "id": "commons.action.changeBaseFeeActionLabel",
        "defaultMessage": "Cambia tariffa base",
        "description": ""
    },
    "manageFeesActionLabel": {
        "id": "commons.action.manageFeesActionLabel",
        "defaultMessage": "Gestisci tariffe",
        "description": ""
    },
    "duplicateActionLabel": {
        "id": "commons.action.duplicateActionLabel",
        "defaultMessage": "Duplica",
        "description": ""
    },
    "downloadSingleFareActionLabel": {
        "id": "commons.action.downloadSingleFareActionLabel",
        "defaultMessage": "Scarica Contratto",
        "description": ""
    },
    "updateFareActionLabel": {
        "id": "commons.action.updateFareActionLabel",
        "defaultMessage": "Modifica",
        "description": ""
    },
    "deleteFareActionLabel": {
        "id": "commons.action.deleteFareActionLabel",
        "defaultMessage": "Elimina",
        "description": ""
    },
    "updateProviderActionLabel": {
        "id": "commons.action.updateProviderActionLabel",
        "defaultMessage": "Modifica",
        "description": ""
    },
    "deleteProviderActionLabel": {
        "id": "commons.action.deleteProviderActionLabel",
        "defaultMessage": "Elimina",
        "description": ""
    },
});