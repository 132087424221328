import React from 'react';
import { useIntl } from "react-intl";
import { Link } from 'react-router-dom';

import { Routes } from '../../../../common/routing/routes';
import contractsMessages from '../../../contracts.messages';

export const ContractsSectionHeader = () => {
    
    const { formatMessage } = useIntl();

    return <div className='contracts-section-header'>
        
        <Link to={Routes.Providers} className='back-to-cpo'>
            {formatMessage(contractsMessages.goBackToProvidersPage)}
        </Link>
        
    </div>;
};