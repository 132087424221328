import { defineMessages } from 'react-intl';

export default defineMessages({
    "sectionTitle": {
        "id": "containers.users.sectionTitle",
        "defaultMessage": "Utenti",
        "description": ""
    },
    "emailFilterPlaceholder": {
        "id": "containers.users.emailFilterPlaceholder",
        "defaultMessage": "Cerca per email",
        "description": ""
    },
    "companiesFilterPlaceholder": {
        "id": "containers.users.companiesFilterPlaceholder",
        "defaultMessage": "Aziende",
        "description": ""
    },
    "privatesFilterPlaceholder": {
        "id": "containers.users.privatesFilterPlaceholder",
        "defaultMessage": "Privati",
        "description": ""
    },
    "allStatusFilterLabel": {
        "id": "containers.users.allStatusFilterLabel",
        "defaultMessage": "Qualsiasi stato",
        "description": "Valore mostrato nel filtro di selezione degli utenti, per indicare tutti gli stati (bloccato, attivo, ...)"
    },
    "resetFiltersButton": {
        "id": "containers.users.resetFiltersButton",
        "defaultMessage": "Reset",
        "description": ""
    },
    "tableHeaderEmail": {
        "id": "containers.users.tableHeaderEmail",
        "defaultMessage": "Email",
        "description": ""
    },
    "tableHeaderRegistered": {
        "id": "containers.users.tableHeaderRegistered",
        "defaultMessage": "Registrato",
        "description": ""
    },
    "tableHeaderStatus": {
        "id": "containers.users.tableHeaderStatus",
        "defaultMessage": "Stato",
        "description": ""
    },
    "tableHeaderLastActivity": {
        "id": "containers.users.tableHeaderLastActivity",
        "defaultMessage": "Ultima attività",
        "description": ""
    },
    "tableHeaderPaidAmounts": {
        "id": "containers.users.tableHeaderPaidAmounts",
        "defaultMessage": "Importi pagati (€)",
        "description": ""
    },
    "tableHeaderLDueAmounts": {
        "id": "containers.users.tableHeaderLDueAmounts",
        "defaultMessage": "Importi dovuti (€)",
        "description": ""
    },
    "userStatusActive": {
        "id": "containers.users.userStatusActive",
        "defaultMessage": "Attivato",
        "description": ""
    },
    "userStatusSuspended": {
        "id": "containers.users.userStatusSuspended",
        "defaultMessage": "Sospeso",
        "description": ""
    },
    "userStatusBlocked": {
        "id": "containers.users.userStatusBlocked",
        "defaultMessage": "Bloccato",
        "description": ""
    },
    "userStatusUnconfirmed": {
        "id": "containers.users.userStatusUnconfirmed",
        "defaultMessage": "In attivazione",
        "description": ""
    },
    "actionChargesDetail": {
        "id": "containers.users.actionChargesDetail",
        "defaultMessage": "Dettaglio ricariche",
        "description": ""
    },
    "actionRestoreAccount": {
        "id": "containers.users.actionRestoreAccount",
        "defaultMessage": "Ripristina account",
        "description": ""
    },
    "actionBlockAccount": {
        "id": "containers.users.actionBlockAccount",
        "defaultMessage": "Blocca account",
        "description": ""
    },
    "actionDeleteAccount": {
        "id": "containers.users.actionDeleteAccount",
        "defaultMessage": "Cancella account",
        "description": ""
    },
    "blockButton": {
        "id": "containers.users.blockButton",
        "defaultMessage": "Blocca",
        "description": ""
    },
    "restoreButton" : {
        "id": "commons.restoreButton",
        "defaultMessage": "Ripristina"
    },
    "deleteUserButton" : {
        "id": "commons.deleteUserButton",
        "defaultMessage": "Cancella definitivamente"
    },
    "blockUserDialogTitle": {
        "id": "containers.users.blockUserDialogTitle",
        "defaultMessage": "Bloccare {email}?",
        "description": ""
    },
    "blockUserDialogMessage": {
        "id": "containers.users.blockUserDialogMessage",
        "defaultMessage": "L’utente non sarà più in grado di accedere all’applicazione",
        "description": ""
    },
    "restoreUserDialogTitle": {
        "id": "containers.users.restoreUserDialogTitle",
        "defaultMessage": "Ripristinare {email}?",
        "description": ""
    },
    "restoreUserDialogMessage": {
        "id": "containers.users.restoreUserDialogMessage",
        "defaultMessage": "L’utente sarà nuovamente in grado di accedere all’app",
        "description": ""
    },
    "deleteUserDialogTitle": {
        "id": "containers.users.deleteUserDialogTitle",
        "defaultMessage": "Cancellare {email}?",
        "description": ""
    },
    "deleteUserDialogMessage": {
        "id": "containers.users.deleteUserDialogMessage",
        "defaultMessage": "ATTENZIONE: L’utente sarà rimosso dal database e non potrà più essere ripristinato. Tutti i movimenti ad esso associati non saranno più riconducibili a nessun account. Questa operazione è irreversibile.L’indirizzo mail “{email}” potrà essere attivato su un nuovo account.",
        "description": ""
    }
    ,
    "userTransactionsHeader": {
        "id": "containers.users.userTransactionsHeader",
        "defaultMessage": "Transazioni dell’utente relative a",
        "description": ""
    }

    
});