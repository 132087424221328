import { isToday, isYesterday } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import commonMessages from '../../messages/common.messages';

import { useStore } from '../../stores/root-store';
import { formatDate, formatTime } from '../../utils/formatters';

import packageJson from '../../../../package.json';

import './footer.scss';

export const Footer = observer(() => {

    const { formatMessage } = useIntl();

    const { globalStore } = useStore();
    const { lastDataUpdateTime } = globalStore;

    const updateTimeLabel = useMemo<string>(() => {
        if (lastDataUpdateTime == null) {
            return '';
        }

        const timeLabel = formatTime(lastDataUpdateTime);

        if (isToday(lastDataUpdateTime)) {
            return `${formatMessage(commonMessages.today)} ${timeLabel}`;
        } else if (isYesterday(lastDataUpdateTime)) {
            return `${formatMessage(commonMessages.yesterday)} ${timeLabel}`;
        } else {
            return `${formatDate(lastDataUpdateTime)} ${timeLabel}`;
        }
        return '';
    }, [lastDataUpdateTime, formatMessage]);

    // TODO real labels
    return <footer>
        <span>
            Innovationclub Srl - P.iva: 03945110363 - Via Emilia Est, 776/6 - 41125 - Modena (MO) - info@chargecar.com
        </span>
        {updateTimeLabel && <span>
            v{packageJson.version}{process.env.REACT_APP_ENV != 'PROD' ? `-${process.env.REACT_APP_ENV}` : ''} - {formatMessage(commonMessages.dataUpdateTimeLabel)} {updateTimeLabel}
        </span>}
    </footer>;
});