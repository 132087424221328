import { useEffect } from "react";

export const useEnterKeyListener = (callback: () => void) => {
    useEffect(() => {
        const listener = (event: { code: string; }) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                callback();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });
};