import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { StyledSelect } from "../../../../../../common/components/input/styled-select";
import contractDetailsMessages from "../../../../../contract-details.messages";
import { ChargingPowerRange, ChargingPowerRangeModel, RangeValueCondition, RangeValueModel, SocketType } from "../../../../../model/fare";
import { PowerRangeSelector } from "./power-selector";

const allSocketTypesOption = '___all_socket_types___';

export interface SocketDataProps {
    socketType?: SocketType;
    onSocketTypeChanged: (socketType?: SocketType) => void;

    chargingPowerRange?: ChargingPowerRange;
    onChargingPowerRangeChanged: (powerRange?: ChargingPowerRange) => void;

    canUpdateData: boolean;
}

export const SocketData = observer((props: SocketDataProps) => {

    const { formatMessage } = useIntl();

    const [minValue, setMinValue] = useState('');
    const [minRangeValueCondition, setMinRangeValueCondition] = useState(RangeValueCondition.INCLUSIVE);

    const [maxValue, setMaxValue] = useState('');
    const [maxRangeValueCondition, setMaxRangeValueCondition] = useState(RangeValueCondition.INCLUSIVE);

    const socketTypeItems = useMemo(() => [
        {
            id: allSocketTypesOption,
            label: formatMessage(contractDetailsMessages.allSockets)
        },
        ...Object.values(SocketType).map(elem => {
            let socketMessage;

            switch(elem){
                case SocketType.AC:
                    socketMessage = contractDetailsMessages.acSocketType;
                    break;
                case SocketType.DC:
                    socketMessage =  contractDetailsMessages.dcSocketType;
                    break;
                case SocketType.HPC_UFC:
                    socketMessage =  contractDetailsMessages.hpcUfcSocketType;
                    break;
            }

            return {
                id: elem,
                label: formatMessage(socketMessage)
            };
        })
    ], [formatMessage]);

    const onSocketTypeSelected = useCallback((socketTypeId: string) => {
        if(socketTypeId === allSocketTypesOption){
            props.onSocketTypeChanged(undefined);
            return;
        }

        const socketType = Object.values(SocketType).find(elem => elem === socketTypeId);
        socketType && props.onSocketTypeChanged(socketType);
    }, [props]);

    const onMinValueChanged = useCallback((value: string) => {
        setMinValue(value);
        if(value.trim().length > 0 || maxValue.trim().length > 0){
            props.onChargingPowerRangeChanged(ChargingPowerRangeModel.create({
                min: value.trim().length > 0 ? RangeValueModel.create({
                    value: Number(value),
                    condition: minRangeValueCondition
                }) : undefined,
                max: maxValue.trim().length > 0 ? RangeValueModel.create({
                    value: Number(maxValue),
                    condition: maxRangeValueCondition
                }) : undefined,
            }));
        } else {
            props.onChargingPowerRangeChanged(undefined);
        }
    }, [maxRangeValueCondition, maxValue, minRangeValueCondition, props]);

    const onMinRangeValueConditionChanged = useCallback((rangeValue: RangeValueCondition) => {
        setMinRangeValueCondition(rangeValue);
        if(minValue.trim().length > 0){
            props.onChargingPowerRangeChanged(ChargingPowerRangeModel.create({
                min: RangeValueModel.create({
                    value: Number(minValue),
                    condition: rangeValue
                }),
                max: maxValue.trim().length > 0 ? RangeValueModel.create({
                    value: Number(maxValue),
                    condition: maxRangeValueCondition
                }) : undefined,
            }));
        }
    }, [maxRangeValueCondition, maxValue, minValue, props]);

    const onMaxValueChanged = useCallback((value: string) => {
        setMaxValue(value);
        if(minValue.trim().length > 0 || value.length > 0) {
            props.onChargingPowerRangeChanged(ChargingPowerRangeModel.create({
                min: minValue.trim().length > 0 ? RangeValueModel.create({
                    value: Number(minValue),
                    condition: minRangeValueCondition
                }) : undefined,
                max: value.length > 0 ? RangeValueModel.create({
                    value: Number(value),
                    condition: maxRangeValueCondition
                }) : undefined,
            }));
        } else {
            props.onChargingPowerRangeChanged(undefined);
        }
    }, [maxRangeValueCondition, minValue, minRangeValueCondition, props]);

    const onMaxRangeValueConditionChanged = useCallback((rangeValue: RangeValueCondition) => {
        setMaxRangeValueCondition(rangeValue);
        if(maxValue.trim().length > 0){
            props.onChargingPowerRangeChanged(ChargingPowerRangeModel.create({
                min: minValue.trim().length > 0 ? RangeValueModel.create({
                    value: Number(minValue),
                    condition: minRangeValueCondition
                }) : undefined,
                max: RangeValueModel.create({
                    value: Number(maxValue),
                    condition: rangeValue
                }),
            }));
        }
    }, [minRangeValueCondition, maxValue, minValue, props]);

    useEffect(() => {
        setMinValue(props.chargingPowerRange?.min?.value.toString() ?? '');
        setMinRangeValueCondition(props.chargingPowerRange?.min?.condition ?? RangeValueCondition.EXCLUSIVE);
        setMaxValue(props.chargingPowerRange?.max?.value.toString() ?? '');
        setMaxRangeValueCondition(props.chargingPowerRange?.max?.condition ?? RangeValueCondition.EXCLUSIVE);
    }, [
        props.chargingPowerRange?.max?.condition,
        props.chargingPowerRange?.max?.value,
        props.chargingPowerRange?.min?.condition,
        props.chargingPowerRange?.min?.value
    ]);

    const isChargingPowerRangeValid = useMemo(() => { 
        if(!props.chargingPowerRange) {
            return true;
        } else if (props.chargingPowerRange.min && props.chargingPowerRange.max) { 
            if(props.chargingPowerRange.min.condition === RangeValueCondition.INCLUSIVE || props.chargingPowerRange.max.condition === RangeValueCondition.INCLUSIVE) {
                return props.chargingPowerRange.min.value <= props.chargingPowerRange.max.value;
            } else {
                return props.chargingPowerRange.min.value < props.chargingPowerRange.max.value;
            }
        } else {
            return (!!props.chargingPowerRange.min && !props.chargingPowerRange.max)
                || (!!props.chargingPowerRange.max && !props.chargingPowerRange.min);
        }
    }, [props.chargingPowerRange]);

    return <div className="socket-data">
        <StyledSelect
            containerClassName="socket-type"
            label={formatMessage(contractDetailsMessages.socketTypeLabel)}
            items={socketTypeItems}
            selectedItemId={props.socketType ?? allSocketTypesOption}
            onItemSelected={onSocketTypeSelected}
            disabled={!props.canUpdateData}
            hideLabelItem
        />

        <div className="power-ranges-column">

            <div className="power-ranges-row">
                <PowerRangeSelector 
                    isMin={true}
                    value={minValue}
                    rangeValueCondition={minRangeValueCondition}
                    onRangeValueConditionChange={onMinRangeValueConditionChanged}
                    onValueChange={onMinValueChanged}
                    disabled={!props.canUpdateData}
                />

                <PowerRangeSelector 
                    isMin={false}
                    value={maxValue}
                    rangeValueCondition={maxRangeValueCondition}
                    onRangeValueConditionChange={onMaxRangeValueConditionChanged}
                    onValueChange={onMaxValueChanged}
                    disabled={!props.canUpdateData}
                />
            </div>

            {
                !isChargingPowerRangeValid &&
                <span className="error-label">{formatMessage(contractDetailsMessages.wrongChargingPowerRangeMessage)}</span>
            }

        </div>

    </div>;
}); 