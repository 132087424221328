import React, { ReactNode } from 'react';

import logo from '../../../assets/images/chargecar-com.svg';

import '../login-section-page.scss';

interface LoginSectionPageProps {
    title: string;
    children: ReactNode;
}

export const LoginSectionPage = ({ title, children }: LoginSectionPageProps) => {

    return <div className='login-page page'>
        <div className='login-box'>
            <img className='logo' src={logo} />
            <h4>{title}</h4>
            {children}
        </div>
    </div>;
};