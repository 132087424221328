import React, { ChangeEventHandler, useCallback } from "react";
import { useIntl } from "react-intl";

import { StyledInput } from "../../../../../common/components/input/styled-input";
import { isNumber } from "../../../../../common/utils/validators";

import contractsMessages from '../../../../contracts.messages';

export interface ManageContractDialogTitleProps {
    baseInflation?: string
    onBaseInflationChange: (fee?: string) => void
}

export const ManageContractDialogTitle = (props: ManageContractDialogTitleProps) => {
    
    const { formatMessage } = useIntl();
    
    const onBaseInflationChange: ChangeEventHandler<HTMLInputElement> = useCallback((ev) => {
        const value = ev.target.value;
        if (isNumber(value) || value.startsWith('-')) {
            if (value.endsWith('.')) {
                props.onBaseInflationChange(value);
                return;
            } else {
                if (value !== undefined && value !== null && value.trim().length > 0) {
                    props.onBaseInflationChange(value);
                    return;
                }
            }
        }
        props.onBaseInflationChange(undefined);
    }, [props]);

    return <div className='manage-contract-dialog-title'>
        {formatMessage(contractsMessages.manageContractDialogTitle)}
        <div className='base-fee'>
            {formatMessage(contractsMessages.contractBaseFeeLabel, {currency: '%'})}
            <StyledInput 
                value={props.baseInflation ?? ''}
                onChange={onBaseInflationChange}
            />
        </div>
    </div>;
};