import React from 'react';
import { useIntl } from 'react-intl';
import { PagedListCell, PagedListCellVariant } from '../../../common/components/paged-list/paged-list-cell';
import messages from '../../../common/messages/list.messages';

interface TransactionTableSummaryProps {
    totalDebitAmount: number,
    totalCollectedAmount: number,
    totalEarnedAmount: number,
}

export const TransactionTableSummary = (props: TransactionTableSummaryProps) => {
    const { formatMessage } = useIntl();
    return <tr className='transaction-summary-th'>
        <PagedListCell variant={PagedListCellVariant.Semibold}>
            {formatMessage(messages.tableHeaderTotal).toUpperCase()}
        </PagedListCell>
        <td/>
        <PagedListCell variant={PagedListCellVariant.Bold}>{props.totalDebitAmount.toFixed(2)}</PagedListCell>
        <PagedListCell variant={props.totalDebitAmount > props.totalCollectedAmount ? PagedListCellVariant.Warning : PagedListCellVariant.Bold}>
            {props.totalCollectedAmount.toFixed(2)}
        </PagedListCell>
        <PagedListCell variant={props.totalEarnedAmount > 0 ? PagedListCellVariant.Success : PagedListCellVariant.Warning}>
            {props.totalEarnedAmount.toFixed(2)}
        </PagedListCell>
        <td/>
    </tr>;
};