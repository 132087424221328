import React from 'react';
import { useIntl } from 'react-intl';

import { SortableTableHeader } from '../../../../common/components/paged-list/table-header-sortable';

import { OrderManagerProps } from '../../../../common/models/order-criteria';

import messages from '../../../../common/messages/list.messages';
import { ContractOrderCriteria } from '../../../model/contract';

export const ContractsTableHeader = (props: OrderManagerProps) => {
    const { formatMessage } = useIntl();

    return <tr className='contracts-th'>
        <td>{formatMessage(messages.tableHeaderID)}</td>
        <td>{formatMessage(messages.tableHeaderContractType)}</td>
        <td>{formatMessage(messages.tableHeaderContractStatus)}</td>
        <SortableTableHeader 
            sortKey={ContractOrderCriteria.TIME_PERIOD}
            label={formatMessage(messages.tableHeaderContractPeriod)} 
            {...props}
        />
        <td>{formatMessage(messages.tableHeaderKwhDelivered)}</td>
        <td>{formatMessage(messages.tableHeaderNumberOfCharges)}</td>
        <td>{formatMessage(messages.tableHeaderCostsAmount, {currency: '€'})}</td>
        <td>{formatMessage(messages.tableHeaderContractFee, {currency: '%'})}</td>
        <td>{formatMessage(messages.tableHeaderAmoundEarned, {currency: '€'})}</td>
        <td>{formatMessage(messages.tableHeaderActions)}</td>
    </tr >;

};