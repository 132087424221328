import React, { useEffect } from 'react';
import { useCallback } from 'react';
import { PagedList } from '../../../common/components/paged-list/paged-list';
import { useStore } from '../../../common/stores/root-store';
import { useIntl } from 'react-intl';

import { TransactionSectionHeader } from './transaction-section-header';
import { TransactionTableHeader } from './transaction-table-header';

import { TopMenu, TopMenuProps } from '../../../common/components/menu/top-menu';
import { Footer } from '../../../common/components/footer/footer';
import { OrderManagerProps } from '../../../common/models/order-criteria';
import { TransactionTableItem } from './transaction-table-item';
import { Transaction, TransactionsSummary } from '../../model/transaction';
import { AppButtonVariant } from '../../../common/components/button/app-button';
import { ButtonRow } from '../../../common/components/button-row/button-row';

import { TransactionTableSummary } from './transaction-table-summary';
import { observer } from 'mobx-react-lite';
import { UserDetailPage } from '../../../users/ui/detail/user-detail-page';
import {supportedCountries} from '../../../common/models/country';
import { TimePeriodFilter } from '../../../common/models/filters';

import commonMessages from '../../../common/messages/common.messages';

import './transaction-list.scss';

interface TransactionPagedListProps extends TopMenuProps { }

export const TransactionPagedList = observer((props: TransactionPagedListProps) => {

    const { transactionStore, appUsersStore, userStore } = useStore();
    const { formatMessage } = useIntl();

    useEffect(() => {
        transactionStore.updateCpoList(transactionStore.filters?.cpoId ?? undefined);
        transactionStore.reload();
    }, [transactionStore]);


    const onUserSelected = useCallback((userId: string) => {
        appUsersStore.downloadAndSelectUser(userId);
    }, [appUsersStore]);

    const onSelectedProvider = useCallback((provider?: string) => {
        transactionStore.filters?.setCPO(provider);
        transactionStore.applyFilters();
    }, [transactionStore]);

    const onSelectedTimePeriod = useCallback((timePeriod: TimePeriodFilter) => {
        transactionStore.filters?.setTimePeriod(timePeriod);
        transactionStore.applyFilters();
    }, [transactionStore]);

    const onSelectedCountry = useCallback((country?: string) => {
        transactionStore.filters?.setCountryId(country);
        transactionStore.updateCpoList(country);
        transactionStore.applyFilters();
    }, [transactionStore]);

    const renderHeader = useCallback(() => {
        return <TransactionSectionHeader
            totalItems={transactionStore.totalItems}
            providers={transactionStore.cpoListForSelectedCountry}
            availableCountries={supportedCountries}

            selectedTimePeriodFilter={
                transactionStore.filters?.timePeriod
                ?? TimePeriodFilter.THIS_MONTH
            }
            selectedProviderFilter={transactionStore.filters?.cpoId ?? undefined}
            selectedCountryFilter={transactionStore.filters?.countryId ?? undefined}

            onSelectedProvider={onSelectedProvider}
            onSelectedTimePeriod={onSelectedTimePeriod}
            onSelectedCountry={onSelectedCountry}

            canUserChangeCountryFilter={userStore.isAdminLogged}
        />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        transactionStore.totalItems, transactionStore.filters?.cpoId, transactionStore.filters?.countryId,
        transactionStore.filters?.timePeriod, userStore.isAdminLogged, 
        transactionStore.cpoListForSelectedCountry.length, onSelectedProvider,
        onSelectedTimePeriod, onSelectedCountry
    ]);

    const renderTableHeader = useCallback((orderProps: OrderManagerProps) =>
        <TransactionTableHeader {...orderProps} showActions={true} />,
        []
    );

    const renderTableSummary = useCallback((summary?: TransactionsSummary) =>
        <TransactionTableSummary
            totalCollectedAmount={summary?.totalAmountCollected ?? 0}
            totalDebitAmount={summary?.totalAmountDue ?? 0}
            totalEarnedAmount={summary?.totalAmountEarned ?? 0}
        />, []);

    const renderItem = useCallback((item: Transaction, index: number) =>
        <TransactionTableItem
            onUserSelected={onUserSelected}
            key={index}
            item={item}
            showActions={true}
        />, [onUserSelected]);

    const renderTableFooter = useCallback((isTableEmpty: boolean) => {

        const downloadXLS = useCallback(() => {
            transactionStore.downloadReport();
        }, []);

        return <ButtonRow buttons={[{
            label:
                formatMessage(commonMessages.downloadXLS),
            buttonClasses: AppButtonVariant.Dark,
            onClick: downloadXLS,
            disabled: transactionStore.isDownloadingReport || isTableEmpty
        },]} />;
    }, [formatMessage, transactionStore]);

    return <div className='page'>

        <TopMenu {...props} />
        <PagedList
            tableClass='transaction-table'
            store={transactionStore}
            columnsCount={6}
            renderTableHeader={renderTableHeader}
            renderTableSummary={renderTableSummary}
            renderTableRow={renderItem}
            renderHeader={renderHeader}
            renderTableFooter={renderTableFooter} />
        <Footer />

        <UserDetailPage 
            user={appUsersStore.selectedItem} 
            showLoader={appUsersStore.isDownloadingUserDetail}/>

    </div>;
});