export enum ApiPath {
}

export enum ApiResponseCode {
    OK = 200,
    CREATED = 201,
    NO_CONTENT = 204,
    PARTIAL_RESULT = 206,
    BAD_CONTENT = 400,
    UNAUTHORIZED = 401,
    NOT_FOUND = 404,
    TIMEOUT = 408,
    INTERNAL_SERVER_ERROR = 500,
    COMMUNICATION_ERROR = 501,
    UNKNOWN_EXCEPTION = 502
}

export const API_TIMEOUT = 15000;
