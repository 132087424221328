import React, { ReactNode, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { IAnyType, Instance, SnapshotOrInstance } from 'mobx-state-tree';
import { PagedListStoreModel } from '../../../../common/stores/paged-list/paged-list-store';
import { OrderManagerProps } from '../../../../common/models/order-criteria';
import { AppAccordion } from '../../../../common/components/accordion/app-accordion';
import { TopMenu } from '../../../../common/components/menu/top-menu';
import { useStore } from '../../../../common/stores/root-store';
import { UserRole } from '../../../../common/models/user';
import { AccordionPagedList } from './accordion-paged-list';
import { Contract, ContractType } from '../../../model/contract';
import { useIntl } from 'react-intl';
import contractDetailsMessages from '../../../contract-details.messages';
import { Footer } from '../../../../common/components/footer/footer';
import './accordion-list.scss';
import { SectionPagedListHeader } from '../section-paged-list/section-paged-list-header';
import { AppButton, AppButtonProps, AppButtonVariant } from '../../../../common/components/button/app-button';
import commonMessages from '../../../../common/messages/common.messages';

interface AccordionListProps<ItemsType extends IAnyType, FiltersType extends IAnyType, SummaryType extends IAnyType> {
    accordions: {
        title: string;
        groupId: string;
        store: PagedListStoreModel<ItemsType, FiltersType, SummaryType>;
    }[];

    // contractStore: PagedListStoreModel<ItemsType, FiltersType, SummaryType>;
    columnsCount: number;
    selectedContract: Contract;
    renderTableHeader: (orderProps: OrderManagerProps) => ReactNode;
    renderItem: (item: Instance<ItemsType>, index: number) => ReactNode;
    renderSummary?: () => ReactNode;
    renderTableSummary?: (summaryData?: Instance<SummaryType>) => ReactNode;
    renderTableFooter?: (isTableEmpty: boolean) => ReactNode;
    openItemDetail?: (item: SnapshotOrInstance<ItemsType>) => void;
    onManageFareButtonClick: (click: boolean) => () => void;
    renderAccordionHeader: (isLoadingElements: boolean) => ReactNode;

}

export const AccordionList = observer(<ItemsType extends IAnyType, FiltersType extends IAnyType, SummaryType extends IAnyType>(
    { accordions, ...props }: AccordionListProps<ItemsType, FiltersType, SummaryType>) => {

    const { userStore } = useStore();

    const intl = useIntl();

    const { formatMessage } = intl;

    // const downloadXLS = useCallback(() => {
    //     props.contractStore.downloadReport();
    // }, [props.contractStore]);


    // TODO: Enable button downloadXLS

    const buttons: AppButtonProps[] = [
        {
            label: formatMessage(commonMessages.downloadXLS),
            variant: AppButtonVariant.Dark,
            // onClick: downloadXLS,
            disabled: true
        }];

    const renderAdditionalHeaderComponents = useCallback(() => {
        return props.renderAccordionHeader(false);
    }, [props]);

    const renderHeader = useCallback(() => {

        return <SectionPagedListHeader
            renderAdditionalHeaderComponents={renderAdditionalHeaderComponents}
        />;
    }, [renderAdditionalHeaderComponents]);



    return (
        <div className='page'>

            <TopMenu isAdmin={userStore.user?.role === UserRole.Admin} />


            <div className='page-content'>
                {renderHeader()}
                {props.renderSummary && props.renderSummary()}


                <div className='accordion-list'>
                    {accordions.map((accordion, index) => (
                        <AppAccordion
                            key={index}
                            title={formatMessage(contractDetailsMessages.AccordionTitle) + accordion.title}
                            defaultExpanded={false}
                        >
                            <AccordionPagedList
                                isAdmin={userStore.user?.role === UserRole.Admin}
                                store={accordion.store}
                                tableClass='contract-details-table'
                                renderTableHeader={props.renderTableHeader}
                                renderItem={props.renderItem}
                                columnsCount={10}
                                createButtonEnabled={props.selectedContract.isNotExpired && props.selectedContract.type !== ContractType.HUBJECT_DYNAMIC}
                                onAddNewButtonClick={props.onManageFareButtonClick(false)}
                                tableTitle={formatMessage(contractDetailsMessages.faresTableTitle)}
                            />
                        </AppAccordion>
                    ))}
                </div>

                <div className='buttons'>
                    {buttons.map((buttonProps, index) => (
                        <AppButton key={index} {...buttonProps} />
                    ))}
                </div>
            </div>

            <Footer />
        </div>
    );
});
