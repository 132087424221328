/* tslint:disable */
/* eslint-disable */
/**
 * ChargeCar.com - Backoffice API
 * ChargeCar Backoffice Backend - Rest API
 *
 * The version of the OpenAPI document: 1.3.0
 * Contact: service@antreem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export enum ActiveContractStatus {
    Blocked = 'BLOCKED',
    None = 'NONE'
}

/**
 * @type ActiveContractStatusFilter
 * @export
 */
export type ActiveContractStatusFilter = ActiveContractStatus | ContractStatus;

/**
 * 
 * @export
 * @interface AdminUpdateBackofficeUser
 */
export interface AdminUpdateBackofficeUser {
    /**
     * ISO 3166-1 alpha-2
     * @type {string}
     * @memberof AdminUpdateBackofficeUser
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateBackofficeUser
     */
    telephone?: string;
    /**
     * 
     * @type {BackofficeRole}
     * @memberof AdminUpdateBackofficeUser
     */
    role?: BackofficeRole;
    /**
     * Full name of the user
     * @type {string}
     * @memberof AdminUpdateBackofficeUser
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateBackofficeUser
     */
    contact_details?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum BackofficeRole {
    Admin = 'ADMIN',
    Operator = 'OPERATOR',
    Manager = 'MANAGER'
}

/**
 * Information of a backoffice user
 * @export
 * @interface BackofficeUser
 */
export interface BackofficeUser {
    /**
     * Identification of the user
     * @type {string}
     * @memberof BackofficeUser
     */
    id: string;
    /**
     * Email linked to the user
     * @type {string}
     * @memberof BackofficeUser
     */
    email: string;
    /**
     * ISO 3166-1 alpha-2
     * @type {string}
     * @memberof BackofficeUser
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof BackofficeUser
     */
    telephone?: string;
    /**
     * 
     * @type {BackofficeRole}
     * @memberof BackofficeUser
     */
    role: BackofficeRole;
    /**
     * Full name of the user
     * @type {string}
     * @memberof BackofficeUser
     */
    name: string;
    /**
     * 
     * @type {Locale}
     * @memberof BackofficeUser
     */
    locale: Locale;
    /**
     * 
     * @type {string}
     * @memberof BackofficeUser
     */
    contact_details: string;
}
/**
 * 
 * @export
 * @interface BackofficeUserSearchResponse
 */
export interface BackofficeUserSearchResponse {
    /**
     * 
     * @type {number}
     * @memberof BackofficeUserSearchResponse
     */
    totalNumberOfItems: number;
    /**
     * 
     * @type {Array<BackofficeUser>}
     * @memberof BackofficeUserSearchResponse
     */
    items: Array<BackofficeUser>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ChargesPeriodFilter {
    Last30Days = 'LAST_30_DAYS',
    CurrentMonth = 'CURRENT_MONTH',
    LastMonth = 'LAST_MONTH',
    Last3Months = 'LAST_3_MONTHS',
    Last6Months = 'LAST_6_MONTHS',
    LastYear = 'LAST_YEAR'
}

/**
 * 
 * @export
 * @interface ChargingPowerRange
 */
export interface ChargingPowerRange {
    /**
     * 
     * @type {RangeValue}
     * @memberof ChargingPowerRange
     */
    min?: RangeValue;
    /**
     * 
     * @type {RangeValue}
     * @memberof ChargingPowerRange
     */
    max?: RangeValue;
}
/**
 * 
 * @export
 * @interface ConfirmForgotPasswordBody
 */
export interface ConfirmForgotPasswordBody {
    /**
     * 
     * @type {string}
     * @memberof ConfirmForgotPasswordBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmForgotPasswordBody
     */
    newPassword: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmForgotPasswordBody
     */
    confirmationCode: string;
}
/**
 * 
 * @export
 * @interface ContractAnagraphic
 */
export interface ContractAnagraphic {
    /**
     * 
     * @type {string}
     * @memberof ContractAnagraphic
     */
    contractId: string;
    /**
     * 
     * @type {number}
     * @memberof ContractAnagraphic
     */
    inflation: number;
    /**
     * 
     * @type {ContractValidityPeriod}
     * @memberof ContractAnagraphic
     */
    validityPeriod: ContractValidityPeriod;
    /**
     * 
     * @type {ContractType}
     * @memberof ContractAnagraphic
     */
    type: ContractType;
    /**
     * 
     * @type {string}
     * @memberof ContractAnagraphic
     */
    ngpContractFilename?: string;
    /**
     * 
     * @type {Operation}
     * @memberof ContractAnagraphic
     */
    creation: Operation;
    /**
     * 
     * @type {Operation}
     * @memberof ContractAnagraphic
     */
    lastChange: Operation;
    /**
     * 
     * @type {ContractStatus}
     * @memberof ContractAnagraphic
     */
    status: ContractStatus;
    /**
     * 
     * @type {string}
     * @memberof ContractAnagraphic
     */
    operatorNotes?: string;
    /**
     * 
     * @type {ContractContactPerson}
     * @memberof ContractAnagraphic
     */
    contactPerson?: ContractContactPerson;
    /**
     * 
     * @type {boolean}
     * @memberof ContractAnagraphic
     */
    isBlocked: boolean;
}
/**
 * 
 * @export
 * @interface ContractContactPerson
 */
export interface ContractContactPerson {
    /**
     * 
     * @type {string}
     * @memberof ContractContactPerson
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractContactPerson
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractContactPerson
     */
    phoneNumber?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ContractStatus {
    Active = 'ACTIVE',
    NonActive = 'NON_ACTIVE',
    Expired = 'EXPIRED'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum ContractType {
    Standard = 'HUBJECT_STANDARD',
    Product = 'HUBJECT_PRODUCT',
    Dynamic = 'HUBJECT_DYNAMIC',
    Ngp = 'HUBJECT_NGP'
}

/**
 * 
 * @export
 * @interface ContractValidityPeriod
 */
export interface ContractValidityPeriod {
    /**
     * 
     * @type {string}
     * @memberof ContractValidityPeriod
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof ContractValidityPeriod
     */
    to: string;
}
/**
 * 
 * @export
 * @interface CreateContractData
 */
export interface CreateContractData {
    /**
     * 
     * @type {number}
     * @memberof CreateContractData
     */
    inflation: number;
    /**
     * 
     * @type {ContractValidityPeriod}
     * @memberof CreateContractData
     */
    validityPeriod: ContractValidityPeriod;
    /**
     * 
     * @type {ContractType}
     * @memberof CreateContractData
     */
    type: ContractType;
    /**
     * 
     * @type {string}
     * @memberof CreateContractData
     */
    operatorNotes?: string;
    /**
     * 
     * @type {ContractContactPerson}
     * @memberof CreateContractData
     */
    contactPerson?: ContractContactPerson;
    /**
     * 
     * @type {boolean}
     * @memberof CreateContractData
     */
    isBlocked?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateContractData
     */
    ngpContractFilename?: string;
    /**
     * The XLSX file containing the contract details
     * @type {string}
     * @memberof CreateContractData
     */
    ngpContractFile?: string;
}
/**
 * 
 * @export
 * @interface CreateFareData
 */
export interface CreateFareData {
    /**
     * 
     * @type {string}
     * @memberof CreateFareData
     */
    fareId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFareData
     */
    fareName: string;
    /**
     * 
     * @type {Currency}
     * @memberof CreateFareData
     */
    currency: Currency;
    /**
     * 
     * @type {string}
     * @memberof CreateFareData
     */
    operatorNotes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateFareData
     */
    isValid24Hours: boolean;
    /**
     * 
     * @type {Array<FareValidityPeriod>}
     * @memberof CreateFareData
     */
    validityPeriods?: Array<FareValidityPeriod>;
    /**
     * 
     * @type {SocketType}
     * @memberof CreateFareData
     */
    socketType?: SocketType;
    /**
     * 
     * @type {ChargingPowerRange}
     * @memberof CreateFareData
     */
    chargingPowerRange?: ChargingPowerRange;
    /**
     * 
     * @type {number}
     * @memberof CreateFareData
     */
    inflation?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateFareData
     */
    minimumPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateFareData
     */
    maximumPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateFareData
     */
    fixedPrice?: number;
    /**
     * 
     * @type {FareFee}
     * @memberof CreateFareData
     */
    sessionFee?: FareFee;
    /**
     * 
     * @type {FareFee}
     * @memberof CreateFareData
     */
    chargingFee?: FareFee;
    /**
     * 
     * @type {FareFee}
     * @memberof CreateFareData
     */
    parkingFee?: FareFee;
    /**
     * 
     * @type {Array<OtherFee>}
     * @memberof CreateFareData
     */
    otherFees?: Array<OtherFee>;
    /**
     * 
     * @type {string}
     * @memberof CreateFareData
     */
    subCpo?: string;
}
/**
 * 
 * @export
 * @interface CreateGroupData
 */
export interface CreateGroupData {
    /**
     * 
     * @type {string}
     * @memberof CreateGroupData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateGroupData
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface CredentialsUpdateProfileData
 */
export interface CredentialsUpdateProfileData {
    /**
     * 
     * @type {string}
     * @memberof CredentialsUpdateProfileData
     */
    oldPassword: string;
    /**
     * 
     * @type {string}
     * @memberof CredentialsUpdateProfileData
     */
    newPassword: string;
}
/**
 * ISO 4217
 * @export
 * @enum {string}
 */

export enum Currency {
    Eur = 'EUR'
}

/**
 * 
 * @export
 * @interface DayTimeInterval
 */
export interface DayTimeInterval {
    /**
     * 
     * @type {string}
     * @memberof DayTimeInterval
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof DayTimeInterval
     */
    to: string;
}
/**
 * 4xx:   - are errors due to the client;   - for example the speified data are not consistent.  5xx:   - are errors due to the server;   - for example the server creates a resource but after that it does not exists. 
 * @export
 * @enum {string}
 */

export enum ErrorCode {
    _4xxMalformedInformation = '4xx_MALFORMED-INFORMATION',
    _4xxUserAlreadyExists = '4xx_USER-ALREADY-EXISTS',
    _4xxUserNotFound = '4xx_USER-NOT-FOUND',
    _4xxUserNotAuthenticated = '4xx_USER-NOT-AUTHENTICATED',
    _4xxUserNotAuthorized = '4xx_USER-NOT-AUTHORIZED',
    _4xxUserNotConfirmed = '4xx_USER-NOT-CONFIRMED',
    _4xxUserForceChangePassword = '4xx_USER-FORCE-CHANGE-PASSWORD',
    _4xxItemAlreadyExists = '4xx_ITEM-ALREADY-EXISTS',
    _4xxItemNotFound = '4xx_ITEM-NOT-FOUND',
    _4xxUpdateProfileTooManyDefaultPositions = '4xx_UPDATE-PROFILE_TOO-MANY-DEFAULT-POSITIONS',
    _4xxUpdateCredentialsOldPasswordWrong = '4xx_UPDATE-CREDENTIALS_OLD-PASSWORD-WRONG',
    _4xxUserCannotUpdateItsRole = '4xx_USER-CANNOT-UPDATE-ITS-ROLE',
    _4xxUserCannotDeleteItself = '4xx_USER-CANNOT-DELETE-ITSELF',
    _4xxCannotUpdateProviderId = '4xx_CANNOT-UPDATE-PROVIDER-ID',
    _5xxInternalServerError = '5xx_INTERNAL-SERVER-ERROR',
    _5xxItemNotCreated = '5xx_ITEM-NOT-CREATED',
    _5xxItemNotUpdated = '5xx_ITEM-NOT-UPDATED',
    _5xxItemNotDeleted = '5xx_ITEM-NOT-DELETED',
    _5xxItemNotRetrieved = '5xx_ITEM-NOT-RETRIEVED',
    _5xxItemSearchError = '5xx_ITEM-SEARCH-ERROR',
    _5xxItemReturnValuesMissing = '5xx_ITEM-RETURN-VALUES-MISSING',
    _5xxUserDataNotFound = '5xx_USER-DATA-NOT-FOUND'
}

/**
 * Error information regarding a question about Backend
 * @export
 * @interface ErrorData
 */
export interface ErrorData {
    /**
     * 
     * @type {string}
     * @memberof ErrorData
     */
    description?: string;
    /**
     * 
     * @type {ErrorCode}
     * @memberof ErrorData
     */
    errorCode?: ErrorCode;
}
/**
 * 
 * @export
 * @interface Fare
 */
export interface Fare {
    /**
     * 
     * @type {string}
     * @memberof Fare
     */
    fareId: string;
    /**
     * 
     * @type {string}
     * @memberof Fare
     */
    fareName: string;
    /**
     * 
     * @type {Currency}
     * @memberof Fare
     */
    currency: Currency;
    /**
     * 
     * @type {string}
     * @memberof Fare
     */
    operatorNotes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Fare
     */
    isValid24Hours: boolean;
    /**
     * 
     * @type {Array<FareValidityPeriod>}
     * @memberof Fare
     */
    validityPeriods?: Array<FareValidityPeriod>;
    /**
     * 
     * @type {SocketType}
     * @memberof Fare
     */
    socketType?: SocketType;
    /**
     * 
     * @type {ChargingPowerRange}
     * @memberof Fare
     */
    chargingPowerRange?: ChargingPowerRange;
    /**
     * 
     * @type {number}
     * @memberof Fare
     */
    inflation?: number;
    /**
     * 
     * @type {number}
     * @memberof Fare
     */
    minimumPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Fare
     */
    maximumPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Fare
     */
    fixedPrice?: number;
    /**
     * 
     * @type {FareFee}
     * @memberof Fare
     */
    sessionFee?: FareFee;
    /**
     * 
     * @type {FareFee}
     * @memberof Fare
     */
    chargingFee?: FareFee;
    /**
     * 
     * @type {FareFee}
     * @memberof Fare
     */
    parkingFee?: FareFee;
    /**
     * 
     * @type {Array<OtherFee>}
     * @memberof Fare
     */
    otherFees?: Array<OtherFee>;
    /**
     * 
     * @type {string}
     * @memberof Fare
     */
    subCpo?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Fare
     */
    plugIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface FareAllOf
 */
export interface FareAllOf {
    /**
     * 
     * @type {string}
     * @memberof FareAllOf
     */
    fareId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FareAllOf
     */
    plugIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface FareFee
 */
export interface FareFee {
    /**
     * 
     * @type {number}
     * @memberof FareFee
     */
    price: number;
    /**
     * 
     * @type {PriceReferenceUnit}
     * @memberof FareFee
     */
    priceReferenceUnit?: PriceReferenceUnit;
    /**
     * 
     * @type {number}
     * @memberof FareFee
     */
    minimumReferenceUnit?: number;
    /**
     * 
     * @type {SessionMoment}
     * @memberof FareFee
     */
    startingFrom?: SessionMoment;
    /**
     * 
     * @type {SessionMoment}
     * @memberof FareFee
     */
    endingTo?: SessionMoment;
    /**
     * 
     * @type {GracePeriod}
     * @memberof FareFee
     */
    gracePeriod?: GracePeriod;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum FareFeeType {
    Parking = 'PARKING',
    Charging = 'CHARGING',
    Session = 'SESSION',
    Start = 'START',
    Generic = 'GENERIC'
}

/**
 * 
 * @export
 * @interface FareValidityPeriod
 */
export interface FareValidityPeriod {
    /**
     * 
     * @type {DayTimeInterval}
     * @memberof FareValidityPeriod
     */
    timeInterval: DayTimeInterval;
    /**
     * 
     * @type {ValidityDay}
     * @memberof FareValidityPeriod
     */
    day: ValidityDay;
}
/**
 * 
 * @export
 * @interface FirstLoginData
 */
export interface FirstLoginData {
    /**
     * 
     * @type {string}
     * @memberof FirstLoginData
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof FirstLoginData
     */
    temporaryPassword: string;
    /**
     * 
     * @type {string}
     * @memberof FirstLoginData
     */
    newPassword: string;
}
/**
 * 
 * @export
 * @interface ForgotPasswordBody
 */
export interface ForgotPasswordBody {
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordBody
     */
    email: string;
}
/**
 * 
 * @export
 * @interface GracePeriod
 */
export interface GracePeriod {
    /**
     * 
     * @type {PriceReferenceUnit}
     * @memberof GracePeriod
     */
    priceReferenceUnit: PriceReferenceUnit;
    /**
     * 
     * @type {number}
     * @memberof GracePeriod
     */
    minimumValue: number;
}
/**
 * 
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    id: string;
}
/**
 * 
 * @export
 * @interface GroupAllOf
 */
export interface GroupAllOf {
    /**
     * 
     * @type {string}
     * @memberof GroupAllOf
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GroupAllOf
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GroupAllOf
     */
    description?: string;
}
/**
 * <ISO 639-1>-<ISO 3166-1 alpha-2>
 * @export
 * @enum {string}
 */

export enum Locale {
    ItIt = 'it-IT',
    EsEs = 'es-ES',
    NlNl = 'nl-NL'
}

/**
 * 
 * @export
 * @interface LoginData
 */
export interface LoginData {
    /**
     * 
     * @type {string}
     * @memberof LoginData
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LoginData
     */
    password: string;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    accessToken: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    idToken: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    refreshToken: string;
}
/**
 * 
 * @export
 * @interface MobileUser
 */
export interface MobileUser {
    /**
     * 
     * @type {string}
     * @memberof MobileUser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MobileUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof MobileUser
     */
    registrationDate: string;
    /**
     * 
     * @type {MobileUserStatus}
     * @memberof MobileUser
     */
    status: MobileUserStatus;
    /**
     * 
     * @type {string}
     * @memberof MobileUser
     */
    lastChargeDate: string;
    /**
     * 
     * @type {number}
     * @memberof MobileUser
     */
    amountPaid: number;
    /**
     * 
     * @type {number}
     * @memberof MobileUser
     */
    amountDue: number;
    /**
     * Specify if the user is a business user. If he has active invoice he\'s a business user
     * @type {boolean}
     * @memberof MobileUser
     */
    businessUser: boolean;
}
/**
 * 
 * @export
 * @interface MobileUserData
 */
export interface MobileUserData {
    /**
     * 
     * @type {string}
     * @memberof MobileUserData
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof MobileUserData
     */
    userId: string;
}
/**
 * 
 * @export
 * @interface MobileUserPayment
 */
export interface MobileUserPayment {
    /**
     * 
     * @type {string}
     * @memberof MobileUserPayment
     */
    cpo: string;
    /**
     * 
     * @type {string}
     * @memberof MobileUserPayment
     */
    transactionDate: string;
    /**
     * 
     * @type {number}
     * @memberof MobileUserPayment
     */
    amountDue: number;
    /**
     * 
     * @type {number}
     * @memberof MobileUserPayment
     */
    amountCollected: number;
}
/**
 * 
 * @export
 * @interface MobileUserSearchResponse
 */
export interface MobileUserSearchResponse {
    /**
     * 
     * @type {number}
     * @memberof MobileUserSearchResponse
     */
    totalNumberOfItems: number;
    /**
     * 
     * @type {Array<MobileUser>}
     * @memberof MobileUserSearchResponse
     */
    items: Array<MobileUser>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum MobileUserStatus {
    Active = 'ACTIVE',
    Suspended = 'SUSPENDED',
    Blocked = 'BLOCKED',
    Unconfirmed = 'UNCONFIRMED'
}

/**
 * 
 * @export
 * @interface Operation
 */
export interface Operation {
    /**
     * 
     * @type {string}
     * @memberof Operation
     */
    dateTime: string;
    /**
     * 
     * @type {string}
     * @memberof Operation
     */
    operatorName: string;
    /**
     * 
     * @type {string}
     * @memberof Operation
     */
    operatorEmail: string;
}
/**
 * 
 * @export
 * @interface OtherFee
 */
export interface OtherFee {
    /**
     * 
     * @type {FareFeeType}
     * @memberof OtherFee
     */
    type: FareFeeType;
    /**
     * 
     * @type {FareFee}
     * @memberof OtherFee
     */
    fee: FareFee;
}
/**
 * 
 * @export
 * @interface PaginatedSearchResult
 */
export interface PaginatedSearchResult {
    /**
     * 
     * @type {number}
     * @memberof PaginatedSearchResult
     */
    totalNumberOfItems: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof PaginatedSearchResult
     */
    items: Array<object>;
}
/**
 * 
 * @export
 * @interface PaymentSearchResult
 */
export interface PaymentSearchResult {
    /**
     * 
     * @type {string}
     * @memberof PaymentSearchResult
     */
    cpo: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSearchResult
     */
    transactionDate: string;
    /**
     * the amount to be paid to the CPO
     * @type {number}
     * @memberof PaymentSearchResult
     */
    amountDue: number;
    /**
     * the amount to be paid to InnovationClub by the user
     * @type {number}
     * @memberof PaymentSearchResult
     */
    amountCollected: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentSearchResult
     */
    isPaymentFailed: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentSearchResult
     */
    userId: string;
}
/**
 * 
 * @export
 * @interface PaymentsSearchResult
 */
export interface PaymentsSearchResult {
    /**
     * 
     * @type {number}
     * @memberof PaymentsSearchResult
     */
    totalNumberOfItems: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentsSearchResult
     */
    totalAmountDue: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentsSearchResult
     */
    totalAmountCollected: number;
    /**
     * 
     * @type {Array<PaymentSearchResult>}
     * @memberof PaymentsSearchResult
     */
    items: Array<PaymentSearchResult>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PriceReferenceUnit {
    Hour = 'HOUR',
    KilowattHour = 'KILOWATT_HOUR',
    Minute = 'MINUTE'
}

/**
 * 
 * @export
 * @interface ProviderAnagraphic
 */
export interface ProviderAnagraphic {
    /**
     * 
     * @type {string}
     * @memberof ProviderAnagraphic
     */
    cpoId: string;
    /**
     * Name of the CPO Provider
     * @type {string}
     * @memberof ProviderAnagraphic
     */
    cpoName: string;
    /**
     * ISO 3166-1 alpha-2
     * @type {string}
     * @memberof ProviderAnagraphic
     */
    country: string;
    /**
     * 
     * @type {Array<SocketTypePowerRange>}
     * @memberof ProviderAnagraphic
     */
    socketTypePowerRanges?: Array<SocketTypePowerRange>;
}
/**
 * 
 * @export
 * @interface ProviderContractsSearchResultItem
 */
export interface ProviderContractsSearchResultItem {
    /**
     * 
     * @type {string}
     * @memberof ProviderContractsSearchResultItem
     */
    contractId: string;
    /**
     * 
     * @type {number}
     * @memberof ProviderContractsSearchResultItem
     */
    inflation: number;
    /**
     * 
     * @type {ContractValidityPeriod}
     * @memberof ProviderContractsSearchResultItem
     */
    validityPeriod: ContractValidityPeriod;
    /**
     * 
     * @type {ContractType}
     * @memberof ProviderContractsSearchResultItem
     */
    type: ContractType;
    /**
     * 
     * @type {string}
     * @memberof ProviderContractsSearchResultItem
     */
    ngpContractFilename?: string;
    /**
     * 
     * @type {Operation}
     * @memberof ProviderContractsSearchResultItem
     */
    creation: Operation;
    /**
     * 
     * @type {Operation}
     * @memberof ProviderContractsSearchResultItem
     */
    lastChange: Operation;
    /**
     * 
     * @type {ContractStatus}
     * @memberof ProviderContractsSearchResultItem
     */
    status: ContractStatus;
    /**
     * 
     * @type {string}
     * @memberof ProviderContractsSearchResultItem
     */
    operatorNotes?: string;
    /**
     * 
     * @type {ContractContactPerson}
     * @memberof ProviderContractsSearchResultItem
     */
    contactPerson?: ContractContactPerson;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderContractsSearchResultItem
     */
    isBlocked: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProviderContractsSearchResultItem
     */
    kWhDelivered: number;
    /**
     * 
     * @type {number}
     * @memberof ProviderContractsSearchResultItem
     */
    chargesCount: number;
    /**
     * 
     * @type {number}
     * @memberof ProviderContractsSearchResultItem
     */
    costsAmount: number;
    /**
     * 
     * @type {number}
     * @memberof ProviderContractsSearchResultItem
     */
    earningsAmount: number;
    /**
     * true if there is at least a fare  with a specific inflation set otherwise false 
     * @type {boolean}
     * @memberof ProviderContractsSearchResultItem
     */
    hasFareSpecificInflation: boolean;
}
/**
 * 
 * @export
 * @interface ProviderContractsSearchResultItemAllOf
 */
export interface ProviderContractsSearchResultItemAllOf {
    /**
     * 
     * @type {number}
     * @memberof ProviderContractsSearchResultItemAllOf
     */
    kWhDelivered: number;
    /**
     * 
     * @type {number}
     * @memberof ProviderContractsSearchResultItemAllOf
     */
    chargesCount: number;
    /**
     * 
     * @type {number}
     * @memberof ProviderContractsSearchResultItemAllOf
     */
    costsAmount: number;
    /**
     * 
     * @type {number}
     * @memberof ProviderContractsSearchResultItemAllOf
     */
    earningsAmount: number;
    /**
     * 
     * @type {number}
     * @memberof ProviderContractsSearchResultItemAllOf
     */
    inflation: number;
    /**
     * true if there is at least a fare  with a specific inflation set otherwise false 
     * @type {boolean}
     * @memberof ProviderContractsSearchResultItemAllOf
     */
    hasFareSpecificInflation: boolean;
}
/**
 * 
 * @export
 * @interface ProvidersSearchResult
 */
export interface ProvidersSearchResult {
    /**
     * 
     * @type {number}
     * @memberof ProvidersSearchResult
     */
    totalNumberOfItems: number;
    /**
     * 
     * @type {Array<ProvidersSearchResultItem>}
     * @memberof ProvidersSearchResult
     */
    items: Array<ProvidersSearchResultItem>;
}
/**
 * 
 * @export
 * @interface ProvidersSearchResultAllOf
 */
export interface ProvidersSearchResultAllOf {
    /**
     * 
     * @type {Array<ProvidersSearchResultItem>}
     * @memberof ProvidersSearchResultAllOf
     */
    items?: Array<ProvidersSearchResultItem>;
}
/**
 * 
 * @export
 * @interface ProvidersSearchResultItem
 */
export interface ProvidersSearchResultItem {
    /**
     * 
     * @type {string}
     * @memberof ProvidersSearchResultItem
     */
    cpoId: string;
    /**
     * Name of the CPO Provider
     * @type {string}
     * @memberof ProvidersSearchResultItem
     */
    cpoName: string;
    /**
     * ISO 3166-1 alpha-2
     * @type {string}
     * @memberof ProvidersSearchResultItem
     */
    country: string;
    /**
     * 
     * @type {Array<SocketTypePowerRange>}
     * @memberof ProvidersSearchResultItem
     */
    socketTypePowerRanges?: Array<SocketTypePowerRange>;
    /**
     * 
     * @type {ContractAnagraphic}
     * @memberof ProvidersSearchResultItem
     */
    activeContract?: ContractAnagraphic;
    /**
     * 
     * @type {number}
     * @memberof ProvidersSearchResultItem
     */
    kWhDelivered: number;
    /**
     * 
     * @type {number}
     * @memberof ProvidersSearchResultItem
     */
    chargesCount: number;
    /**
     * 
     * @type {number}
     * @memberof ProvidersSearchResultItem
     */
    costsAmount: number;
    /**
     * 
     * @type {number}
     * @memberof ProvidersSearchResultItem
     */
    earningsAmount: number;
    /**
     * 
     * @type {ProvidersSearchResultItemValueStatus}
     * @memberof ProvidersSearchResultItem
     */
    deliveredkWhStatus: ProvidersSearchResultItemValueStatus;
    /**
     * 
     * @type {ProvidersSearchResultItemValueStatus}
     * @memberof ProvidersSearchResultItem
     */
    chargesCountStatus: ProvidersSearchResultItemValueStatus;
    /**
     * 
     * @type {ProvidersSearchResultItemValueStatus}
     * @memberof ProvidersSearchResultItem
     */
    earningsAmountStatus: ProvidersSearchResultItemValueStatus;
}
/**
 * 
 * @export
 * @interface ProvidersSearchResultItemAllOf
 */
export interface ProvidersSearchResultItemAllOf {
    /**
     * 
     * @type {ContractAnagraphic}
     * @memberof ProvidersSearchResultItemAllOf
     */
    activeContract?: ContractAnagraphic;
    /**
     * 
     * @type {number}
     * @memberof ProvidersSearchResultItemAllOf
     */
    kWhDelivered: number;
    /**
     * 
     * @type {number}
     * @memberof ProvidersSearchResultItemAllOf
     */
    chargesCount: number;
    /**
     * 
     * @type {number}
     * @memberof ProvidersSearchResultItemAllOf
     */
    costsAmount: number;
    /**
     * 
     * @type {number}
     * @memberof ProvidersSearchResultItemAllOf
     */
    earningsAmount: number;
    /**
     * 
     * @type {ProvidersSearchResultItemValueStatus}
     * @memberof ProvidersSearchResultItemAllOf
     */
    deliveredkWhStatus: ProvidersSearchResultItemValueStatus;
    /**
     * 
     * @type {ProvidersSearchResultItemValueStatus}
     * @memberof ProvidersSearchResultItemAllOf
     */
    chargesCountStatus: ProvidersSearchResultItemValueStatus;
    /**
     * 
     * @type {ProvidersSearchResultItemValueStatus}
     * @memberof ProvidersSearchResultItemAllOf
     */
    earningsAmountStatus: ProvidersSearchResultItemValueStatus;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ProvidersSearchResultItemValueStatus {
    Max = 'MAX',
    Min = 'MIN',
    None = 'NONE'
}

/**
 * 
 * @export
 * @interface RangeValue
 */
export interface RangeValue {
    /**
     * 
     * @type {number}
     * @memberof RangeValue
     */
    value: number;
    /**
     * 
     * @type {RangeValueCondition}
     * @memberof RangeValue
     */
    condition: RangeValueCondition;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum RangeValueCondition {
    Inclusive = 'INCLUSIVE',
    Exclusive = 'EXCLUSIVE'
}

/**
 * 
 * @export
 * @interface RegisterBackofficeUser
 */
export interface RegisterBackofficeUser {
    /**
     * Email linked to the user
     * @type {string}
     * @memberof RegisterBackofficeUser
     */
    email: string;
    /**
     * ISO 3166-1 alpha-2
     * @type {string}
     * @memberof RegisterBackofficeUser
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterBackofficeUser
     */
    telephone?: string;
    /**
     * 
     * @type {BackofficeRole}
     * @memberof RegisterBackofficeUser
     */
    role: BackofficeRole;
    /**
     * Full name of the user
     * @type {string}
     * @memberof RegisterBackofficeUser
     */
    name: string;
    /**
     * 
     * @type {Locale}
     * @memberof RegisterBackofficeUser
     */
    locale: Locale;
    /**
     * 
     * @type {string}
     * @memberof RegisterBackofficeUser
     */
    contact_details: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum SessionMoment {
    SessionStart = 'SESSION_START',
    ChargingStart = 'CHARGING_START',
    ChargingEnd = 'CHARGING_END',
    SessionEnd = 'SESSION_END'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum SocketType {
    Ac = 'AC',
    Dc = 'DC',
    HpcUfc = 'HPC_UFC'
}

/**
 * 
 * @export
 * @interface SocketTypePowerRange
 */
export interface SocketTypePowerRange {
    /**
     * 
     * @type {SocketType}
     * @memberof SocketTypePowerRange
     */
    socketType: SocketType;
    /**
     * 
     * @type {ChargingPowerRange}
     * @memberof SocketTypePowerRange
     */
    powerRange: ChargingPowerRange;
    /**
     * 
     * @type {SocketType}
     * @memberof SocketTypePowerRange
     */
    newSocketType: SocketType;
}
/**
 * 
 * @export
 * @interface SuccessResponse
 */
export interface SuccessResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SuccessResponse
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateMobileUserStatus
 */
export interface UpdateMobileUserStatus {
    /**
     * 
     * @type {string}
     * @memberof UpdateMobileUserStatus
     */
    status?: UpdateMobileUserStatusStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateMobileUserStatusStatusEnum {
    Active = 'ACTIVE',
    Suspended = 'SUSPENDED',
    Blocked = 'BLOCKED'
}

/**
 * 
 * @export
 * @interface UpdateProviderData
 */
export interface UpdateProviderData {
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderData
     */
    cpoId?: string;
    /**
     * Name of the CPO Provider
     * @type {string}
     * @memberof UpdateProviderData
     */
    cpoName?: string;
    /**
     * 
     * @type {Array<SocketTypePowerRange>}
     * @memberof UpdateProviderData
     */
    socketTypePowerRanges?: Array<SocketTypePowerRange>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ValidityDay {
    Everyday = 'EVERYDAY',
    Workdays = 'WORKDAYS',
    Weekend = 'WEEKEND',
    Monday = 'MONDAY',
    Tuesday = 'TUESDAY',
    Wednesday = 'WEDNESDAY',
    Thursday = 'THURSDAY',
    Friday = 'FRIDAY',
    Saturday = 'SATURDAY',
    Sunday = 'SUNDAY'
}


/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add new backoffice user
         * @param {string} xIdToken The id token
         * @param {RegisterBackofficeUser} [registerBackofficeUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBackofficeUser: async (xIdToken: string, registerBackofficeUser?: RegisterBackofficeUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('addBackofficeUser', 'xIdToken', xIdToken)
            const localVarPath = `/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerBackofficeUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Admin update backoffice user
         * @param {string} userId ID of the user
         * @param {string} xIdToken The id token
         * @param {AdminUpdateBackofficeUser} [adminUpdateBackofficeUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateBackofficeUser: async (userId: string, xIdToken: string, adminUpdateBackofficeUser?: AdminUpdateBackofficeUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('adminUpdateBackofficeUser', 'userId', userId)
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('adminUpdateBackofficeUser', 'xIdToken', xIdToken)
            const localVarPath = `/admin/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateBackofficeUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete backoffice user
         * @param {string} userId ID of the user
         * @param {string} xIdToken The id token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBackofficeUser: async (userId: string, xIdToken: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteBackofficeUser', 'userId', userId)
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('deleteBackofficeUser', 'xIdToken', xIdToken)
            const localVarPath = `/admin/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of backoffice users
         * @param {string} xIdToken The id token
         * @param {number} [offset] The number of items to be skipped.
         * @param {number} [limit] The number of items to return.
         * @param {string} [sort] field:order  - field: the name of the field to be used for ordering   - the allowe fields are: name, country, email and role  - ordering: asc (for ascending) or desc (for descending)  example: name:asc 
         * @param {string} [freeText] The items will be filtered for both email and name. 
         * @param {BackofficeRole} [role] 
         * @param {string} [country] Country to filter by  ISO 3166-1 alpha-2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBackofficeUsersData: async (xIdToken: string, offset?: number, limit?: number, sort?: string, freeText?: string, role?: BackofficeRole, country?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('getBackofficeUsersData', 'xIdToken', xIdToken)
            const localVarPath = `/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (freeText !== undefined) {
                localVarQueryParameter['freeText'] = freeText;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add new backoffice user
         * @param {string} xIdToken The id token
         * @param {RegisterBackofficeUser} [registerBackofficeUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBackofficeUser(xIdToken: string, registerBackofficeUser?: RegisterBackofficeUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addBackofficeUser(xIdToken, registerBackofficeUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Admin update backoffice user
         * @param {string} userId ID of the user
         * @param {string} xIdToken The id token
         * @param {AdminUpdateBackofficeUser} [adminUpdateBackofficeUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateBackofficeUser(userId: string, xIdToken: string, adminUpdateBackofficeUser?: AdminUpdateBackofficeUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateBackofficeUser(userId, xIdToken, adminUpdateBackofficeUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete backoffice user
         * @param {string} userId ID of the user
         * @param {string} xIdToken The id token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBackofficeUser(userId: string, xIdToken: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBackofficeUser(userId, xIdToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of backoffice users
         * @param {string} xIdToken The id token
         * @param {number} [offset] The number of items to be skipped.
         * @param {number} [limit] The number of items to return.
         * @param {string} [sort] field:order  - field: the name of the field to be used for ordering   - the allowe fields are: name, country, email and role  - ordering: asc (for ascending) or desc (for descending)  example: name:asc 
         * @param {string} [freeText] The items will be filtered for both email and name. 
         * @param {BackofficeRole} [role] 
         * @param {string} [country] Country to filter by  ISO 3166-1 alpha-2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBackofficeUsersData(xIdToken: string, offset?: number, limit?: number, sort?: string, freeText?: string, role?: BackofficeRole, country?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackofficeUserSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBackofficeUsersData(xIdToken, offset, limit, sort, freeText, role, country, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Add new backoffice user
         * @param {string} xIdToken The id token
         * @param {RegisterBackofficeUser} [registerBackofficeUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBackofficeUser(xIdToken: string, registerBackofficeUser?: RegisterBackofficeUser, options?: any): AxiosPromise<void> {
            return localVarFp.addBackofficeUser(xIdToken, registerBackofficeUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Admin update backoffice user
         * @param {string} userId ID of the user
         * @param {string} xIdToken The id token
         * @param {AdminUpdateBackofficeUser} [adminUpdateBackofficeUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateBackofficeUser(userId: string, xIdToken: string, adminUpdateBackofficeUser?: AdminUpdateBackofficeUser, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateBackofficeUser(userId, xIdToken, adminUpdateBackofficeUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete backoffice user
         * @param {string} userId ID of the user
         * @param {string} xIdToken The id token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBackofficeUser(userId: string, xIdToken: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBackofficeUser(userId, xIdToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of backoffice users
         * @param {string} xIdToken The id token
         * @param {number} [offset] The number of items to be skipped.
         * @param {number} [limit] The number of items to return.
         * @param {string} [sort] field:order  - field: the name of the field to be used for ordering   - the allowe fields are: name, country, email and role  - ordering: asc (for ascending) or desc (for descending)  example: name:asc 
         * @param {string} [freeText] The items will be filtered for both email and name. 
         * @param {BackofficeRole} [role] 
         * @param {string} [country] Country to filter by  ISO 3166-1 alpha-2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBackofficeUsersData(xIdToken: string, offset?: number, limit?: number, sort?: string, freeText?: string, role?: BackofficeRole, country?: string, options?: any): AxiosPromise<BackofficeUserSearchResponse> {
            return localVarFp.getBackofficeUsersData(xIdToken, offset, limit, sort, freeText, role, country, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addBackofficeUser operation in AdminApi.
 * @export
 * @interface AdminApiAddBackofficeUserRequest
 */
export interface AdminApiAddBackofficeUserRequest {
    /**
     * The id token
     * @type {string}
     * @memberof AdminApiAddBackofficeUser
     */
    readonly xIdToken: string

    /**
     * 
     * @type {RegisterBackofficeUser}
     * @memberof AdminApiAddBackofficeUser
     */
    readonly registerBackofficeUser?: RegisterBackofficeUser
}

/**
 * Request parameters for adminUpdateBackofficeUser operation in AdminApi.
 * @export
 * @interface AdminApiAdminUpdateBackofficeUserRequest
 */
export interface AdminApiAdminUpdateBackofficeUserRequest {
    /**
     * ID of the user
     * @type {string}
     * @memberof AdminApiAdminUpdateBackofficeUser
     */
    readonly userId: string

    /**
     * The id token
     * @type {string}
     * @memberof AdminApiAdminUpdateBackofficeUser
     */
    readonly xIdToken: string

    /**
     * 
     * @type {AdminUpdateBackofficeUser}
     * @memberof AdminApiAdminUpdateBackofficeUser
     */
    readonly adminUpdateBackofficeUser?: AdminUpdateBackofficeUser
}

/**
 * Request parameters for deleteBackofficeUser operation in AdminApi.
 * @export
 * @interface AdminApiDeleteBackofficeUserRequest
 */
export interface AdminApiDeleteBackofficeUserRequest {
    /**
     * ID of the user
     * @type {string}
     * @memberof AdminApiDeleteBackofficeUser
     */
    readonly userId: string

    /**
     * The id token
     * @type {string}
     * @memberof AdminApiDeleteBackofficeUser
     */
    readonly xIdToken: string
}

/**
 * Request parameters for getBackofficeUsersData operation in AdminApi.
 * @export
 * @interface AdminApiGetBackofficeUsersDataRequest
 */
export interface AdminApiGetBackofficeUsersDataRequest {
    /**
     * The id token
     * @type {string}
     * @memberof AdminApiGetBackofficeUsersData
     */
    readonly xIdToken: string

    /**
     * The number of items to be skipped.
     * @type {number}
     * @memberof AdminApiGetBackofficeUsersData
     */
    readonly offset?: number

    /**
     * The number of items to return.
     * @type {number}
     * @memberof AdminApiGetBackofficeUsersData
     */
    readonly limit?: number

    /**
     * field:order  - field: the name of the field to be used for ordering   - the allowe fields are: name, country, email and role  - ordering: asc (for ascending) or desc (for descending)  example: name:asc 
     * @type {string}
     * @memberof AdminApiGetBackofficeUsersData
     */
    readonly sort?: string

    /**
     * The items will be filtered for both email and name. 
     * @type {string}
     * @memberof AdminApiGetBackofficeUsersData
     */
    readonly freeText?: string

    /**
     * 
     * @type {BackofficeRole}
     * @memberof AdminApiGetBackofficeUsersData
     */
    readonly role?: BackofficeRole

    /**
     * Country to filter by  ISO 3166-1 alpha-2 
     * @type {string}
     * @memberof AdminApiGetBackofficeUsersData
     */
    readonly country?: string
}

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @summary Add new backoffice user
     * @param {AdminApiAddBackofficeUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public addBackofficeUser(requestParameters: AdminApiAddBackofficeUserRequest, options?: any) {
        return AdminApiFp(this.configuration).addBackofficeUser(requestParameters.xIdToken, requestParameters.registerBackofficeUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Admin update backoffice user
     * @param {AdminApiAdminUpdateBackofficeUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpdateBackofficeUser(requestParameters: AdminApiAdminUpdateBackofficeUserRequest, options?: any) {
        return AdminApiFp(this.configuration).adminUpdateBackofficeUser(requestParameters.userId, requestParameters.xIdToken, requestParameters.adminUpdateBackofficeUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete backoffice user
     * @param {AdminApiDeleteBackofficeUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteBackofficeUser(requestParameters: AdminApiDeleteBackofficeUserRequest, options?: any) {
        return AdminApiFp(this.configuration).deleteBackofficeUser(requestParameters.userId, requestParameters.xIdToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of backoffice users
     * @param {AdminApiGetBackofficeUsersDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getBackofficeUsersData(requestParameters: AdminApiGetBackofficeUsersDataRequest, options?: any) {
        return AdminApiFp(this.configuration).getBackofficeUsersData(requestParameters.xIdToken, requestParameters.offset, requestParameters.limit, requestParameters.sort, requestParameters.freeText, requestParameters.role, requestParameters.country, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The user has to set a new password replacing the temporary password at the first login
         * @summary First login of a user registered by an ADMIN
         * @param {FirstLoginData} firstLoginData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firstLogin: async (firstLoginData: FirstLoginData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'firstLoginData' is not null or undefined
            assertParamExists('firstLogin', 'firstLoginData', firstLoginData)
            const localVarPath = `/auth/firstLogin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(firstLoginData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Forgot password
         * @param {ForgotPasswordBody} forgotPasswordBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: async (forgotPasswordBody: ForgotPasswordBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPasswordBody' is not null or undefined
            assertParamExists('forgotPassword', 'forgotPasswordBody', forgotPasswordBody)
            const localVarPath = `/auth/forgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Request for the user to log into the system
         * @summary Log user into the system
         * @param {LoginData} loginData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUser: async (loginData: LoginData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginData' is not null or undefined
            assertParamExists('loginUser', 'loginData', loginData)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh user token
         * @param {string} xRefreshToken The refresh token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: async (xRefreshToken: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xRefreshToken' is not null or undefined
            assertParamExists('refreshToken', 'xRefreshToken', xRefreshToken)
            const localVarPath = `/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xRefreshToken !== undefined && xRefreshToken !== null) {
                localVarHeaderParameter['x-refresh-token'] = String(xRefreshToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User reset password
         * @param {ConfirmForgotPasswordBody} confirmForgotPasswordBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userResetPassword: async (confirmForgotPasswordBody: ConfirmForgotPasswordBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'confirmForgotPasswordBody' is not null or undefined
            assertParamExists('userResetPassword', 'confirmForgotPasswordBody', confirmForgotPasswordBody)
            const localVarPath = `/auth/resetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmForgotPasswordBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * The user has to set a new password replacing the temporary password at the first login
         * @summary First login of a user registered by an ADMIN
         * @param {FirstLoginData} firstLoginData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async firstLogin(firstLoginData: FirstLoginData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.firstLogin(firstLoginData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Forgot password
         * @param {ForgotPasswordBody} forgotPasswordBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPassword(forgotPasswordBody: ForgotPasswordBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPassword(forgotPasswordBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Request for the user to log into the system
         * @summary Log user into the system
         * @param {LoginData} loginData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginUser(loginData: LoginData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginUser(loginData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Refresh user token
         * @param {string} xRefreshToken The refresh token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshToken(xRefreshToken: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(xRefreshToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User reset password
         * @param {ConfirmForgotPasswordBody} confirmForgotPasswordBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userResetPassword(confirmForgotPasswordBody: ConfirmForgotPasswordBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userResetPassword(confirmForgotPasswordBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * The user has to set a new password replacing the temporary password at the first login
         * @summary First login of a user registered by an ADMIN
         * @param {FirstLoginData} firstLoginData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firstLogin(firstLoginData: FirstLoginData, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.firstLogin(firstLoginData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Forgot password
         * @param {ForgotPasswordBody} forgotPasswordBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(forgotPasswordBody: ForgotPasswordBody, options?: any): AxiosPromise<void> {
            return localVarFp.forgotPassword(forgotPasswordBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Request for the user to log into the system
         * @summary Log user into the system
         * @param {LoginData} loginData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUser(loginData: LoginData, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.loginUser(loginData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh user token
         * @param {string} xRefreshToken The refresh token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(xRefreshToken: string, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.refreshToken(xRefreshToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User reset password
         * @param {ConfirmForgotPasswordBody} confirmForgotPasswordBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userResetPassword(confirmForgotPasswordBody: ConfirmForgotPasswordBody, options?: any): AxiosPromise<void> {
            return localVarFp.userResetPassword(confirmForgotPasswordBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for firstLogin operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiFirstLoginRequest
 */
export interface AuthenticationApiFirstLoginRequest {
    /**
     * 
     * @type {FirstLoginData}
     * @memberof AuthenticationApiFirstLogin
     */
    readonly firstLoginData: FirstLoginData
}

/**
 * Request parameters for forgotPassword operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiForgotPasswordRequest
 */
export interface AuthenticationApiForgotPasswordRequest {
    /**
     * 
     * @type {ForgotPasswordBody}
     * @memberof AuthenticationApiForgotPassword
     */
    readonly forgotPasswordBody: ForgotPasswordBody
}

/**
 * Request parameters for loginUser operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiLoginUserRequest
 */
export interface AuthenticationApiLoginUserRequest {
    /**
     * 
     * @type {LoginData}
     * @memberof AuthenticationApiLoginUser
     */
    readonly loginData: LoginData
}

/**
 * Request parameters for refreshToken operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiRefreshTokenRequest
 */
export interface AuthenticationApiRefreshTokenRequest {
    /**
     * The refresh token
     * @type {string}
     * @memberof AuthenticationApiRefreshToken
     */
    readonly xRefreshToken: string
}

/**
 * Request parameters for userResetPassword operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiUserResetPasswordRequest
 */
export interface AuthenticationApiUserResetPasswordRequest {
    /**
     * 
     * @type {ConfirmForgotPasswordBody}
     * @memberof AuthenticationApiUserResetPassword
     */
    readonly confirmForgotPasswordBody: ConfirmForgotPasswordBody
}

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * The user has to set a new password replacing the temporary password at the first login
     * @summary First login of a user registered by an ADMIN
     * @param {AuthenticationApiFirstLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public firstLogin(requestParameters: AuthenticationApiFirstLoginRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).firstLogin(requestParameters.firstLoginData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Forgot password
     * @param {AuthenticationApiForgotPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public forgotPassword(requestParameters: AuthenticationApiForgotPasswordRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).forgotPassword(requestParameters.forgotPasswordBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Request for the user to log into the system
     * @summary Log user into the system
     * @param {AuthenticationApiLoginUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public loginUser(requestParameters: AuthenticationApiLoginUserRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).loginUser(requestParameters.loginData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh user token
     * @param {AuthenticationApiRefreshTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public refreshToken(requestParameters: AuthenticationApiRefreshTokenRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).refreshToken(requestParameters.xRefreshToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User reset password
     * @param {AuthenticationApiUserResetPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public userResetPassword(requestParameters: AuthenticationApiUserResetPasswordRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).userResetPassword(requestParameters.confirmForgotPasswordBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MobileUserApi - axios parameter creator
 * @export
 */
export const MobileUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete mobile user
         * @param {string} userId ID of the user
         * @param {string} xIdToken The id token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMobileUser: async (userId: string, xIdToken: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteMobileUser', 'userId', userId)
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('deleteMobileUser', 'xIdToken', xIdToken)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get mobile user
         * @param {string} xIdToken The id token
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMobileUser: async (xIdToken: string, userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('getMobileUser', 'xIdToken', xIdToken)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getMobileUser', 'userId', userId)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of mobile users
         * @param {string} xIdToken The id token
         * @param {boolean} businessUser Filters users that have invoice enabled
         * @param {boolean} privateUser Filters users that doesn\&#39;t have invoice enabled
         * @param {number} [offset] The number of items to be skipped.
         * @param {number} [limit] The number of items to return.
         * @param {MobileUserStatus} [status] 
         * @param {string} [sort] field:order  - field: the name of the field to be used for ordering      the allowed fields are:     - email     - registrationDate     - lastChargeDate     - amountPaid     - amountDue  - ordering: asc (for ascending) or desc (for descending)  example: email:asc 
         * @param {string} [freeText] The items will be filtered by email. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMobileUsersData: async (xIdToken: string, businessUser: boolean, privateUser: boolean, offset?: number, limit?: number, status?: MobileUserStatus, sort?: string, freeText?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('getMobileUsersData', 'xIdToken', xIdToken)
            // verify required parameter 'businessUser' is not null or undefined
            assertParamExists('getMobileUsersData', 'businessUser', businessUser)
            // verify required parameter 'privateUser' is not null or undefined
            assertParamExists('getMobileUsersData', 'privateUser', privateUser)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (businessUser !== undefined) {
                localVarQueryParameter['businessUser'] = businessUser;
            }

            if (privateUser !== undefined) {
                localVarQueryParameter['privateUser'] = privateUser;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (freeText !== undefined) {
                localVarQueryParameter['freeText'] = freeText;
            }

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update mobile user status
         * @param {string} userId ID of the user
         * @param {string} xIdToken The id token
         * @param {UpdateMobileUserStatus} [updateMobileUserStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMobileUserStatus: async (userId: string, xIdToken: string, updateMobileUserStatus?: UpdateMobileUserStatus, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateMobileUserStatus', 'userId', userId)
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('updateMobileUserStatus', 'xIdToken', xIdToken)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMobileUserStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileUserApi - functional programming interface
 * @export
 */
export const MobileUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete mobile user
         * @param {string} userId ID of the user
         * @param {string} xIdToken The id token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMobileUser(userId: string, xIdToken: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMobileUser(userId, xIdToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get mobile user
         * @param {string} xIdToken The id token
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMobileUser(xIdToken: string, userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileUserData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMobileUser(xIdToken, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of mobile users
         * @param {string} xIdToken The id token
         * @param {boolean} businessUser Filters users that have invoice enabled
         * @param {boolean} privateUser Filters users that doesn\&#39;t have invoice enabled
         * @param {number} [offset] The number of items to be skipped.
         * @param {number} [limit] The number of items to return.
         * @param {MobileUserStatus} [status] 
         * @param {string} [sort] field:order  - field: the name of the field to be used for ordering      the allowed fields are:     - email     - registrationDate     - lastChargeDate     - amountPaid     - amountDue  - ordering: asc (for ascending) or desc (for descending)  example: email:asc 
         * @param {string} [freeText] The items will be filtered by email. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMobileUsersData(xIdToken: string, businessUser: boolean, privateUser: boolean, offset?: number, limit?: number, status?: MobileUserStatus, sort?: string, freeText?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileUserSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMobileUsersData(xIdToken, businessUser, privateUser, offset, limit, status, sort, freeText, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update mobile user status
         * @param {string} userId ID of the user
         * @param {string} xIdToken The id token
         * @param {UpdateMobileUserStatus} [updateMobileUserStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMobileUserStatus(userId: string, xIdToken: string, updateMobileUserStatus?: UpdateMobileUserStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMobileUserStatus(userId, xIdToken, updateMobileUserStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MobileUserApi - factory interface
 * @export
 */
export const MobileUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileUserApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete mobile user
         * @param {string} userId ID of the user
         * @param {string} xIdToken The id token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMobileUser(userId: string, xIdToken: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMobileUser(userId, xIdToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get mobile user
         * @param {string} xIdToken The id token
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMobileUser(xIdToken: string, userId: string, options?: any): AxiosPromise<MobileUserData> {
            return localVarFp.getMobileUser(xIdToken, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of mobile users
         * @param {string} xIdToken The id token
         * @param {boolean} businessUser Filters users that have invoice enabled
         * @param {boolean} privateUser Filters users that doesn\&#39;t have invoice enabled
         * @param {number} [offset] The number of items to be skipped.
         * @param {number} [limit] The number of items to return.
         * @param {MobileUserStatus} [status] 
         * @param {string} [sort] field:order  - field: the name of the field to be used for ordering      the allowed fields are:     - email     - registrationDate     - lastChargeDate     - amountPaid     - amountDue  - ordering: asc (for ascending) or desc (for descending)  example: email:asc 
         * @param {string} [freeText] The items will be filtered by email. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMobileUsersData(xIdToken: string, businessUser: boolean, privateUser: boolean, offset?: number, limit?: number, status?: MobileUserStatus, sort?: string, freeText?: string, options?: any): AxiosPromise<MobileUserSearchResponse> {
            return localVarFp.getMobileUsersData(xIdToken, businessUser, privateUser, offset, limit, status, sort, freeText, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update mobile user status
         * @param {string} userId ID of the user
         * @param {string} xIdToken The id token
         * @param {UpdateMobileUserStatus} [updateMobileUserStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMobileUserStatus(userId: string, xIdToken: string, updateMobileUserStatus?: UpdateMobileUserStatus, options?: any): AxiosPromise<void> {
            return localVarFp.updateMobileUserStatus(userId, xIdToken, updateMobileUserStatus, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteMobileUser operation in MobileUserApi.
 * @export
 * @interface MobileUserApiDeleteMobileUserRequest
 */
export interface MobileUserApiDeleteMobileUserRequest {
    /**
     * ID of the user
     * @type {string}
     * @memberof MobileUserApiDeleteMobileUser
     */
    readonly userId: string

    /**
     * The id token
     * @type {string}
     * @memberof MobileUserApiDeleteMobileUser
     */
    readonly xIdToken: string
}

/**
 * Request parameters for getMobileUser operation in MobileUserApi.
 * @export
 * @interface MobileUserApiGetMobileUserRequest
 */
export interface MobileUserApiGetMobileUserRequest {
    /**
     * The id token
     * @type {string}
     * @memberof MobileUserApiGetMobileUser
     */
    readonly xIdToken: string

    /**
     * ID of the user
     * @type {string}
     * @memberof MobileUserApiGetMobileUser
     */
    readonly userId: string
}

/**
 * Request parameters for getMobileUsersData operation in MobileUserApi.
 * @export
 * @interface MobileUserApiGetMobileUsersDataRequest
 */
export interface MobileUserApiGetMobileUsersDataRequest {
    /**
     * The id token
     * @type {string}
     * @memberof MobileUserApiGetMobileUsersData
     */
    readonly xIdToken: string

    /**
     * Filters users that have invoice enabled
     * @type {boolean}
     * @memberof MobileUserApiGetMobileUsersData
     */
    readonly businessUser: boolean

    /**
     * Filters users that doesn\&#39;t have invoice enabled
     * @type {boolean}
     * @memberof MobileUserApiGetMobileUsersData
     */
    readonly privateUser: boolean

    /**
     * The number of items to be skipped.
     * @type {number}
     * @memberof MobileUserApiGetMobileUsersData
     */
    readonly offset?: number

    /**
     * The number of items to return.
     * @type {number}
     * @memberof MobileUserApiGetMobileUsersData
     */
    readonly limit?: number

    /**
     * 
     * @type {MobileUserStatus}
     * @memberof MobileUserApiGetMobileUsersData
     */
    readonly status?: MobileUserStatus

    /**
     * field:order  - field: the name of the field to be used for ordering      the allowed fields are:     - email     - registrationDate     - lastChargeDate     - amountPaid     - amountDue  - ordering: asc (for ascending) or desc (for descending)  example: email:asc 
     * @type {string}
     * @memberof MobileUserApiGetMobileUsersData
     */
    readonly sort?: string

    /**
     * The items will be filtered by email. 
     * @type {string}
     * @memberof MobileUserApiGetMobileUsersData
     */
    readonly freeText?: string
}

/**
 * Request parameters for updateMobileUserStatus operation in MobileUserApi.
 * @export
 * @interface MobileUserApiUpdateMobileUserStatusRequest
 */
export interface MobileUserApiUpdateMobileUserStatusRequest {
    /**
     * ID of the user
     * @type {string}
     * @memberof MobileUserApiUpdateMobileUserStatus
     */
    readonly userId: string

    /**
     * The id token
     * @type {string}
     * @memberof MobileUserApiUpdateMobileUserStatus
     */
    readonly xIdToken: string

    /**
     * 
     * @type {UpdateMobileUserStatus}
     * @memberof MobileUserApiUpdateMobileUserStatus
     */
    readonly updateMobileUserStatus?: UpdateMobileUserStatus
}

/**
 * MobileUserApi - object-oriented interface
 * @export
 * @class MobileUserApi
 * @extends {BaseAPI}
 */
export class MobileUserApi extends BaseAPI {
    /**
     * 
     * @summary Delete mobile user
     * @param {MobileUserApiDeleteMobileUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileUserApi
     */
    public deleteMobileUser(requestParameters: MobileUserApiDeleteMobileUserRequest, options?: any) {
        return MobileUserApiFp(this.configuration).deleteMobileUser(requestParameters.userId, requestParameters.xIdToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get mobile user
     * @param {MobileUserApiGetMobileUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileUserApi
     */
    public getMobileUser(requestParameters: MobileUserApiGetMobileUserRequest, options?: any) {
        return MobileUserApiFp(this.configuration).getMobileUser(requestParameters.xIdToken, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of mobile users
     * @param {MobileUserApiGetMobileUsersDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileUserApi
     */
    public getMobileUsersData(requestParameters: MobileUserApiGetMobileUsersDataRequest, options?: any) {
        return MobileUserApiFp(this.configuration).getMobileUsersData(requestParameters.xIdToken, requestParameters.businessUser, requestParameters.privateUser, requestParameters.offset, requestParameters.limit, requestParameters.status, requestParameters.sort, requestParameters.freeText, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update mobile user status
     * @param {MobileUserApiUpdateMobileUserStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileUserApi
     */
    public updateMobileUserStatus(requestParameters: MobileUserApiUpdateMobileUserStatusRequest, options?: any) {
        return MobileUserApiFp(this.configuration).updateMobileUserStatus(requestParameters.userId, requestParameters.xIdToken, requestParameters.updateMobileUserStatus, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search payments
         * @param {string} xIdToken The id token
         * @param {number} [offset] The number of items to be skipped.
         * @param {number} [limit] The number of items to return.
         * @param {string} [country] Country to filter by  ISO 3166-1 alpha-2 
         * @param {string} [userId] ID of the user
         * @param {string} [cpoId] 
         * @param {number} [fromLastNumberOfMonths] Combined with the paramter &#x60;toLastNumberOfMonths&#x60; allows to set the months to skip  Example 1:    &#x60;fromLastNumberOfMonths&#x60; &#x3D; 0    &#x60;toLastNumberOfMonths&#x60; &#x3D; 3      will return the results for the last 3 months  Example 2:    &#x60;fromLastNumberOfMonths&#x60; &#x3D; 2    &#x60;toLastNumberOfMonths&#x60; &#x3D; 3      will skip the results for the last 2 months   and will return the results 3 the third month   before the current 
         * @param {number} [toLastNumberOfMonths] Indicates to return the results for the last X months  X: a number from 1 to 12  If set to 1 (or if not set) will return  the results for the last month  If set to 12 will return the results for the last 12 months (the last year)  If not limit will be set and alla the results will be returned 
         * @param {string} [sort] field:order  - field: the name of the field to be used for ordering      the allowed fields are:   - transactionDate   - amountDue   - amountCollected  - ordering: asc (for ascending) or desc (for descending)  example: amountCollected:asc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPayments: async (xIdToken: string, offset?: number, limit?: number, country?: string, userId?: string, cpoId?: string, fromLastNumberOfMonths?: number, toLastNumberOfMonths?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('searchPayments', 'xIdToken', xIdToken)
            const localVarPath = `/payments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (cpoId !== undefined) {
                localVarQueryParameter['cpoId'] = cpoId;
            }

            if (fromLastNumberOfMonths !== undefined) {
                localVarQueryParameter['fromLastNumberOfMonths'] = fromLastNumberOfMonths;
            }

            if (toLastNumberOfMonths !== undefined) {
                localVarQueryParameter['toLastNumberOfMonths'] = toLastNumberOfMonths;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Search payments
         * @param {string} xIdToken The id token
         * @param {number} [offset] The number of items to be skipped.
         * @param {number} [limit] The number of items to return.
         * @param {string} [country] Country to filter by  ISO 3166-1 alpha-2 
         * @param {string} [userId] ID of the user
         * @param {string} [cpoId] 
         * @param {number} [fromLastNumberOfMonths] Combined with the paramter &#x60;toLastNumberOfMonths&#x60; allows to set the months to skip  Example 1:    &#x60;fromLastNumberOfMonths&#x60; &#x3D; 0    &#x60;toLastNumberOfMonths&#x60; &#x3D; 3      will return the results for the last 3 months  Example 2:    &#x60;fromLastNumberOfMonths&#x60; &#x3D; 2    &#x60;toLastNumberOfMonths&#x60; &#x3D; 3      will skip the results for the last 2 months   and will return the results 3 the third month   before the current 
         * @param {number} [toLastNumberOfMonths] Indicates to return the results for the last X months  X: a number from 1 to 12  If set to 1 (or if not set) will return  the results for the last month  If set to 12 will return the results for the last 12 months (the last year)  If not limit will be set and alla the results will be returned 
         * @param {string} [sort] field:order  - field: the name of the field to be used for ordering      the allowed fields are:   - transactionDate   - amountDue   - amountCollected  - ordering: asc (for ascending) or desc (for descending)  example: amountCollected:asc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPayments(xIdToken: string, offset?: number, limit?: number, country?: string, userId?: string, cpoId?: string, fromLastNumberOfMonths?: number, toLastNumberOfMonths?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentsSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPayments(xIdToken, offset, limit, country, userId, cpoId, fromLastNumberOfMonths, toLastNumberOfMonths, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentApiFp(configuration)
    return {
        /**
         * 
         * @summary Search payments
         * @param {string} xIdToken The id token
         * @param {number} [offset] The number of items to be skipped.
         * @param {number} [limit] The number of items to return.
         * @param {string} [country] Country to filter by  ISO 3166-1 alpha-2 
         * @param {string} [userId] ID of the user
         * @param {string} [cpoId] 
         * @param {number} [fromLastNumberOfMonths] Combined with the paramter &#x60;toLastNumberOfMonths&#x60; allows to set the months to skip  Example 1:    &#x60;fromLastNumberOfMonths&#x60; &#x3D; 0    &#x60;toLastNumberOfMonths&#x60; &#x3D; 3      will return the results for the last 3 months  Example 2:    &#x60;fromLastNumberOfMonths&#x60; &#x3D; 2    &#x60;toLastNumberOfMonths&#x60; &#x3D; 3      will skip the results for the last 2 months   and will return the results 3 the third month   before the current 
         * @param {number} [toLastNumberOfMonths] Indicates to return the results for the last X months  X: a number from 1 to 12  If set to 1 (or if not set) will return  the results for the last month  If set to 12 will return the results for the last 12 months (the last year)  If not limit will be set and alla the results will be returned 
         * @param {string} [sort] field:order  - field: the name of the field to be used for ordering      the allowed fields are:   - transactionDate   - amountDue   - amountCollected  - ordering: asc (for ascending) or desc (for descending)  example: amountCollected:asc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPayments(xIdToken: string, offset?: number, limit?: number, country?: string, userId?: string, cpoId?: string, fromLastNumberOfMonths?: number, toLastNumberOfMonths?: number, sort?: string, options?: any): AxiosPromise<PaymentsSearchResult> {
            return localVarFp.searchPayments(xIdToken, offset, limit, country, userId, cpoId, fromLastNumberOfMonths, toLastNumberOfMonths, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for searchPayments operation in PaymentApi.
 * @export
 * @interface PaymentApiSearchPaymentsRequest
 */
export interface PaymentApiSearchPaymentsRequest {
    /**
     * The id token
     * @type {string}
     * @memberof PaymentApiSearchPayments
     */
    readonly xIdToken: string

    /**
     * The number of items to be skipped.
     * @type {number}
     * @memberof PaymentApiSearchPayments
     */
    readonly offset?: number

    /**
     * The number of items to return.
     * @type {number}
     * @memberof PaymentApiSearchPayments
     */
    readonly limit?: number

    /**
     * Country to filter by  ISO 3166-1 alpha-2 
     * @type {string}
     * @memberof PaymentApiSearchPayments
     */
    readonly country?: string

    /**
     * ID of the user
     * @type {string}
     * @memberof PaymentApiSearchPayments
     */
    readonly userId?: string

    /**
     * 
     * @type {string}
     * @memberof PaymentApiSearchPayments
     */
    readonly cpoId?: string

    /**
     * Combined with the paramter &#x60;toLastNumberOfMonths&#x60; allows to set the months to skip  Example 1:    &#x60;fromLastNumberOfMonths&#x60; &#x3D; 0    &#x60;toLastNumberOfMonths&#x60; &#x3D; 3      will return the results for the last 3 months  Example 2:    &#x60;fromLastNumberOfMonths&#x60; &#x3D; 2    &#x60;toLastNumberOfMonths&#x60; &#x3D; 3      will skip the results for the last 2 months   and will return the results 3 the third month   before the current 
     * @type {number}
     * @memberof PaymentApiSearchPayments
     */
    readonly fromLastNumberOfMonths?: number

    /**
     * Indicates to return the results for the last X months  X: a number from 1 to 12  If set to 1 (or if not set) will return  the results for the last month  If set to 12 will return the results for the last 12 months (the last year)  If not limit will be set and alla the results will be returned 
     * @type {number}
     * @memberof PaymentApiSearchPayments
     */
    readonly toLastNumberOfMonths?: number

    /**
     * field:order  - field: the name of the field to be used for ordering      the allowed fields are:   - transactionDate   - amountDue   - amountCollected  - ordering: asc (for ascending) or desc (for descending)  example: amountCollected:asc 
     * @type {string}
     * @memberof PaymentApiSearchPayments
     */
    readonly sort?: string
}

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * 
     * @summary Search payments
     * @param {PaymentApiSearchPaymentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public searchPayments(requestParameters: PaymentApiSearchPaymentsRequest, options?: any) {
        return PaymentApiFp(this.configuration).searchPayments(requestParameters.xIdToken, requestParameters.offset, requestParameters.limit, requestParameters.country, requestParameters.userId, requestParameters.cpoId, requestParameters.fromLastNumberOfMonths, requestParameters.toLastNumberOfMonths, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get profile user data
         * @param {string} xAccessToken The access token
         * @param {string} xIdToken The id token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (xAccessToken: string, xIdToken: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAccessToken' is not null or undefined
            assertParamExists('getProfile', 'xAccessToken', xAccessToken)
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('getProfile', 'xIdToken', xIdToken)
            const localVarPath = `/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xAccessToken !== undefined && xAccessToken !== null) {
                localVarHeaderParameter['x-access-token'] = String(xAccessToken);
            }

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user credentials
         * @param {string} xAccessToken The access token
         * @param {string} xIdToken The id token
         * @param {CredentialsUpdateProfileData} credentialsUpdateProfileData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserCredentials: async (xAccessToken: string, xIdToken: string, credentialsUpdateProfileData: CredentialsUpdateProfileData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAccessToken' is not null or undefined
            assertParamExists('updateUserCredentials', 'xAccessToken', xAccessToken)
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('updateUserCredentials', 'xIdToken', xIdToken)
            // verify required parameter 'credentialsUpdateProfileData' is not null or undefined
            assertParamExists('updateUserCredentials', 'credentialsUpdateProfileData', credentialsUpdateProfileData)
            const localVarPath = `/profile/credentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xAccessToken !== undefined && xAccessToken !== null) {
                localVarHeaderParameter['x-access-token'] = String(xAccessToken);
            }

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(credentialsUpdateProfileData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get profile user data
         * @param {string} xAccessToken The access token
         * @param {string} xIdToken The id token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(xAccessToken: string, xIdToken: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackofficeUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(xAccessToken, xIdToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user credentials
         * @param {string} xAccessToken The access token
         * @param {string} xIdToken The id token
         * @param {CredentialsUpdateProfileData} credentialsUpdateProfileData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserCredentials(xAccessToken: string, xIdToken: string, credentialsUpdateProfileData: CredentialsUpdateProfileData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserCredentials(xAccessToken, xIdToken, credentialsUpdateProfileData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileApiFp(configuration)
    return {
        /**
         * 
         * @summary Get profile user data
         * @param {string} xAccessToken The access token
         * @param {string} xIdToken The id token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(xAccessToken: string, xIdToken: string, options?: any): AxiosPromise<BackofficeUser> {
            return localVarFp.getProfile(xAccessToken, xIdToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user credentials
         * @param {string} xAccessToken The access token
         * @param {string} xIdToken The id token
         * @param {CredentialsUpdateProfileData} credentialsUpdateProfileData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserCredentials(xAccessToken: string, xIdToken: string, credentialsUpdateProfileData: CredentialsUpdateProfileData, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserCredentials(xAccessToken, xIdToken, credentialsUpdateProfileData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getProfile operation in ProfileApi.
 * @export
 * @interface ProfileApiGetProfileRequest
 */
export interface ProfileApiGetProfileRequest {
    /**
     * The access token
     * @type {string}
     * @memberof ProfileApiGetProfile
     */
    readonly xAccessToken: string

    /**
     * The id token
     * @type {string}
     * @memberof ProfileApiGetProfile
     */
    readonly xIdToken: string
}

/**
 * Request parameters for updateUserCredentials operation in ProfileApi.
 * @export
 * @interface ProfileApiUpdateUserCredentialsRequest
 */
export interface ProfileApiUpdateUserCredentialsRequest {
    /**
     * The access token
     * @type {string}
     * @memberof ProfileApiUpdateUserCredentials
     */
    readonly xAccessToken: string

    /**
     * The id token
     * @type {string}
     * @memberof ProfileApiUpdateUserCredentials
     */
    readonly xIdToken: string

    /**
     * 
     * @type {CredentialsUpdateProfileData}
     * @memberof ProfileApiUpdateUserCredentials
     */
    readonly credentialsUpdateProfileData: CredentialsUpdateProfileData
}

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
    /**
     * 
     * @summary Get profile user data
     * @param {ProfileApiGetProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public getProfile(requestParameters: ProfileApiGetProfileRequest, options?: any) {
        return ProfileApiFp(this.configuration).getProfile(requestParameters.xAccessToken, requestParameters.xIdToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user credentials
     * @param {ProfileApiUpdateUserCredentialsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public updateUserCredentials(requestParameters: ProfileApiUpdateUserCredentialsRequest, options?: any) {
        return ProfileApiFp(this.configuration).updateUserCredentials(requestParameters.xAccessToken, requestParameters.xIdToken, requestParameters.credentialsUpdateProfileData, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProviderApi - axios parameter creator
 * @export
 */
export const ProviderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create contract for a provider
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {CreateContractData} [createContractData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContract: async (xIdToken: string, providerId: string, createContractData?: CreateContractData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('createContract', 'xIdToken', xIdToken)
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('createContract', 'providerId', providerId)
            const localVarPath = `/providers/{providerId}/contracts`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createContractData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create fare for a group
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {string} contractId ID of the Contract
         * @param {string} groupId ID of the Group
         * @param {CreateFareData} [createFareData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFare: async (xIdToken: string, providerId: string, contractId: string, groupId: string, createFareData?: CreateFareData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('createFare', 'xIdToken', xIdToken)
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('createFare', 'providerId', providerId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('createFare', 'contractId', contractId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('createFare', 'groupId', groupId)
            const localVarPath = `/providers/{providerId}/contracts/{contractId}/groups/{groupId}/fares`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFareData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create group for a contract
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {string} contractId ID of the Contract
         * @param {CreateGroupData} [createGroupData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup: async (xIdToken: string, providerId: string, contractId: string, createGroupData?: CreateGroupData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('createGroup', 'xIdToken', xIdToken)
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('createGroup', 'providerId', providerId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('createGroup', 'contractId', contractId)
            const localVarPath = `/providers/{providerId}/contracts/{contractId}/groups`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGroupData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Provider
         * @param {string} xIdToken The id token
         * @param {ProviderAnagraphic} [providerAnagraphic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProvider: async (xIdToken: string, providerAnagraphic?: ProviderAnagraphic, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('createProvider', 'xIdToken', xIdToken)
            const localVarPath = `/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(providerAnagraphic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete contract for a provider
         * @param {string} providerId ID of the CPO Provider
         * @param {string} xIdToken The id token
         * @param {string} contractId ID of the Contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContract: async (providerId: string, xIdToken: string, contractId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('deleteContract', 'providerId', providerId)
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('deleteContract', 'xIdToken', xIdToken)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('deleteContract', 'contractId', contractId)
            const localVarPath = `/providers/{providerId}/contracts/{contractId}`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete fare for a group
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {string} contractId ID of the Contract
         * @param {string} groupId ID of the Group
         * @param {string} fareId ID of the Fare
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFare: async (xIdToken: string, providerId: string, contractId: string, groupId: string, fareId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('deleteFare', 'xIdToken', xIdToken)
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('deleteFare', 'providerId', providerId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('deleteFare', 'contractId', contractId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('deleteFare', 'groupId', groupId)
            // verify required parameter 'fareId' is not null or undefined
            assertParamExists('deleteFare', 'fareId', fareId)
            const localVarPath = `/providers/{providerId}/contracts/{contractId}/groups/{groupId}/fares/{fareId}`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"fareId"}}`, encodeURIComponent(String(fareId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete provider information
         * @param {string} providerId ID of the CPO Provider
         * @param {string} xIdToken The id token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProvider: async (providerId: string, xIdToken: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('deleteProvider', 'providerId', providerId)
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('deleteProvider', 'xIdToken', xIdToken)
            const localVarPath = `/providers/{providerId}`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contract for a provider
         * @param {string} providerId ID of the CPO Provider
         * @param {string} xIdToken The id token
         * @param {string} contractId ID of the Contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractById: async (providerId: string, xIdToken: string, contractId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getContractById', 'providerId', providerId)
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('getContractById', 'xIdToken', xIdToken)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('getContractById', 'contractId', contractId)
            const localVarPath = `/providers/{providerId}/contracts/{contractId}`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contracts for a provider
         * @param {string} providerId ID of the CPO Provider
         * @param {string} xIdToken The id token
         * @param {string} [sort] field:order  - field: the name of the field to be used for ordering      the allowed fields are:     - validFrom     - validTo  - order: asc (for ascending) or desc (for descending)  example: validFrom:asc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContracts: async (providerId: string, xIdToken: string, sort?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getContracts', 'providerId', providerId)
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('getContracts', 'xIdToken', xIdToken)
            const localVarPath = `/providers/{providerId}/contracts`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get fare by ID for a group
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {string} contractId ID of the Contract
         * @param {string} groupId ID of the Group
         * @param {string} fareId ID of the Fare
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFareById: async (xIdToken: string, providerId: string, contractId: string, groupId: string, fareId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('getFareById', 'xIdToken', xIdToken)
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getFareById', 'providerId', providerId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('getFareById', 'contractId', contractId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('getFareById', 'groupId', groupId)
            // verify required parameter 'fareId' is not null or undefined
            assertParamExists('getFareById', 'fareId', fareId)
            const localVarPath = `/providers/{providerId}/contracts/{contractId}/groups/{groupId}/fares/{fareId}`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"fareId"}}`, encodeURIComponent(String(fareId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get fares for a group
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {string} groupId ID of the Group
         * @param {string} contractId ID of the Contract
         * @param {string} [sort] field:order  - field: the name of the field to be used for ordering      the allowed fields are:     - fareId     - fareName     - minChargingPower     - maxChargingPower  - order: asc (for ascending) or desc (for descending)  example: fareId:asc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFares: async (xIdToken: string, providerId: string, groupId: string, contractId: string, sort?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('getFares', 'xIdToken', xIdToken)
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getFares', 'providerId', providerId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('getFares', 'groupId', groupId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('getFares', 'contractId', contractId)
            const localVarPath = `/providers/{providerId}/contracts/{contractId}/groups/{groupId}/fares`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get specific group for a contract
         * @param {string} providerId ID of the CPO Provider
         * @param {string} contractId ID of the Contract
         * @param {string} groupId ID of the Group
         * @param {string} xIdToken The id token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupById: async (providerId: string, contractId: string, groupId: string, xIdToken: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getGroupById', 'providerId', providerId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('getGroupById', 'contractId', contractId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('getGroupById', 'groupId', groupId)
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('getGroupById', 'xIdToken', xIdToken)
            const localVarPath = `/providers/{providerId}/contracts/{contractId}/groups/{groupId}`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get groups for a contract
         * @param {string} providerId ID of the CPO Provider
         * @param {string} contractId ID of the Contract
         * @param {string} xIdToken The id token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroups: async (providerId: string, contractId: string, xIdToken: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getGroups', 'providerId', providerId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('getGroups', 'contractId', contractId)
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('getGroups', 'xIdToken', xIdToken)
            const localVarPath = `/providers/{providerId}/contracts/{contractId}/groups`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get provider information
         * @param {string} providerId ID of the CPO Provider
         * @param {string} xIdToken The id token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvider: async (providerId: string, xIdToken: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getProvider', 'providerId', providerId)
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('getProvider', 'xIdToken', xIdToken)
            const localVarPath = `/providers/{providerId}`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search providers
         * @param {string} xIdToken The id token
         * @param {number} [limit] The number of items to return.
         * @param {number} [offset] The number of items to be skipped.
         * @param {string} [country] Country to filter by  ISO 3166-1 alpha-2 
         * @param {ChargesPeriodFilter} [chargesPeriod] 
         * @param {ActiveContractStatusFilter} [activeContractStatus] 
         * @param {string} [freeText] filters by operatorName
         * @param {string} [sort] field:order  - field: the name of the field to be used for ordering      the allowed fields are:     - cpoId     - cpoName     - activeContractValidFrom     - activeContractValidTo     - kWhDelivered     - chargesCount     - costsAmount     - earningsAmount  - order: asc (for ascending) or desc (for descending)  example: cpoId:asc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProviders: async (xIdToken: string, limit?: number, offset?: number, country?: string, chargesPeriod?: ChargesPeriodFilter, activeContractStatus?: ActiveContractStatusFilter, freeText?: string, sort?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('searchProviders', 'xIdToken', xIdToken)
            const localVarPath = `/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (chargesPeriod !== undefined) {
                localVarQueryParameter['chargesPeriod'] = chargesPeriod;
            }

            if (activeContractStatus !== undefined) {
                localVarQueryParameter['activeContractStatus'] = activeContractStatus;
            }

            if (freeText !== undefined) {
                localVarQueryParameter['freeText'] = freeText;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update contract for a provider
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {string} contractId ID of the Contract
         * @param {CreateContractData} createContractData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContract: async (xIdToken: string, providerId: string, contractId: string, createContractData: CreateContractData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('updateContract', 'xIdToken', xIdToken)
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('updateContract', 'providerId', providerId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('updateContract', 'contractId', contractId)
            // verify required parameter 'createContractData' is not null or undefined
            assertParamExists('updateContract', 'createContractData', createContractData)
            const localVarPath = `/providers/{providerId}/contracts/{contractId}`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createContractData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update fare for a group
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {string} contractId ID of the Contract
         * @param {string} groupId ID of the Group
         * @param {string} fareId ID of the Fare
         * @param {CreateFareData} [createFareData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFare: async (xIdToken: string, providerId: string, contractId: string, groupId: string, fareId: string, createFareData?: CreateFareData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('updateFare', 'xIdToken', xIdToken)
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('updateFare', 'providerId', providerId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('updateFare', 'contractId', contractId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('updateFare', 'groupId', groupId)
            // verify required parameter 'fareId' is not null or undefined
            assertParamExists('updateFare', 'fareId', fareId)
            const localVarPath = `/providers/{providerId}/contracts/{contractId}/groups/{groupId}/fares/{fareId}`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"fareId"}}`, encodeURIComponent(String(fareId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFareData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update provider information
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {UpdateProviderData} updateProviderData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProvider: async (xIdToken: string, providerId: string, updateProviderData: UpdateProviderData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xIdToken' is not null or undefined
            assertParamExists('updateProvider', 'xIdToken', xIdToken)
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('updateProvider', 'providerId', providerId)
            // verify required parameter 'updateProviderData' is not null or undefined
            assertParamExists('updateProvider', 'updateProviderData', updateProviderData)
            const localVarPath = `/providers/{providerId}`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xIdToken !== undefined && xIdToken !== null) {
                localVarHeaderParameter['x-id-token'] = String(xIdToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProviderData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProviderApi - functional programming interface
 * @export
 */
export const ProviderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProviderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create contract for a provider
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {CreateContractData} [createContractData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContract(xIdToken: string, providerId: string, createContractData?: CreateContractData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractAnagraphic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContract(xIdToken, providerId, createContractData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create fare for a group
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {string} contractId ID of the Contract
         * @param {string} groupId ID of the Group
         * @param {CreateFareData} [createFareData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFare(xIdToken: string, providerId: string, contractId: string, groupId: string, createFareData?: CreateFareData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Fare>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFare(xIdToken, providerId, contractId, groupId, createFareData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create group for a contract
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {string} contractId ID of the Contract
         * @param {CreateGroupData} [createGroupData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGroup(xIdToken: string, providerId: string, contractId: string, createGroupData?: CreateGroupData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Group>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGroup(xIdToken, providerId, contractId, createGroupData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Provider
         * @param {string} xIdToken The id token
         * @param {ProviderAnagraphic} [providerAnagraphic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProvider(xIdToken: string, providerAnagraphic?: ProviderAnagraphic, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderAnagraphic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProvider(xIdToken, providerAnagraphic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete contract for a provider
         * @param {string} providerId ID of the CPO Provider
         * @param {string} xIdToken The id token
         * @param {string} contractId ID of the Contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContract(providerId: string, xIdToken: string, contractId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContract(providerId, xIdToken, contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete fare for a group
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {string} contractId ID of the Contract
         * @param {string} groupId ID of the Group
         * @param {string} fareId ID of the Fare
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFare(xIdToken: string, providerId: string, contractId: string, groupId: string, fareId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFare(xIdToken, providerId, contractId, groupId, fareId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete provider information
         * @param {string} providerId ID of the CPO Provider
         * @param {string} xIdToken The id token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProvider(providerId: string, xIdToken: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProvider(providerId, xIdToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get contract for a provider
         * @param {string} providerId ID of the CPO Provider
         * @param {string} xIdToken The id token
         * @param {string} contractId ID of the Contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContractById(providerId: string, xIdToken: string, contractId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractAnagraphic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContractById(providerId, xIdToken, contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get contracts for a provider
         * @param {string} providerId ID of the CPO Provider
         * @param {string} xIdToken The id token
         * @param {string} [sort] field:order  - field: the name of the field to be used for ordering      the allowed fields are:     - validFrom     - validTo  - order: asc (for ascending) or desc (for descending)  example: validFrom:asc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContracts(providerId: string, xIdToken: string, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProviderContractsSearchResultItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContracts(providerId, xIdToken, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get fare by ID for a group
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {string} contractId ID of the Contract
         * @param {string} groupId ID of the Group
         * @param {string} fareId ID of the Fare
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFareById(xIdToken: string, providerId: string, contractId: string, groupId: string, fareId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Fare>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFareById(xIdToken, providerId, contractId, groupId, fareId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get fares for a group
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {string} groupId ID of the Group
         * @param {string} contractId ID of the Contract
         * @param {string} [sort] field:order  - field: the name of the field to be used for ordering      the allowed fields are:     - fareId     - fareName     - minChargingPower     - maxChargingPower  - order: asc (for ascending) or desc (for descending)  example: fareId:asc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFares(xIdToken: string, providerId: string, groupId: string, contractId: string, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Fare>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFares(xIdToken, providerId, groupId, contractId, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get specific group for a contract
         * @param {string} providerId ID of the CPO Provider
         * @param {string} contractId ID of the Contract
         * @param {string} groupId ID of the Group
         * @param {string} xIdToken The id token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroupById(providerId: string, contractId: string, groupId: string, xIdToken: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Group>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupById(providerId, contractId, groupId, xIdToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get groups for a contract
         * @param {string} providerId ID of the CPO Provider
         * @param {string} contractId ID of the Contract
         * @param {string} xIdToken The id token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroups(providerId: string, contractId: string, xIdToken: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Group>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroups(providerId, contractId, xIdToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get provider information
         * @param {string} providerId ID of the CPO Provider
         * @param {string} xIdToken The id token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProvider(providerId: string, xIdToken: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderAnagraphic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProvider(providerId, xIdToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search providers
         * @param {string} xIdToken The id token
         * @param {number} [limit] The number of items to return.
         * @param {number} [offset] The number of items to be skipped.
         * @param {string} [country] Country to filter by  ISO 3166-1 alpha-2 
         * @param {ChargesPeriodFilter} [chargesPeriod] 
         * @param {ActiveContractStatusFilter} [activeContractStatus] 
         * @param {string} [freeText] filters by operatorName
         * @param {string} [sort] field:order  - field: the name of the field to be used for ordering      the allowed fields are:     - cpoId     - cpoName     - activeContractValidFrom     - activeContractValidTo     - kWhDelivered     - chargesCount     - costsAmount     - earningsAmount  - order: asc (for ascending) or desc (for descending)  example: cpoId:asc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchProviders(xIdToken: string, limit?: number, offset?: number, country?: string, chargesPeriod?: ChargesPeriodFilter, activeContractStatus?: ActiveContractStatusFilter, freeText?: string, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProvidersSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchProviders(xIdToken, limit, offset, country, chargesPeriod, activeContractStatus, freeText, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update contract for a provider
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {string} contractId ID of the Contract
         * @param {CreateContractData} createContractData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContract(xIdToken: string, providerId: string, contractId: string, createContractData: CreateContractData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractAnagraphic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContract(xIdToken, providerId, contractId, createContractData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update fare for a group
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {string} contractId ID of the Contract
         * @param {string} groupId ID of the Group
         * @param {string} fareId ID of the Fare
         * @param {CreateFareData} [createFareData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFare(xIdToken: string, providerId: string, contractId: string, groupId: string, fareId: string, createFareData?: CreateFareData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Fare>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFare(xIdToken, providerId, contractId, groupId, fareId, createFareData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update provider information
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {UpdateProviderData} updateProviderData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProvider(xIdToken: string, providerId: string, updateProviderData: UpdateProviderData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderAnagraphic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProvider(xIdToken, providerId, updateProviderData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProviderApi - factory interface
 * @export
 */
export const ProviderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProviderApiFp(configuration)
    return {
        /**
         * 
         * @summary Create contract for a provider
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {CreateContractData} [createContractData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContract(xIdToken: string, providerId: string, createContractData?: CreateContractData, options?: any): AxiosPromise<ContractAnagraphic> {
            return localVarFp.createContract(xIdToken, providerId, createContractData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create fare for a group
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {string} contractId ID of the Contract
         * @param {string} groupId ID of the Group
         * @param {CreateFareData} [createFareData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFare(xIdToken: string, providerId: string, contractId: string, groupId: string, createFareData?: CreateFareData, options?: any): AxiosPromise<Fare> {
            return localVarFp.createFare(xIdToken, providerId, contractId, groupId, createFareData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create group for a contract
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {string} contractId ID of the Contract
         * @param {CreateGroupData} [createGroupData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup(xIdToken: string, providerId: string, contractId: string, createGroupData?: CreateGroupData, options?: any): AxiosPromise<Group> {
            return localVarFp.createGroup(xIdToken, providerId, contractId, createGroupData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Provider
         * @param {string} xIdToken The id token
         * @param {ProviderAnagraphic} [providerAnagraphic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProvider(xIdToken: string, providerAnagraphic?: ProviderAnagraphic, options?: any): AxiosPromise<ProviderAnagraphic> {
            return localVarFp.createProvider(xIdToken, providerAnagraphic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete contract for a provider
         * @param {string} providerId ID of the CPO Provider
         * @param {string} xIdToken The id token
         * @param {string} contractId ID of the Contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContract(providerId: string, xIdToken: string, contractId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteContract(providerId, xIdToken, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete fare for a group
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {string} contractId ID of the Contract
         * @param {string} groupId ID of the Group
         * @param {string} fareId ID of the Fare
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFare(xIdToken: string, providerId: string, contractId: string, groupId: string, fareId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFare(xIdToken, providerId, contractId, groupId, fareId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete provider information
         * @param {string} providerId ID of the CPO Provider
         * @param {string} xIdToken The id token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProvider(providerId: string, xIdToken: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProvider(providerId, xIdToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contract for a provider
         * @param {string} providerId ID of the CPO Provider
         * @param {string} xIdToken The id token
         * @param {string} contractId ID of the Contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractById(providerId: string, xIdToken: string, contractId: string, options?: any): AxiosPromise<ContractAnagraphic> {
            return localVarFp.getContractById(providerId, xIdToken, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contracts for a provider
         * @param {string} providerId ID of the CPO Provider
         * @param {string} xIdToken The id token
         * @param {string} [sort] field:order  - field: the name of the field to be used for ordering      the allowed fields are:     - validFrom     - validTo  - order: asc (for ascending) or desc (for descending)  example: validFrom:asc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContracts(providerId: string, xIdToken: string, sort?: string, options?: any): AxiosPromise<Array<ProviderContractsSearchResultItem>> {
            return localVarFp.getContracts(providerId, xIdToken, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get fare by ID for a group
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {string} contractId ID of the Contract
         * @param {string} groupId ID of the Group
         * @param {string} fareId ID of the Fare
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFareById(xIdToken: string, providerId: string, contractId: string, groupId: string, fareId: string, options?: any): AxiosPromise<Fare> {
            return localVarFp.getFareById(xIdToken, providerId, contractId, groupId, fareId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get fares for a group
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {string} groupId ID of the Group
         * @param {string} contractId ID of the Contract
         * @param {string} [sort] field:order  - field: the name of the field to be used for ordering      the allowed fields are:     - fareId     - fareName     - minChargingPower     - maxChargingPower  - order: asc (for ascending) or desc (for descending)  example: fareId:asc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFares(xIdToken: string, providerId: string, groupId: string, contractId: string, sort?: string, options?: any): AxiosPromise<Array<Fare>> {
            return localVarFp.getFares(xIdToken, providerId, groupId, contractId, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get specific group for a contract
         * @param {string} providerId ID of the CPO Provider
         * @param {string} contractId ID of the Contract
         * @param {string} groupId ID of the Group
         * @param {string} xIdToken The id token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupById(providerId: string, contractId: string, groupId: string, xIdToken: string, options?: any): AxiosPromise<Group> {
            return localVarFp.getGroupById(providerId, contractId, groupId, xIdToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get groups for a contract
         * @param {string} providerId ID of the CPO Provider
         * @param {string} contractId ID of the Contract
         * @param {string} xIdToken The id token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroups(providerId: string, contractId: string, xIdToken: string, options?: any): AxiosPromise<Array<Group>> {
            return localVarFp.getGroups(providerId, contractId, xIdToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get provider information
         * @param {string} providerId ID of the CPO Provider
         * @param {string} xIdToken The id token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvider(providerId: string, xIdToken: string, options?: any): AxiosPromise<ProviderAnagraphic> {
            return localVarFp.getProvider(providerId, xIdToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search providers
         * @param {string} xIdToken The id token
         * @param {number} [limit] The number of items to return.
         * @param {number} [offset] The number of items to be skipped.
         * @param {string} [country] Country to filter by  ISO 3166-1 alpha-2 
         * @param {ChargesPeriodFilter} [chargesPeriod] 
         * @param {ActiveContractStatusFilter} [activeContractStatus] 
         * @param {string} [freeText] filters by operatorName
         * @param {string} [sort] field:order  - field: the name of the field to be used for ordering      the allowed fields are:     - cpoId     - cpoName     - activeContractValidFrom     - activeContractValidTo     - kWhDelivered     - chargesCount     - costsAmount     - earningsAmount  - order: asc (for ascending) or desc (for descending)  example: cpoId:asc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProviders(xIdToken: string, limit?: number, offset?: number, country?: string, chargesPeriod?: ChargesPeriodFilter, activeContractStatus?: ActiveContractStatusFilter, freeText?: string, sort?: string, options?: any): AxiosPromise<ProvidersSearchResult> {
            return localVarFp.searchProviders(xIdToken, limit, offset, country, chargesPeriod, activeContractStatus, freeText, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update contract for a provider
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {string} contractId ID of the Contract
         * @param {CreateContractData} createContractData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContract(xIdToken: string, providerId: string, contractId: string, createContractData: CreateContractData, options?: any): AxiosPromise<ContractAnagraphic> {
            return localVarFp.updateContract(xIdToken, providerId, contractId, createContractData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update fare for a group
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {string} contractId ID of the Contract
         * @param {string} groupId ID of the Group
         * @param {string} fareId ID of the Fare
         * @param {CreateFareData} [createFareData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFare(xIdToken: string, providerId: string, contractId: string, groupId: string, fareId: string, createFareData?: CreateFareData, options?: any): AxiosPromise<Fare> {
            return localVarFp.updateFare(xIdToken, providerId, contractId, groupId, fareId, createFareData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update provider information
         * @param {string} xIdToken The id token
         * @param {string} providerId ID of the CPO Provider
         * @param {UpdateProviderData} updateProviderData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProvider(xIdToken: string, providerId: string, updateProviderData: UpdateProviderData, options?: any): AxiosPromise<ProviderAnagraphic> {
            return localVarFp.updateProvider(xIdToken, providerId, updateProviderData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createContract operation in ProviderApi.
 * @export
 * @interface ProviderApiCreateContractRequest
 */
export interface ProviderApiCreateContractRequest {
    /**
     * The id token
     * @type {string}
     * @memberof ProviderApiCreateContract
     */
    readonly xIdToken: string

    /**
     * ID of the CPO Provider
     * @type {string}
     * @memberof ProviderApiCreateContract
     */
    readonly providerId: string

    /**
     * 
     * @type {CreateContractData}
     * @memberof ProviderApiCreateContract
     */
    readonly createContractData?: CreateContractData
}

/**
 * Request parameters for createFare operation in ProviderApi.
 * @export
 * @interface ProviderApiCreateFareRequest
 */
export interface ProviderApiCreateFareRequest {
    /**
     * The id token
     * @type {string}
     * @memberof ProviderApiCreateFare
     */
    readonly xIdToken: string

    /**
     * ID of the CPO Provider
     * @type {string}
     * @memberof ProviderApiCreateFare
     */
    readonly providerId: string

    /**
     * ID of the Contract
     * @type {string}
     * @memberof ProviderApiCreateFare
     */
    readonly contractId: string

    /**
     * ID of the Group
     * @type {string}
     * @memberof ProviderApiCreateFare
     */
    readonly groupId: string

    /**
     * 
     * @type {CreateFareData}
     * @memberof ProviderApiCreateFare
     */
    readonly createFareData?: CreateFareData
}

/**
 * Request parameters for createGroup operation in ProviderApi.
 * @export
 * @interface ProviderApiCreateGroupRequest
 */
export interface ProviderApiCreateGroupRequest {
    /**
     * The id token
     * @type {string}
     * @memberof ProviderApiCreateGroup
     */
    readonly xIdToken: string

    /**
     * ID of the CPO Provider
     * @type {string}
     * @memberof ProviderApiCreateGroup
     */
    readonly providerId: string

    /**
     * ID of the Contract
     * @type {string}
     * @memberof ProviderApiCreateGroup
     */
    readonly contractId: string

    /**
     * 
     * @type {CreateGroupData}
     * @memberof ProviderApiCreateGroup
     */
    readonly createGroupData?: CreateGroupData
}

/**
 * Request parameters for createProvider operation in ProviderApi.
 * @export
 * @interface ProviderApiCreateProviderRequest
 */
export interface ProviderApiCreateProviderRequest {
    /**
     * The id token
     * @type {string}
     * @memberof ProviderApiCreateProvider
     */
    readonly xIdToken: string

    /**
     * 
     * @type {ProviderAnagraphic}
     * @memberof ProviderApiCreateProvider
     */
    readonly providerAnagraphic?: ProviderAnagraphic
}

/**
 * Request parameters for deleteContract operation in ProviderApi.
 * @export
 * @interface ProviderApiDeleteContractRequest
 */
export interface ProviderApiDeleteContractRequest {
    /**
     * ID of the CPO Provider
     * @type {string}
     * @memberof ProviderApiDeleteContract
     */
    readonly providerId: string

    /**
     * The id token
     * @type {string}
     * @memberof ProviderApiDeleteContract
     */
    readonly xIdToken: string

    /**
     * ID of the Contract
     * @type {string}
     * @memberof ProviderApiDeleteContract
     */
    readonly contractId: string
}

/**
 * Request parameters for deleteFare operation in ProviderApi.
 * @export
 * @interface ProviderApiDeleteFareRequest
 */
export interface ProviderApiDeleteFareRequest {
    /**
     * The id token
     * @type {string}
     * @memberof ProviderApiDeleteFare
     */
    readonly xIdToken: string

    /**
     * ID of the CPO Provider
     * @type {string}
     * @memberof ProviderApiDeleteFare
     */
    readonly providerId: string

    /**
     * ID of the Contract
     * @type {string}
     * @memberof ProviderApiDeleteFare
     */
    readonly contractId: string

    /**
     * ID of the Group
     * @type {string}
     * @memberof ProviderApiDeleteFare
     */
    readonly groupId: string

    /**
     * ID of the Fare
     * @type {string}
     * @memberof ProviderApiDeleteFare
     */
    readonly fareId: string
}

/**
 * Request parameters for deleteProvider operation in ProviderApi.
 * @export
 * @interface ProviderApiDeleteProviderRequest
 */
export interface ProviderApiDeleteProviderRequest {
    /**
     * ID of the CPO Provider
     * @type {string}
     * @memberof ProviderApiDeleteProvider
     */
    readonly providerId: string

    /**
     * The id token
     * @type {string}
     * @memberof ProviderApiDeleteProvider
     */
    readonly xIdToken: string
}

/**
 * Request parameters for getContractById operation in ProviderApi.
 * @export
 * @interface ProviderApiGetContractByIdRequest
 */
export interface ProviderApiGetContractByIdRequest {
    /**
     * ID of the CPO Provider
     * @type {string}
     * @memberof ProviderApiGetContractById
     */
    readonly providerId: string

    /**
     * The id token
     * @type {string}
     * @memberof ProviderApiGetContractById
     */
    readonly xIdToken: string

    /**
     * ID of the Contract
     * @type {string}
     * @memberof ProviderApiGetContractById
     */
    readonly contractId: string
}

/**
 * Request parameters for getContracts operation in ProviderApi.
 * @export
 * @interface ProviderApiGetContractsRequest
 */
export interface ProviderApiGetContractsRequest {
    /**
     * ID of the CPO Provider
     * @type {string}
     * @memberof ProviderApiGetContracts
     */
    readonly providerId: string

    /**
     * The id token
     * @type {string}
     * @memberof ProviderApiGetContracts
     */
    readonly xIdToken: string

    /**
     * field:order  - field: the name of the field to be used for ordering      the allowed fields are:     - validFrom     - validTo  - order: asc (for ascending) or desc (for descending)  example: validFrom:asc 
     * @type {string}
     * @memberof ProviderApiGetContracts
     */
    readonly sort?: string
}

/**
 * Request parameters for getFareById operation in ProviderApi.
 * @export
 * @interface ProviderApiGetFareByIdRequest
 */
export interface ProviderApiGetFareByIdRequest {
    /**
     * The id token
     * @type {string}
     * @memberof ProviderApiGetFareById
     */
    readonly xIdToken: string

    /**
     * ID of the CPO Provider
     * @type {string}
     * @memberof ProviderApiGetFareById
     */
    readonly providerId: string

    /**
     * ID of the Contract
     * @type {string}
     * @memberof ProviderApiGetFareById
     */
    readonly contractId: string

    /**
     * ID of the Group
     * @type {string}
     * @memberof ProviderApiGetFareById
     */
    readonly groupId: string

    /**
     * ID of the Fare
     * @type {string}
     * @memberof ProviderApiGetFareById
     */
    readonly fareId: string
}

/**
 * Request parameters for getFares operation in ProviderApi.
 * @export
 * @interface ProviderApiGetFaresRequest
 */
export interface ProviderApiGetFaresRequest {
    /**
     * The id token
     * @type {string}
     * @memberof ProviderApiGetFares
     */
    readonly xIdToken: string

    /**
     * ID of the CPO Provider
     * @type {string}
     * @memberof ProviderApiGetFares
     */
    readonly providerId: string

    /**
     * ID of the Group
     * @type {string}
     * @memberof ProviderApiGetFares
     */
    readonly groupId: string

    /**
     * ID of the Contract
     * @type {string}
     * @memberof ProviderApiGetFares
     */
    readonly contractId: string

    /**
     * field:order  - field: the name of the field to be used for ordering      the allowed fields are:     - fareId     - fareName     - minChargingPower     - maxChargingPower  - order: asc (for ascending) or desc (for descending)  example: fareId:asc 
     * @type {string}
     * @memberof ProviderApiGetFares
     */
    readonly sort?: string
}

/**
 * Request parameters for getGroupById operation in ProviderApi.
 * @export
 * @interface ProviderApiGetGroupByIdRequest
 */
export interface ProviderApiGetGroupByIdRequest {
    /**
     * ID of the CPO Provider
     * @type {string}
     * @memberof ProviderApiGetGroupById
     */
    readonly providerId: string

    /**
     * ID of the Contract
     * @type {string}
     * @memberof ProviderApiGetGroupById
     */
    readonly contractId: string

    /**
     * ID of the Group
     * @type {string}
     * @memberof ProviderApiGetGroupById
     */
    readonly groupId: string

    /**
     * The id token
     * @type {string}
     * @memberof ProviderApiGetGroupById
     */
    readonly xIdToken: string
}

/**
 * Request parameters for getGroups operation in ProviderApi.
 * @export
 * @interface ProviderApiGetGroupsRequest
 */
export interface ProviderApiGetGroupsRequest {
    /**
     * ID of the CPO Provider
     * @type {string}
     * @memberof ProviderApiGetGroups
     */
    readonly providerId: string

    /**
     * ID of the Contract
     * @type {string}
     * @memberof ProviderApiGetGroups
     */
    readonly contractId: string

    /**
     * The id token
     * @type {string}
     * @memberof ProviderApiGetGroups
     */
    readonly xIdToken: string
}

/**
 * Request parameters for getProvider operation in ProviderApi.
 * @export
 * @interface ProviderApiGetProviderRequest
 */
export interface ProviderApiGetProviderRequest {
    /**
     * ID of the CPO Provider
     * @type {string}
     * @memberof ProviderApiGetProvider
     */
    readonly providerId: string

    /**
     * The id token
     * @type {string}
     * @memberof ProviderApiGetProvider
     */
    readonly xIdToken: string
}

/**
 * Request parameters for searchProviders operation in ProviderApi.
 * @export
 * @interface ProviderApiSearchProvidersRequest
 */
export interface ProviderApiSearchProvidersRequest {
    /**
     * The id token
     * @type {string}
     * @memberof ProviderApiSearchProviders
     */
    readonly xIdToken: string

    /**
     * The number of items to return.
     * @type {number}
     * @memberof ProviderApiSearchProviders
     */
    readonly limit?: number

    /**
     * The number of items to be skipped.
     * @type {number}
     * @memberof ProviderApiSearchProviders
     */
    readonly offset?: number

    /**
     * Country to filter by  ISO 3166-1 alpha-2 
     * @type {string}
     * @memberof ProviderApiSearchProviders
     */
    readonly country?: string

    /**
     * 
     * @type {ChargesPeriodFilter}
     * @memberof ProviderApiSearchProviders
     */
    readonly chargesPeriod?: ChargesPeriodFilter

    /**
     * 
     * @type {ActiveContractStatusFilter}
     * @memberof ProviderApiSearchProviders
     */
    readonly activeContractStatus?: ActiveContractStatusFilter

    /**
     * filters by operatorName
     * @type {string}
     * @memberof ProviderApiSearchProviders
     */
    readonly freeText?: string

    /**
     * field:order  - field: the name of the field to be used for ordering      the allowed fields are:     - cpoId     - cpoName     - activeContractValidFrom     - activeContractValidTo     - kWhDelivered     - chargesCount     - costsAmount     - earningsAmount  - order: asc (for ascending) or desc (for descending)  example: cpoId:asc 
     * @type {string}
     * @memberof ProviderApiSearchProviders
     */
    readonly sort?: string
}

/**
 * Request parameters for updateContract operation in ProviderApi.
 * @export
 * @interface ProviderApiUpdateContractRequest
 */
export interface ProviderApiUpdateContractRequest {
    /**
     * The id token
     * @type {string}
     * @memberof ProviderApiUpdateContract
     */
    readonly xIdToken: string

    /**
     * ID of the CPO Provider
     * @type {string}
     * @memberof ProviderApiUpdateContract
     */
    readonly providerId: string

    /**
     * ID of the Contract
     * @type {string}
     * @memberof ProviderApiUpdateContract
     */
    readonly contractId: string

    /**
     * 
     * @type {CreateContractData}
     * @memberof ProviderApiUpdateContract
     */
    readonly createContractData: CreateContractData
}

/**
 * Request parameters for updateFare operation in ProviderApi.
 * @export
 * @interface ProviderApiUpdateFareRequest
 */
export interface ProviderApiUpdateFareRequest {
    /**
     * The id token
     * @type {string}
     * @memberof ProviderApiUpdateFare
     */
    readonly xIdToken: string

    /**
     * ID of the CPO Provider
     * @type {string}
     * @memberof ProviderApiUpdateFare
     */
    readonly providerId: string

    /**
     * ID of the Contract
     * @type {string}
     * @memberof ProviderApiUpdateFare
     */
    readonly contractId: string

    /**
     * ID of the Group
     * @type {string}
     * @memberof ProviderApiUpdateFare
     */
    readonly groupId: string

    /**
     * ID of the Fare
     * @type {string}
     * @memberof ProviderApiUpdateFare
     */
    readonly fareId: string

    /**
     * 
     * @type {CreateFareData}
     * @memberof ProviderApiUpdateFare
     */
    readonly createFareData?: CreateFareData
}

/**
 * Request parameters for updateProvider operation in ProviderApi.
 * @export
 * @interface ProviderApiUpdateProviderRequest
 */
export interface ProviderApiUpdateProviderRequest {
    /**
     * The id token
     * @type {string}
     * @memberof ProviderApiUpdateProvider
     */
    readonly xIdToken: string

    /**
     * ID of the CPO Provider
     * @type {string}
     * @memberof ProviderApiUpdateProvider
     */
    readonly providerId: string

    /**
     * 
     * @type {UpdateProviderData}
     * @memberof ProviderApiUpdateProvider
     */
    readonly updateProviderData: UpdateProviderData
}

/**
 * ProviderApi - object-oriented interface
 * @export
 * @class ProviderApi
 * @extends {BaseAPI}
 */
export class ProviderApi extends BaseAPI {
    /**
     * 
     * @summary Create contract for a provider
     * @param {ProviderApiCreateContractRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public createContract(requestParameters: ProviderApiCreateContractRequest, options?: any) {
        return ProviderApiFp(this.configuration).createContract(requestParameters.xIdToken, requestParameters.providerId, requestParameters.createContractData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create fare for a group
     * @param {ProviderApiCreateFareRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public createFare(requestParameters: ProviderApiCreateFareRequest, options?: any) {
        return ProviderApiFp(this.configuration).createFare(requestParameters.xIdToken, requestParameters.providerId, requestParameters.contractId, requestParameters.groupId, requestParameters.createFareData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create group for a contract
     * @param {ProviderApiCreateGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public createGroup(requestParameters: ProviderApiCreateGroupRequest, options?: any) {
        return ProviderApiFp(this.configuration).createGroup(requestParameters.xIdToken, requestParameters.providerId, requestParameters.contractId, requestParameters.createGroupData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Provider
     * @param {ProviderApiCreateProviderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public createProvider(requestParameters: ProviderApiCreateProviderRequest, options?: any) {
        return ProviderApiFp(this.configuration).createProvider(requestParameters.xIdToken, requestParameters.providerAnagraphic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete contract for a provider
     * @param {ProviderApiDeleteContractRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public deleteContract(requestParameters: ProviderApiDeleteContractRequest, options?: any) {
        return ProviderApiFp(this.configuration).deleteContract(requestParameters.providerId, requestParameters.xIdToken, requestParameters.contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete fare for a group
     * @param {ProviderApiDeleteFareRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public deleteFare(requestParameters: ProviderApiDeleteFareRequest, options?: any) {
        return ProviderApiFp(this.configuration).deleteFare(requestParameters.xIdToken, requestParameters.providerId, requestParameters.contractId, requestParameters.groupId, requestParameters.fareId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete provider information
     * @param {ProviderApiDeleteProviderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public deleteProvider(requestParameters: ProviderApiDeleteProviderRequest, options?: any) {
        return ProviderApiFp(this.configuration).deleteProvider(requestParameters.providerId, requestParameters.xIdToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contract for a provider
     * @param {ProviderApiGetContractByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getContractById(requestParameters: ProviderApiGetContractByIdRequest, options?: any) {
        return ProviderApiFp(this.configuration).getContractById(requestParameters.providerId, requestParameters.xIdToken, requestParameters.contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contracts for a provider
     * @param {ProviderApiGetContractsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getContracts(requestParameters: ProviderApiGetContractsRequest, options?: any) {
        return ProviderApiFp(this.configuration).getContracts(requestParameters.providerId, requestParameters.xIdToken, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get fare by ID for a group
     * @param {ProviderApiGetFareByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getFareById(requestParameters: ProviderApiGetFareByIdRequest, options?: any) {
        return ProviderApiFp(this.configuration).getFareById(requestParameters.xIdToken, requestParameters.providerId, requestParameters.contractId, requestParameters.groupId, requestParameters.fareId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get fares for a group
     * @param {ProviderApiGetFaresRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getFares(requestParameters: ProviderApiGetFaresRequest, options?: any) {
        return ProviderApiFp(this.configuration).getFares(requestParameters.xIdToken, requestParameters.providerId, requestParameters.groupId, requestParameters.contractId, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get specific group for a contract
     * @param {ProviderApiGetGroupByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getGroupById(requestParameters: ProviderApiGetGroupByIdRequest, options?: any) {
        return ProviderApiFp(this.configuration).getGroupById(requestParameters.providerId, requestParameters.contractId, requestParameters.groupId, requestParameters.xIdToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get groups for a contract
     * @param {ProviderApiGetGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getGroups(requestParameters: ProviderApiGetGroupsRequest, options?: any) {
        return ProviderApiFp(this.configuration).getGroups(requestParameters.providerId, requestParameters.contractId, requestParameters.xIdToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get provider information
     * @param {ProviderApiGetProviderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProvider(requestParameters: ProviderApiGetProviderRequest, options?: any) {
        return ProviderApiFp(this.configuration).getProvider(requestParameters.providerId, requestParameters.xIdToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search providers
     * @param {ProviderApiSearchProvidersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public searchProviders(requestParameters: ProviderApiSearchProvidersRequest, options?: any) {
        return ProviderApiFp(this.configuration).searchProviders(requestParameters.xIdToken, requestParameters.limit, requestParameters.offset, requestParameters.country, requestParameters.chargesPeriod, requestParameters.activeContractStatus, requestParameters.freeText, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update contract for a provider
     * @param {ProviderApiUpdateContractRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public updateContract(requestParameters: ProviderApiUpdateContractRequest, options?: any) {
        return ProviderApiFp(this.configuration).updateContract(requestParameters.xIdToken, requestParameters.providerId, requestParameters.contractId, requestParameters.createContractData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update fare for a group
     * @param {ProviderApiUpdateFareRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public updateFare(requestParameters: ProviderApiUpdateFareRequest, options?: any) {
        return ProviderApiFp(this.configuration).updateFare(requestParameters.xIdToken, requestParameters.providerId, requestParameters.contractId, requestParameters.groupId, requestParameters.fareId, requestParameters.createFareData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update provider information
     * @param {ProviderApiUpdateProviderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public updateProvider(requestParameters: ProviderApiUpdateProviderRequest, options?: any) {
        return ProviderApiFp(this.configuration).updateProvider(requestParameters.xIdToken, requestParameters.providerId, requestParameters.updateProviderData, options).then((request) => request(this.axios, this.basePath));
    }
}


