import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { onSnapshot } from 'mobx-state-tree';

import { ContractsTableHeader } from './contracts-table-header';
import { SectionPagedList } from '../../common/section-paged-list/section-paged-list';
import { DeleteItemDialog } from '../../common/dialog/delete-item-dialog';
import { ContractsSectionHeader } from './contracts-section-header';
import { ContractsTableItem } from './contracts-table-item';
import { ManageContractDialog } from '../dialog/manage-contract-dialog/manage-contract-dialog';

import { OrderManagerProps, SortOrder } from '../../../../common/models/order-criteria';
import { UserRole } from '../../../../common/models/user';
import { Contract, ContractOrderCriteria, ContractStatus } from '../../../model/contract';
import { useStore } from '../../../../common/stores/root-store';
import { ApiRequestState } from '../../../../common/api/api-request';
import { createServerErrorNotification, createSuccessNotification } from '../../../../common/stores/notifications-store';
import { ContractInfo } from '../../../model/contract-info';
import { Routes } from '../../../../common/routing/routes';

import contractsMessages from '../../../contracts.messages';
import './contracts-paged-list.scss';

export interface ContractsPagedListRouteState {
    hasToCreateNewContract?: boolean
}

interface ContractsPagedListProps {
    isAdmin: boolean;
}

export const ContractsPagedList = observer((props: ContractsPagedListProps) => {

    /* ---------------- Setup ---------------- */
    const { userStore, providerStore, notificationStore } = useStore();

    const contractsStore = providerStore.contractsStore;
    const selectedProvider = providerStore.selectedItem;


    if (!contractsStore || !selectedProvider) {
        return <div />;
    }

    const intl = useIntl();
    const { formatMessage } = intl;

    const location = useLocation();

    const openContractDetails = useCallback(
        (provider: Contract) => contractsStore && contractsStore.openItemDetail(provider),
        [contractsStore]
    );

    useEffect(() => {
        contractsStore.initContractsStore(selectedProvider);
        contractsStore.deselectItem();
        contractsStore.reload();
    }, [contractsStore, selectedProvider]);

    /* ---------------- Add new contract dialog ---------------- */
    const [showNewContractDialog, setShowNewContractDialog] = useState(false);
    const [contractTemplate, setContractTemplate] = useState<ContractInfo>();

    const onAddNewButtonClick = useCallback(() => {
        const currentItems = contractsStore.currentPageItems.slice();
        if (contractsStore.orderCriteria?.key !== ContractOrderCriteria.TIME_PERIOD
            || contractsStore.orderCriteria?.sortOrder !== SortOrder.DESC) {
            currentItems.sort((a, b) =>
                b.validityPeriod.to.getTime() - a.validityPeriod.to.getTime()
            );
        }

        /*
            since the list is ordered by the end date of the contracts,
            if there is at least one active contract than it will be an active one,
            otherwise it will be an expired one
        */
        const mostRecentContract = currentItems.find(elem =>
            elem.status === ContractStatus.ACTIVE
            || elem.status === ContractStatus.EXPIRED
        );

        if (mostRecentContract !== undefined) {
            const date = new Date(mostRecentContract.validityPeriod.to)
                .setDate(mostRecentContract.validityPeriod.to.getDate() + 1);
            setContractTemplate({
                status: ContractStatus.NOT_ACTIVE,
                startDate: new Date(date),
                endDate: new Date(date),
                type: mostRecentContract.type,
                baseInflation: mostRecentContract.inflation.baseInflation
            });
        }
        setShowNewContractDialog(true);
    }, [contractsStore]);
    const onCloseAddContractDialog = useCallback(
        () => setShowNewContractDialog(false),
        []
    );
    const onConfirmAddContractSubmitted = useCallback((contractInfo: ContractInfo) => {


        console.log("ContractInfo", contractInfo);
        const dispose = onSnapshot(contractsStore, (snapshot) => {
            let managed = false;

            if (snapshot?.manageContractRequestState === ApiRequestState.COMPLETED) {
                notificationStore.showNotification(createSuccessNotification(intl))
                    .then(() => {
                        if (contractsStore.lastNewCreatedContract) {
                            openContractDetails(contractsStore.lastNewCreatedContract);
                        }
                    });
                managed = true;
            } else if (snapshot?.manageContractRequestState === ApiRequestState.FAILED) {
                notificationStore.showNotification(createServerErrorNotification(
                    intl
                ));
                managed = true;
            }

            if (managed) {
                dispose();
                setShowNewContractDialog(false);
                contractsStore.clearManageContractRequestState();
                contractsStore.clearContractError();
            }
        });
        contractsStore.addContract(contractInfo);
    }, [contractsStore, notificationStore, intl, openContractDetails]);

    /* ---------------- Delete contract dialog ---------------- */
    const [contractToDelete, setContractToDelete] = useState<Contract>();
    const [showContractToDeleteDialog, setShowContractToDeleteDialog] = useState(false);

    const onDeleteContractClick = useCallback((contract: Contract) => {
        setContractToDelete(contract);
        setShowContractToDeleteDialog(true);
    }, []);
    const onCloseDeleteContractDialog = useCallback(
        () => setShowContractToDeleteDialog(false),
        []
    );
    const onConfirmDeleteContractSubmitted = useCallback(() => {
        const dispose = onSnapshot(contractsStore, (snapshot) => {
            let managed = false;

            if (snapshot?.deleteContractRequestState === ApiRequestState.COMPLETED) {
                notificationStore.showNotification(createSuccessNotification(intl));
                managed = true;
                contractsStore.onItemDeleted();
            } else if (snapshot?.deleteContractRequestState === ApiRequestState.FAILED) {
                notificationStore.showNotification(createServerErrorNotification(
                    intl
                ));
                managed = true;
            }

            if (managed) {
                dispose();
                setShowContractToDeleteDialog(false);
                contractsStore.clearDeleteContractRequestState();
                contractsStore.clearContractError();
            }
        });
        contractToDelete && contractsStore.deleteContract(contractToDelete);
    }, [contractsStore, contractToDelete, notificationStore, intl]);

    /* ---------------- Render page elements ---------------- */

    const renderHeader = useCallback(() => <ContractsSectionHeader />, []);

    const renderTableHeader = useCallback((orderProps: OrderManagerProps) =>
        <ContractsTableHeader {...orderProps} />, []);

    const renderItem = useCallback((item: Contract, index: number) => {
        return <ContractsTableItem
            key={index}
            item={item}
            onItemClick={openContractDetails}
            onDeleteItemClick={onDeleteContractClick}
            canUserDeleteItem={userStore.user?.role !== UserRole.Operator && item.canBeDeleted}
        />;
    }, [onDeleteContractClick, openContractDetails, userStore.user?.role]);

    useEffect(() => {
        const state = location.state as ContractsPagedListRouteState;
        if (state?.hasToCreateNewContract && userStore.user?.role !== UserRole.Operator) {
            setShowNewContractDialog(true);
        }
    }, [location.state, userStore.user?.role]);

    /* ---------------- Layout ---------------- */

    return <>


        <SectionPagedList
            isAdmin={props.isAdmin}
            store={contractsStore}
            tableClass='contracts-table'

            renderSectionHeader={renderHeader}
            renderTableHeader={renderTableHeader}
            renderItem={renderItem}
            columnsCount={10}

            createButtonEnabled
            onAddNewButtonClick={onAddNewButtonClick}
        />



        <DeleteItemDialog
            title={formatMessage(contractsMessages.deleteContractDialogTitle, { contractId: contractToDelete?.contractId })}
            open={showContractToDeleteDialog}
            onClose={onCloseDeleteContractDialog}
            isOperationRunning={contractsStore?.deleteContractRequestState === ApiRequestState.RUNNING}
            onConfirmButtonClick={onConfirmDeleteContractSubmitted}
        />

        <ManageContractDialog
            open={showNewContractDialog}
            isOperationRunning={contractsStore.manageContractRequestState === ApiRequestState.RUNNING}
            onPrimaryButtonClick={onConfirmAddContractSubmitted}
            onClose={onCloseAddContractDialog}
            provider={selectedProvider}
            contract={contractTemplate}
            clearContractEndDateField
        />

        {
            contractsStore.selectedItem
            && <Redirect to={Routes.ProviderContractDetail} />
        }

    </>;
});