import { defineMessages } from 'react-intl';

export default defineMessages({
    "serverErrorNotificationMessage" : {
        "id": "commons.serverErrorNotificationMessage",
        "defaultMessage": "Errore di connessione al server",
        "description": "Testo della notifica mostrata quando si verifica un errore di comunicazione con il server"
    },
    "genericError" : {
        "id": "commons.genericError",
        "defaultMessage": "Si è verificato un errore",
        "description": "Testo mostrato quando si verifica un errore generico"
    },

    "ok" : {
        "id": "commons.ok",
        "defaultMessage": "Ok",
    },

    "addNew" : {
        "id": "commons.addNew",
        "defaultMessage": "Aggiungi nuovo",
        "description": "Testo dei bottoni utilizzati per aggiungere un nuovo elemento"
    },

    "searchPlaceholderText": {
        "id": "commons.searchPlaceholderText",
        "defaultMessage": "Cerca",
        "description": "Placeholder per campi di ricerca"
    },

    
    "successNotificationMessage" : {
        "id": "commons.successNotificationMessage",
        "defaultMessage": "Operazione completata con successo",
        "description": "Testo della notifica generica mostrata in caso di successo di un'operazione"
    },
    "tooltipComingSoonMessage" : {
        "id": "commons.tooltipComingSoonMessage",
        "defaultMessage": "Work in progress",
        "description": "Testo del tooltip mostrato negli elementi da sviluppare"
    },
    "ChargeCar" : {
        "id": "commons.ChargeCar",
        "defaultMessage": "ChargeCar.com"
    },
    "providerTitle" : {
        "id": "commons.providerTitle",
        "defaultMessage": "Contratti"
    },
    "transactionsTitle" : {
        "id": "commons.transactionsTitle",
        "defaultMessage": "Transazioni"
    },
    "usersTitle" : {
        "id": "commons.usersTitle",
        "defaultMessage": "Utenti"
    },
    "administrationTitle" : {
        "id": "commons.administrationTitle",
        "defaultMessage": "Amministrazione"
    },
    "checkBoxSelectedAltLabel" : {
        "id": "commons.checkBoxSelectedAltLabel",
        "defaultMessage": "Selezionato",
        "description": "Testo alternativo per utenti non vedenti per indicare una checkbox selezionata"
    },
    "checkBoxUnselectedAltLabel" : {
        "id": "commons.checkBoxUnselectedAltLabel",
        "defaultMessage": "Deselezionato",
        "description": "Testo alternativo per utenti non vedenti per indicare una checkbox non selezionata"
    },

    "downloadXLS" : {
        "id": "commons.downloadXLS",
        "defaultMessage": "Scarica XLS"
    },

    "sortableColumnNoneAlt" : {
        "id": "commons.sortableColumnNoneAlt",
        "defaultMessage": "La tabella non è ordinata per questa colonna"
    },
    "sortableColumnAscAlt" : {
        "id": "commons.sortableColumnAscAlt",
        "defaultMessage": "La tabella è ordinata per questa colonna ascendente"
    },
    "sortableColumnDescAlt" : {
        "id": "commons.sortableColumnDescAlt",
        "defaultMessage": "La tabella è ordinata per questa colonna discendente"
    },

    // Buttons
    "deleteButton" : {
        "id": "commons.deleteButton",
        "defaultMessage": "Rimuovi"
    },
    "cancelButton" : {
        "id": "commons.cancelButton",
        "defaultMessage": "Annulla"
    },
    "saveButton" : {
        "id": "commons.saveButton",
        "defaultMessage": "Salva"
    },
    "createButton" : {
        "id": "commons.createButton",
        "defaultMessage": "Crea"
    },
    "addButton" : {
        "id": "commons.addButton",
        "defaultMessage": "Aggiungi"
    },
    "resetButton" : {
        "id": "commons.resetButton",
        "defaultMessage": "Reset"
    },
    "closeButton" : {
        "id": "commons.closeButton",
        "defaultMessage": "Chiudi"
    },
    "confirmButton" : {
        "id": "commons.confirmButton",
        "defaultMessage": "Conferma"
    },
    "changeButton" : {
        "id": "commons.changeButton",
        "defaultMessage": "Modifica"
    },
    "blockButton" : {
        "id": "commons.blockButton",
        "defaultMessage": "Blocca"
    },
    "unlockButton" : {
        "id": "commons.unlockButton",
        "defaultMessage": "Sblocca"
    },
    

    // Footer

    "dataUpdateTimeLabel" : {
        "id": "commons.emailInputPlaceholder",
        "defaultMessage": "Dati aggiornati"
    },
    "today" : {
        "id": "commons.today",
        "defaultMessage": "Oggi"
    },
    "yesterday" : {
        "id": "commons.yesterday",
        "defaultMessage": "Ieri"
    },
    // Input placeholder
    "emailInputPlaceholder" : {
        "id": "commons.emailInputPlaceholder",
        "defaultMessage": "Email"
    },
    "passwordInputPlaceholder" : {
        "id": "commons.passwordInputPlaceholder",
        "defaultMessage": "Password"
    },

    // Input label
    "userNameLabel" : {
        "id": "commons.userNameLabel",
        "defaultMessage": "Nome utente"
    },
    "emailLabel" : {
        "id": "commons.emailLabel",
        "defaultMessage": "Indirizzo e-mail"
    },
    "countryLabel" : {
        "id": "commons.countryLabel",
        "defaultMessage": "Paese di riferimento"
    },
    "roleLabel" : {
        "id": "commons.roleLabel",
        "defaultMessage": "Ruolo"
    },

    // Dialogs
    "genericErrorDialogTitle" : {
        "id": "commons.genericErrorDialogTitle",
        "defaultMessage": "Sì è verificato un problema"
    },
    "genericErrorDialogBodyMessage" : {
        "id": "commons.genericErrorDialogBodyMessage",
        "defaultMessage": "L'operazione non può essere conclusa. Riprova più tardi o contatta l'amministratore."
    },

    // Currency
    "euroCurrency" : {
        "id": "commons.euroCurrency",
        "defaultMessage": "EUR"
    },

    // Validity periods
    "everydayValidityPeriod" : {
        "id": "commons.everydayValidityPeriod",
        "defaultMessage": "Tutti i giorni"
    },
    "workdaysValidityPeriod" : {
        "id": "commons.workdaysValidityPeriod",
        "defaultMessage": "Durante la settimana"
    },
    "weekendsValidityPeriod" : {
        "id": "commons.weekendsValidityPeriod",
        "defaultMessage": "Weekend"
    },
    "mondaysValidityPeriod" : {
        "id": "commons.mondaysValidityPeriod",
        "defaultMessage": "Lunedì"
    },
    "tuesdaysValidityPeriod" : {
        "id": "commons.tuesdaysValidityPeriod",
        "defaultMessage": "Martedì"
    },
    "wednesdaysValidityPeriod" : {
        "id": "commons.wednesdaysValidityPeriod",
        "defaultMessage": "Mercoledì"
    },
    "thursdaysValidityPeriod" : {
        "id": "commons.thursdaysValidityPeriod",
        "defaultMessage": "Giovedì"
    },
    "fridaysValidityPeriod" : {
        "id": "commons.fridaysValidityPeriod",
        "defaultMessage": "Venerdì"
    },
    "saturdaysValidityPeriod" : {
        "id": "commons.saturdaysValidityPeriod",
        "defaultMessage": "Sabato"
    },
    "sundaysValidityPeriod" : {
        "id": "commons.sundaysValidityPeriod",
        "defaultMessage": "Domenica"
    },

});