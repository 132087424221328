export enum Routes {
    Login = '/login',
    FirstLogin = '/first-login',
    Home = '/',
    ChangePassword = '/change-password',
    Providers = '/operators',
    ProviderContracts = '/operators/contracts',
    ProviderContractDetail = '/operators/contracts/detail',
    Transactions = '/transactions',
    Users = '/users',
    UserDetail = '/users/detail',
    Admin = '/admin',
}