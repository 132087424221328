import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { PagedListCell, PagedListCellVariant } from '../../../../common/components/paged-list/paged-list-cell';
import { Action, ActionsMenu } from '../../../../common/components/actions/actions-menu';
import { formatDate } from '../../../../common/utils/formatters';
import { Provider } from '../../../model/provider';
import { ContractStatus } from '../../../model/contract';

import IconArrowRight from '../../../../assets/images/icon-arrow-right.svg';

import contractsMessages from '../../../contracts.messages';
import actionMessages from '../../../../common/messages/action.messages';

interface ProviderTableItemProps {
    item: Provider;
    canUserDeleteItem: boolean;
    canUserUpdateItem: boolean;
    onDeleteItemClick?: (item: Provider) => void;
    onUpdateItemClick?: (item: Provider) => void;
    onItemClick?: (item: Provider) => void;
}
export const ProviderTableItem = (props: ProviderTableItemProps) => {

    const { formatMessage } = useIntl();

    const [contractStatus, contractStatusStyle] = useMemo(() => {
        
        if(props.item.hasNoContracts){
            return [
                formatMessage(contractsMessages.noContracts),
                PagedListCellVariant.Warning
            ];
        }

        if(props.item.activeContract?.isBlocked && props.item.activeContract.status !== ContractStatus.NOT_ACTIVE){
            return [
                formatMessage(contractsMessages.contractBlockedState),
                PagedListCellVariant.Warning
            ];
        }

        switch(props.item.activeContract?.status){
            case ContractStatus.ACTIVE:
                return [
                    formatMessage(contractsMessages.contractActiveState),
                    PagedListCellVariant.Success
                ];
            case ContractStatus.NOT_ACTIVE:
                return [
                    formatMessage(contractsMessages.contractNotActiveState),
                    PagedListCellVariant.Warning
                ];
            case ContractStatus.EXPIRED:
                return [
                    formatMessage(contractsMessages.contractExpiredState),
                    PagedListCellVariant.Warning
                ];
                
        }

        return ['', PagedListCellVariant.Warning];
    }, [
        formatMessage, props.item.hasNoContracts,
        props.item.activeContract?.isBlocked, 
        props.item.activeContract?.status
    ]);

    const bestWorstOrInRangeStyle = useMemo(() => {
        if(props.item.hasNoContracts){
            return PagedListCellVariant.Warning;
        }
        return undefined;
    }, [props.item.hasNoContracts]);

    const onItemClick = useCallback(
        () => props.onItemClick && props.onItemClick(props.item),
        [props]
    );

    const actions: Action[] = useMemo(() => {
        
        let actions: Action[] = [];

        if(props.canUserUpdateItem){
            actions = [
                ...actions, 
                {
                    label: formatMessage(actionMessages.updateProviderActionLabel),
                    execute: () => props.onUpdateItemClick && props.onUpdateItemClick(props.item)
                }
            ];
        }

        if(props.canUserDeleteItem){
            actions = [
                ...actions, 
                {
                    label: formatMessage(actionMessages.deleteProviderActionLabel),
                    execute: () => props.onDeleteItemClick && props.onDeleteItemClick(props.item)
                }
            ];
        }
        
        return actions;
    }, [formatMessage, props]);

    return <tr className={"provider-td"} onClick={onItemClick}>
        <PagedListCell isTextNotCapitalized={true}>{props.item.cpoId}</PagedListCell>
        <PagedListCell variant={PagedListCellVariant.Bold}>{props.item.cpoName}</PagedListCell>
        <PagedListCell variant={contractStatusStyle}>{contractStatus}</PagedListCell>
        <PagedListCell>
            {props.item.activeContract?.validityPeriod.from 
                ? formatDate(props.item.activeContract.validityPeriod.from) 
                : '-'}
        </PagedListCell>
        <PagedListCell>
            {props.item.activeContract?.validityPeriod.to 
                ? formatDate(props.item.activeContract.validityPeriod.to) 
                : '-'}
        </PagedListCell>
        <PagedListCell variant={bestWorstOrInRangeStyle}>{props.item.kwhDelivered.toFixed(2)}</PagedListCell>
        <PagedListCell variant={bestWorstOrInRangeStyle}>{props.item.chargesCount.toString()}</PagedListCell>
        <PagedListCell>{props.item.costsAmount.toFixed(2)}</PagedListCell>
        <PagedListCell variant={bestWorstOrInRangeStyle}>{(props.item.earningsAmount - props.item.costsAmount).toFixed(2)}</PagedListCell>
        <td className='actions'>
            { actions.length > 0 && <ActionsMenu actions={actions} /> }
            <img src={IconArrowRight} alt="" className='arrow-icon' />
        </td>
    </tr>;
};