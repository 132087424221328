import { PaymentSearchResult, PaymentsSearchResult } from "../../_generated/api";
import { Transactions, TransactionsModel, Transaction, TransactionModel, TransactionsSummaryModel, TransactionsSummary } from "../model/transaction";

export const parseTransactionFromApiModel = (apiProvider: PaymentSearchResult): Transaction => {
    return TransactionModel.create({
        cpoName: apiProvider.cpo,
        amountCollected: apiProvider.amountCollected,
        amountDue: apiProvider.amountDue,
        date: new Date(apiProvider.transactionDate),
        userId: apiProvider.userId
    });
};

export const parseTransactionsFromApiModel = (apiProvider: PaymentsSearchResult): Transactions => {
    return TransactionsModel.create({
        summary: TransactionsSummaryModel.create({
            totalAmountCollected: apiProvider.totalAmountCollected,
            totalAmountDue: apiProvider.totalAmountDue
        }),
        totalNumberOfItems: apiProvider.totalNumberOfItems,
        items: apiProvider.items.map(parseTransactionFromApiModel)
    });
};

export const parseTransactionsSummaryFromApiModel = (apiProvider: PaymentsSearchResult): TransactionsSummary => {
    return TransactionsSummaryModel.create({
        totalAmountCollected: apiProvider.totalAmountCollected,
        totalAmountDue: apiProvider.totalAmountDue
    });
};