import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { AppButtonVariant } from '../../../common/components/button/app-button';
import { BaseDialog } from '../../../common/components/dialogs/base-dialog';

import { StyledInput } from '../../../common/components/input/styled-input';
import { StyledSelect } from '../../../common/components/input/styled-select';

import { BackofficeUserInfo } from '../../model/backoffice-user-info';
import { isEmailValid as isEmailFormatValid, isPhoneNumberValid as isPhoneNumberFormatValid } from '../../../common/utils/validators';
import { convertRoleToMessageDescriptor, UserRole } from '../../../common/models/user';
import { Country, nameFromCountryId } from '../../../common/models/country';

import messages from '../../administration.messages';
import commonMessages from '../../../common/messages/common.messages';

import './manage-user-dialog.scss';

export interface ManageBackofficeUserDialogProps {
    open: boolean
    isOperationRunning: boolean
    onClose: () => void
    onConfirmButtonClick: (info: BackofficeUserInfo) => void

    countries: Country[]
    roles: UserRole[]
    currentUserInfo?: BackofficeUserInfo
}

export const ManageBackofficeUserDialog = observer((props: ManageBackofficeUserDialogProps) => {

    const { formatMessage } = useIntl();
    
    const isUpdatingInfo = props.currentUserInfo != null;

    const [userName, setUserName] = useState(props.currentUserInfo?.name ?? '');
    const [email, setEmail] = useState(props.currentUserInfo?.email ?? '');
    const [country, setCountry] = useState<Country | undefined>(props.currentUserInfo?.country);
    const [role, setRole] = useState<UserRole | undefined>(props.currentUserInfo?.role);
    const [contactDetails, setContactDetails] = useState(props.currentUserInfo?.contact_details ?? '');
    const [showNameError, setShowNameError] = useState(false);
    const [showEmailError, setShowEmailError] = useState(false);
    const [changed, setChanged] = useState(false);

    useEffect(() => {
        setUserName(props.currentUserInfo?.name ?? '');
        setEmail(props.currentUserInfo?.email ?? '');
        setContactDetails(props.currentUserInfo?.contact_details ?? '');
        setCountry(props.currentUserInfo?.country);
        setRole(props.currentUserInfo?.role);
    }, [props.currentUserInfo]);

    const onClose = useCallback(() => {
        if(!props.isOperationRunning) {
            setUserName('');
            setEmail('');
            setContactDetails('');
            setCountry(undefined);
            setRole(undefined);
            setShowEmailError(false);
            setShowNameError(false);
            props.onClose();
        }
    }, [props]);

    const countries = useMemo(() => 
        props.countries
            .map((country) => {
                const nameDescriptor = nameFromCountryId(country);
                return {
                    id: country, 
                    label: nameDescriptor ? formatMessage(nameDescriptor) : country
                };
            }).sort((a,b) => a.label.localeCompare(b.label)),
        [formatMessage, props.countries]
    );

    const roles = useMemo(() => 
        props.roles
            .map((role) => ({
                id: role, 
                label: formatMessage(convertRoleToMessageDescriptor(role))
            })),
        [formatMessage, props.roles]
    );

    const onCountrySelected = useCallback(
        (countryId: string) => {
            setCountry(countryId);
            setChanged(true);
        },
        []
    );

    const onRoleSelected = useCallback(
        (roleId: string) => {
            setRole(props.roles.find(elem => elem == roleId));
            setChanged(true);
        },
        [props.roles]
    );

    const onNameChanged = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setUserName(event.target.value);
            setChanged(true);
        },
        []
    );

    const onNameBlur = useCallback(
        () => setShowNameError(userName.trim().length == 0),
        [userName]
    );

    const onEmailChanged = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
            setChanged(true);
        },
        []
    );

    const onEmailBlur = useCallback(
        () => setShowEmailError(!isEmailFormatValid(email)),
        [email]
    );

    const onContactDetailsChanged = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setContactDetails(event.target.value);
            setChanged(true);
        },
        []
    );

    const isConfirmButtonEnabled = useMemo(() => {
        
        const isUserNameValid = userName.trim().length > 0;
        const isEmailValid = isEmailFormatValid(email);
        const isPhoneNumberValid = contactDetails.trim().length == 0 || isPhoneNumberFormatValid(contactDetails) != undefined;
        
        return country != undefined
            && role != undefined
            && isUserNameValid
            && isEmailValid
            && isPhoneNumberValid
            && changed;
    }, [country, email, contactDetails, role, userName, changed]);

    const onConfirmButtonClick = useCallback(() => {
        if(isConfirmButtonEnabled) {
            props.onConfirmButtonClick({
                country: country ?? '',
                email: email,
                name: userName,
                role: role ?? UserRole.Operator,
                contact_details: contactDetails
            });

            setChanged(false);
        }
            
    }, [country, email, isConfirmButtonEnabled, contactDetails, props, role, userName]);

    return <BaseDialog
        containerClassName='manage-backoffice-user-dialog'
        open={props.open}
        title={isUpdatingInfo ? props.currentUserInfo?.name ?? '': formatMessage(messages.newOperatorDialogTitle)}
        buttons={[
            {
                label: formatMessage(commonMessages.cancelButton),
                disabled: props.isOperationRunning,
                buttonClasses: AppButtonVariant.Dark,
                onClick: onClose
            },
            {
                label: formatMessage(isUpdatingInfo ? commonMessages.saveButton : commonMessages.createButton),
                disabled: !isConfirmButtonEnabled || props.isOperationRunning,
                buttonClasses: AppButtonVariant.Primary,
                onClick: onConfirmButtonClick
            },
        ]}
        onClose={onClose}
    >
        <p>{formatMessage(isUpdatingInfo ? messages.updateOperatorDialogBody : messages.newOperatorDialogBody)}</p>

        <div className='form-field'>
            <StyledInput
                label={formatMessage(commonMessages.userNameLabel)}
                value={userName}
                onChange={onNameChanged}
                onBlur={onNameBlur}
                errorLabel={showNameError ? formatMessage(messages.nameEmptyError) : undefined}
            />
            <StyledInput
                label={formatMessage(commonMessages.emailLabel)}
                value={email}
                disabled={isUpdatingInfo}
                type='email'
                onChange={onEmailChanged}
                onBlur={onEmailBlur}
                errorLabel={showEmailError ? formatMessage(messages.invalidEmailError) : undefined}
            />
            <StyledSelect
                label={formatMessage(commonMessages.countryLabel)}
                items={countries} 
                onItemSelected={onCountrySelected}
                selectedItemId={country}
            />
            <StyledSelect
                label={formatMessage(commonMessages.roleLabel)}
                items={roles} 
                onItemSelected={onRoleSelected}
                selectedItemId={role}
            />
            <StyledInput
                label={formatMessage(messages.contactDetails)}
                value={contactDetails}
                onChange={onContactDetailsChanged}
            />
        </div>
    </BaseDialog>;

});