import React, { useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

import userIcon from '../../../assets/images/icon-user.svg';
import { AppButton, AppButtonVariant } from '../../../common/components/button/app-button';
import { CheckedState, StyledCheckBox } from '../../../common/components/input/styled-checkbox';
import { StyledInput } from '../../../common/components/input/styled-input';
import { StyledSelect } from '../../../common/components/input/styled-select';

import searchIcon from '../../../assets/images/icon-search.svg';

import messages from '../../users.messages';

import { useStore } from '../../../common/stores/root-store';
import { UsersFilter } from '../../model/users-filters';
import { observer } from 'mobx-react-lite';
import { SelectItem } from '../../../common/components/input/select-item';
import { messageDescriptorForStatus, MobileUserStatus } from '../../model/app-user';

import './users-section-header.scss';

const allStatusFilterItemId = 'allStatus';

export const UsersSectionHeader = observer(() => {

    const { formatMessage } = useIntl();
    const { appUsersStore } = useStore();

    const emailRef = useRef<HTMLInputElement>(null);

    const filters: UsersFilter = appUsersStore.filters;

    const onEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        filters.setEmail(e.target.value);
    }, [filters]);
    const onEmailSearchClick = useCallback(() =>
        emailRef.current?.blur(), [emailRef]);
    const onEmailBlur = useCallback(() =>
        appUsersStore.applyFilters(), [appUsersStore]);

    const userStatusItems: SelectItem[] = useMemo(() => {
        return [
            {
                id: allStatusFilterItemId,
                label: formatMessage(messages.allStatusFilterLabel)
            },
            ...Object.values(MobileUserStatus).map(value => ({
                id: value,
                label: formatMessage(messageDescriptorForStatus(value))
            }))
        ];
    }, [formatMessage]);
    const selectUserStatus = useCallback((statusId: string) => {
        filters.setUserStatus(statusId == allStatusFilterItemId ? undefined : statusId as MobileUserStatus);
        appUsersStore.applyFilters();
    }, [appUsersStore, filters]);

    const togglePrivateUserFilter = useCallback(
        () => {
            filters.setPrivateUserEnabled(!filters.privateUser);
            appUsersStore.applyFilters();
        },
        [appUsersStore, filters]
    );
    const toggleBusinessUserFilter = useCallback(
        () => {
            filters.setBusinessUserEnabled(!filters.businessUser);
            appUsersStore.applyFilters();
        },
        [appUsersStore, filters]
    );

    const resetFilters = useCallback(() => {
        filters.reset();
        appUsersStore.applyFilters();
    }, [appUsersStore, filters]);

    return <div className='users-section-header section-header'>
        <img className='user-icon' src={userIcon} alt='' />
        <h2>{formatMessage(messages.sectionTitle)} ({appUsersStore.totalItems})</h2>

        <StyledInput
            inputRef={emailRef}
            className='email-search-input'
            onChange={onEmailChange}
            value={filters.email}
            placeholder={formatMessage(messages.emailFilterPlaceholder)}
            icon={searchIcon}
            onIconClick={onEmailSearchClick}
            onBlur={onEmailBlur} />
        <StyledSelect
            containerClassName='state-search-select'
            items={userStatusItems}
            selectedItemId={filters.userStatus || allStatusFilterItemId}
            onItemSelected={selectUserStatus} />
        <StyledCheckBox
            checked={filters.privateUser ? CheckedState.Checked : CheckedState.Unchecked}
            onClick={togglePrivateUserFilter}
            label={`${formatMessage(messages.privatesFilterPlaceholder)}`}
            containerClassName='users-header-checkbox companies-checkbox' />
        <StyledCheckBox
            checked={filters.businessUser ? CheckedState.Checked : CheckedState.Unchecked}
            onClick={toggleBusinessUserFilter}
            label={formatMessage(messages.companiesFilterPlaceholder)}
            containerClassName='users-header-checkbox privates-checkbox' />

        <AppButton
            buttonClasses='reset-button'
            label={formatMessage(messages.resetFiltersButton)}
            onClick={resetFilters}
            variant={AppButtonVariant.Tertiary}
        />
    </div>;
});