import { MessageDescriptor } from "react-intl";
import { PriceReferenceUnit as ApiPricingReferenceUnit } from '../../_generated/api/api';

import messages from '../messages/pricing.messages';

export enum PricingReferenceUnit {
    KWH = 'kwh',
    PER_HOUR = 'h',
    PER_MINUTE = 'm',
}

export function fromPricingRefernceUnitToMessageDescriptor(pricing: PricingReferenceUnit): MessageDescriptor {
    switch(pricing){
        case PricingReferenceUnit.PER_HOUR:
            return messages.perHour;
        case PricingReferenceUnit.PER_MINUTE:
            return messages.perMinute;
        case PricingReferenceUnit.KWH:
            return messages.kilowattPerHour;
        default:
            return messages.kilowattPerHour;
    }
}

export function parsePricingReferenceUnitToApiModel(pricing: PricingReferenceUnit): ApiPricingReferenceUnit {
    switch(pricing){
        case PricingReferenceUnit.PER_HOUR:
            return ApiPricingReferenceUnit.Hour;
        case PricingReferenceUnit.PER_MINUTE:
            return ApiPricingReferenceUnit.Minute;
        case PricingReferenceUnit.KWH:
        default:
            return ApiPricingReferenceUnit.KilowattHour;
    }
}

export function parsePricingReferenceUnitFromApiModel(pricing: ApiPricingReferenceUnit): PricingReferenceUnit {
    switch(pricing){
        case ApiPricingReferenceUnit.Hour:
            return PricingReferenceUnit.PER_HOUR;
        case ApiPricingReferenceUnit.Minute:
            return PricingReferenceUnit.PER_MINUTE;
        case ApiPricingReferenceUnit.KilowattHour:
        default:
            return PricingReferenceUnit.KWH;
    }
}