import { defineMessages } from "react-intl";

export default defineMessages({
  sectionTitle: {
    id: "containers.administration.sectionTitle",
    defaultMessage: "Amministrazione",
    description: "",
  },
  searchAdminHintLabel: {
    id: "containers.administration.searchAdminHintLabel",
    defaultMessage: "Cerca per nome o email",
    description: "Messaggio d'aiuto mostrato nella barra di ricerca",
  },
  anyRoleFilter: {
    id: "containers.administration.anyRoleFilter",
    defaultMessage: "Qualsiasi ruolo",
    description: "",
  },
  createNewUser: {
    id: "containers.administration.createNewUser",
    defaultMessage: "Crea nuovo",
    description:
      "Testo del bottone che abilita alla creazione di un nuovo utente del back-office",
  },
  newOperatorDialogTitle: {
    id: "containers.administration.dialogs.newOperatorDialogTitle",
    defaultMessage: "Nuovo operatore",
    description:
      "Titolo della dialog che permette la creazione di un utente del backoffice",
  },
  newOperatorDialogBody: {
    id: "containers.administration.dialogs.newOperatorDialogBody",
    defaultMessage:
      "Compila il form per creare la nuova utenza. Verrà inviata una mail all'indirizzo \
        indicato contenente il link al portale e la password temporanea di accesso.",
    description:
      "Testo della dialog che permette la creazione di un utente del backoffice",
  },
  contactDetails: {
    id: "containers.administration.dialogs.contactDetails",
    defaultMessage: "Recapito (opzionale)",
    description: "Label per il recapito del nuovo utente",
  },
  nameEmptyError: {
    id: "containers.administration.dialogs.nameEmptyError",
    defaultMessage: "Campo obbligatorio",
    description: "Errore da mostrare quando il nome inserito non è valido",
  },
  invalidEmailError: {
    id: "containers.administration.dialogs.invalidEmailError",
    defaultMessage: 'Una mail deve contenere almeno una "@" e un "."',
    description: "Errore da mostrare quando la email inserita non è valida",
  },
  backofficeUserCreated: {
    id: "containers.administration.dialogs.backofficeUserCreated",
    defaultMessage: "Operatore aggiunto",
    description: "Messaggio di conferma per l'aggiunta di un operatore",
  },
  updateOperatorDialogBody: {
    id: "containers.administration.dialogs.updateOperatorDialogBody",
    defaultMessage:
      "Da qui puoi rivedere e modificare i dati ed i permessi associati a questo utente.",
    description:
      "Testo della dialog che permette l'aggiornamento di un utente del backoffice",
  },
  backofficeUserUpdated: {
    id: "containers.administration.dialogs.backofficeUserUpdated",
    defaultMessage: "Modifiche salvate",
    description: "Messaggio di conferma per la modifica di un operatore",
  },
  deleteOperatorDialogBody: {
    id: "containers.administration.dialogs.deleteOperatorDialogBody",
    defaultMessage:
      "L'account associato a {email} non avrà più accesso al portale.",
    description:
      "Testo della dialog che permette la rimozione di un utente del backoffice",
  },
  deleteOperatorDialogTitle: {
    id: "containers.administration.dialogs.deleteOperatorDialogTitle",
    defaultMessage: "Rimuovere {name}?",
    description:
      "Titolo della dialog che permette la rimozione di un utente del backoffice",
  },
  backofficeUserDeleted: {
    id: "containers.administration.dialogs.backofficeUserDeleted",
    defaultMessage: "Operatore rimosso",
    description: "Messaggio di conferma per la rimozione di un operatore",
  },
  backofficeUserCannotDeleteHimselfError: {
    id: "containers.administration.dialogs.backofficeUserCannotDeleteHimselfError",
    defaultMessage: "Un amministratore non può rimuovere se stesso.",
    description:
      "Messaggio di errore mostrato quando un utente prova a rimuovere se stesso",
  },
  backofficeUserCannotUpdateHisRoleError: {
    id: "containers.administration.dialogs.backofficeUserCannotUpdateHisRoleError",
    defaultMessage: "Un amministratore non può aggiornare il proprio ruolo.",
    description:
      "Messaggio di errore mostrato quando un utente prova ad aggiornare il proprio ruolo",
  },
  backofficeUserAlreadyExistsError: {
    id: "containers.administration.dialogs.backofficeUserAlreadyExistsError",
    defaultMessage:
      "Questo indirizzo e-mail è già stato registrato per un altro operatore.",
    description:
      "Messaggio di errore mostrato quando si prova a registrare un utente con una e-mail già registrata",
  },
});
