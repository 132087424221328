import { Instance, types } from "mobx-state-tree";
import { MessageDescriptor } from "react-intl";
import { BackofficeRole } from "../../_generated/api";

import messages from '../messages/role.messages';

export enum UserRole {
    Admin = 'ADMIN',
    Operator = 'OPERATOR',
    Manager = 'MANAGER'
}

export function convertRoleToMessageDescriptor(role: UserRole): MessageDescriptor {
    switch(role){
        case UserRole.Admin:
            return messages.admin;
        case UserRole.Manager:
            return messages.manager;
        case UserRole.Operator:
            return messages.operator; 
    }
}

export function fromRoleToBackofficeRole(role: UserRole): BackofficeRole {
    switch(role){
        case UserRole.Admin:
            return BackofficeRole.Admin;
        case UserRole.Manager:
            return BackofficeRole.Manager;
        case UserRole.Operator:
        default:
            return BackofficeRole.Operator;
    }
}

export const UserModel = types.model({
    role: types.enumeration<UserRole>(Object.values(UserRole)),
    id: types.identifier,
    email: types.string,
    country: types.string,
    name: types.string,
    locale: types.string,
    contactDetails: types.maybeNull(types.string)
});

export interface User extends Instance<typeof UserModel> { }