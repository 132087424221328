import { AxiosPromise } from "axios";

import { ListApiService } from "../../../common/api/items-list/list-api-service";
import { parseCreateFareDataToApiModel, parseFareFromApiModel, parseUpdateFareDataToApiModel } from "./fare-api-parser";
import { OrderCriteria } from "../../../common/models/order-criteria";
import { Fare } from "../../model/fare";
import { GetItemsResponse } from "../../../common/api/items-list/get-items";
import apiManager from "../../../common/api/api-manager";
import { DEFAULT_PAGE_ITEMS } from "../../../common/config/constants";
import HttpStatusCode from "../../../common/api/http-status.code";
import { CreateFareData, UpdateFareData } from "./fare-request";
import { ErrorCode, Fare as ApiFare } from "../../../_generated/api";
import { FareWithSameIdAlreadyExists } from "./fare-api-exception";

export class FareApiService extends ListApiService<Fare, never, never> {

    private providerId?: string;
    private contractId?: string;
    private groupId = "";

    parseListItem = (apiResponse: any) => parseFareFromApiModel(apiResponse);

    parseSummary = () => undefined;

    async getItems(pageNumber: number, filters: never, orderCriteria?: OrderCriteria, itemPerPage?: number): Promise<GetItemsResponse<Fare, never>> {

        const offset = (itemPerPage || 0) * (pageNumber - 1);
        const limit = itemPerPage || DEFAULT_PAGE_ITEMS;

        const result = await this.downloadItemsList(offset, limit, filters, orderCriteria);

        if (result.status !== HttpStatusCode.OK) {
            throw new Error();
        }

        const data = result.data.slice(offset, limit);

        return Promise.resolve({
            totalItems: result.data.length,
            items: data.map(this.parseListItem)
        });
    }

    downloadItemsList(offset: number, limit: number, filters: never, orderCriteria?: OrderCriteria, accept?: string): AxiosPromise<ApiFare[]> {
        if (!this.providerId || !this.contractId) {
            throw new Error('provider id or contract id not specified');
        }

        const config = accept === undefined ? undefined : {
            headers: {
                'Accept': accept
            }
        };

        return apiManager.providerApi.getFares(
            apiManager.idToken,
            this.providerId,
            this.groupId,
            this.contractId,
            orderCriteria ? `${orderCriteria.key}:${orderCriteria.sortOrder.toLowerCase()}` : undefined,
            config
        );
    }

    async deleteFare(fare: Fare): Promise<void> {
        if (!this.providerId || !this.contractId) {
            throw new Error('provider id or contract id not specified');
        }

        const result = await apiManager.providerApi.deleteFare(
            apiManager.idToken,
            this.providerId,
            this.contractId,
            this.groupId,
            fare.fareId,
        );

        if (result.status === HttpStatusCode.NO_CONTENT) {
            return Promise.resolve();
        }
        else {
            throw new Error();
        }
    }

    async addFare(fare: CreateFareData): Promise<Fare> {
        if (!this.providerId || !this.contractId) {
            throw new Error('provider id or contract id not specified');
        }

        try {
            const result = await apiManager.providerApi.createFare(
                apiManager.idToken,
                this.providerId,
                this.contractId,
                this.groupId,
                parseCreateFareDataToApiModel(fare)
            );

            if (result.status === HttpStatusCode.CREATED) {
                return Promise.resolve(
                    parseFareFromApiModel(result.data)
                );
            }
            else {
                throw new Error();
            }
        } catch (e: any) {
            if (e.response?.data?.errorCode === ErrorCode._4xxItemAlreadyExists || e.response?.status === HttpStatusCode.CONFLICT) {
                throw new FareWithSameIdAlreadyExists();
            } else {
                throw e;
            }
        }
    }

    async updateFare(fareId: string, fare: UpdateFareData): Promise<void> {
        if (!this.providerId || !this.contractId) {
            throw new Error('provider id or contract id not specified');
        }

        try {
            const result = await apiManager.providerApi.updateFare(
                apiManager.idToken,
                this.providerId,
                this.contractId,
                this.groupId,
                fareId,
                parseUpdateFareDataToApiModel(fare)
            );

            if (result.status === HttpStatusCode.OK) {
                return Promise.resolve();
            }
            else {
                throw new Error();
            }
        } catch (e: any) {
            if (e.response?.data?.errorCode === ErrorCode._4xxItemAlreadyExists || e.response?.status === HttpStatusCode.CONFLICT) {
                throw new FareWithSameIdAlreadyExists();
            } else {
                throw e;
            }
        }
    }

    setProviderId(providerId: string) {
        this.providerId = providerId;
    }

    setContractId(contractId: string) {
        this.contractId = contractId;
    }

    setGroupId(groupId: string) {
        this.groupId = groupId;
    }

}

export const fareApiService = new FareApiService('fares');