import { IntlFormatters } from "react-intl";

import filterMessages from "../messages/filter.messages";

export enum TimePeriodFilter {
    THIS_MONTH = '1',
    LAST_MONTH = '2',
    LAST_3_MONTHS = '3',
    LAST_6_MONTHS = '4',
    LAST_YEAR = '5',
    LAST_30_DAYS = '6',
}

export const getTimeFilters = (intl: IntlFormatters) => [
    { id: TimePeriodFilter.THIS_MONTH, label: intl.formatMessage(filterMessages.thisMonthFilter) },
    { id: TimePeriodFilter.LAST_MONTH, label: intl.formatMessage(filterMessages.lastMonthFilter) },
    { id: TimePeriodFilter.LAST_3_MONTHS, label: intl.formatMessage(filterMessages.lastThreeMonthsFilter) },
    { id: TimePeriodFilter.LAST_6_MONTHS, label: intl.formatMessage(filterMessages.lastSixMonthsFilter) },
    { id: TimePeriodFilter.LAST_YEAR, label: intl.formatMessage(filterMessages.lastYearFilter) },
];