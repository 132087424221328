import React, { useState } from 'react';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { Redirect } from 'react-router';
import { onSnapshot } from 'mobx-state-tree';

import { PagedList } from '../../../common/components/paged-list/paged-list';
import { useStore } from '../../../common/stores/root-store';
import { AdminSectionHeader } from './admin-section-header';
import { AdminTableHeader } from './admin-table-header';
import { TopMenu, TopMenuProps } from '../../../common/components/menu/top-menu';
import { Footer } from '../../../common/components/footer/footer';
import { OrderManagerProps } from '../../../common/models/order-criteria';
import { AppButtonVariant } from '../../../common/components/button/app-button';
import { ButtonRow } from '../../../common/components/button-row/button-row';
import { DeleteBackofficeUserDialog } from '../dialog/delete-user-dialog';
import { AdminTableItem } from './admin-table-item';
import { ManageBackofficeUserDialog } from '../dialog/manage-user-dialog';

import { BackofficeUserInfo } from '../../model/backoffice-user-info';
import { ApiRequestState } from '../../../common/api/api-request';
import { createServerErrorNotification, createSuccessNotification } from '../../../common/stores/notifications-store';
import { User, UserRole } from '../../../common/models/user';
import { AdministrationError } from '../../store/admin-store';
import { Routes } from '../../../common/routing/routes';
import { supportedCountries } from '../../../common/models/country';

import commonMessages from '../../../common/messages/common.messages';
import messages from '../../administration.messages';

import './admin-paged-list.scss';

interface AdminPagedListProps extends TopMenuProps { }

export const AdminPagedList = observer((props: AdminPagedListProps) => {

    const { administrationStore, notificationStore, userStore } = useStore();
    const intl = useIntl();
    const { formatMessage, locale } = intl;

    /* ---------- CREATE/UPDATE USER DIALOG MANAGEMENT ---------- */

    const [showManageUserDialog, setShowManageUserDialog] = useState(false);
    const [userInfoToUpdate, setUserInfoToUpdate] = useState<BackofficeUserInfo | undefined>();

    const toggleManageUserDialog = useCallback((userInfo?: BackofficeUserInfo) => {
        setUserInfoToUpdate(userInfo);
        setShowManageUserDialog(!showManageUserDialog);
    }, [showManageUserDialog]);

    const onNewBackofficeUserInfoSubmitted = useCallback((userInfo: BackofficeUserInfo) => {
        const dispose = onSnapshot(administrationStore, (snapshot) => {
            let managed = false;

            if (snapshot.manageUserRequestState === ApiRequestState.COMPLETED) {
                notificationStore.showNotification(createSuccessNotification(intl, messages.backofficeUserCreated));
                managed = true;
                administrationStore.reload();
                setShowManageUserDialog(false);
            } else if (snapshot.manageUserRequestState === ApiRequestState.FAILED) {
                notificationStore.showNotification(createServerErrorNotification(
                    intl,
                    snapshot.administrationError === AdministrationError.USER_ALREADY_EXISTS_ERROR ?
                        messages.backofficeUserAlreadyExistsError : undefined
                ));
                managed = true;
            }

            if (managed) {
                dispose();
                administrationStore.clearManageUserRequestState();
            }
        });
        administrationStore.createUser(userInfo, locale);
    }, [administrationStore, intl, locale, notificationStore]);

    const onUpdateBackofficeUserInfoSubmitted = useCallback((userInfo: BackofficeUserInfo) => {
        const dispose = onSnapshot(administrationStore, (snapshot) => {
            let managed = false;

            if (snapshot.manageUserRequestState === ApiRequestState.COMPLETED) {
                notificationStore.showNotification(createSuccessNotification(intl, messages.backofficeUserUpdated));
                managed = true;
                administrationStore.reload();
                setUserInfoToUpdate(undefined);
                setShowManageUserDialog(false);
            } else if (snapshot.manageUserRequestState === ApiRequestState.FAILED) {
                notificationStore.showNotification(createServerErrorNotification(
                    intl,
                    snapshot.administrationError === AdministrationError.USER_CANNOT_UPDATE_HIS_ROLE ?
                        messages.backofficeUserCannotUpdateHisRoleError : undefined
                ));
                managed = true;
            }

            if (managed) {
                dispose();
                administrationStore.clearManageUserRequestState();
                administrationStore.clearAdministrationError();
            }
        });
        administrationStore.updateUserInfo(userInfoToUpdate?.id ?? '', userInfo);
    }, [administrationStore, intl, notificationStore, userInfoToUpdate]);

    /* ---------- DELETE USER DIALOG MANAGEMENT ---------- */

    const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false);
    const [userInfoToDelete, setUserInfoToDelete] = useState<User | undefined>();

    const toggleDeleteUserDialog = useCallback((user?: User) => {
        setUserInfoToDelete(user);
        setShowDeleteUserDialog(!showDeleteUserDialog);
    }, [showDeleteUserDialog]);

    const onDeleteUserButtonClick = useCallback(() => {
        const dispose = onSnapshot(administrationStore, (snapshot) => {
            let managed = false;

            if (snapshot.deleteUserRequestState === ApiRequestState.COMPLETED) {
                notificationStore.showNotification(createSuccessNotification(intl, messages.backofficeUserDeleted));
                managed = true;
                administrationStore.onItemDeleted();
            } else if (snapshot.deleteUserRequestState === ApiRequestState.FAILED) {
                notificationStore.showNotification(createServerErrorNotification(
                    intl,
                    snapshot.administrationError === AdministrationError.USER_CANNOT_DELETE_HIMSELF ?
                        messages.backofficeUserCannotDeleteHimselfError : undefined
                ));
                managed = true;
            }

            if (managed) {
                dispose();
                toggleDeleteUserDialog();
                administrationStore.clearDeleteUserRequestState();
                administrationStore.clearAdministrationError();
            }
        });
        if (userInfoToDelete != null)
            administrationStore.deleteUser(userInfoToDelete);
    }, [administrationStore, intl, notificationStore, toggleDeleteUserDialog, userInfoToDelete]);

    /* ----------  TABLE MANAGEMENT ---------- */

    const applyFilters = useCallback(() => administrationStore.applyFilters(), [administrationStore]);

    const renderHeader = useCallback(() =>
        <AdminSectionHeader
            totalItems={administrationStore.totalItems}
            countries={supportedCountries}
            roles={Object.values(UserRole)}

            filters={administrationStore.filters}
            applyFilters={applyFilters}
        />, [administrationStore.filters, administrationStore.totalItems, applyFilters]);

    const renderTableHeader = useCallback((orderProps: OrderManagerProps) =>
        <AdminTableHeader {...orderProps} />, []
    );

    const renderItem = useCallback((item: User) =>
        <AdminTableItem
            key={item.id}
            user={item}
            onUpdateUserClick={toggleManageUserDialog}
            onDeleteUserClick={toggleDeleteUserDialog}
        />,
        [toggleDeleteUserDialog, toggleManageUserDialog]
    );

    const downloadXLS = useCallback(() => {
        administrationStore.downloadReport();
    }, [administrationStore]);

    const renderTableFooter = useCallback((isTableEmpty: boolean) =>
        <ButtonRow buttons={[
            {
                onClick: downloadXLS,
                label: formatMessage(commonMessages.downloadXLS),
                disabled: administrationStore.isDownloadingReport || isTableEmpty,
                buttonClasses: AppButtonVariant.Dark
            },
            {
                label: formatMessage(messages.createNewUser),
                buttonClasses: AppButtonVariant.Primary,
                onClick: () => toggleManageUserDialog()
            },
        ]} />,
        [administrationStore.isDownloadingReport, downloadXLS, formatMessage, toggleManageUserDialog]);



    return userStore.isAdminLogged ? <div className='page'>

        <TopMenu {...props} />
        <PagedList
            tableClass='admin-table'
            store={administrationStore}
            columnsCount={6}
            renderTableHeader={renderTableHeader}
            renderTableRow={renderItem}
            renderHeader={renderHeader}
            renderTableFooter={renderTableFooter} />
        <Footer />

        <ManageBackofficeUserDialog
            key={showManageUserDialog ? 'shown' : 'hidden'} /** Used to reset fields when dialog is closed */
            open={showManageUserDialog}
            isOperationRunning={administrationStore.manageUserRequestState === ApiRequestState.RUNNING}
            onClose={toggleManageUserDialog}
            onConfirmButtonClick={
                userInfoToUpdate != null ? onUpdateBackofficeUserInfoSubmitted
                    : onNewBackofficeUserInfoSubmitted
            }

            countries={supportedCountries}
            roles={Object.values(UserRole)}
            currentUserInfo={userInfoToUpdate}
        />

        <DeleteBackofficeUserDialog
            open={showDeleteUserDialog}
            isOperationRunning={administrationStore.deleteUserRequestState === ApiRequestState.RUNNING}
            onClose={toggleDeleteUserDialog}
            onConfirmButtonClick={onDeleteUserButtonClick}

            user={userInfoToDelete}
        />
    </div> : <Redirect to={Routes.Providers} />;
});