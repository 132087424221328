import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import userIcon from '../../../assets/images/user.svg';

import { useIntl } from 'react-intl';
import { useStore } from '../../stores/root-store';
import { Routes } from '../../routing/routes';
import { ActionsMenu } from '../actions/actions-menu';

import './top-menu.scss';
import commonMessages from '../../messages/common.messages';
import actionMessages from '../../messages/action.messages';
import { Redirect, useLocation } from 'react-router';
import { ChangePasswordLocationState } from '../../../change-password/ui/components/change-password';

interface MenuVoice {
    label: string;
    destination: string;
}

export interface TopMenuProps {
    isAdmin: boolean;
}

export const TopMenu = observer(({ isAdmin }: TopMenuProps) => {


    const { formatMessage } = useIntl();
    const { userStore } = useStore();

    const [hasToRedirectToChangePassword, setHasToRedirectToChangePassword] = useState(false);

    const voices: MenuVoice[] = useMemo(() => {
        const items = [{
            label: formatMessage(commonMessages.providerTitle),
            destination: `#${Routes.Providers}`
        }, {
            label: formatMessage(commonMessages.transactionsTitle),
            destination: `#${Routes.Transactions}`
        }, {
            label: formatMessage(commonMessages.usersTitle),
            destination: `#${Routes.Users}`
        }];
        if (isAdmin) {
            items.push({
                label: formatMessage(commonMessages.administrationTitle),
                destination: `#${Routes.Admin}`
            });
        }
        return items;
    }, [isAdmin, formatMessage]);

    const onLogoutSelected = useCallback(() => userStore.logout(), [userStore]);

    return <header>
        <nav>
            <div className="top-nav">
                <a href={'#' + Routes.Providers} className="logo" aria-label='home'><h1>ChargeCar.com BackOffice</h1></a>
                <ul>
                    {voices.map((voice, index) => <li key={index}>
                        <a
                            className={`${location.hash.startsWith(voice.destination) ? ' selected' : ''}`}
                            href={voice.destination}>{voice.label}</a>
                    </li>)}
                </ul>
                <span className='username'>{userStore.user?.name}</span>
                <ActionsMenu
                    icon={userIcon}
                    actions={[
                        {
                            label: formatMessage(actionMessages.changePasswordLabel),
                            execute: () => setHasToRedirectToChangePassword(true)
                        },
                        {
                            label: formatMessage(actionMessages.logoutLabel),
                            execute: onLogoutSelected
                        },
                    ]}
                />
                {hasToRedirectToChangePassword && <Redirect to={{
                    pathname: Routes.ChangePassword,
                    state: { previousLocation: useLocation().pathname } as ChangePasswordLocationState
                }} />}
            </div>
        </nav>
    </header>;
});

