import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '@material-ui/core';

import ArrowRight from '../../../assets/images/icon-arrow-right.svg';

import './app-breadcrumb.scss';

export interface BreadcrumbPageInfo {
    label: string;
    to: string;
}

export interface AppBreadcrumbProps {
    currentPage: BreadcrumbPageInfo;
    path?: BreadcrumbPageInfo[];
    className?: string;
    separator?: string;
}

export const AppBreadcrumb = (props: AppBreadcrumbProps) => {
    return <div className={`app-breadcrumb ${props.className ?? ''}`}>
        <Breadcrumbs separator={<img src={props.separator ?? ArrowRight} alt='breadcrumb separator' />}>

            {
                props.path && props.path.map((elem, index) =>
                    <Link key={index} to={elem.to} className='link'> {elem.label} </Link>
                )
            }

            <span className='current-page'>{props.currentPage.label}</span>

        </Breadcrumbs>
    </div>;
};