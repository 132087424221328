export const DEFAULT_PAGE_ITEMS = 10;

export const NOTIFICATION_TIME_MILLISECONDS = 3000;

export const HOME_ROUTE = '/insurances';

export const HEADER_ACCEPT_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export enum ResourceType {
    Providers = 'providers',
    Contracts = 'contracts',
    Fares = 'fares',
    Users = 'users',
    Transaction = 'transactions',
    BackofficeUser = 'backoffice_users',
    Groups = "groups"
}