import React from 'react';
import { useIntl } from 'react-intl';
import { ActionsMenu } from '../../../common/components/actions/actions-menu';
import { PagedListCell, PagedListCellVariant } from '../../../common/components/paged-list/paged-list-cell';
import { formatDateAndTime } from '../../../common/utils/formatters';
import { Transaction } from '../../model/transaction';
import actionMessages from '../../../common/messages/action.messages';
import { emptyFunction } from '../../../common/utils/utils';
import { observer } from 'mobx-react-lite';

interface TransactionTableItemProps {
    item: Transaction;
    showActions: boolean;
    onUserSelected?: (userId: string) => void;
}

export const TransactionTableItem = observer(({ item, showActions, onUserSelected }: TransactionTableItemProps) => {

    const { formatMessage } = useIntl();
    const { amountEarned } = item;

    return <>
        <tr className='transaction-tr'>
            <PagedListCell>{formatDateAndTime(item.date)}</PagedListCell>
            <PagedListCell>{item.cpoName}</PagedListCell>
            <PagedListCell variant={PagedListCellVariant.Bold}>{item.amountDue.toFixed(2)}</PagedListCell>
            <PagedListCell
                variant={item.amountCollected < item.amountDue
                    ? PagedListCellVariant.Warning
                    : PagedListCellVariant.Bold}
            >{item.amountCollected.toFixed(2)}</PagedListCell>
            <PagedListCell variant={amountEarned > 0 ? PagedListCellVariant.Success : PagedListCellVariant.Warning}>
                {amountEarned.toFixed(2)}
            </PagedListCell>
            {showActions && <td>
                <ActionsMenu
                    actions={[
                        {
                            label: formatMessage(actionMessages.goToUserDetail),
                            execute: () => onUserSelected ? onUserSelected(item.userId) : emptyFunction
                        }
                    ]}
                />
            </td>}
        </tr>
    </>;
});