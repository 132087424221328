import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router';
import { AppButton, AppButtonVariant } from '../../common/components/button/app-button';
import { Routes } from '../../common/routing/routes';

import messages from '../page-not-found-messages';
import errorIcon from '../../assets/images/icon-stop-colored.svg';

import './page-not-found.scss'; 

export const PageNotFound = () => {
    
    const { formatMessage } = useIntl();
    
    const [hasToRedirect, setHasToRedirect] = useState(false);
    
    const enableRedirect = useCallback(() => setHasToRedirect(true), []);

    return <div className='not-found page'>
        <div className='message'>
            <img src={errorIcon} alt=""/>
            <h1>{formatMessage(messages.pageNotFoundTitle)}</h1>
            <span>{formatMessage(messages.pageNotFoundBody)}</span>
        </div>
        <AppButton 
            label={formatMessage(messages.goBackToBackoffice)}
            variant={AppButtonVariant.Primary}
            onClick={enableRedirect}
        />
        {hasToRedirect && <Redirect to={Routes.Providers} />}
    </div>;
};