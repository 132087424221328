import { format, parse } from "date-fns";

export const parseDateFromServer = (serverDate: string): Date => {
    return parse(serverDate, "yyyy-MM-dd", new Date());
};
export const parseDateTimeFromServer = (serverDate: string): Date => {
    return parse(serverDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", new Date());
};
export const parseNotesTimestampFromServer = (serverDate: string): Date => {
    return parse(serverDate, "yyyy-MM-dd HH:mm:ss", new Date());
};

export const parseDateToServer = (date: Date): string => {
    return format(date, "yyyy-MM-dd");
};
