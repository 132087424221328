import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { StyledInput } from '../../../common/components/input/styled-input';
import { LoginSectionPage } from '../../../login/ui/components/login-section-page';

import commonMessages from '../../../common/messages/common.messages';
import messages from '../../change-password-messages';

import lockIcon from '../../../assets/images/icon-lock.svg';
import { AppButton, AppButtonVariant } from '../../../common/components/button/app-button';
import { isPasswordValid } from '../../../common/utils/validators';
import { useHistory } from 'react-router';

interface ChangePasswordSectionProps {

    isSaving: boolean;
    isSaveFailed: boolean;

    isCancelAvailable: boolean;

    onSave: (oldPassword: string, newPassword: string) => void;
    clearSaveError: () => void;

}

export interface ChangePasswordLocationState {
    previousLocation: string
}

/**
 * Functional component which represent the ui of both 'first login' and 'change password' pages
 */
export const ChangePasswordSection = (props: ChangePasswordSectionProps) => {

    const { formatMessage } = useIntl();
    const history = useHistory();

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    const [passwordNotMatching, setPasswordNotMatching] = useState(false);
    const [passwordValidationFailed, setPasswordValidationFailed] = useState(false);

    const onFieldEdited = useCallback(() => {
        if(props.isSaveFailed) {
            props.clearSaveError();
        }
    }, [props]);
    
    const goBack = useCallback(() => {
        history.replace((history.location.state as ChangePasswordLocationState).previousLocation);
    }, [history]);

    const onOldPasswordChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        onFieldEdited();
        setOldPassword(e.target.value);
    }, [setOldPassword, onFieldEdited]);
    const onNewPasswordChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        onFieldEdited();
        setNewPassword(e.target.value);
        if (passwordValidationFailed) {
            setPasswordValidationFailed(false);
        }
        if (passwordNotMatching) {
            setPasswordNotMatching(false);
        }
    }, [passwordValidationFailed, passwordNotMatching, 
        onFieldEdited, setPasswordNotMatching, setPasswordValidationFailed, setNewPassword]);
    const onConfirmPasswordChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        onFieldEdited();
        setPasswordConfirm(e.target.value);
        if (passwordNotMatching) {
            setPasswordNotMatching(false);
        }
    }, [passwordNotMatching, onFieldEdited, setPasswordNotMatching, setPasswordConfirm]);

    const checkIfConfirmMatches = useCallback(() => {
        if (newPassword.length > 0 && passwordConfirm.length > 0 && newPassword !== passwordConfirm) {
            setPasswordNotMatching(true);
        }
    }, [newPassword, passwordConfirm, setPasswordNotMatching]);

    const onNewPasswordBlur = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (!isPasswordValid(e.target.value)) {
            setPasswordValidationFailed(true);
        } else {
            checkIfConfirmMatches();
        }
    }, [setPasswordValidationFailed, checkIfConfirmMatches]);

    const isButtonEnabled = useMemo(
        () => oldPassword.length > 0 && newPassword.length > 0
            && isPasswordValid(newPassword) && newPassword === passwordConfirm && !props.isSaving,
        [oldPassword, newPassword, passwordConfirm, props.isSaving]
    );
    
    const passwordConfirmError = useMemo(() => {
        if(passwordNotMatching) {
            return formatMessage(messages.notMatchingPasswords);
        } else if(props.isSaveFailed) {
            return formatMessage(commonMessages.genericError);
        } else {
            return undefined;
        }
    }, [props.isSaveFailed, passwordNotMatching, formatMessage]);

    const onSaveClick = useCallback(() => {
        props.onSave(oldPassword, newPassword);
    }, [props, oldPassword, newPassword]);

    return <LoginSectionPage title={formatMessage(messages.boxTitle)}>
        <span className='login-section-label'>{formatMessage(messages.oldPasswordSectionTitle)}</span>
        <StyledInput
            type='password'
            value={oldPassword}
            disabled={props.isSaving}
            onChange={onOldPasswordChanged}
            icon={lockIcon}
            containerClassName='input-container pwd-input-container' />
        <span className='login-section-label'>{formatMessage(messages.newPasswordSectionTitle)}</span>
        <StyledInput
            type='password'
            value={newPassword}
            onChange={onNewPasswordChanged}
            icon={lockIcon}
            disabled={props.isSaving}
            onBlur={onNewPasswordBlur}
            errorLabel={passwordValidationFailed ? formatMessage(messages.passwordValidationFailed) : undefined}
            placeholder={formatMessage(commonMessages.passwordInputPlaceholder)}
            containerClassName='input-container pwd-input-container' />
        <StyledInput
            type='password'
            value={passwordConfirm}
            onChange={onConfirmPasswordChanged}
            onBlur={checkIfConfirmMatches}
            icon={lockIcon}
            disabled={props.isSaving}
            errorLabel={passwordConfirmError}
            placeholder={formatMessage(messages.confirmPasswordPlaceholder)}
            containerClassName='input-container pwd-input-container' />
        <div className='buttons-container'>
            {props.isCancelAvailable && <AppButton
                onClick={goBack}
                label={formatMessage(commonMessages.cancelButton)}
                variant={AppButtonVariant.Dark} />}
            <AppButton
                label={formatMessage(commonMessages.saveButton)}
                disabled={!isButtonEnabled}
                onClick={onSaveClick} />
        </div>
    </LoginSectionPage>;
};