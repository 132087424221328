import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AppButtonVariant } from '../../../common/components/button/app-button';
import { BaseDialog } from '../../../common/components/dialogs/base-dialog';
import commonMessages from '../../../common/messages/common.messages';

import { User } from '../../../common/models/user';
import messages from '../../administration.messages';

export interface DeleteBackofficeUserDialogProps {
    open: boolean
    isOperationRunning: boolean
    onClose: () => void
    onConfirmButtonClick: () => void

    user?: User
}

export const DeleteBackofficeUserDialog = (props: DeleteBackofficeUserDialogProps) => {
    const { formatMessage } = useIntl();
    
    const onClose = useCallback(() => {
        if(!props.isOperationRunning)
            props.onClose();
    }, [props]);
    
    return <BaseDialog
        open={props.open}
        title={formatMessage(messages.deleteOperatorDialogTitle, {name: props.user?.name})}
        buttons={[
            {
                label: formatMessage(commonMessages.cancelButton),
                disabled: props.isOperationRunning,
                buttonClasses: AppButtonVariant.Dark,
                onClick: onClose
            },
            {
                label: formatMessage(commonMessages.deleteButton),
                disabled: props.isOperationRunning,
                buttonClasses: AppButtonVariant.Primary,
                onClick: props.onConfirmButtonClick
            },
        ]}
        onClose={onClose}
    >
        <span>{formatMessage(messages.deleteOperatorDialogBody, {email: props.user?.email})}</span>
    </BaseDialog>;

};