import { flow, SnapshotOrInstance, types } from "mobx-state-tree";
import { ApiRequestState } from "../../common/api/api-request";
import { ResourceType } from "../../common/config/constants";
import { Env } from "../../common/config/env";
import { createPagedListStoreModel } from "../../common/stores/paged-list/paged-list-store";
import { emptyFunction } from "../../common/utils/utils";
import { GroupWithSameIdAlreadyExists } from "../api/groups/group-api-exception";
import {
    GroupApiService,
    groupApiService as groupApiServ
} from "../api/groups/group-api-service";
import { Contract } from "../model/contract";
import { Group, GroupModel } from "../model/group";
import { Provider } from "../model/provider";
import { mockGroupApiService } from "../mock/mock-group-api-service";
import { CreateGroupData } from "../api/groups/group-request";



export enum GroupError {
    GROUP_WITH_SAME_ID_ALREADY_EXISTS = '1',
}


const groupApiService: GroupApiService = Env.useMocks
    ? mockGroupApiService
    : groupApiServ;


const AdditionalGroupModel = types.model({
    groupError: types.maybe(
        types.enumeration<GroupError>(Object.values(GroupError))
    ),
    manageGroupRequestState: types.maybe(
        types.enumeration<ApiRequestState>(Object.values(ApiRequestState))
    ),
    deleteGroupRequestState: types.maybe(
        types.enumeration<ApiRequestState>(Object.values(ApiRequestState))
    )
});

export const GroupListStoreModel = types.compose(createPagedListStoreModel({
    type: GroupModel,
    apiService: groupApiService,
    typeLabel: ResourceType.Groups,
    selectedItemReferenceOptions: {
        get(identifier: string, parent: any) {
            const contract = parent.currentPageItems.find((elem: any) => elem.contractId === identifier);
            return contract ?? parent.lastNewCreatedContract;
        },
        set(elem: Group) {
            return elem.groupId;
        }
    }
}), AdditionalGroupModel)
    .actions(self => {


        const parentSelectItem = self.openItemDetail;

        const openItemDetail = (item: SnapshotOrInstance<Group>) => {
            parentSelectItem(item);
        };


        const clearManageGroupRequestState = () => {
            self.manageGroupRequestState = undefined;
        };

        const clearDeleteGroupRequestState = () => {
            self.deleteGroupRequestState = undefined;
        };

        const clearGroupError = () => {
            self.groupError = undefined;
        };

        const addGroup = flow(function* (group: CreateGroupData) {
            self.manageGroupRequestState = ApiRequestState.RUNNING;
            try {
                yield groupApiService.addGroup(group);
                self.manageGroupRequestState = ApiRequestState.COMPLETED;
            } catch (e: any) {
                if (e instanceof GroupWithSameIdAlreadyExists) {
                    self.groupError = GroupError.GROUP_WITH_SAME_ID_ALREADY_EXISTS;
                }
                self.manageGroupRequestState = ApiRequestState.FAILED;
            }
        });



        const init = emptyFunction;

        const initGroupStore = (provider: Provider, contract: Contract) => {
            groupApiService.setProviderId(provider.cpoId);
            groupApiService.setContractId(contract.contractId);
        };

        return {
            init, clearManageGroupRequestState, clearGroupError,
            clearDeleteGroupRequestState,
            addGroup,
            initGroupStore
        };
    })
    .named('GroupListStoreModel');
