import React, { ReactNode, useCallback } from "react";
import { useIntl } from "react-intl";

import { AppAccordion } from "../../../../../../common/components/accordion/app-accordion";
import { CheckedState, StyledCheckBox } from "../../../../../../common/components/input/styled-checkbox";

import { Currency, fromCurrencyToMessageDescriptor, fromCurrencyToSymbol } from "../../../../../../common/models/currency";
import { fromPricingRefernceUnitToMessageDescriptor } from "../../../../../../common/models/pricing-reference-unit";

import { FareFeeInfo } from "../../../../../model/fare-info";

import './fare-fee-data.scss';

export interface FareFeeDataProps {
    
    renderTitle: (value: string, unitOfMeasure: string) => string;
    renderDescription: () => string;
    renderFeeOptions: () => ReactNode;
    
    showFareFeeOptions: boolean;
    fareFee?: FareFeeInfo;
    onSelectFareFeeChange: (isSelected: boolean) => void;
    canUpdateData: boolean;

    containerClassName?: string;
}

/**
 * Accordion with a given title, a description and a checkbox that when enabled
 * shows the components rendered with renderFeeOptions
 * @param props FareFeeOptionsProps
 * @returns FareFeeOptions
 */
export const FareFeeData = (props: FareFeeDataProps) => {
    
    const { formatMessage } = useIntl();

    /* ------ Title ------ */
    const toggleShowFareFeeOptions = useCallback(() => {
        props.onSelectFareFeeChange(!props.showFareFeeOptions);
    }, [props]);
    
    const renderTitle = useCallback(() => {
        let value = '0';
        let unitOfMeasure = formatMessage(fromCurrencyToMessageDescriptor(Currency.EURO));

        if(props.fareFee) {
            if(props.fareFee.price) {
                value = props.fareFee.price.trim().length > 0 ? Number(props.fareFee.price).toFixed(2) : '0';
            }

            if(props.fareFee.priceReferenceUnit){
                unitOfMeasure = formatMessage(
                    fromPricingRefernceUnitToMessageDescriptor(props.fareFee.priceReferenceUnit),
                    {
                        currency: fromCurrencyToSymbol(Currency.EURO)
                    }
                );
            }
        }

        return <StyledCheckBox 
            containerClassName="app-accordion-title"
            checked={props.showFareFeeOptions ? CheckedState.Checked : CheckedState.Unchecked}
            label={props.renderTitle(value, unitOfMeasure)}
            onClick={toggleShowFareFeeOptions}
            disabled={!props.canUpdateData}
        />;
    }, [formatMessage, props, toggleShowFareFeeOptions]);
    

    
    return <div className={`fare-fee-data bordered no-padding ${props.containerClassName ?? ''}`}>

        <AppAccordion defaultExpanded title={renderTitle} bodyClassName="fare-fee-accordion">

            <p>{ props.renderDescription() }</p>

            { props.showFareFeeOptions && props.renderFeeOptions() }

        </AppAccordion>

    </div>;
};