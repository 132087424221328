import React from "react";
import { useIntl } from "react-intl";

import { SocketData } from "./socket-data";
import { SubCpoData } from "./sub-cpo-data";
import { ValidityPeriodsData } from "./validity-periods-data";

import { ChargingPowerRange, FareValidityPeriod, SocketType } from "../../../../../model/fare";

import contractDetailsMessages from "../../../../../contract-details.messages";

import './fare-conditions-content.scss';

export interface FareConditionsContentProps {
    
    subCpo?: string;
    onSubCpoChange: (subCpo?: string) => void;

    socketType?: SocketType;
    onSocketTypeChange: (socketType?: SocketType) => void;

    chargingPowerRange?: ChargingPowerRange;
    onChargingPowerRangeChange: (chargingPowerRange?: ChargingPowerRange) => void;

    validityPeriods?: FareValidityPeriod[];
    onValidityPeriodsChange: (validityPeriods?: FareValidityPeriod[]) => void;
    
    canUpdateData: boolean;
}

export const FareConditionsContent = (props: FareConditionsContentProps) => {
    
    const { formatMessage } = useIntl();
    
    return <div className="fare-conditions-content bordered">

        <div className="section-title">
            {formatMessage(contractDetailsMessages.fareConditionsSectionTitle)}
        </div>

        <SubCpoData 
            subCpo={props.subCpo}
            onSubCpoChange={props.onSubCpoChange}
            canUpdateData={props.canUpdateData}
        />

        <SocketData 
            socketType={props.socketType}
            onSocketTypeChanged={props.onSocketTypeChange}
            canUpdateData={props.canUpdateData}

            chargingPowerRange={props.chargingPowerRange}
            onChargingPowerRangeChanged={props.onChargingPowerRangeChange}
        />

        <ValidityPeriodsData
            validityPeriods={props.validityPeriods}
            onValidityPeriodsChange={props.onValidityPeriodsChange}
            canUpdateData={props.canUpdateData}
        />

        <p className="notes">
            {formatMessage(contractDetailsMessages.conditionsNotes, {br: <br/>})}
        </p>

    </div>;
};