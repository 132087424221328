import React, { ReactNode, useMemo } from 'react';
import { useIntl } from "react-intl";
import { useLocation } from 'react-router';

import { Routes } from '../../../../common/routing/routes';
import { AppBreadcrumb } from '../../../../common/components/breadcrumb/app-breadcrumb';
import { useStore } from '../../../../common/stores/root-store';

import messages from '../../../providers.messages';
import ContractsIcon from '../../../../assets/images/icon-contract.svg';

import './section-paged-list-header.scss';

interface SectionPagedListHeaderProps {
    renderAdditionalHeaderComponents?: () => ReactNode;
}

export const SectionPagedListHeader = (props : SectionPagedListHeaderProps) => {
    const { formatMessage } = useIntl();
    const location = useLocation();
    const { providerStore } = useStore();

    const paths = useMemo(() => ({
        providerContractDetails: {
            label: formatMessage(messages.providerContractDetailsPageTitle, {
                contract: providerStore.contractsStore?.selectedItem?.contractId ?? ''
            }),
            to: Routes.ProviderContractDetail
        },
        providerContracts: {
            label: formatMessage(messages.providerContractsPageTitle, {
                provider: providerStore.selectedItem?.cpoName ?? ''
            }),
            to: Routes.ProviderContracts
        },
        providers: {
            label: formatMessage(messages.providersPageTitle),
            to: Routes.Providers
        }
    }), [
        formatMessage, providerStore.contractsStore?.selectedItem, 
        providerStore.selectedItem?.cpoName
    ]);
    
    const [currentPage, path] = useMemo(() => {
        if(location.pathname.startsWith(Routes.ProviderContractDetail)){
            return [
                paths.providerContractDetails,
                [paths.providers, paths.providerContracts]
            ];
        } else if (location.pathname.startsWith(Routes.ProviderContracts)) {
            return [
                paths.providerContracts,
                [paths.providers]
            ];
        } else {
            return [paths.providers];
        }
    }, [location.pathname, paths]);

    return <div className='section-paged-list-header section-header'>
        
        <div className='section-page-title'>
            <img className='contracts-icon' src={ContractsIcon} alt='' />
            <h2>{formatMessage(messages.sectionTitle)}</h2>

            <AppBreadcrumb
                currentPage={currentPage}
                path={path}
            />
        </div>

        {props.renderAdditionalHeaderComponents && props.renderAdditionalHeaderComponents()}
    </div>;
};
