import { cast, flow, getEnv, SnapshotOrInstance, types } from "mobx-state-tree";
import { ApiRequestState } from "../../common/api/api-request";
import { ResourceType } from "../../common/config/constants";
import { TimePeriodFilter } from "../../common/models/filters";
import { createPagedListStoreModel } from "../../common/stores/paged-list/paged-list-store";
import { CancelablePromise, makeCancelable } from "../../common/utils/cancelable-promise";
import { MobileUserData } from "../../_generated/api";
import { userDetailApiService } from "../api/user-detail-api-service";
import { usersApiService } from "../api/users-api-service";
import { AppUser, AppUserModel } from "../model/app-user";
import { UserDetailFilterModel } from "../model/user-detail-filters";
import { UsersFilterModel } from "../model/users-filters";
import { UserDetailStoreModel } from "./user-detail-store";

const AdditionalAppUsersModel = types.model({
    blockUserRequestState: types.maybe(
        types.enumeration<ApiRequestState>(Object.values(ApiRequestState))
    ),
    deleteUserRequestState: types.maybe(
        types.enumeration<ApiRequestState>(Object.values(ApiRequestState))
    ),
    restoreUserRequestState: types.maybe(
        types.enumeration<ApiRequestState>(Object.values(ApiRequestState))
    ),
    userDetailStore: types.maybe(UserDetailStoreModel),
    isDownloadingUserDetail: false
}).actions(self => {

    const restoreUser = flow(function* (user: AppUser) {
        try {
            self.restoreUserRequestState = ApiRequestState.RUNNING;
            yield usersApiService.restoreUser(user);
            self.restoreUserRequestState = ApiRequestState.COMPLETED;
        } catch {
            self.restoreUserRequestState = ApiRequestState.FAILED;
        }
    });
    const clearRestoreUserState = () => {
        self.restoreUserRequestState = undefined;
    };

    const blockUser = flow(function* (user: AppUser) {
        try {
            self.blockUserRequestState = ApiRequestState.RUNNING;
            yield usersApiService.blockUser(user);
            self.blockUserRequestState = ApiRequestState.COMPLETED;
        } catch {
            self.blockUserRequestState = ApiRequestState.FAILED;
        }
    });
    const clearBlockUserState = () => {
        self.blockUserRequestState = undefined;
    };

    const deleteUser = flow(function* (user: AppUser) {
        try {
            self.deleteUserRequestState = ApiRequestState.RUNNING;
            yield usersApiService.deleteUser(user);
            self.deleteUserRequestState = ApiRequestState.COMPLETED;
        } catch {
            self.deleteUserRequestState = ApiRequestState.FAILED;
        }
    });
    const clearDeleteUserState = () => {
        self.deleteUserRequestState = undefined;
    };

    return {
        restoreUser, clearRestoreUserState,
        blockUser, clearBlockUserState,
        deleteUser, clearDeleteUserState
    };
});

export const AppUsersListStoreModel = types.compose(createPagedListStoreModel<any, any, any>({
    type: AppUserModel,
    apiService: usersApiService,
    typeLabel: ResourceType.Users,
    filterType: UsersFilterModel,
    filters: () => UsersFilterModel.create({
    })

}), AdditionalAppUsersModel).actions(self => {
    
    const parentSelectItem = self.openItemDetail;
    const parentClearItem = self.clearSelectedItem;

    let downloadUserDetailPromise: CancelablePromise<any> | null = null;

    const openItemDetail = (item: SnapshotOrInstance<AppUser>) => {
        if(!self.currentPageItems.find(elem => elem.id === item.id)){
            self.currentPageItems = cast([item]);
        }
        parentSelectItem(item);
        self.userDetailStore = UserDetailStoreModel.create({
            userId: item.id,
            userEmail: item.email,
            filters: UserDetailFilterModel.create({
                timePeriod: TimePeriodFilter.THIS_MONTH,
                userId: item.id
            })
        }, getEnv(self));
    };

    const downloadAndSelectUser = flow(function* (userId: string) {
        self.isDownloadingUserDetail = true;

        downloadUserDetailPromise = makeCancelable<MobileUserData>(userDetailApiService.downloadUserDetail(userId));
        const user: MobileUserData = yield downloadUserDetailPromise.promise;
        openItemDetail(AppUserModel.create({
            email: user.email,
            id: user.userId,
        }));

        self.isDownloadingUserDetail = false;
    });

    const cancelUserDetailDownload = () => {
        if (downloadUserDetailPromise) {
            downloadUserDetailPromise.cancel();
            downloadUserDetailPromise = null;
        }
        self.isDownloadingUserDetail = false;
    };

    const clearSelectedItem = () => {
        self.userDetailStore = undefined;
        parentClearItem();
        cancelUserDetailDownload();
    };

    return {
        openItemDetail, clearSelectedItem, downloadAndSelectUser, cancelUserDetailDownload
    };
}).named('AppUsersListStoreModel');