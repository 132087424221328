import { ContactPersonModel, Contract, ContractInflationModel, ContractModel, ContractStatus, ContractType, OperationInfoModel, OperatorModel } from "../../model/contract";
import {
    ProviderContractsSearchResultItem as ApiContract,
    ContractStatus as ApiContractStatus,
    ContractType as ApiContractType,
    ContractAnagraphic as ApiContractAnagraphic,
} from "../../../_generated/api/api";
import { DateIntervalModel } from "../../../common/models/time";
import { getTimezonesForCountry } from 'countries-and-timezones';
import { zonedTimeToUtc } from "date-fns-tz";

export const parseContractStatusFromApiModel = (apiContractStatus: ApiContractStatus): ContractStatus => {
    switch (apiContractStatus) {
        case ApiContractStatus.Active:
            return ContractStatus.ACTIVE;
        case ApiContractStatus.NonActive:
            return ContractStatus.NOT_ACTIVE;
        case ApiContractStatus.Expired:
            return ContractStatus.EXPIRED;
    }
};

export const parseContractTypeFromApiModel = (apiContractType: ApiContractType): ContractType => {
    switch (apiContractType) {
        case ApiContractType.Standard:
            return ContractType.HUBJECT_STANDARD;
        case ApiContractType.Product:
            return ContractType.HUBJECT_PRODUCT;
        case ApiContractType.Dynamic:
            return ContractType.HUBJECT_DYNAMIC;
        case ApiContractType.Ngp:
            return ContractType.HUBJECT_NGP;
    }
};

export const parseContractAnagraphicFromApiModel = (apiContractAnagraphic: ApiContractAnagraphic): Contract => {
    return ContractModel.create({
        contractId: apiContractAnagraphic.contractId,
        status: parseContractStatusFromApiModel(apiContractAnagraphic.status),
        isBlocked: apiContractAnagraphic.isBlocked,
        type: parseContractTypeFromApiModel(apiContractAnagraphic.type),
        inflation: ContractInflationModel.create({
            baseInflation: apiContractAnagraphic.inflation,
            hasFareSpecificInflation: false
        }),
        validityPeriod: DateIntervalModel.create({
            from: new Date(apiContractAnagraphic.validityPeriod.from),
            to: new Date(apiContractAnagraphic.validityPeriod.to),
        }),
        creation: OperationInfoModel.create({
            date: new Date(apiContractAnagraphic.creation.dateTime),
            operator: OperatorModel.create({
                name: apiContractAnagraphic.creation.operatorName,
                email: apiContractAnagraphic.creation.operatorEmail,
            })
        }),
        lastUpdate: OperationInfoModel.create({
            date: new Date(apiContractAnagraphic.lastChange.dateTime),
            operator: OperatorModel.create({
                name: apiContractAnagraphic.lastChange.operatorName,
                email: apiContractAnagraphic.lastChange.operatorEmail,
            })
        }),
        contactPerson: apiContractAnagraphic.contactPerson && ContactPersonModel.create({
            email: apiContractAnagraphic.contactPerson.email,
            name: apiContractAnagraphic.contactPerson.name,
            phoneNumber: apiContractAnagraphic.contactPerson.phoneNumber
        }),
        operatorNotes: apiContractAnagraphic.operatorNotes &&
            apiContractAnagraphic.operatorNotes.trim().length > 0 ? apiContractAnagraphic.operatorNotes : undefined,

        ngpContractFilename: apiContractAnagraphic.ngpContractFilename,
    });
};

export const parseContractFromApiModel = (apiContract: ApiContract): Contract => {
    return ContractModel.create({
        contractId: apiContract.contractId,
        status: parseContractStatusFromApiModel(apiContract.status),
        isBlocked: !!apiContract.isBlocked,
        type: parseContractTypeFromApiModel(apiContract.type),
        validityPeriod: DateIntervalModel.create({
            from: new Date(apiContract.validityPeriod.from),
            to: new Date(apiContract.validityPeriod.to),
        }),
        creation: OperationInfoModel.create({
            date: new Date(apiContract.creation.dateTime),
            operator: OperatorModel.create({
                name: apiContract.creation.operatorName,
                email: apiContract.creation.operatorEmail,
            })
        }),
        lastUpdate: OperationInfoModel.create({
            date: new Date(apiContract.lastChange.dateTime),
            operator: OperatorModel.create({
                name: apiContract.lastChange.operatorName,
                email: apiContract.lastChange.operatorEmail,
            })
        }),
        inflation: ContractInflationModel.create({
            baseInflation: apiContract.inflation,
            hasFareSpecificInflation: apiContract.hasFareSpecificInflation
        }),
        chargesCount: apiContract.chargesCount,
        costsAmount: apiContract.costsAmount,
        kwhDelivered: apiContract.kWhDelivered,
        earningsAmount: apiContract.earningsAmount,
        operatorNotes: apiContract.operatorNotes &&
            apiContract.operatorNotes.trim().length > 0 ? apiContract.operatorNotes : undefined,
        ngpContractFilename: apiContract.ngpContractFilename,
        contactPerson: apiContract.contactPerson
    });
};

export const parseContractTypeToApiModel = (contractType: ContractType): ApiContractType => {
    switch (contractType) {
        case ContractType.HUBJECT_DYNAMIC:
            return ApiContractType.Dynamic;
        case ContractType.HUBJECT_PRODUCT:
            return ApiContractType.Product;
        case ContractType.HUBJECT_STANDARD:
            return ApiContractType.Standard;
        case ContractType.HUBJECT_NGP:
            return ApiContractType.Ngp;
    }
};

export const parseDateTimeToApiModel = (date: Date, country: string): string => {
    const timezone = getTimezonesForCountry(country.toUpperCase());
    if (!timezone || timezone.length === 0) {
        throw new Error('unsupported timezone');
    }
    return zonedTimeToUtc(date, timezone[0].name).toISOString();
};