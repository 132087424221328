import { getSnapshot } from "mobx-state-tree";
import { GetItemsResponse } from "../../common/api/items-list/get-items";
import { ContactPersonModel, Contract, ContractInflationModel, ContractModel, ContractStatus, ContractType, OperationInfoModel, OperatorModel } from "../model/contract";
import { ContractApiService } from "../api/contracts/contract-api-service";
import { ContractInfo } from "../model/contract-info";
import { DateIntervalModel } from "../../common/models/time";

class MockContractApiService extends ContractApiService {

    private _contracts: Contract[] = [
        ContractModel.create({
            contractId: '12345',
            status: ContractStatus.NOT_ACTIVE,
            isBlocked: false,
            type: ContractType.HUBJECT_STANDARD,
            inflation: {
                baseInflation: 10,
                hasFareSpecificInflation: false
            },
            validityPeriod: {
                from: new Date('2022-11-10'),
                to: new Date('2023-12-09'),
            },
            creation: {
                date: new Date('2022-11-10'),
                operator: {
                    name: 'Francesco Grandinetti',
                    email: 'francesco.grandinetti@antreem.com'
                }
            },
            lastUpdate: {
                date: new Date('2022-11-10'),
                operator: {
                    name: 'Francesco Grandinetti',
                    email: 'francesco.grandinetti@antreem.com'
                }
            }
        }),
        ContractModel.create({
            contractId: '67890',
            status: ContractStatus.ACTIVE,
            isBlocked: false,
            type: ContractType.HUBJECT_STANDARD,
            kwhDelivered: 5124,
            chargesCount: 1127,
            costsAmount: 872,
            earningsAmount: 182,
            inflation: {
                baseInflation: 10,
                hasFareSpecificInflation: true
            },
            validityPeriod: {
                from: new Date('2021-11-10'),
                to: new Date('2022-12-09'),
            },
            creation: {
                date: new Date('2022-11-10'),
                operator: {
                    name: 'Francesco Grandinetti',
                    email: 'francesco.grandinetti@antreem.com'
                }
            },
            lastUpdate: {
                date: new Date('2022-11-10'),
                operator: {
                    name: 'Francesco Grandinetti',
                    email: 'francesco.grandinetti@antreem.com'
                }
            }
        }),
        ContractModel.create({
            contractId: '54321',
            status: ContractStatus.EXPIRED,
            isBlocked: false,
            type: ContractType.HUBJECT_PRODUCT,
            kwhDelivered: 3124,
            chargesCount: 1127,
            costsAmount: 1127,
            earningsAmount: 232,
            inflation: {
                baseInflation: 10,
                hasFareSpecificInflation: true
            },
            validityPeriod: {
                from: new Date('2020-11-10'),
                to: new Date('2020-12-09'),
            },
            creation: {
                date: new Date('2022-11-10'),
                operator: {
                    name: 'Francesco Grandinetti',
                    email: 'francesco.grandinetti@antreem.com'
                }
            },
            lastUpdate: {
                date: new Date('2022-11-10'),
                operator: {
                    name: 'Francesco Grandinetti',
                    email: 'francesco.grandinetti@antreem.com'
                }
            }
        }),
        ContractModel.create({
            contractId: '09876',
            status: ContractStatus.EXPIRED,
            isBlocked: false,
            type: ContractType.HUBJECT_DYNAMIC,
            kwhDelivered: 3124,
            chargesCount: 200,
            costsAmount: 872,
            earningsAmount: 189,
            inflation: {
                baseInflation: 10,
                hasFareSpecificInflation: true
            },
            validityPeriod: {
                from: new Date('2017-11-10'),
                to: new Date('2017-12-09'),
            },
            creation: {
                date: new Date('2022-11-10'),
                operator: {
                    name: 'Francesco Grandinetti',
                    email: 'francesco.grandinetti@antreem.com'
                }
            },
            lastUpdate: {
                date: new Date('2022-11-10'),
                operator: {
                    name: 'Francesco Grandinetti',
                    email: 'francesco.grandinetti@antreem.com'
                }
            }
        }),
        ContractModel.create({
            contractId: '13690',
            status: ContractStatus.EXPIRED,
            isBlocked: false,
            type: ContractType.HUBJECT_STANDARD,
            kwhDelivered: 3124,
            chargesCount: 1127,
            costsAmount: 872,
            earningsAmount: 756,
            inflation: {
                baseInflation: 10,
                hasFareSpecificInflation: true
            },
            validityPeriod: {
                from: new Date('2018-11-10'),
                to: new Date('2018-12-09'),
            },
            creation: {
                date: new Date('2022-11-10'),
                operator: {
                    name: 'Francesco Grandinetti',
                    email: 'francesco.grandinetti@antreem.com'
                }
            },
            lastUpdate: {
                date: new Date('2022-11-10'),
                operator: {
                    name: 'Francesco Grandinetti',
                    email: 'francesco.grandinetti@antreem.com'
                }
            }
        }),
        ContractModel.create({
            contractId: '08641',
            status: ContractStatus.EXPIRED,
            isBlocked: false,
            type: ContractType.HUBJECT_DYNAMIC,
            kwhDelivered: 2002,
            chargesCount: 1127,
            costsAmount: 872,
            earningsAmount: 136,
            inflation: {
                baseInflation: 5,
                hasFareSpecificInflation: false
            },
            validityPeriod: {
                from: new Date('2019-11-10'),
                to: new Date('2019-12-09'),
            },
            creation: {
                date: new Date('2022-11-10'),
                operator: {
                    name: 'Francesco Grandinetti',
                    email: 'francesco.grandinetti@antreem.com'
                }
            },
            lastUpdate: {
                date: new Date('2022-11-10'),
                operator: {
                    name: 'Francesco Grandinetti',
                    email: 'francesco.grandinetti@antreem.com'
                }
            }
        }),
    ];

    private get contracts() {    
        return this._contracts.map(elem => {
            const snapshot = getSnapshot(elem);
            return ContractModel.create({...snapshot});
        });
    }

    private set contracts(contracts: Contract[]) {
        this._contracts = contracts;
    }


    getItems(): Promise<GetItemsResponse<Contract, never>> {
        return this.waitAndDo(() => ({
            totalItems: this.contracts.length,
            items: this.contracts.sort((a,b) => b.validityPeriod.to.getTime() - a.validityPeriod.to.getTime())
        }));
    }

    deleteContract(contract: Contract): Promise<void> {
        return this.waitAndDo(() => {
                this.contracts = this._contracts.filter(elem => elem.contractId !== contract.contractId);
        });
    }

    addContract(newContract: ContractInfo): Promise<Contract> {
        
        let contractStatus = ContractStatus.NOT_ACTIVE;
        if(newContract.endDate < new Date()) {
            contractStatus = ContractStatus.EXPIRED;
        } else if (newContract.startDate <= new Date()){
            contractStatus = ContractStatus.ACTIVE;
        }
        
        return this.waitAndDo(() => {
            const contract = ContractModel.create({
                contractId: `1111${this._contracts.length}`,
                status: contractStatus,
                isBlocked: false,
                type: newContract.type,
                validityPeriod: DateIntervalModel.create({
                    from: newContract.startDate,
                    to: newContract.endDate
                }),
                inflation: ContractInflationModel.create({
                    baseInflation: newContract.baseInflation,
                    hasFareSpecificInflation: false
                }),
                operatorNotes: newContract.operatorNotes,
                contactPerson: newContract.contactPerson && ContactPersonModel.create({
                    name: newContract.contactPerson.name,
                    email: newContract.contactPerson.email,
                    phoneNumber: newContract.contactPerson.phoneNumber,
                }),
                creation: OperationInfoModel.create({
                    date: new Date(),
                    operator: {
                        name: 'Francesco Grandinetti',
                        email: 'francesco.grandinetti@antreem.com'
                    },
                }),
                lastUpdate: OperationInfoModel.create({
                    date: new Date(),
                    operator: {
                        name: 'Francesco Grandinetti',
                        email: 'francesco.grandinetti@antreem.com'
                    },
                })
            });
            this.contracts = [
                contract,
                ...this._contracts
            ];
            return contract;
        }); 
    }

    updateContract(contractId: string, contractInfo: ContractInfo): Promise<Contract> {
        return this.waitAndDo(() => {
            const contractToUpdate = this._contracts.find(elem => elem.contractId === contractId);
            if(!contractToUpdate){
                throw new Error('contract not found');
            }

            contractToUpdate.setContractType(contractInfo.type);
            contractToUpdate.setValidityPeriod(DateIntervalModel.create({
                from: contractInfo.startDate,
                to: contractInfo.endDate
            }));
            contractToUpdate.setBaseInflation(contractInfo.baseInflation);
            contractToUpdate.setContactPerson(contractInfo.contactPerson &&
                ContactPersonModel.create({
                    name: contractInfo.contactPerson.name,
                    email: contractInfo.contactPerson.email,
                    phoneNumber: contractInfo.contactPerson.phoneNumber,
                })
            );
            contractToUpdate.setOperatorNotes(contractInfo.operatorNotes);
            contractToUpdate.setLastUpdateOperationInfo(OperationInfoModel.create({
                date: new Date(),
                operator: OperatorModel.create({
                    name: 'Francesco Grandinetti',
                    email: 'service@antreem.com'
                })
            }));

            return contractToUpdate;
        }); 
    }

    unlockContract(contract: Contract): Promise<void> {    
        return this.waitAndDo(() => {
            
            const contractToUpdate = this._contracts.find(elem => elem.contractId === contract.contractId);
            if(!contractToUpdate){
                throw new Error('contract not found');
            }

            contractToUpdate.unlock();

        }); 
    }

    blockContract(contract: Contract): Promise<void> {    
        return this.waitAndDo(() => {
                
            const contractToUpdate = this._contracts.find(elem => elem.contractId === contract.contractId);
            if(!contractToUpdate){
                throw new Error('contract not found');
            }

            contractToUpdate.block();

        }); 
    }

    private waitAndDo<T>(fun: () => T): Promise<T> {    
        return new Promise((resolve, reject) => setTimeout(
            () => {
                try{
                    resolve(fun());
                } catch(e) {
                    reject(e);
                }
            },
            3000
        ));
    }
    
}

export const mockContractApiService = new MockContractApiService('contracts');