import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { SortableTableHeader } from '../../../../common/components/paged-list/table-header-sortable';
import messages from '../../../../common/messages/list.messages';
import { OrderManagerProps } from '../../../../common/models/order-criteria';
import { UserRole } from '../../../../common/models/user';
import { useStore } from '../../../../common/stores/root-store';
import { ProviderOrderCriteria } from '../../../model/provider';

export const ProviderTableHeader = observer((props: OrderManagerProps) => {
    const { formatMessage } = useIntl();
    const { userStore } = useStore();

    return <tr className='provider-th'>
        <SortableTableHeader 
            {...props} 
            sortKey={ProviderOrderCriteria.ID} 
            label={formatMessage(messages.tableHeaderID)} 
        />
        <SortableTableHeader 
            {...props} 
            sortKey={ProviderOrderCriteria.NAME}
            label={formatMessage(messages.tableHeaderCPO)}
        />
        <td>{formatMessage(messages.tableHeaderContract)}</td>
        <SortableTableHeader 
            {...props} 
            sortKey={ProviderOrderCriteria.START_VALID_DATE}
            label={formatMessage(messages.tableHeaderStartValidDate)}
        />
        <SortableTableHeader 
            {...props} 
            sortKey={ProviderOrderCriteria.END_VALID_DATE}
            label={formatMessage(messages.tableHeaderEndValidDate)}
        />
        <SortableTableHeader 
            {...props} 
            sortKey={ProviderOrderCriteria.KWH_DELIVERED}
            label={formatMessage(messages.tableHeaderKwhDelivered)} 
        />
        <SortableTableHeader 
            {...props}
            sortKey={ProviderOrderCriteria.CHARGES_COUNT}
            label={formatMessage(messages.tableHeaderNumberOfCharges)}
        />
        <SortableTableHeader 
            {...props} 
            sortKey={ProviderOrderCriteria.COSTS_AMOUNT}
            label={formatMessage(messages.tableHeaderCostsAmount, {currency: '€'})} 
        />
        <td>{formatMessage(messages.tableHeaderAmoundEarned, {currency: '€'})}</td>
        {userStore.user?.role !== UserRole.Operator && <td>{formatMessage(messages.tableHeaderActions)}</td>}
    </tr >;

});