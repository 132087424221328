import { defineMessages, MessageDescriptor } from 'react-intl';

const messages = defineMessages({
    "AT": {
       "id": "countries.AT",
       "defaultMessage": "Austria",
       "description": ""
    },
    "AU": {
       "id": "countries.AU",
       "defaultMessage": "Australia",
       "description": ""
    },
    "AW": {
       "id": "countries.AW",
       "defaultMessage": "Aruba",
       "description": ""
    },
    "AX": {
       "id": "countries.AX",
       "defaultMessage": "Isole Åland",
       "description": ""
    },
    "AZ": {
       "id": "countries.AZ",
       "defaultMessage": "Azerbaigian",
       "description": ""
    },
    "BA": {
       "id": "countries.BA",
       "defaultMessage": "Bosnia ed Erzegovina",
       "description": ""
    },
    "BB": {
       "id": "countries.BB",
       "defaultMessage": "Barbados",
       "description": ""
    },
    "BD": {
       "id": "countries.BD",
       "defaultMessage": "Bangladesh",
       "description": ""
    },
    "BE": {
       "id": "countries.BE",
       "defaultMessage": "Belgio",
       "description": ""
    },
    "BG": {
       "id": "countries.BG",
       "defaultMessage": "Bulgaria",
       "description": ""
    },
    "BH": {
       "id": "countries.BH",
       "defaultMessage": "Bahrain",
       "description": ""
    },
    "BI": {
       "id": "countries.BI",
       "defaultMessage": "Burundi",
       "description": ""
    },
    "BJ": {
       "id": "countries.BJ",
       "defaultMessage": "Benin",
       "description": ""
    },
    "BL": {
       "id": "countries.BL",
       "defaultMessage": "Saint-Barthélemy",
       "description": ""
    },
    "BM": {
       "id": "countries.BM",
       "defaultMessage": "Bermuda",
       "description": ""
    },
    "BN": {
       "id": "countries.BN",
       "defaultMessage": "Brunei",
       "description": ""
    },
    "BO": {
       "id": "countries.BO",
       "defaultMessage": "Bolivia",
       "description": ""
    },
    "BQ": {
       "id": "countries.BQ",
       "defaultMessage": "Isole BES",
       "description": ""
    },
    "BR": {
       "id": "countries.BR",
       "defaultMessage": "Brasile",
       "description": ""
    },
    "BS": {
       "id": "countries.BS",
       "defaultMessage": "Bahamas",
       "description": ""
    },
    "BT": {
       "id": "countries.BT",
       "defaultMessage": "Bhutan",
       "description": ""
    },
    "BV": {
       "id": "countries.BV",
       "defaultMessage": "Isola Bouvet",
       "description": ""
    },
    "BW": {
       "id": "countries.BW",
       "defaultMessage": "Botswana",
       "description": ""
    },
    "BY": {
       "id": "countries.BY",
       "defaultMessage": "Bielorussia",
       "description": ""
    },
    "BZ": {
       "id": "countries.BZ",
       "defaultMessage": "Belize",
       "description": ""
    },
    "CA": {
       "id": "countries.CA",
       "defaultMessage": "Canada",
       "description": ""
    },
    "CC": {
       "id": "countries.CC",
       "defaultMessage": "Isole Cocos e Keeling",
       "description": ""
    },
    "CD": {
       "id": "countries.CD",
       "defaultMessage": "Repubblica Democratica del Congo",
       "description": ""
    },
    "CF": {
       "id": "countries.CF",
       "defaultMessage": "Repubblica Centrafricana",
       "description": ""
    },
    "CG": {
       "id": "countries.CG",
       "defaultMessage": "Repubblica del Congo",
       "description": ""
    },
    "CH": {
       "id": "countries.CH",
       "defaultMessage": "Svizzera",
       "description": ""
    },
    "CI": {
       "id": "countries.CI",
       "defaultMessage": "Costa d'Avorio",
       "description": ""
    },
    "CK": {
       "id": "countries.CK",
       "defaultMessage": "Isole Cook",
       "description": ""
    },
    "CL": {
       "id": "countries.CL",
       "defaultMessage": "Cile",
       "description": ""
    },
    "CM": {
       "id": "countries.CM",
       "defaultMessage": "Camerun",
       "description": ""
    },
    "CN": {
       "id": "countries.CN",
       "defaultMessage": "Cina",
       "description": ""
    },
    "CO": {
       "id": "countries.CO",
       "defaultMessage": "Colombia",
       "description": ""
    },
    "CR": {
       "id": "countries.CR",
       "defaultMessage": "Costa Rica",
       "description": ""
    },
    "CU": {
       "id": "countries.CU",
       "defaultMessage": "Cuba",
       "description": ""
    },
    "CV": {
       "id": "countries.CV",
       "defaultMessage": "Capo Verde",
       "description": ""
    },
    "CW": {
       "id": "countries.CW",
       "defaultMessage": "Curaçao",
       "description": ""
    },
    "CX": {
       "id": "countries.CX",
       "defaultMessage": "Isola del Natale",
       "description": ""
    },
    "CY": {
       "id": "countries.CY",
       "defaultMessage": "Cipro",
       "description": ""
    },
    "CZ": {
       "id": "countries.CZ",
       "defaultMessage": "Repubblica Ceca",
       "description": ""
    },
    "DE": {
       "id": "countries.DE",
       "defaultMessage": "Germania",
       "description": ""
    },
    "DJ": {
       "id": "countries.DJ",
       "defaultMessage": "Gibuti",
       "description": ""
    },
    "DK": {
       "id": "countries.DK",
       "defaultMessage": "Danimarca",
       "description": ""
    },
    "DM": {
       "id": "countries.DM",
       "defaultMessage": "Dominica",
       "description": ""
    },
    "DO": {
       "id": "countries.DO",
       "defaultMessage": "Repubblica Dominicana",
       "description": ""
    },
    "DZ": {
       "id": "countries.DZ",
       "defaultMessage": "Algeria",
       "description": ""
    },
    "EC": {
       "id": "countries.EC",
       "defaultMessage": "Ecuador",
       "description": ""
    },
    "EE": {
       "id": "countries.EE",
       "defaultMessage": "Estonia",
       "description": ""
    },
    "EG": {
       "id": "countries.EG",
       "defaultMessage": "Egitto",
       "description": ""
    },
    "EH": {
       "id": "countries.EH",
       "defaultMessage": "Sahara Occidentale",
       "description": ""
    },
    "ER": {
       "id": "countries.ER",
       "defaultMessage": "Eritrea",
       "description": ""
    },
    "ES": {
       "id": "countries.ES",
       "defaultMessage": "Spagna",
       "description": ""
    },
    "ET": {
       "id": "countries.ET",
       "defaultMessage": "Etiopia",
       "description": ""
    },
    "FI": {
       "id": "countries.FI",
       "defaultMessage": "Finlandia",
       "description": ""
    },
    "FJ": {
       "id": "countries.FJ",
       "defaultMessage": "Figi",
       "description": ""
    },
    "FK": {
       "id": "countries.FK",
       "defaultMessage": "Isole Falkland",
       "description": ""
    },
    "FM": {
       "id": "countries.FM",
       "defaultMessage": "Stati Federati di Micronesia",
       "description": ""
    },
    "FO": {
       "id": "countries.FO",
       "defaultMessage": "Isole Fær Øer",
       "description": ""
    },
    "FR": {
       "id": "countries.FR",
       "defaultMessage": "Francia",
       "description": ""
    },
    "GA": {
       "id": "countries.GA",
       "defaultMessage": "Gabon",
       "description": ""
    },
    "GB": {
       "id": "countries.GB",
       "defaultMessage": "Regno Unito",
       "description": ""
    },
    "GD": {
       "id": "countries.GD",
       "defaultMessage": "Grenada",
       "description": ""
    },
    "GE": {
       "id": "countries.GE",
       "defaultMessage": "Georgia",
       "description": ""
    },
    "GF": {
       "id": "countries.GF",
       "defaultMessage": "Guyana Francese",
       "description": ""
    },
    "GG": {
       "id": "countries.GG",
       "defaultMessage": "Guernsey",
       "description": ""
    },
    "GH": {
       "id": "countries.GH",
       "defaultMessage": "Ghana",
       "description": ""
    },
    "GI": {
       "id": "countries.GI",
       "defaultMessage": "Gibilterra",
       "description": ""
    },
    "GL": {
       "id": "countries.GL",
       "defaultMessage": "Groenlandia",
       "description": ""
    },
    "GM": {
       "id": "countries.GM",
       "defaultMessage": "Gambia",
       "description": ""
    },
    "GN": {
       "id": "countries.GN",
       "defaultMessage": "Guinea",
       "description": ""
    },
    "GP": {
       "id": "countries.GP",
       "defaultMessage": "Guadalupa",
       "description": ""
    },
    "GQ": {
       "id": "countries.GQ",
       "defaultMessage": "Guinea Equatoriale",
       "description": ""
    },
    "GR": {
       "id": "countries.GR",
       "defaultMessage": "Grecia",
       "description": ""
    },
    "GS": {
       "id": "countries.GS",
       "defaultMessage": "Georgia del Sud e isole Sandwich meridionali",
       "description": ""
    },
    "GT": {
       "id": "countries.GT",
       "defaultMessage": "Guatemala",
       "description": ""
    },
    "GU": {
       "id": "countries.GU",
       "defaultMessage": "Guam",
       "description": ""
    },
    "GW": {
       "id": "countries.GW",
       "defaultMessage": "Guinea-Bissau",
       "description": ""
    },
    "GY": {
       "id": "countries.GY",
       "defaultMessage": "Guyana",
       "description": ""
    },
    "HK": {
       "id": "countries.HK",
       "defaultMessage": "Hong Kong",
       "description": ""
    },
    "HM": {
       "id": "countries.HM",
       "defaultMessage": "Isole Heard e McDonald",
       "description": ""
    },
    "HN": {
       "id": "countries.HN",
       "defaultMessage": "Honduras",
       "description": ""
    },
    "HR": {
       "id": "countries.HR",
       "defaultMessage": "Croazia",
       "description": ""
    },
    "HT": {
       "id": "countries.HT",
       "defaultMessage": "Haiti",
       "description": ""
    },
    "HU": {
       "id": "countries.HU",
       "defaultMessage": "Ungheria",
       "description": ""
    },
    "ID": {
       "id": "countries.ID",
       "defaultMessage": "Indonesia",
       "description": ""
    },
    "IE": {
       "id": "countries.IE",
       "defaultMessage": "Irlanda",
       "description": ""
    },
    "IL": {
       "id": "countries.IL",
       "defaultMessage": "Israele",
       "description": ""
    },
    "IM": {
       "id": "countries.IM",
       "defaultMessage": "Isola di Man",
       "description": ""
    },
    "IN": {
       "id": "countries.IN",
       "defaultMessage": "India",
       "description": ""
    },
    "IO": {
       "id": "countries.IO",
       "defaultMessage": "Territori Britannici dell'Oceano Indiano",
       "description": ""
    },
    "IQ": {
       "id": "countries.IQ",
       "defaultMessage": "Iraq",
       "description": ""
    },
    "IR": {
       "id": "countries.IR",
       "defaultMessage": "Iran",
       "description": ""
    },
    "IS": {
       "id": "countries.IS",
       "defaultMessage": "Islanda",
       "description": ""
    },
    "IT": {
       "id": "countries.IT",
       "defaultMessage": "Italia",
       "description": ""
    },
    "JE": {
       "id": "countries.JE",
       "defaultMessage": "Jersey",
       "description": ""
    },
    "JM": {
       "id": "countries.JM",
       "defaultMessage": "Giamaica",
       "description": ""
    },
    "JO": {
       "id": "countries.JO",
       "defaultMessage": "Giordania",
       "description": ""
    },
    "JP": {
       "id": "countries.JP",
       "defaultMessage": "Giappone",
       "description": ""
    },
    "KE": {
       "id": "countries.KE",
       "defaultMessage": "Kenya",
       "description": ""
    },
    "KG": {
       "id": "countries.KG",
       "defaultMessage": "Kirghizistan",
       "description": ""
    },
    "KH": {
       "id": "countries.KH",
       "defaultMessage": "Cambogia",
       "description": ""
    },
    "KI": {
       "id": "countries.KI",
       "defaultMessage": "Kiribati",
       "description": ""
    },
    "KM": {
       "id": "countries.KM",
       "defaultMessage": "Comore",
       "description": ""
    },
    "KN": {
       "id": "countries.KN",
       "defaultMessage": "Saint Kitts e Nevis",
       "description": ""
    },
    "KP": {
       "id": "countries.KP",
       "defaultMessage": "Corea del Nord",
       "description": ""
    },
    "KR": {
       "id": "countries.KR",
       "defaultMessage": "Corea del Sud",
       "description": ""
    },
    "KW": {
       "id": "countries.KW",
       "defaultMessage": "Kuwait",
       "description": ""
    },
    "KY": {
       "id": "countries.KY",
       "defaultMessage": "Isole Cayman",
       "description": ""
    },
    "KZ": {
       "id": "countries.KZ",
       "defaultMessage": "Kazakistan",
       "description": ""
    },
    "LA": {
       "id": "countries.LA",
       "defaultMessage": "Laos",
       "description": ""
    },
    "LB": {
       "id": "countries.LB",
       "defaultMessage": "Libano",
       "description": ""
    },
    "LC": {
       "id": "countries.LC",
       "defaultMessage": "Santa Lucia",
       "description": ""
    },
    "LI": {
       "id": "countries.LI",
       "defaultMessage": "Liechtenstein",
       "description": ""
    },
    "LK": {
       "id": "countries.LK",
       "defaultMessage": "Sri Lanka",
       "description": ""
    },
    "LR": {
       "id": "countries.LR",
       "defaultMessage": "Liberia",
       "description": ""
    },
    "LS": {
       "id": "countries.LS",
       "defaultMessage": "Lesotho",
       "description": ""
    },
    "LT": {
       "id": "countries.LT",
       "defaultMessage": "Lituania",
       "description": ""
    },
    "LU": {
       "id": "countries.LU",
       "defaultMessage": "Lussemburgo",
       "description": ""
    },
    "LV": {
       "id": "countries.LV",
       "defaultMessage": "Lettonia",
       "description": ""
    },
    "LY": {
       "id": "countries.LY",
       "defaultMessage": "Libia",
       "description": ""
    },
    "MA": {
       "id": "countries.MA",
       "defaultMessage": "Marocco",
       "description": ""
    },
    "MC": {
       "id": "countries.MC",
       "defaultMessage": "Monaco",
       "description": ""
    },
    "MD": {
       "id": "countries.MD",
       "defaultMessage": "Moldavia",
       "description": ""
    },
    "ME": {
       "id": "countries.ME",
       "defaultMessage": "Montenegro",
       "description": ""
    },
    "MF": {
       "id": "countries.MF",
       "defaultMessage": "Saint-Martin",
       "description": ""
    },
    "MG": {
       "id": "countries.MG",
       "defaultMessage": "Madagascar",
       "description": ""
    },
    "MH": {
       "id": "countries.MH",
       "defaultMessage": "Isole Marshall",
       "description": ""
    },
    "MK": {
       "id": "countries.MK",
       "defaultMessage": "Macedonia del Nord",
       "description": ""
    },
    "ML": {
       "id": "countries.ML",
       "defaultMessage": "Mali",
       "description": ""
    },
    "MM": {
       "id": "countries.MM",
       "defaultMessage": "Birmania  Myanmar",
       "description": ""
    },
    "MN": {
       "id": "countries.MN",
       "defaultMessage": "Mongolia",
       "description": ""
    },
    "MO": {
       "id": "countries.MO",
       "defaultMessage": "Macao",
       "description": ""
    },
    "MP": {
       "id": "countries.MP",
       "defaultMessage": "Isole Marianne Settentrionali",
       "description": ""
    },
    "MQ": {
       "id": "countries.MQ",
       "defaultMessage": "Martinica",
       "description": ""
    },
    "MR": {
       "id": "countries.MR",
       "defaultMessage": "Mauritania",
       "description": ""
    },
    "MS": {
       "id": "countries.MS",
       "defaultMessage": "Montserrat",
       "description": ""
    },
    "MT": {
       "id": "countries.MT",
       "defaultMessage": "Malta",
       "description": ""
    },
    "MU": {
       "id": "countries.MU",
       "defaultMessage": "Mauritius",
       "description": ""
    },
    "MV": {
       "id": "countries.MV",
       "defaultMessage": "Maldive",
       "description": ""
    },
    "MW": {
       "id": "countries.MW",
       "defaultMessage": "Malawi",
       "description": ""
    },
    "MX": {
       "id": "countries.MX",
       "defaultMessage": "Messico",
       "description": ""
    },
    "MY": {
       "id": "countries.MY",
       "defaultMessage": "Malesia",
       "description": ""
    },
    "MZ": {
       "id": "countries.MZ",
       "defaultMessage": "Mozambico",
       "description": ""
    },
    "NA": {
       "id": "countries.NA",
       "defaultMessage": "Namibia",
       "description": ""
    },
    "NC": {
       "id": "countries.NC",
       "defaultMessage": "Nuova Caledonia",
       "description": ""
    },
    "NE": {
       "id": "countries.NE",
       "defaultMessage": "Niger",
       "description": ""
    },
    "NF": {
       "id": "countries.NF",
       "defaultMessage": "Isola Norfolk",
       "description": ""
    },
    "NG": {
       "id": "countries.NG",
       "defaultMessage": "Nigeria",
       "description": ""
    },
    "NI": {
       "id": "countries.NI",
       "defaultMessage": "Nicaragua",
       "description": ""
    },
    "NL": {
       "id": "countries.NL",
       "defaultMessage": "Paesi Bassi",
       "description": ""
    },
    "NO": {
       "id": "countries.NO",
       "defaultMessage": "Norvegia",
       "description": ""
    },
    "NP": {
       "id": "countries.NP",
       "defaultMessage": "Nepal",
       "description": ""
    },
    "NR": {
       "id": "countries.NR",
       "defaultMessage": "Nauru",
       "description": ""
    },
    "NU": {
       "id": "countries.NU",
       "defaultMessage": "Niue",
       "description": ""
    },
    "NZ": {
       "id": "countries.NZ",
       "defaultMessage": "Nuova Zelanda",
       "description": ""
    },
    "OM": {
       "id": "countries.OM",
       "defaultMessage": "Oman",
       "description": ""
    },
    "PA": {
       "id": "countries.PA",
       "defaultMessage": "Panamá",
       "description": ""
    },
    "PE": {
       "id": "countries.PE",
       "defaultMessage": "Perù",
       "description": ""
    },
    "PF": {
       "id": "countries.PF",
       "defaultMessage": "Polinesia Francese",
       "description": ""
    },
    "PG": {
       "id": "countries.PG",
       "defaultMessage": "Papua Nuova Guinea",
       "description": ""
    },
    "PH": {
       "id": "countries.PH",
       "defaultMessage": "Filippine",
       "description": ""
    },
    "PK": {
       "id": "countries.PK",
       "defaultMessage": "Pakistan",
       "description": ""
    },
    "PL": {
       "id": "countries.PL",
       "defaultMessage": "Polonia",
       "description": ""
    },
    "PM": {
       "id": "countries.PM",
       "defaultMessage": "Saint Pierre e Miquelon",
       "description": ""
    },
    "PN": {
       "id": "countries.PN",
       "defaultMessage": "Isole Pitcairn",
       "description": ""
    },
    "PR": {
       "id": "countries.PR",
       "defaultMessage": "Porto Rico",
       "description": ""
    },
    "PS": {
       "id": "countries.PS",
       "defaultMessage": "Stato di Palestina",
       "description": ""
    },
    "PT": {
       "id": "countries.PT",
       "defaultMessage": "Portogallo",
       "description": ""
    },
    "PW": {
       "id": "countries.PW",
       "defaultMessage": "Palau",
       "description": ""
    },
    "PY": {
       "id": "countries.PY",
       "defaultMessage": "Paraguay",
       "description": ""
    },
    "QA": {
       "id": "countries.QA",
       "defaultMessage": "Qatar",
       "description": ""
    },
    "RE": {
       "id": "countries.RE",
       "defaultMessage": "Réunion",
       "description": ""
    },
    "RO": {
       "id": "countries.RO",
       "defaultMessage": "Romania",
       "description": ""
    },
    "RS": {
       "id": "countries.RS",
       "defaultMessage": "Serbia",
       "description": ""
    },
    "RU": {
       "id": "countries.RU",
       "defaultMessage": "Russia",
       "description": ""
    },
    "RW": {
       "id": "countries.RW",
       "defaultMessage": "Ruanda",
       "description": ""
    },
    "SA": {
       "id": "countries.SA",
       "defaultMessage": "Arabia Saudita",
       "description": ""
    },
    "SB": {
       "id": "countries.SB",
       "defaultMessage": "Isole Salomone",
       "description": ""
    },
    "SC": {
       "id": "countries.SC",
       "defaultMessage": "Seychelles",
       "description": ""
    },
    "SD": {
       "id": "countries.SD",
       "defaultMessage": "Sudan",
       "description": ""
    },
    "SE": {
       "id": "countries.SE",
       "defaultMessage": "Svezia",
       "description": ""
    },
    "SG": {
       "id": "countries.SG",
       "defaultMessage": "Singapore",
       "description": ""
    },
    "SH": {
       "id": "countries.SH",
       "defaultMessage": "Isola di Sant'Elena",
       "description": ""
    },
    "SI": {
       "id": "countries.SI",
       "defaultMessage": "Slovenia",
       "description": ""
    },
    "SJ": {
       "id": "countries.SJ",
       "defaultMessage": "Svalbard e Jan Mayen",
       "description": ""
    },
    "SK": {
       "id": "countries.SK",
       "defaultMessage": "Slovacchia",
       "description": ""
    },
    "SL": {
       "id": "countries.SL",
       "defaultMessage": "Sierra Leone",
       "description": ""
    },
    "SM": {
       "id": "countries.SM",
       "defaultMessage": "San Marino",
       "description": ""
    },
    "SN": {
       "id": "countries.SN",
       "defaultMessage": "Senegal",
       "description": ""
    },
    "SO": {
       "id": "countries.SO",
       "defaultMessage": "Somalia",
       "description": ""
    },
    "SR": {
       "id": "countries.SR",
       "defaultMessage": "Suriname",
       "description": ""
    },
    "SS": {
       "id": "countries.SS",
       "defaultMessage": "Sudan del Sud",
       "description": ""
    },
    "ST": {
       "id": "countries.ST",
       "defaultMessage": "São Tomé e Príncipe",
       "description": ""
    },
    "SV": {
       "id": "countries.SV",
       "defaultMessage": "El Salvador",
       "description": ""
    },
    "SX": {
       "id": "countries.SX",
       "defaultMessage": "Sint Maarten",
       "description": ""
    },
    "SY": {
       "id": "countries.SY",
       "defaultMessage": "Siria",
       "description": ""
    },
    "SZ": {
       "id": "countries.SZ",
       "defaultMessage": "Eswatini",
       "description": ""
    },
    "TC": {
       "id": "countries.TC",
       "defaultMessage": "Isole Turks e Caicos",
       "description": ""
    },
    "TD": {
       "id": "countries.TD",
       "defaultMessage": "Ciad",
       "description": ""
    },
    "TF": {
       "id": "countries.TF",
       "defaultMessage": "Territori Francesi del Sud",
       "description": ""
    },
    "TG": {
       "id": "countries.TG",
       "defaultMessage": "Togo",
       "description": ""
    },
    "TH": {
       "id": "countries.TH",
       "defaultMessage": "Thailandia",
       "description": ""
    },
    "TJ": {
       "id": "countries.TJ",
       "defaultMessage": "Tagikistan",
       "description": ""
    },
    "TK": {
       "id": "countries.TK",
       "defaultMessage": "Tokelau",
       "description": ""
    },
    "TL": {
       "id": "countries.TL",
       "defaultMessage": "Timor Est",
       "description": ""
    },
    "TM": {
       "id": "countries.TM",
       "defaultMessage": "Turkmenistan",
       "description": ""
    },
    "TN": {
       "id": "countries.TN",
       "defaultMessage": "Tunisia",
       "description": ""
    },
    "TO": {
       "id": "countries.TO",
       "defaultMessage": "Tonga",
       "description": ""
    },
    "": {
       "id": "countries.",
       "defaultMessage": "Turchia",
       "description": ""
    },
    "TT": {
       "id": "countries.TT",
       "defaultMessage": "Trinidad e Tobago",
       "description": ""
    },
    "TV": {
       "id": "countries.TV",
       "defaultMessage": "Tuvalu",
       "description": ""
    },
    "TW": {
       "id": "countries.TW",
       "defaultMessage": "Repubblica di Cina",
       "description": ""
    },
    "TZ": {
       "id": "countries.TZ",
       "defaultMessage": "Tanzania",
       "description": ""
    },
    "UA": {
       "id": "countries.UA",
       "defaultMessage": "Ucraina",
       "description": ""
    },
    "UG": {
       "id": "countries.UG",
       "defaultMessage": "Uganda",
       "description": ""
    },
    "UM": {
       "id": "countries.UM",
       "defaultMessage": "Isole minori esterne degli Stati Uniti",
       "description": ""
    },
    "US": {
       "id": "countries.US",
       "defaultMessage": "Stati Uniti d'America",
       "description": ""
    },
    "UY": {
       "id": "countries.UY",
       "defaultMessage": "Uruguay",
       "description": ""
    },
    "UZ": {
       "id": "countries.UZ",
       "defaultMessage": "Uzbekistan",
       "description": ""
    },
    "VA": {
       "id": "countries.VA",
       "defaultMessage": "Città del Vaticano",
       "description": ""
    },
    "VC": {
       "id": "countries.VC",
       "defaultMessage": "Saint Vincent e Grenadine",
       "description": ""
    },
    "VE": {
       "id": "countries.VE",
       "defaultMessage": "Venezuela",
       "description": ""
    },
    "VG": {
       "id": "countries.VG",
       "defaultMessage": "Isole Vergini Britanniche",
       "description": ""
    },
    "VI": {
       "id": "countries.VI",
       "defaultMessage": "Isole Vergini Americane",
       "description": ""
    },
    "VN": {
       "id": "countries.VN",
       "defaultMessage": "Vietnam",
       "description": ""
    },
    "VU": {
       "id": "countries.VU",
       "defaultMessage": "Vanuatu",
       "description": ""
    },
    "WF": {
       "id": "countries.WF",
       "defaultMessage": "Wallis e Futuna",
       "description": ""
    },
    "WS": {
       "id": "countries.WS",
       "defaultMessage": "Samoa",
       "description": ""
    },
    "YE": {
       "id": "countries.YE",
       "defaultMessage": "Yemen",
       "description": ""
    },
    "YT": {
       "id": "countries.YT",
       "defaultMessage": "Mayotte",
       "description": ""
    },
    "ZA": {
       "id": "countries.ZA",
       "defaultMessage": "Sudafrica",
       "description": ""
    },
    "ZM": {
       "id": "countries.ZM",
       "defaultMessage": "Zambia",
       "description": ""
    },
    "ZW": {
       "id": "countries.ZW",
       "defaultMessage": "Zimbabwe",
       "description": ""
    },
    "XK": {
       "id": "countries.XK",
       "defaultMessage": "Kosovo",
       "description": ""
    }
 });

export default messages;

export const countryNames = Object.entries(messages).reduce((acc, current) => {
    acc[current[0]] = current[1];
    return acc;
}, {} as {[name: string]: MessageDescriptor});