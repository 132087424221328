import React, { useCallback } from 'react';
import checkboxUnselectedIcon from '../../../assets/images/checkbox-unselected.svg';
import checkboxSelectedIcon from '../../../assets/images/checkbox-selected.svg';
import checkboxPartialIcon from '../../../assets/images/checkbox-mixed.svg';

import { useIntl } from 'react-intl';
import commonMessages from '../../messages/common.messages';

import './styled-checkbox.scss';

export enum CheckedState {
    Checked,
    PartiallyChecked,
    Unchecked
}
interface StyledCheckBoxProps {
    checked: CheckedState;
    onClick: () => void;
    label?: string;
    disabled?: boolean;

    containerClassName?: string;
}

export const StyledCheckBox = ({ checked, label, containerClassName, onClick, disabled }: StyledCheckBoxProps) => {
    const { formatMessage } = useIntl();
    
    const onClickHandler = useCallback((ev: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        onClick();
        ev.stopPropagation();
        return false;
    }, [onClick]);
    
    return <div className={`checkbox-container${containerClassName ? ' ' + containerClassName : ''}`}>
        <img
            className={`checkbox ${disabled ? 'disabled' : ''}`}
            onClick={disabled ? undefined : onClickHandler}
            src={checked == CheckedState.Checked ? checkboxSelectedIcon :
                (checked == CheckedState.PartiallyChecked ? checkboxPartialIcon : checkboxUnselectedIcon)}
            alt={formatMessage(checked
                ? commonMessages.checkBoxSelectedAltLabel
                : commonMessages.checkBoxUnselectedAltLabel)} />
        <span>{label}</span>
    </div>;
};