import { AxiosPromise } from "axios";

import { ListApiService } from "../../../common/api/items-list/list-api-service";
import { Provider } from "../../model/provider";
import { parseContractStatusFilterToApiModel, parseProviderFromApiModel, parseTimePeriodFilterToApiModel, parseProviderSocketTypeRangesToApiModel } from './provider-api-parser';
import { ProvidersFilter } from "../../model/providers-filter";
import { Country } from "../../../common/models/country";
import { OrderCriteria } from "../../../common/models/order-criteria";
import { ErrorCode, ProvidersSearchResultItem as ApiProvider } from "../../../_generated/api";
import apiManager from "../../../common/api/api-manager";
import HttpStatusCode from "../../../common/api/http-status.code";
import { AddProviderData, UpdateProviderData } from "./provider-request";
import { CannotUpdateProviderId, ProviderWithSameIdAlreadyExists } from "./provider-api-exception";

export class ProviderApiService extends ListApiService<Provider, ProvidersFilter, never> {
    
    parseListItem = (apiResponse: ApiProvider) => parseProviderFromApiModel(apiResponse);
    
    parseSummary = () => undefined;
    
    downloadItemsList(offset: number, limit: number, filters: ProvidersFilter, orderCriteria?: OrderCriteria, accept?: string): AxiosPromise<any> {
        const config = accept === undefined ? undefined : {
            headers: {
                'Accept': accept
            }
        };

        return apiManager.providerApi.searchProviders(
            apiManager.idToken,
            limit, 
            offset,
            filters.countryId?.toUpperCase(),
            parseTimePeriodFilterToApiModel(filters.timePeriod),
            filters.contractStatus && parseContractStatusFilterToApiModel(filters.contractStatus),
            filters.cpoName,
            orderCriteria ? `${orderCriteria.key}:${orderCriteria.sortOrder.toLowerCase()}` : undefined, 
            config
        );
    }

    async getProvidersCountryList(): Promise<Country[]> {
        throw new Error("not implemented.");
    }

    async addProvider(provider: AddProviderData): Promise<void> {
        
        try{
            const result = await apiManager.providerApi.createProvider(apiManager.idToken, {
                country: provider.country.toUpperCase(),
                cpoId: provider.cpoId,
                cpoName: provider.cpoName,
                socketTypePowerRanges: parseProviderSocketTypeRangesToApiModel(provider.socketTypePowerRanges)
            });

            if (result.status === HttpStatusCode.CREATED) {
                return Promise.resolve();
            }
            else {
                throw new Error();
            }
        } catch(e: any) {
            if (e.response?.data?.errorCode === ErrorCode._4xxItemAlreadyExists || e.response?.status === HttpStatusCode.CONFLICT) {
                throw new ProviderWithSameIdAlreadyExists();
            } else {
                throw e;
            }
        }

    }

    async deleteProvider(provider: Provider): Promise<void> {
        
        const result = await apiManager.providerApi.deleteProvider(
            provider.cpoId,
            apiManager.idToken, 
        );

        if (result.status === HttpStatusCode.NO_CONTENT) {
            return Promise.resolve();
        }
        else {
            throw new Error();
        }

    }

    async updateProvider(oldProviderData: Provider, updateData: UpdateProviderData): Promise<void> {
        
        try {
            const result = await apiManager.providerApi.updateProvider(
                apiManager.idToken, 
                oldProviderData.cpoId,
                {
                    cpoName: oldProviderData.cpoName !== updateData.cpoName ? updateData.cpoName : undefined,
                    cpoId: oldProviderData.cpoId !== updateData.cpoId ? updateData.cpoId : undefined,
                    socketTypePowerRanges: oldProviderData.socketTypePowerRanges !== updateData.socketTypePowerRanges 
                        ? parseProviderSocketTypeRangesToApiModel(updateData.socketTypePowerRanges)
                        : undefined,
                }
            );

            if (result.status === HttpStatusCode.OK) {
                return Promise.resolve();
            }
            else {
                throw new Error();
            }
        } catch(e: any) {
            if (e.response?.data?.errorCode === ErrorCode._4xxCannotUpdateProviderId) {
                throw new CannotUpdateProviderId();
            } else if (e.response?.data?.errorCode === ErrorCode._4xxItemAlreadyExists || e.response?.status === HttpStatusCode.CONFLICT) {
                throw new ProviderWithSameIdAlreadyExists();
            } else {
                throw e;
            }
        }

    }

}

export const providerApiService = new ProviderApiService('providers');