import axios from "axios";
import { AuthenticationApiFactory, PaymentApiFactory, ProfileApiFactory, ProviderApiFactory, MobileUserApiFactory, AdminApiFactory } from "../../_generated/api";
import { Env } from "../config/env";
import HttpStatusCode from "./http-status.code";

class ApiManager {

    readonly authApi = AuthenticationApiFactory(Env.apiConfig, Env.baseUrl);
    readonly profileApi = ProfileApiFactory(Env.apiConfig, Env.baseUrl);
    readonly providerApi = ProviderApiFactory(Env.apiConfig, Env.baseUrl);
    readonly paymentApi = PaymentApiFactory(Env.apiConfig, Env.baseUrl);
    readonly mobileUsersApi = MobileUserApiFactory(Env.apiConfig, Env.baseUrl);
    readonly adminApi = AdminApiFactory(Env.apiConfig, Env.baseUrl);

    private _idToken = '';
    get idToken() { return this._idToken; }

    private _accessToken = '';
    get accessToken() { return this._accessToken; }

    private _refreshToken = '';
    get refreshToken() { return this._refreshToken; }



    public updateCredentials(idToken: string, accessToken: string, refreshToken: string) {



        this._idToken = idToken;
        this._accessToken = accessToken;
        this._refreshToken = refreshToken;

        Env.apiConfig.accessToken = accessToken;

        if (process.env.NODE_ENV === 'development') {
            sessionStorage.setItem('refresh_token', refreshToken);
        }
    }

    private  refreshExpiredToken = () => this.authApi.refreshToken(this._refreshToken);

    public logout() {
        this._idToken = '';
        this._accessToken = '';
        this._refreshToken = '';
        if (process.env.NODE_ENV === 'development') {
            sessionStorage.removeItem('refresh_token');
        }
    }

    constructor() {

        axios.interceptors.response.use((config) => config, (error) => {
            if (this._refreshToken && error.response.data.errorCode === HttpStatusCode.UNAUTHORIZED) {

                return this.refreshExpiredToken().then(refreshResponse => {
                    error.config.headers['X-id-token'] = refreshResponse.data.idToken;
                    error.config.baseURL = undefined;
                    return axios.request(error.config);
                });
            } else {
                return new Promise((_, reject) => reject(error));
            }
        });

    }

}

const apiManager = new ApiManager();
export default apiManager;