import { ApiService } from "../api-service";

export interface LogoutListener {
    logout: () => void;
}

/**
 * Global logout manager used to cleanup multiple objects whene the logout operation is performed
 */
class LogoutManager {

    listeners: LogoutListener[] = [];

    addLogoutListener = (listener: LogoutListener) => {
        if (this.listeners.indexOf(listener) < 0) {
            this.listeners.push(listener);
        }
    };

    removeLogoutListener = (listener: LogoutListener) => {
        const index = this.listeners.indexOf(listener);
        if (index >= 0) {
            delete this.listeners[index];
        }
    };

    logout = () => {
        this.listeners = this.listeners.filter(item => item !== null && item !== undefined);
        this.listeners.forEach(listener => listener.logout());

        ApiService.clearToken();
    };
}

const logoutManager = new LogoutManager();
export default logoutManager;