import React, { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useIntl } from "react-intl";
import { observer } from 'mobx-react-lite';

import { StyledSelect } from '../../../common/components/input/styled-select';
import { AppButton, AppButtonVariant } from '../../../common/components/button/app-button';

import { StyledInput } from '../../../common/components/input/styled-input';
import { AdministrationFilter } from '../../model/admin-filter';
import { convertRoleToMessageDescriptor, UserRole } from '../../../common/models/user';
import { Country, nameFromCountryId } from '../../../common/models/country';

import searchIcon from '../../../assets/images/icon-search.svg';
import iconSettings from '../../../assets/images/icon-settings.svg';

import commonMessages from '../../../common/messages/common.messages';
import filterMessages from '../../../common/messages/filter.messages';
import messages from '../../administration.messages';

import './admin-section-header.scss';

interface AdminSectionHeaderProps {
    totalItems: number
    countries: Country[]
    roles: UserRole[]

    filters: AdministrationFilter

    applyFilters: () => void
}

const noCountry = 'noCountry';
const noRole = 'noRole';

export const AdminSectionHeader = observer((props: AdminSectionHeaderProps) => {
    const { formatMessage } = useIntl();

    const backendUserSearchedRef = useRef<HTMLInputElement>(null);
    const [oldBackendUserSearched, setOldBackedUserSearched] = useState<string>();

    const countries = useMemo(() => 
        [ 
            {id: noCountry, label: formatMessage(filterMessages.anyCountryFilter)},
            ...props.countries
                .map((country) => {
                    const nameDescriptor = nameFromCountryId(country);
                    return {
                        id: country, 
                        label: nameDescriptor ? formatMessage(nameDescriptor) : country
                    };
                })
        ],
        [formatMessage, props.countries]
    );

    const roles = useMemo(() => 
        [ 
            {id: noRole, label: formatMessage(messages.anyRoleFilter)},
            ...props.roles
                .map((role) => ({
                    id: role, 
                    label: formatMessage(convertRoleToMessageDescriptor(role))
                }))
        ],
        [formatMessage, props.roles]
    );

    const onCountrySelected = useCallback((countryId: string) => {
        if(countryId != props.filters.country){
            if (!props.countries.includes(countryId)) 
                props.filters.setCountry(undefined);
            else 
                props.filters.setCountry(countryId);
            props.applyFilters();
        }
    }, [props]);

    const onRoleSelected = useCallback((roleId: string) => {
        if(roleId != props.filters.role){
            props.filters.setRole(props.roles.find(elem => elem == roleId));
            props.applyFilters();
        }
    }, [props]);

    const onBackendUserSearchedChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value !== '' ? event.target.value : undefined;    
        if(newValue != props.filters.backendUserSearched){
            props.filters.setBackendUserSearched(newValue);
        }
    },[props]);

    const onResetFilters = useCallback(() => {
        props.filters.resetFilter();
        props.applyFilters();
        setOldBackedUserSearched(undefined);
    }, [props]);

    const onBackendUserSearchedClick = useCallback(
        () => backendUserSearchedRef.current?.blur(), 
        [backendUserSearchedRef]
    );
    const onBackendUserSearchedBlur = useCallback(() => {
        if(oldBackendUserSearched != props.filters.backendUserSearched){
            props.applyFilters();
            setOldBackedUserSearched(props.filters.backendUserSearched ?? undefined);
        }
    }, [props, oldBackendUserSearched]);

    return <div className='admin-section-header section-header'>
        <div className='admin-section-header-title'>
            <img className='settings-icon' src={iconSettings} alt='' />
            <h2> {formatMessage(messages.sectionTitle)} </h2>
            <span className='items-count items-upper'>({props.totalItems})</span>
        </div>
        <div className='admin-section-header-filters'>
            <StyledInput
                className='email-search-input'
                onChange={onBackendUserSearchedChange}
                value={props.filters.backendUserSearched ?? ''}
                placeholder={formatMessage(messages.searchAdminHintLabel)}
                icon={searchIcon}
                onBlur={onBackendUserSearchedBlur}
                onIconClick={onBackendUserSearchedClick}
            />
            <StyledSelect 
                    items={countries} 
                    onItemSelected={onCountrySelected}
                    selectedItemId={props.filters.country ?? noCountry}
            />
            <div>
                <StyledSelect 
                    items={roles} 
                    onItemSelected={onRoleSelected}
                    selectedItemId={props.filters.role ?? noRole}
                />
                <span className='items-count items-right'>({props.totalItems})</span>
            </div>
        </div>
        <AppButton 
            label={formatMessage(commonMessages.resetButton)}
            variant={AppButtonVariant.Tertiary}
            onClick={onResetFilters}
        />
    </div>;
});