import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { Routes } from '../../common/routing/routes';
import { useStore } from '../../common/stores/root-store';
import { emptyFunction, goBack } from '../../common/utils/utils';
import loginApiService from '../api/login-api-service';
import { ChangePasswordSection } from "../../change-password/ui/components/change-password";

export const FirstLoginPage = observer(() => {

    const { userStore } = useStore();
    const email = userStore.firstLoginEmail;

    useEffect(() => {
        // Check needed only for the very first render, so no dependencies must be passed to this hook
        if(email == null) {
            goBack();
        }
        return emptyFunction;
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    const [isSaving, setIsSaving] = useState(false);

    const [saveFailed, setSaveFailed] = useState(false);
    const clearError = useCallback(() => setSaveFailed(false), [setSaveFailed]);

    const [loginCompleted, setLoginCompleted] = useState(false);

    const onSave = useCallback(async (oldPassword: string, newPassword: string) => {

        let saveSuccessful = false;

        if (saveFailed) {
            setSaveFailed(false);
        }
        setIsSaving(true);

        try {
            const user = await loginApiService.firstLogin(email || '', oldPassword, newPassword);
            userStore.clearFirstLoginEmail();
            userStore.setUser(user);
            saveSuccessful = true;
        } catch (e) {
            setSaveFailed(true);
        } finally {
            setIsSaving(false);
        }

        if (saveSuccessful) {
            setLoginCompleted(true);
        }

    }, [saveFailed, email, userStore]);

    return <>
        <ChangePasswordSection
            isCancelAvailable={false}
            isSaveFailed={saveFailed}
            isSaving={isSaving}
            clearSaveError={clearError}
            onSave={onSave}
        />
        {loginCompleted && 
        <Redirect to={Routes.Providers} />}
    </>;
});