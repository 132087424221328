import React from 'react';
import { useIntl } from 'react-intl';

import { SortableTableHeader } from '../../../common/components/paged-list/table-header-sortable';

import listMessages from '../../../common/messages/list.messages';
import { OrderManagerProps } from '../../../common/models/order-criteria';
import messages from '../../users.messages';

export const UsersTableHeader = (props: OrderManagerProps) => {

    const { formatMessage } = useIntl();

    return <tr className='provider-th'>
        <td>{formatMessage(messages.tableHeaderEmail)} </td>
        <SortableTableHeader {...props} sortKey='registrationDate' label={formatMessage(messages.tableHeaderRegistered)} />
        <td>{formatMessage(messages.tableHeaderStatus)} </td>
        <SortableTableHeader {...props} sortKey='lastChargeDate' label={formatMessage(messages.tableHeaderLastActivity)} />
        <SortableTableHeader {...props} sortKey='amountPaid' label={formatMessage(messages.tableHeaderPaidAmounts)} />
        <SortableTableHeader {...props} sortKey='amountDue' label={formatMessage(messages.tableHeaderLDueAmounts)} />
        <td>{formatMessage(listMessages.tableHeaderActions)}</td>
    </tr >;
};