import React from 'react';
import { useIntl } from 'react-intl';

import { SortableTableHeader } from '../../../../common/components/paged-list/table-header-sortable';

import { FareOrderCriteria } from '../../../model/fare';
import { OrderManagerProps } from '../../../../common/models/order-criteria';


import messages from '../../../../common/messages/list.messages';

export const ContractDetailsTableHeader = (props: OrderManagerProps) => {
    const { formatMessage } = useIntl();

    return <tr className='contract-details-th'>
        <SortableTableHeader 
            sortKey={FareOrderCriteria.ID}
            label={formatMessage(messages.tableHeaderID)}
            {...props}
        />
        <SortableTableHeader 
            sortKey={FareOrderCriteria.NAME}
            label={formatMessage(messages.tableHeaderName)}
            {...props}
        />
        <td>{formatMessage(messages.tableHeaderCurrency)}</td>
        <td>{formatMessage(messages.tableHeaderCableConnector)}</td>
        <SortableTableHeader 
            sortKey={FareOrderCriteria.POWER}
            label={formatMessage(messages.tableHeaderPower)}
            {...props}
        />
        <td>{formatMessage(messages.tableHeaderContractFee, { currency: '%' })}</td>
        <td>{formatMessage(messages.tableHeaderRange)}</td>
        <td>{formatMessage(messages.tableHeaderEvseId)}</td>
        <td>{formatMessage(messages.tableHeaderNotes)}</td>
        <td>{formatMessage(messages.tableHeaderActions)}</td>
    </tr >;

};