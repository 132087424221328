import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';

import { ButtonRow } from '../../../common/components/button-row/button-row';
import { AppButtonVariant } from '../../../common/components/button/app-button';
import { BaseDialog } from '../../../common/components/dialogs/base-dialog';
import { PagedList } from '../../../common/components/paged-list/paged-list';
import { OrderManagerProps } from '../../../common/models/order-criteria';
import { useStore } from '../../../common/stores/root-store';
import { Transaction, TransactionsSummary } from '../../../transaction/model/transaction';
import { TimePeriodFilter } from '../../../common/models/filters';
import { TransactionTableHeader } from '../../../transaction/ui/list/transaction-table-header';
import { TransactionTableItem } from '../../../transaction/ui/list/transaction-table-item';
import { TransactionTableSummary } from '../../../transaction/ui/list/transaction-table-summary';
import { UserDetailHeader } from './user-detail-header';
import { BubbleLoader } from '../../../common/components/loader/bubble-loader';
import { User } from '../../../common/models/user';

import commonMessages from '../../../common/messages/common.messages';

import './user-detail-page.scss';

interface UserDetailPageProps {
    user?: User;
    showLoader?: boolean;
}

export const UserDetailPage = observer(({ user, showLoader }: UserDetailPageProps) => {

    const { formatMessage } = useIntl();
    const { appUsersStore } = useStore();

    const userDetailStore = appUsersStore.userDetailStore;

    const onTimeFilterSelected = useCallback((newItem: string) => {
        if (newItem != userDetailStore?.filters?.timePeriod) {
            const timeFiltered = Object.values(TimePeriodFilter).find((val) => val == newItem);
            if (timeFiltered != null) {
                userDetailStore?.filters?.setTimePeriod(timeFiltered);
                userDetailStore?.applyFilters();
            }
        }
    }, [userDetailStore]);

    const renderHeader = useCallback(() => {
        return <UserDetailHeader
            transactionsCount={userDetailStore?.totalItems || 0}
            onTimeFilterSelected={onTimeFilterSelected}
            selectedTimePeriodFilter={userDetailStore?.filters?.timePeriod ?? TimePeriodFilter.THIS_MONTH}
        />;
    }, [onTimeFilterSelected, userDetailStore?.filters?.timePeriod, userDetailStore?.totalItems]);

    const renderTableHeader = useCallback((orderProps: OrderManagerProps) =>
        <TransactionTableHeader {...orderProps} showActions={false} />,
        []
    );

    const renderTableSummary = useCallback((summary?: TransactionsSummary) =>
        <TransactionTableSummary
            totalCollectedAmount={summary?.totalAmountCollected ?? 0}
            totalDebitAmount={summary?.totalAmountDue ?? 0}
            totalEarnedAmount={summary?.totalAmountEarned ?? 0}
        />, []);

    const renderItem = useCallback((item: Transaction, index: number) => {
        return <TransactionTableItem item={item} key={index.toString()} showActions={false} />;
    }, []);

    const renderTableFooter = useCallback((isTableEmpty: boolean) => {
        const downloadXLS = useCallback(() => {
            userDetailStore?.downloadReport();
        }, []);

        return <ButtonRow buttons={[{
            label:
                formatMessage(commonMessages.downloadXLS),
            buttonClasses: AppButtonVariant.Dark,
            onClick: downloadXLS,
            disabled: userDetailStore?.isDownloadingReport || isTableEmpty
        },]} />;
    }, [userDetailStore, formatMessage]);

    return <BaseDialog
        containerClassName='user-detail-dialog'
        buttons={[{
            label: formatMessage(commonMessages.closeButton),
            onClick: appUsersStore.clearSelectedItem
        }]}
        onClose={appUsersStore.clearSelectedItem}
        open={!!user || !!showLoader}
        title={user?.email || ''}>
        {showLoader && <BubbleLoader className='user-detail-loader' />}
        {userDetailStore != undefined && <PagedList
            renderTableFooter={renderTableFooter}
            tableClass='user-detail-table'
            store={userDetailStore}
            columnsCount={6}
            renderTableHeader={renderTableHeader}
            renderTableRow={renderItem}
            renderTableSummary={renderTableSummary}
            renderHeader={renderHeader}
            openItemDetail={appUsersStore.openItemDetail}
        />}
    </BaseDialog>;
});