import React, { useMemo } from 'react';

import { useIntl } from 'react-intl';
import { StyledSelect } from '../../../common/components/input/styled-select';
import { getTimeFilters, TimePeriodFilter } from '../../../common/models/filters';

import messages from '../../users.messages';

import './user-detail-header.scss';

interface UserDetailHeaderProps {
    transactionsCount: number;
    selectedTimePeriodFilter: TimePeriodFilter;
    onTimeFilterSelected: (itemId: string) => void;
}


export const UserDetailHeader = ({ transactionsCount, selectedTimePeriodFilter, onTimeFilterSelected }: UserDetailHeaderProps) => {

    const intl = useIntl();
    const { formatMessage } = intl;

    const timeFilters = useMemo(() => getTimeFilters(intl), [intl]);

    return <div className='user-detail-header'>
        <span>{formatMessage(messages.userTransactionsHeader)}</span>
        <StyledSelect
            containerClassName='month-filter'
            items={timeFilters}
            onItemSelected={onTimeFilterSelected}
            selectedItemId={selectedTimePeriodFilter} />
        <span>({transactionsCount})</span>
    </div>;
};