import React, { ReactNode, useEffect } from 'react';
import { IAnyType, Instance, SnapshotOrInstance } from 'mobx-state-tree';
import { observer } from 'mobx-react-lite';

import { PagedListStoreModel } from '../../stores/paged-list/paged-list-store';
import { PageSelector } from './page-selector';
import { ListErrorBox } from './list-error-box';
import { EmptyListBox } from './empty-list-box';
import { BubbleLoader } from '../loader/bubble-loader';
import { ShadowContainer } from '../container/shadow-container';


import { OrderManagerProps } from '../../models/order-criteria';

import './paged-list.scss';

interface PagedListProps<ItemsType extends IAnyType, FiltersType extends IAnyType, SummaryType extends IAnyType> {

    columnsCount: number;

    store: PagedListStoreModel<ItemsType, FiltersType, SummaryType>;

    tableClass?: string;
    tableTitle?: string;

    renderHeader: () => ReactNode;
    renderTableHeader: (orderProps: OrderManagerProps) => ReactNode;
    renderTableRow: (item: Instance<ItemsType>, index: number) => ReactNode;

    renderSummary?: () => ReactNode;
    renderTableSummary?: (summaryData?: Instance<SummaryType>) => ReactNode;
    renderTableFooter?: (isTableEmpty: boolean) => ReactNode;

    openItemDetail?: (item: SnapshotOrInstance<ItemsType>) => void;

}

export const PagedList = observer(<ItemsType extends IAnyType, FiltersType extends IAnyType, SummaryType extends IAnyType>(
    {store, columnsCount,  ...props}: PagedListProps<ItemsType, FiltersType, SummaryType>) => {

    const { currentPageItems, isLoadingItems, pageNumber, itemsPerPage, pagesCount, error, orderCriteria, summaryData,
        nextPage, previousPage, firstPage, lastPage, reload, setPage, updateItemsPerPage, selectOrderCriteria } = store;

    useEffect(() => {
        store.init();
    }, [store]);

    return <div className='paged-list page-content'>
        <div className='list-box'>
            <div className='table-column'>
                {props.renderHeader()}
                {props.renderSummary && props.renderSummary()}

                <ShadowContainer className={`shadow-table table-wrapper${error ? ' error' : ''}`}>
                    {props.tableTitle && <div className='table-title'>{props.tableTitle}</div>}
                    <table className={props.tableClass ?? undefined}>
                        <thead>
                            {props.renderTableHeader({ setOrder: selectOrderCriteria, selectedOrder: orderCriteria })}
                            {props.renderTableSummary && props.renderTableSummary(summaryData)}
                        </thead>
                        <tbody>
                            {
                                (!currentPageItems || currentPageItems.length === 0) && <tr>
                                    <td colSpan={columnsCount} className={`overlay${isLoadingItems ? ' loading' : ''}${error ? ' error' : ''}${currentPageItems.length === 0 && !isLoadingItems ? ' empty' : ''}`}>
                                        {isLoadingItems && <div className='loader'><BubbleLoader /> </div>}
                                        {!isLoadingItems && error && <ListErrorBox onClick={reload} />}
                                        {!isLoadingItems && !error && currentPageItems.length === 0 && <EmptyListBox />}
                                    </td>
                                </tr>
                            }
                            {currentPageItems.map(props.renderTableRow)}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={columnsCount}>
                                    <div>
                                        {props.renderTableFooter && props.renderTableFooter(!currentPageItems || currentPageItems.length === 0)}
                                        <PageSelector
                                            itemsPerPage={itemsPerPage} currentPage={pageNumber} pagesCount={pagesCount} disableNavigation={isLoadingItems}
                                            showFirstPage={firstPage} showPreviousPage={previousPage} showNextPage={nextPage} showLastPage={lastPage}
                                            showPage={setPage} itemsPerPageChanged={updateItemsPerPage}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </ShadowContainer>

            </div>
        </div>
    </div>;
});