import React, { useCallback, useEffect, useMemo } from "react";
import { PricingReferenceUnit } from "../../../../../../common/models/pricing-reference-unit";
import { emptyFunction } from "../../../../../../common/utils/utils";
import { FareFeeType, SessionMoment } from '../../../../../model/fare';
import { FareFeeInfo } from "../../../../../model/fare-info";
import { FareFeeOptions } from "./fare-fee-options";

interface FareFeeTypeConfigurations {
    startingFrom: SessionMoment;
    endingTo: SessionMoment;
    canChangeStartingFrom: boolean;
    canChangeEndingTo: boolean;
    availableStartSessionCostSessionMoments?: SessionMoment[];
    availableEndSessionCostSessionMoments?: SessionMoment[];
    availablePricingReferenceUnits?: PricingReferenceUnit[];
}

export interface FareFeeTypeOptionsProps {
    fareFeeType: FareFeeType;
    
    fareFee: FareFeeInfo;
    onFareFeeChange: (fareFee: FareFeeInfo) => void;
    canUpdateData: boolean;
}

/**
 * Component that displays the FareFeeOption component adding the logic
 * based on the type of FareFee passed in the props.
 * @param props FareFeeTypeOptionsProps
 * @returns FareFeeTypeOptions
 */
export const FareFeeTypeOptions = (props: FareFeeTypeOptionsProps) => {

    /* -------- Custom-------- */
    const configsBasedOnFareFeeType: FareFeeTypeConfigurations = useMemo(() => {
        switch(props.fareFeeType){
            case FareFeeType.SESSION:
                return {
                    startingFrom: SessionMoment.SESSION_START,
                    endingTo: SessionMoment.SESSION_END,
                    canChangeStartingFrom: false,
                    canChangeEndingTo: true,
                    availableStartSessionCostSessionMoments: [SessionMoment.SESSION_START],
                    availableEndSessionCostSessionMoments: [
                        SessionMoment.SESSION_END,
                        SessionMoment.CHARGING_END
                    ],
                    availablePricingReferenceUnits: [
                        PricingReferenceUnit.PER_MINUTE,
                        PricingReferenceUnit.PER_HOUR,
                    ],
                };
            case FareFeeType.CHARGING:
                return {
                    startingFrom: SessionMoment.CHARGING_START,
                    endingTo: SessionMoment.CHARGING_END,
                    canChangeStartingFrom: false,
                    canChangeEndingTo: false,
                    availableStartSessionCostSessionMoments: [SessionMoment.CHARGING_START],
                    availableEndSessionCostSessionMoments: [SessionMoment.CHARGING_END],
                };
            case FareFeeType.PARKING:
                return {
                    startingFrom: SessionMoment.CHARGING_END,
                    endingTo: SessionMoment.SESSION_END,
                    canChangeStartingFrom: true,
                    canChangeEndingTo: true,
                    availableStartSessionCostSessionMoments: [
                        SessionMoment.SESSION_START,
                        SessionMoment.CHARGING_START,
                        SessionMoment.CHARGING_END,
                    ],
                    availableEndSessionCostSessionMoments: [
                        SessionMoment.CHARGING_START,
                        SessionMoment.CHARGING_END,
                        SessionMoment.SESSION_END
                    ],
                    availablePricingReferenceUnits: [
                        PricingReferenceUnit.PER_MINUTE,
                        PricingReferenceUnit.PER_HOUR,
                    ],
                };
            case FareFeeType.START:
                return {
                    startingFrom: SessionMoment.CHARGING_END,
                    endingTo: SessionMoment.SESSION_END,
                    canChangeStartingFrom: true,
                    canChangeEndingTo: true,
                    // TODO: to check with ui designer
                };
            case FareFeeType.GENERIC:
                return {
                    startingFrom: SessionMoment.SESSION_START,
                    endingTo: SessionMoment.SESSION_END,
                    canChangeStartingFrom: true,
                    canChangeEndingTo: true,
                };
            default:
                throw new Error(`Unsupported fare fee type: ${props.fareFeeType}`);
        }
    }, [props.fareFeeType]);

    /* -------- Common -------- */

    const onCostTypeSelected = useCallback((isDynamic: boolean) => {
        if(isDynamic){
            props.onFareFeeChange({
                price: props.fareFee.price,
                priceReferenceUnit: configsBasedOnFareFeeType.availablePricingReferenceUnits 
                    ? configsBasedOnFareFeeType.availablePricingReferenceUnits[0] 
                    : PricingReferenceUnit.KWH,
                startingFrom: configsBasedOnFareFeeType.startingFrom,
                endingTo: configsBasedOnFareFeeType.endingTo,
                gracePeriod: props.fareFee.gracePeriod
            });
        } else {
            props.onFareFeeChange({
                price: props.fareFee.price,
                startingFrom: configsBasedOnFareFeeType.startingFrom,
            });
        }
    }, [
        configsBasedOnFareFeeType.availablePricingReferenceUnits,
        configsBasedOnFareFeeType.endingTo,
        configsBasedOnFareFeeType.startingFrom,
        props
    ]);

    const onStartCostSessionMomentChange = useCallback((sessionMoment?: SessionMoment) => 
        props.onFareFeeChange({
            price: props.fareFee.price,
            priceReferenceUnit: props.fareFee.priceReferenceUnit,
            minimumReferenceUnit: props.fareFee.minimumReferenceUnit,
            startingFrom: sessionMoment,
            endingTo: props.fareFee.endingTo,
            gracePeriod: props.fareFee.gracePeriod
        })
    , [props]);

    const toggleShowGracePeriodConditions = useCallback(() => {
        if(props.fareFee.gracePeriod){
            props.onFareFeeChange({
                price: props.fareFee.price,
                priceReferenceUnit: props.fareFee.priceReferenceUnit,
                minimumReferenceUnit: props.fareFee.minimumReferenceUnit,
                startingFrom: props.fareFee.startingFrom,
                endingTo: props.fareFee.endingTo,
            });
        } else {
            props.onFareFeeChange({
                price: props.fareFee.price,
                priceReferenceUnit: props.fareFee.priceReferenceUnit,
                minimumReferenceUnit: props.fareFee.minimumReferenceUnit,
                startingFrom: props.fareFee.startingFrom,
                endingTo: props.fareFee.endingTo,
                gracePeriod: {
                    minimumValue: '',
                    priceReferenceUnit: props.fareFee.priceReferenceUnit ?? PricingReferenceUnit.KWH,
                }
            });
        }
    }, [props]);

    const onGracePeriodPricingReferenceUnitChange = useCallback((pricingReferenceUnit?: PricingReferenceUnit) => 
        props.onFareFeeChange({
            price: props.fareFee.price,
            priceReferenceUnit: props.fareFee.priceReferenceUnit,
            minimumReferenceUnit: props.fareFee.minimumReferenceUnit,
            startingFrom: props.fareFee.startingFrom,
            endingTo: props.fareFee.endingTo,
            gracePeriod: pricingReferenceUnit && {
                minimumValue: props.fareFee.gracePeriod?.minimumValue ?? '',
                priceReferenceUnit: pricingReferenceUnit
            }
        })
    , [props]);

    const onGracePeriodValueChange = useCallback((value?: string) => 
        props.onFareFeeChange({
            price: props.fareFee.price,
            priceReferenceUnit: props.fareFee.priceReferenceUnit,
            minimumReferenceUnit: props.fareFee.minimumReferenceUnit,
            startingFrom: props.fareFee.startingFrom,
            endingTo: props.fareFee.endingTo,
            gracePeriod: value !== undefined ? {
                minimumValue: value,
                priceReferenceUnit: props.fareFee.gracePeriod?.priceReferenceUnit ?? PricingReferenceUnit.PER_MINUTE
            } : undefined
        })
    , [props]);

    const onEndCostSessionMomentChange = useCallback((sessionMoment?: SessionMoment) => 
        props.onFareFeeChange({
            price: props.fareFee.price,
            priceReferenceUnit: props.fareFee.priceReferenceUnit,
            minimumReferenceUnit: props.fareFee.minimumReferenceUnit,
            startingFrom: props.fareFee.startingFrom,
            endingTo: sessionMoment,
            gracePeriod: props.fareFee.gracePeriod
        })
    , [props]);

    const onPricingReferenceUnitChange = useCallback((pricingReferenceUnit?: PricingReferenceUnit) => {
        if(pricingReferenceUnit){
            props.onFareFeeChange({
                price: props.fareFee.price,
                priceReferenceUnit: pricingReferenceUnit,
                minimumReferenceUnit: props.fareFee.minimumReferenceUnit,
                startingFrom: props.fareFee.startingFrom,
                endingTo: props.fareFee.endingTo,
                gracePeriod: props.fareFee.gracePeriod && {
                    priceReferenceUnit: pricingReferenceUnit, 
                    minimumValue: props.fareFee.gracePeriod.minimumValue,
                },
            });
        } else {
            onCostTypeSelected(false);
        }
    }, [onCostTypeSelected, props]);
    
    const onFeeChange = useCallback((value: string) => 
        props.onFareFeeChange({
            price: value,
            priceReferenceUnit: props.fareFee.priceReferenceUnit,
            minimumReferenceUnit: props.fareFee.minimumReferenceUnit,
            startingFrom: props.fareFee.startingFrom,
            endingTo: props.fareFee.endingTo,
            gracePeriod: props.fareFee.gracePeriod
        })
    , [props]);

    const onMinimumReferenceUnitChange = useCallback((value?: string) => 
        props.onFareFeeChange({
            price: props.fareFee.price,
            priceReferenceUnit: props.fareFee.priceReferenceUnit,
            minimumReferenceUnit: value,
            startingFrom: props.fareFee.startingFrom,
            endingTo: props.fareFee.endingTo,
            gracePeriod: props.fareFee.gracePeriod
        })
    , [props]);

    /* -------- Custom-------- */
    //effect used when the props.fareFee parameter is equal to {} => initialize the needed data
    useEffect(() => {
        if(Object.keys(props.fareFee).length === 0 && Object.getPrototypeOf(props.fareFee) === Object.prototype) {
            switch(props.fareFeeType){
                case FareFeeType.SESSION:
                    onCostTypeSelected(false);
                    break;
                case FareFeeType.CHARGING:
                    onCostTypeSelected(true);
                    break;
                case FareFeeType.PARKING:
                    onCostTypeSelected(true);
                    break;
                default:
                    throw new Error(`Unsupported fare fee type: ${props.fareFeeType}`);
            }
        }
    }, [onCostTypeSelected, props.fareFee, props.fareFeeType]);
    
    return <FareFeeOptions 
        isDynamic={!!props.fareFee.priceReferenceUnit}
        onIsDynamicChange={onCostTypeSelected}

        startCostSessionMoment={props.fareFee.startingFrom ?? SessionMoment.SESSION_START}
        canChangeStartSessionMoment={configsBasedOnFareFeeType.canChangeStartingFrom}
        onStartCostSessionMomentChange={configsBasedOnFareFeeType.canChangeStartingFrom
            ? onStartCostSessionMomentChange
            : emptyFunction
        }

        gracePeriodPricingReferenceUnit={props.fareFee.gracePeriod?.priceReferenceUnit}
        toggleShowGracePeriodConditions={toggleShowGracePeriodConditions}
        onGracePeriodPricingReferenceUnitChange={onGracePeriodPricingReferenceUnitChange}
        gracePeriodValue={props.fareFee.gracePeriod?.minimumValue}
        onGracePeriodValueChange={onGracePeriodValueChange}
        
        endCostSessionMoment={props.fareFee.endingTo}
        canChangeEndSessionMoment={configsBasedOnFareFeeType.canChangeEndingTo}
        onEndCostSessionMomentChange={configsBasedOnFareFeeType.canChangeEndingTo
            ? onEndCostSessionMomentChange
            : emptyFunction
        }

        pricingReferenceUnit={props.fareFee.priceReferenceUnit}
        onPricingReferenceUnitChange={onPricingReferenceUnitChange}

        fee={props.fareFee.price ?? ''}
        onFeeChange={onFeeChange}

        minimumReferenceUnit={props.fareFee.minimumReferenceUnit}
        onMinimumReferenceUnitChange={onMinimumReferenceUnitChange}

        availableStartSessionCostSessionMoments={configsBasedOnFareFeeType.availableStartSessionCostSessionMoments}
        availableEndSessionCostSessionMoments={configsBasedOnFareFeeType.availableEndSessionCostSessionMoments}
        availablePricingReferenceUnits={configsBasedOnFareFeeType.availablePricingReferenceUnits}

        canUpdateData={props.canUpdateData}
    />;
};