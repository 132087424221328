import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { StyledInput } from '../../common/components/input/styled-input';
import { AppButton } from '../../common/components/button/app-button';

import mailIcon from '../../assets/images/icon-mail.svg';
import lockIcon from '../../assets/images/icon-lock.svg';

import commonMessages from '../../common/messages/common.messages';
import messages from '../login.messages';

import loginApiService from '../api/login-api-service';

import { UserMustChangePasswordError } from '../api/login-api-exceptions';
import { Redirect } from 'react-router';
import { LoginSectionPage } from './components/login-section-page';
import { useStore } from '../../common/stores/root-store';
import { Routes } from '../../common/routing/routes';
import { isEmailValid } from '../../common/utils/validators';
import { useEnterKeyListener } from '../../common/components/hooks/use-key-listener';

export const LoginPage = () => {

    const { formatMessage } = useIntl();
    const { userStore } = useStore();


    const [isUpdating, setIsUpdating] = useState(false);

    const isDev = process.env.NODE_ENV === 'development';

    useEffect(() => {
        if (isDev) {
            setIsUpdating(true);
            loginApiService.loadLoggedUser().then((user) => {
                userStore.setUser(user);
                setLoginSuccessful(true);
            }).catch(() => {
                setIsUpdating(false);
            });
        }
    }, [isDev, userStore, setIsUpdating]);

    const [showInvalidMailError, setEmailInvalid] = useState(false);

    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [isLoginFailed, setIsLoginFailed] = useState(false);

    const [email, setEmail] = useState(isDev ? 'service@antreem.com' : '');
    const [password, setPassword] = useState(isDev ? 'Antreem1!' : '');

    const [mustChangePassword, setMustChangePassword] = useState(false);

    const [loginSuccessful, setLoginSuccessful] = useState(false);

    const onLoginClick = useCallback(async () => {
        
        if(!isEmailValid(email)) {
            setEmailInvalid(true);
            return;
        }

        // Mandatory in order to prevent a state change when the component is unmounted
        let willRedirect = false;

        setIsLoggingIn(true);

        try {
            const user = await loginApiService.login(email, password);
            userStore.setUser(user);
            willRedirect = true;
            setLoginSuccessful(true);
        } catch (e) {
            console.log('Login error', e);
            if (e instanceof UserMustChangePasswordError) {
                willRedirect = true;
                userStore.setFirstLoginEmail(email);
                console.log('Must Change pwd');
                setMustChangePassword(true);
            } else {
                setIsLoginFailed(true);
            }
        }
        if (!willRedirect) {
            setIsLoggingIn(false);
        }

    }, [email, password, userStore, setIsLoggingIn, setIsLoginFailed, setMustChangePassword]);
    const onEmailChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailInvalid(false);
        setIsLoginFailed(false);
        setEmail(e.target.value);
    }, [setEmail, setIsLoginFailed]);
    const onPasswordChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoginFailed(false);
        setPassword(e.target.value);
    }, [setPassword, setIsLoginFailed]);


    // Reset state when page is unmounted
    useEffect(() => {
        return () => {
            setMustChangePassword(false);
            setLoginSuccessful(false);
        };
    }, []);

    useEnterKeyListener(onLoginClick);

    return <LoginSectionPage title={formatMessage(messages.loginBoxTitle)}>
        <StyledInput
            value={email}
            onChange={onEmailChanged}
            disabled={isLoggingIn}
            icon={mailIcon}
            errorLabel={showInvalidMailError ? formatMessage(messages.invalidEmailError): undefined}
            placeholder={formatMessage(commonMessages.emailInputPlaceholder)}
            containerClassName='input-container email-input-container' />
        <StyledInput
            type='password'
            value={password}
            onChange={onPasswordChanged}
            icon={lockIcon}
            disabled={isLoggingIn}
            errorLabel={isLoginFailed ? formatMessage(messages.loginErrorMessage) : undefined}
            placeholder={formatMessage(commonMessages.passwordInputPlaceholder)}
            containerClassName='input-container pwd-input-container' />
        <AppButton
            buttonClasses='login-button'
            disabled={isLoggingIn || isUpdating}
            label={formatMessage(messages.loginButton)}
            onClick={onLoginClick} />
        {mustChangePassword && <Redirect to={Routes.FirstLogin} />}
        {loginSuccessful && <Redirect to={Routes.Providers} push={false} />}
    </LoginSectionPage>;
};