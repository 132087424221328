import React from 'react';

export enum PagedListCellVariant {
    Bold = 'bold',
    Warning = 'warning',
    Success = 'success',
    Semibold = 'semibold',
}

interface PagedListCellProps {
    children: string;
    variant?: PagedListCellVariant;
    isTextNotCapitalized?: boolean;
    className?: string;
}

export const PagedListCell = ({ children, variant, isTextNotCapitalized, className }: PagedListCellProps) => {
    
    let customClassName = className ? `${className} ` : '';
    if(variant != null) customClassName += variant;
    if(isTextNotCapitalized == null || !isTextNotCapitalized) customClassName += ` capitalized`;
    
    return <td className={customClassName}>
        {children}
    </td>;
};