import React from "react";
import { useIntl } from "react-intl";

import { AppButton, AppButtonVariant } from "../../../../../common/components/button/app-button";
import { ContractDetailItem } from "./contract-detail-item";

import commonMessages from "../../../../../common/messages/common.messages";
import { formatDate } from "../../../../../common/utils/formatters";

import contractDetailsMessages from "../../../../contract-details.messages";

export interface ContractDetailsSummaryTitleProps {
    contractStartDate: Date;
    contractEndDate: Date;
    baseFee: number;
    onUpdateButtonClick: () => void;
    showUpdateButton?: boolean;
    canUpdateContract?: boolean;
}

export const ContractDetailsSummaryTitle = (props: ContractDetailsSummaryTitleProps) => {
    
    const { formatMessage } = useIntl();
    
    return <div className="summary-title">
        <span>{formatMessage(contractDetailsMessages.contractDetailsSummaryTitle)}</span>

        <ContractDetailItem 
            title={formatMessage(contractDetailsMessages.validityTimeRangeLabel)}
            value={`${formatDate(props.contractStartDate)} - ${formatDate(props.contractEndDate)}`}
        />

        <ContractDetailItem 
            title={formatMessage(contractDetailsMessages.baseFeeLabel)}
            value={`${props.baseFee.toFixed(2)} %`}
        />

        { 
            props.showUpdateButton && 
                <AppButton
                    label={formatMessage(commonMessages.changeButton)}
                    variant={AppButtonVariant.Primary}
                    onClick={props.onUpdateButtonClick}
                    buttonClasses={'change-button'}
                    disabled={!props.canUpdateContract}
                />
        }

    </div>;
};