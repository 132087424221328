import { ResourceType } from "../../common/config/constants";
import { createPagedListStoreModel } from "../../common/stores/paged-list/paged-list-store";
import { administrationApiService } from "../api/admin-api-service";
import { AdministrationFilterModel } from "../model/admin-filter";
import { flow, types } from "mobx-state-tree";
import { ApiRequestState } from "../../common/api/api-request";
import { BackofficeUserInfo } from "../model/backoffice-user-info";
import { User, UserModel } from "../../common/models/user";
import { UserCannotDeleteHimselfError, UserCannotUpdateHisRoleError, UserAlreadyExistsError } from "../api/admin-api-exceptions";
import { OrderCriteriaModel, SortOrder } from "../../common/models/order-criteria";

export enum AdministrationError {
    USER_CANNOT_DELETE_HIMSELF = '1',
    USER_CANNOT_UPDATE_HIS_ROLE = '2',
    USER_ALREADY_EXISTS_ERROR = '3',
}


const AdditionalProviderModel = types.model({
    manageUserRequestState: types.maybe(
        types.enumeration<ApiRequestState>(Object.values(ApiRequestState))
    ),
    deleteUserRequestState: types.maybe(
        types.enumeration<ApiRequestState>(Object.values(ApiRequestState))
    ),
    administrationError: types.maybe(
        types.enumeration<AdministrationError>(Object.values(AdministrationError))
    ),
});

export const AdministrationListStoreModel = types.compose(
    createPagedListStoreModel<any, any, any>({
        type: UserModel,
        apiService: administrationApiService,
        typeLabel: ResourceType.BackofficeUser,
        filterType: AdministrationFilterModel,
        filters: () => AdministrationFilterModel.create({}),
        defaultOrderCriteria: OrderCriteriaModel.create({
            key: 'name',
            sortOrder: SortOrder.ASC
        })
    }),
    AdditionalProviderModel
).actions((self) => {

    const createUser = flow(function* (userInfo: BackofficeUserInfo, locale: string) {
        self.manageUserRequestState = ApiRequestState.RUNNING;
        try {
            yield administrationApiService.createNewBackofficeUser(userInfo, locale);
            self.manageUserRequestState = ApiRequestState.COMPLETED;
        } catch (e: any) {
            if (e instanceof UserAlreadyExistsError) {
                self.administrationError = AdministrationError.USER_ALREADY_EXISTS_ERROR;
            }
            self.manageUserRequestState = ApiRequestState.FAILED;
        }
    });

    const updateUserInfo = flow(function* (userId: string, userInfo: BackofficeUserInfo) {
        self.manageUserRequestState = ApiRequestState.RUNNING;
        try {
            yield administrationApiService.updateBackofficeUserInfo(userId, userInfo);
            self.manageUserRequestState = ApiRequestState.COMPLETED;
        } catch (e: any) {
            if (e instanceof UserCannotUpdateHisRoleError) {
                self.administrationError = AdministrationError.USER_CANNOT_UPDATE_HIS_ROLE;
            }
            self.manageUserRequestState = ApiRequestState.FAILED;
        }
    });

    const deleteUser = flow(function* (user: User) {
        self.deleteUserRequestState = ApiRequestState.RUNNING;
        try {
            yield administrationApiService.deleteBackofficeUser(user);
            self.deleteUserRequestState = ApiRequestState.COMPLETED;
        } catch (e: any) {
            if (e instanceof UserCannotDeleteHimselfError) {
                self.administrationError = AdministrationError.USER_CANNOT_DELETE_HIMSELF;
            }
            self.deleteUserRequestState = ApiRequestState.FAILED;
        }
    });

    const clearManageUserRequestState = () => self.manageUserRequestState = undefined;

    const clearDeleteUserRequestState = () => self.deleteUserRequestState = undefined;

    const clearAdministrationError = () => self.administrationError = undefined;

    return {
        createUser, clearManageUserRequestState,
        updateUserInfo,
        deleteUser, clearDeleteUserRequestState,
        clearAdministrationError
    };
}).named('ProviderListStoreModel');
