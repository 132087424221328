import { flow, types, cast } from "mobx-state-tree";
import { ResourceType } from "../../common/config/constants";
import { createPagedListStoreModel } from "../../common/stores/paged-list/paged-list-store";
import { ProviderModel } from "../../providers/model/provider";
import { transactionApiService } from "../api/transaction-api-service";
import { TransactionModel, TransactionsSummaryModel } from "../model/transaction";
import { TransactionsFilterModel } from "../model/transaction-filter";

const AdditionalTransactionModel = types.model({
    cpoListForSelectedCountry: types.array(ProviderModel)
});

export const TransactionListStoreModel = types.compose(
    createPagedListStoreModel({
        type: TransactionModel,
        apiService: transactionApiService,
        typeLabel: ResourceType.Transaction,
        filterType: TransactionsFilterModel,
        summaryType: TransactionsSummaryModel,
        filters: () => TransactionsFilterModel.create()
    }),
    AdditionalTransactionModel
).actions(self => {
    
    const parentInit = self.init;

    const updateCpoList = flow(function* (countryId?: string) {
        try {
            const cpoList = yield transactionApiService.getListOfCPO(countryId);
            self.cpoListForSelectedCountry = cast(cpoList);
        } catch(e) {
            //
        }
    });
    
    return {

        init() {
            if(self.filters?.countryId){
                parentInit();
            }
        },

        updateCpoList,

    };
})
.named('TransactionListStoreModel');
