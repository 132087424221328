import { Instance, types } from "mobx-state-tree";
import { TimePeriodFilter } from "../../common/models/filters";

export const TransactionsFilterModel = types.model({
    cpoId: types.maybeNull(types.string),
    timePeriod: types.optional(
        types.enumeration(Object.values(TimePeriodFilter)),
        TimePeriodFilter.THIS_MONTH
    ),
    countryId: types.maybe(types.string)
})
.actions((self) => ({
    
    setCPO(cpoId?: string) {
        self.cpoId = cpoId || null;
        return this;
    },

    setTimePeriod(timePeriod: TimePeriodFilter) {
        self.timePeriod = timePeriod;
        return this;
    },

    setCountryId(country?: string) {
        self.countryId = country;
        return this;
    },

    resetFilters() {
        self.cpoId = null;
        self.timePeriod = TimePeriodFilter.THIS_MONTH;
    }

}));

export interface TimePeriodApiFilter {
    monthStartIndex: number;
    monthEndIndex: number;
}

export const apiMonthFilterFromTimePeriod = (period: TimePeriodFilter | undefined) => {
    switch(period) {
        case TimePeriodFilter.LAST_MONTH:
            return {
                monthStartIndex:1,
                monthEndIndex:2,
            };
        case TimePeriodFilter.LAST_3_MONTHS:
            return {
                monthStartIndex:0,
                monthEndIndex:3
            };
        case TimePeriodFilter.LAST_6_MONTHS:
            return {
                monthStartIndex:0,
                monthEndIndex:6
            };
        case TimePeriodFilter.LAST_YEAR:
            return {
                monthStartIndex: 0,
                monthEndIndex: 12
            };
        case TimePeriodFilter.THIS_MONTH: 
        return {
            monthStartIndex: 0,
            monthEndIndex:1
        };
        default:
            return {
                monthStartIndex: 0,
                monthEndIndex:1
            };
    }
};


export interface TransactionsFilter extends Instance<typeof TransactionsFilterModel> { }