import { AxiosPromise } from "axios";

import { ListApiService } from "../../../common/api/items-list/list-api-service";
import { OrderCriteria } from "../../../common/models/order-criteria";
import apiManager from "../../../common/api/api-manager";
import HttpStatusCode from "../../../common/api/http-status.code";
import { GetItemsResponse } from "../../../common/api/items-list/get-items";
import { DEFAULT_PAGE_ITEMS } from "../../../common/config/constants";
import { parseCreateGroupDataToApiModel, parseGroupFromApiModel } from "./group-api-parser";
import { GroupWithSameIdAlreadyExists } from "./group-api-exception";
import { CreateGroupData } from "./group-request";
import { ErrorCode, Group as ApiGroup } from "../../../_generated/api";
import { Group } from "../../model/group";

export class GroupApiService extends ListApiService<Group, never, never> {

    private providerId?: string;
    private contractId?: string;

    parseListItem = (apiResponse: any) => parseGroupFromApiModel(apiResponse);

    parseSummary = () => undefined;

    async getItems(pageNumber: number, filters: never, orderCriteria?: OrderCriteria, itemPerPage?: number): Promise<GetItemsResponse<Group, never>> {

        const offset = (itemPerPage || 0) * (pageNumber - 1);
        const limit = itemPerPage || DEFAULT_PAGE_ITEMS;

        const result = await this.downloadItemsList(offset, limit, filters, orderCriteria);


        if (result.status !== HttpStatusCode.OK) {
            throw new Error();
        }



        const data = result.data.slice(offset, limit);

        return Promise.resolve({
            totalItems: result.data.length,
            items: data.map(this.parseListItem)
        });
    }

    downloadItemsList(offset: number, limit: number, filters: never, orderCriteria?: OrderCriteria, accept?: string): AxiosPromise<ApiGroup[]> {
        if (!this.providerId || !this.contractId) {
            throw new Error('provider id or contract id not specified');
        }

        const config = accept === undefined ? undefined : {
            headers: {
                'Accept': accept
            }
        };


        const result = apiManager.providerApi.getGroups(
            this.providerId,
            this.contractId,
            apiManager.idToken,
            config
        );

        return result;


    }

    async addGroup(Group: CreateGroupData): Promise<Group> {
        if (!this.providerId || !this.contractId) {
            throw new Error('provider id or contract id not specified');
        }

        try {
            const result = await apiManager.providerApi.createGroup(
                apiManager.idToken,
                this.providerId,
                this.contractId,
                parseCreateGroupDataToApiModel(Group)
            );

            if (result.status === HttpStatusCode.CREATED) {
                return Promise.resolve(
                    parseGroupFromApiModel(result.data)
                );
            }
            else {
                throw new Error();
            }
        } catch (e: any) {
            if (e.response?.data?.errorCode === ErrorCode._4xxItemAlreadyExists || e.response?.status === HttpStatusCode.CONFLICT) {
                throw new GroupWithSameIdAlreadyExists();
            } else {
                throw e;
            }
        }
    }


    setProviderId(providerId: string) {
        this.providerId = providerId;
    }

    setContractId(contractId: string) {
        this.contractId = contractId;
    }


}

export const groupApiService = new GroupApiService('groups');
