import { Instance, types } from "mobx-state-tree";

export enum GroupOrderCriteria {
    ID = 'groupId',
    NAME = 'name',
    POWER = 'description',
}

export const GroupModel = types.model({
    groupId: types.string,
    name: types.string,
    description: types.maybeNull(types.string),
});

export interface Group extends Instance<typeof GroupModel> { }

