import React, { ChangeEventHandler, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import { SelectItem } from "../../../../../../common/components/input/select-item";
import { CheckedState, StyledCheckBox } from "../../../../../../common/components/input/styled-checkbox";
import { StyledInput } from "../../../../../../common/components/input/styled-input";
import { StyledSelect } from "../../../../../../common/components/input/styled-select";

import { SessionMoment } from "../../../../../model/fare";
import { PricingReferenceUnit } from "../../../../../../common/models/pricing-reference-unit";
import { isNumber } from "../../../../../../common/utils/validators";

import contractDetailsMessages from "../../../../../contract-details.messages";

import './fare-fee-options.scss';
import { emptyFunction } from '../../../../../../common/utils/utils';

export interface FareFeeOptionsProps {
    isDynamic: boolean;
    onIsDynamicChange: (isDynamic: boolean) => void;

    startCostSessionMoment: SessionMoment;
    onStartCostSessionMomentChange: (sessionMoment: SessionMoment) => void;
    canChangeStartSessionMoment: boolean;

    gracePeriodPricingReferenceUnit?: PricingReferenceUnit;
    toggleShowGracePeriodConditions: () => void;
    onGracePeriodPricingReferenceUnitChange: (pricingReferenceUnit?: PricingReferenceUnit) => void;
    gracePeriodValue?: string;
    onGracePeriodValueChange: (value?: string) => void;


    endCostSessionMoment?: SessionMoment;
    onEndCostSessionMomentChange: (sessionMoment?: SessionMoment) => void;
    canChangeEndSessionMoment?: boolean;

    pricingReferenceUnit?: PricingReferenceUnit;
    onPricingReferenceUnitChange: (pricingReferenceUnit?: PricingReferenceUnit) => void;

    fee: string;
    onFeeChange: (fee: string) => void;

    minimumReferenceUnit?: string;
    onMinimumReferenceUnitChange: (minimumReferenceUnit?: string) => void;

    availableStartSessionCostSessionMoments?: SessionMoment[];
    availableEndSessionCostSessionMoments?: SessionMoment[];
    availablePricingReferenceUnits?: PricingReferenceUnit[];

    canUpdateData: boolean;
}

const staticPricingReferenceUnit = '____staticPricingReferenceUnit____';

/**
 * Component that handles the layout of the fee options and 
 * the retrieval of the data selected by the user without taking 
 * in consideration which type of fee has to display
 * @param props FareFeeOptionsProps
 * @returns FareFeeOptions
 */
export const FareFeeOptions = (props: FareFeeOptionsProps) => {

    const { formatMessage } = useIntl();

    const sessionMomentItems: SelectItem[] = useMemo(() => Object.values(SessionMoment).map(elem => {
        let label;
        switch(elem) {
            case SessionMoment.SESSION_START:
                label = contractDetailsMessages.sessionMomentSessionStart;
                break;
            case SessionMoment.SESSION_END:
                label = contractDetailsMessages.sessionMomentSessionEnd;
                break;
            case SessionMoment.CHARGING_START:
                label = contractDetailsMessages.sessionMomentChargingStart;
                break;
            case SessionMoment.CHARGING_END:
                label = contractDetailsMessages.sessionMomentChargingEnd;
                break;
        }
        return {
            id: elem,
            label: formatMessage(label)
        };
    }), [formatMessage]);

    const startSessionMomentItems = useMemo(() => {
        if(props.availableStartSessionCostSessionMoments){
            return sessionMomentItems.filter(elem => 
                props.availableStartSessionCostSessionMoments?.find(sessionMoment => sessionMoment === elem.id)
            );
        } else {
            return sessionMomentItems;
        }
    }, [props.availableStartSessionCostSessionMoments, sessionMomentItems]);

    const endSessionMomentItems = useMemo(() => {
        if(props.availableEndSessionCostSessionMoments){
            return sessionMomentItems.filter(elem => 
                props.availableEndSessionCostSessionMoments?.find(sessionMoment => sessionMoment === elem.id)
            );
        } else {
            return sessionMomentItems;
        }
    }, [props.availableEndSessionCostSessionMoments, sessionMomentItems]);
 
    const pricingReferenceUnitItems: SelectItem[] = useMemo(() => [
        ...(props.availablePricingReferenceUnits ?? Object.values(PricingReferenceUnit))
        .map(elem => {
            let label;
            switch(elem) {
                case PricingReferenceUnit.KWH:
                    label = contractDetailsMessages.kwhPricingReferenceUnit;
                    break;
                case PricingReferenceUnit.PER_HOUR:
                    label = contractDetailsMessages.hoursPricingReferenceUnit;
                    break;
                case PricingReferenceUnit.PER_MINUTE:
                    label = contractDetailsMessages.minutesPricingReferenceUnit;
                    break;
            }
            return {
                id: elem,
                label: formatMessage(label)
            };
        }),
        {
            id: staticPricingReferenceUnit,
            label: formatMessage(contractDetailsMessages.staticPricingReferenceUnit)
        }
    ], [formatMessage, props.availablePricingReferenceUnits]);

    const gracePeriodItems: SelectItem[] = useMemo(() => [
        {
            id: PricingReferenceUnit.PER_MINUTE,
            label: formatMessage(contractDetailsMessages.minutesGracePeriod)
        },
        {
            id: PricingReferenceUnit.PER_HOUR,
            label: formatMessage(contractDetailsMessages.hoursGracePeriod)
        },
        {
            id: PricingReferenceUnit.KWH,
            label: formatMessage(contractDetailsMessages.kwhGracePeriod)
        },
    ], [formatMessage]);

    /* ------ Cost type ------ */
    const costTypeItems: SelectItem[] = useMemo(() => [
        {
            id: 'false',
            label: formatMessage(contractDetailsMessages.staticFareCostType),
        },
        {
            id: 'true',
            label: formatMessage(contractDetailsMessages.dynamicFareCostType)
        }
    ], [formatMessage]);

    const onCostTypeSelected = useCallback((isDynamicString: string) => 
        props.onIsDynamicChange(isDynamicString === 'true')
    , [props]);

    /* ------ Start cost ------ */
    const onStartCostSelected = useCallback((sessionMomentId: string) => {
        const sessionMoment = Object.values(SessionMoment).find(elem => elem === sessionMomentId);
        sessionMoment && props.onStartCostSessionMomentChange(sessionMoment);
    }, [props]);
    
    /* ------ Grace period ------ */
    const onGracePeriodValueChange: ChangeEventHandler<HTMLInputElement> = useCallback((ev) => {
        const value = ev.target.value;
        if (isNumber(value)) {
            if (value.endsWith('.')) {
                props.onGracePeriodValueChange(value);
                return;
            } else {
                if (value !== undefined && value !== null) {
                    props.onGracePeriodValueChange(value);
                    return;
                }
            }
        }
    }, [props]);

    /* ------ End cost ------ */
    const onEndCostSelected = useCallback((sessionMomentId: string) => {
        const sessionMoment = Object.values(SessionMoment).find(elem => elem === sessionMomentId);
        sessionMoment &&  props.onEndCostSessionMomentChange(sessionMoment);
    }, [props]);

    /* ------ Fare cost ------ */
    const onPricingReferenceUnitSelected = useCallback((pricingReferenceUnitId: string) => {
        const pricingReferenceUnit = Object.values(PricingReferenceUnit).find(elem => elem === pricingReferenceUnitId);
        props.onGracePeriodPricingReferenceUnitChange(pricingReferenceUnit);
        props.onPricingReferenceUnitChange(pricingReferenceUnit);
    }, [props]);

    const onFeeChange: ChangeEventHandler<HTMLInputElement> = useCallback((ev) => {
        const value = ev.target.value;
        if (isNumber(value)) {
            if (value.endsWith('.')) {
                props.onFeeChange(value);
                return;
            } else {
                if (value !== undefined && value !== null) {
                    props.onFeeChange(value);
                    return;
                }
            }
        }
    }, [props]);

    const onMinimumReferenceUnitChange: ChangeEventHandler<HTMLInputElement> = useCallback((ev) => {
        const value = ev.target.value;
        if (isNumber(value)) {
            if (value.endsWith('.')) {
                props.onMinimumReferenceUnitChange(value);
                return;
            } else {
                if (value !== undefined && value !== null) {
                    props.onMinimumReferenceUnitChange(value);
                    return;
                }
            }
        }
    }, [props]);
    
    return <div className="fare-fee-options">

        <StyledSelect
            containerClassName="cost-type" 
            items={costTypeItems}
            onItemSelected={onCostTypeSelected}
            selectedItemId={props.isDynamic.toString()}
            label={formatMessage(contractDetailsMessages.costTypeLabel)}
            disabled={!props.canUpdateData}
            hideLabelItem
        />

        <div className="start-costs">

            <StyledSelect 
                items={startSessionMomentItems}
                selectedItemId={props.startCostSessionMoment}
                onItemSelected={onStartCostSelected}
                label={formatMessage(props.isDynamic ? contractDetailsMessages.startCostFromLabel : contractDetailsMessages.setCostWhenLabel)}
                disabled={!props.canChangeStartSessionMoment || !props.canUpdateData}
                hideLabelItem
            />
            
            {
                props.isDynamic && <StyledCheckBox
                    checked={props.gracePeriodPricingReferenceUnit ? CheckedState.Checked : CheckedState.Unchecked}
                    label={formatMessage(contractDetailsMessages.setAdditionalConditionsLabel)}
                    onClick={props.toggleShowGracePeriodConditions}
                    disabled={!props.canUpdateData}
                />
            }
            

        </div>

        {
            props.gracePeriodPricingReferenceUnit && <div className="grace-period">

                <StyledSelect 
                    items={gracePeriodItems}
                    selectedItemId={props.gracePeriodPricingReferenceUnit}
                    onItemSelected={emptyFunction}
                    label={formatMessage(contractDetailsMessages.gracePeriodPricingReferenceUnitLabel)}
                    hideLabelItem
                    disabled
                />

                <StyledInput 
                    label={
                        gracePeriodItems
                            .find(elem => elem.id === props.gracePeriodPricingReferenceUnit)?.label
                    }
                    value={props.gracePeriodValue ?? ''}
                    onChange={onGracePeriodValueChange}
                    disabled={!props.canUpdateData}
                />

            </div>
        }

        {
            props.isDynamic && <StyledSelect
                containerClassName="end-cost" 
                items={endSessionMomentItems}
                onItemSelected={onEndCostSelected}
                selectedItemId={props.endCostSessionMoment}
                label={formatMessage(contractDetailsMessages.endCostWhenLabel)}
                disabled={!props.canChangeEndSessionMoment || !props.canUpdateData}
                hideLabelItem
            />
        }

        <div className="price-value">

            <StyledSelect 
                items={pricingReferenceUnitItems}
                onItemSelected={onPricingReferenceUnitSelected}
                selectedItemId={props.pricingReferenceUnit ?? staticPricingReferenceUnit}
                label={formatMessage(contractDetailsMessages.costPricingReferenceUnitLabel)}
                disabled={!props.isDynamic || !props.canUpdateData}
                hideLabelItem
            />

            <div className="input-style-row">

                <StyledInput 
                    label={formatMessage(contractDetailsMessages.priceValueLabel)}
                    value={props.fee}
                    onChange={onFeeChange}
                    disabled={!props.canUpdateData}
                />

                {
                    props.isDynamic && <StyledInput 
                        label={formatMessage(contractDetailsMessages.minimumReferenceUnitLabel)}
                        value={props.minimumReferenceUnit ?? ''}
                        onChange={onMinimumReferenceUnitChange}
                        disabled={!props.canUpdateData}
                    />
                }

            </div>

        </div>
    </div>;
};