import { getSnapshot } from "mobx-state-tree";
import { GetItemsResponse } from "../../common/api/items-list/get-items";
import { Country } from "../../common/models/country";
import { ProviderApiService } from "../api/providers/provider-api-service";
import { Provider, ProviderModel, ProviderValueStatus } from "../model/provider";
import { ContractStatus, ContractType } from "../model/contract";
import { ExtraContractStatusFilter, ProvidersFilter } from "../model/providers-filter";
import { UpdateProviderData } from "../api/providers/provider-request";

class MockProviderApiService extends ProviderApiService {

    private _providers: Provider[] = [
        ProviderModel.create({
            cpoId: '123',
            cpoName: 'Charge Plus',
            activeContract: {
                contractId: '',
                status: ContractStatus.ACTIVE,
                isBlocked: false,
                type: ContractType.HUBJECT_DYNAMIC,
                inflation: {
                    baseInflation: 0,
                    hasFareSpecificInflation: false
                },
                earningsAmount: 170,
                costsAmount: 1127,
                kwhDelivered: 3124,
                chargesCount: 1801,
                validityPeriod: {
                    from: new Date('2021-11-12'),
                    to: new Date('2021-11-12')
                },
                creation: {
                    date: new Date(),
                    operator: {
                        name: 'Francesco Grandinetti',
                        email: 'francesco.grandinetti@antreem.com'
                    }
                },
                lastUpdate: {
                    date: new Date(),
                    operator: {
                        name: 'Francesco Grandinetti',
                        email: 'francesco.grandinetti@antreem.com'
                    }
                }
            },
            country: 'nl',
            chargesCountStatus: ProviderValueStatus.MAX,
            deliveredKwhStatus: ProviderValueStatus.NONE,
            earningsAmountStatus: ProviderValueStatus.NONE
        }),
        ProviderModel.create({
            cpoId: '321',
            cpoName: 'Enel',
            activeContract: {
                contractId: '',
                status: ContractStatus.ACTIVE,
                isBlocked: false,
                type: ContractType.HUBJECT_DYNAMIC,
                inflation: {
                    baseInflation: 0,
                    hasFareSpecificInflation: false
                },
                earningsAmount: 280,
                costsAmount: 2127,
                kwhDelivered: 5124,
                chargesCount: 1127,
                validityPeriod: {
                    from: new Date('2021-11-12'),
                    to: new Date('2021-11-12')
                },
                creation: {
                    date: new Date(),
                    operator: {
                        name: 'Francesco Grandinetti',
                        email: 'francesco.grandinetti@antreem.com'
                    }
                },
                lastUpdate: {
                    date: new Date(),
                    operator: {
                        name: 'Francesco Grandinetti',
                        email: 'francesco.grandinetti@antreem.com'
                    }
                }
            },
            country: 'it',
            chargesCountStatus: ProviderValueStatus.NONE,
            deliveredKwhStatus: ProviderValueStatus.MAX,
            earningsAmountStatus: ProviderValueStatus.MAX
        }),
        ProviderModel.create({
            cpoId: '234',
            cpoName: 'Enel X',
            activeContract: {
                contractId: '',
                status: ContractStatus.NOT_ACTIVE,
                isBlocked: false,
                type: ContractType.HUBJECT_DYNAMIC,
                inflation: {
                    baseInflation: 0,
                    hasFareSpecificInflation: false
                },
                earningsAmount: 170,
                costsAmount: 1127,
                kwhDelivered: 3124,
                chargesCount: 1127,
                validityPeriod: {
                    from: new Date('2021-11-12'),
                    to: new Date('2021-11-12')
                },
                creation: {
                    date: new Date(),
                    operator: {
                        name: 'Francesco Grandinetti',
                        email: 'francesco.grandinetti@antreem.com'
                    }
                },
                lastUpdate: {
                    date: new Date(),
                    operator: {
                        name: 'Francesco Grandinetti',
                        email: 'francesco.grandinetti@antreem.com'
                    }
                }
            },
            country: 'it',
            chargesCountStatus: ProviderValueStatus.NONE,
            deliveredKwhStatus: ProviderValueStatus.NONE,
            earningsAmountStatus: ProviderValueStatus.NONE
        }),
        ProviderModel.create({
            cpoId: '098',
            cpoName: 'ENI',
            activeContract: {
                contractId: '',
                status: ContractStatus.NOT_ACTIVE,
                isBlocked: false,
                type: ContractType.HUBJECT_DYNAMIC,
                inflation: {
                    baseInflation: 0,
                    hasFareSpecificInflation: false
                },
                earningsAmount: 57,
                costsAmount: 982,
                kwhDelivered: 3124,
                chargesCount: 200,
                validityPeriod: {
                    from: new Date('2021-11-12'),
                    to: new Date('2021-11-12')
                },
                creation: {
                    date: new Date(),
                    operator: {
                        name: 'Francesco Grandinetti',
                        email: 'francesco.grandinetti@antreem.com'
                    }
                },
                lastUpdate: {
                    date: new Date(),
                    operator: {
                        name: 'Francesco Grandinetti',
                        email: 'francesco.grandinetti@antreem.com'
                    }
                }
            },
            country: 'nl',
            chargesCountStatus: ProviderValueStatus.NONE,
            deliveredKwhStatus: ProviderValueStatus.NONE,
            earningsAmountStatus: ProviderValueStatus.NONE
        }),
        ProviderModel.create({
            cpoId: '111',
            cpoName: 'Volta',
            activeContract: {
                contractId: '',
                status: ContractStatus.EXPIRED,
                isBlocked: false,
                type: ContractType.HUBJECT_DYNAMIC,
                inflation: {
                    baseInflation: 0,
                    hasFareSpecificInflation: false
                },
                earningsAmount: 170,
                costsAmount: 1127,
                kwhDelivered: 3124,
                chargesCount: 1127,
                validityPeriod: {
                    from: new Date('2021-11-12'),
                    to: new Date('2021-11-12')
                },
                creation: {
                    date: new Date(),
                    operator: {
                        name: 'Francesco Grandinetti',
                        email: 'francesco.grandinetti@antreem.com'
                    }
                },
                lastUpdate: {
                    date: new Date(),
                    operator: {
                        name: 'Francesco Grandinetti',
                        email: 'francesco.grandinetti@antreem.com'
                    }
                }
            },
            country: 'ch',
            chargesCountStatus: ProviderValueStatus.NONE,
            deliveredKwhStatus: ProviderValueStatus.NONE,
            earningsAmountStatus: ProviderValueStatus.NONE
        }),
        ProviderModel.create({
            cpoId: '000',
            cpoName: 'Zapp',
            country: 'ch',
            chargesCountStatus: ProviderValueStatus.MIN,
            deliveredKwhStatus: ProviderValueStatus.MIN,
            earningsAmountStatus: ProviderValueStatus.MIN
        })
    ];

    private get providers() {
        return this._providers.map(elem => {
            const snapshot = getSnapshot(elem);
            return ProviderModel.create({ ...snapshot });
        });
    }

    private set providers(providers: Provider[]) {
        this._providers = providers;
    }


    getItems(pageNumber: number, filters: ProvidersFilter): Promise<GetItemsResponse<Provider, never>> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    totalItems: this.providers.length,
                    items: this.providers.filter(elem => {
                        let filtered = true;

                        if (filters.cpoName) {
                            filtered = filtered && elem.cpoName.toLowerCase().startsWith(filters.cpoName.toLowerCase());
                        }

                        if (filters.countryId) {
                            filtered = filtered && elem.country === filters.countryId;
                        }

                        if (filters.contractStatus === ExtraContractStatusFilter.NO_CONTRACTS) {
                            filtered = filtered && elem.activeContract === undefined;
                        } else if (filters.contractStatus) {
                            filtered = filtered && elem.activeContract?.status === filters.contractStatus;
                        }

                        return filtered;
                    })
                });
            }, 3000);
        });
    }

    getProvidersCountryList(): Promise<Country[]> {
        return new Promise((resolve) => setTimeout(
            () => resolve(
                this.providers
                    .map(elem => elem.country)
                    .filter((value, index, self) => self.indexOf(value) === index)
            ),
            3000
        ));
    }

    deleteProvider(provider: Provider): Promise<void> {
        return new Promise((resolve) => setTimeout(
            () => {
                this.providers = this.providers.filter(elem => elem.cpoId !== provider.cpoId);
                resolve();
            },
            3000
        ));
    }

    addProvider(provider: Provider): Promise<void> {
        return new Promise((resolve) => setTimeout(
            () => {
                this.providers = [
                    provider,
                    ...this.providers
                ];
                resolve();
            },
            3000
        ));
    }

    updateProvider(oldProvider: Provider, updateData: UpdateProviderData): Promise<void> {
        this.providers = this.providers.map(elem => {
            if (elem.cpoId === oldProvider.cpoId) {
                const snapshot = getSnapshot(elem);
                return ProviderModel.create({ ...snapshot, cpoName: updateData.cpoName });
            } else {
                return elem;
            }
        });
        return Promise.resolve();
    }

}

export const mockProviderApiService = new MockProviderApiService('providers');