import React from 'react';
import { useIntl } from 'react-intl';
import { nameFromCountryId, supportedCountries } from '../../../common/models/country';
import { ActionsMenu } from '../../../common/components/actions/actions-menu';
import { PagedListCell, PagedListCellVariant } from '../../../common/components/paged-list/paged-list-cell';
import { convertRoleToMessageDescriptor, User } from '../../../common/models/user';

import messages from '../../../common/messages/action.messages';
import { BackofficeUserInfo } from '../../model/backoffice-user-info';

interface AdminTableItemProps {
    user: User

    onUpdateUserClick: (info: BackofficeUserInfo) => void
    onDeleteUserClick: (info: User) => void
}
export const AdminTableItem = ({ user, onUpdateUserClick, onDeleteUserClick }: AdminTableItemProps) => {

    const { formatMessage } = useIntl();

    const countryName = nameFromCountryId(user.country);

    return  <tr className='admin-tr'>
        <PagedListCell variant={PagedListCellVariant.Bold}>{user.name}</PagedListCell>
        <PagedListCell>{
            countryName != null ? formatMessage(countryName) : user.country
        }</PagedListCell>
        <PagedListCell variant={PagedListCellVariant.Bold}>{formatMessage(convertRoleToMessageDescriptor(user.role))}</PagedListCell>
        <PagedListCell isTextNotCapitalized={true}>{user.email}</PagedListCell>
        <PagedListCell isTextNotCapitalized={true}>{user.contactDetails ?? ''}</PagedListCell>
        <td> 
            <ActionsMenu 
                actions={[
                    {
                        execute: () => onUpdateUserClick({
                            country: supportedCountries
                                .find(elem => elem.toUpperCase() == user.country.toUpperCase()) 
                                ?? supportedCountries[0],
                            email: user.email,
                            name: user.name,
                            role: user.role,
                            id: user.id,
                            contact_details: user.contactDetails ?? undefined
                        }),
                        label: formatMessage(messages.updateBackofficeUserLabel)
                    },
                    {
                        execute: () => onDeleteUserClick(user),
                        label: formatMessage(messages.deleteBackofficeUserLabel)
                    }
                ]}        
            /> 
        </td>
    </tr>;
};