import React, { useCallback } from "react";
import { useIntl } from "react-intl";

import { FareFeeTypeOptions } from "../fare-fee-type-options/fare-fee-type-options";
import { FareFeeData } from "./fare-fee-data";

import { FareFeeInfo } from "../../../../../model/fare-info";
import { FareFeeType } from "../../../../../model/fare";

import contractDetailsMessages from "../../../../../contract-details.messages";

export interface SessionFeeDataProps {
    sessionFee?: FareFeeInfo;
    onSessionFeeChange: (sessionFee?: FareFeeInfo) => void;
    canUpdateData: boolean;
}

export const SessionFeeData = (props: SessionFeeDataProps) => {
    
    const { formatMessage } = useIntl();

    const renderTitle = useCallback((value: string, unitOfMeasure: string) => formatMessage(
        contractDetailsMessages.fareSessionCostsSectionTitle, 
        {
            value,
            unitOfMeasure
        }
    ),[formatMessage]);

    const renderDescription = useCallback(() => formatMessage(
        contractDetailsMessages.fareSessionCostsSectionDescription 
    ),[formatMessage]);
    
    const renderFeeOptions = useCallback(() => props.sessionFee && 
        <FareFeeTypeOptions 
            fareFeeType={FareFeeType.SESSION}
            fareFee={props.sessionFee}
            onFareFeeChange={props.onSessionFeeChange}
            canUpdateData={props.canUpdateData}
        />
    , [props.canUpdateData, props.onSessionFeeChange, props.sessionFee]);

    const onSelectFareFeeChange = useCallback((isSelected: boolean) =>
        props.onSessionFeeChange(isSelected ? {} : undefined)
    , [props]);
    
    return <FareFeeData     
        
        renderTitle={renderTitle}
        renderDescription={renderDescription}
        renderFeeOptions={renderFeeOptions}

        showFareFeeOptions={!!props.sessionFee}
        fareFee={props.sessionFee}
        onSelectFareFeeChange={onSelectFareFeeChange}
        canUpdateData={!!props.canUpdateData}
    /> ;
};