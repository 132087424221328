import React from "react";
import { useIntl } from "react-intl";
import { AppButtonVariant } from "../../../../../common/components/button/app-button";
import { BaseDialog } from "../../../../../common/components/dialogs/base-dialog";
import commonMessages from "../../../../../common/messages/common.messages";

import contractsMessages from '../../../../contracts.messages';

export interface LowFeeConfirmationDialogProps {
    open: boolean
    onClose: () => void
    onConfirmButtonClick: () => void
}

export const LowFeeConfirmationDialog = (props: LowFeeConfirmationDialogProps) => {
    const { formatMessage } = useIntl();
    return <BaseDialog 
        title={formatMessage(contractsMessages.lowFeeConfirmationDialogTitle)}
        open={props.open}
        onClose={props.onClose}
        buttons={[
            {
                variant: AppButtonVariant.Dark,
                label: formatMessage(commonMessages.cancelButton),
                onClick: props.onClose
            },
            {
                variant: AppButtonVariant.Primary,
                label: formatMessage(commonMessages.confirmButton),
                onClick: props.onConfirmButtonClick
            },
        ]}
    >
        <span>{formatMessage(contractsMessages.lowFeeConfirmationDialogMessage)}</span>
    </BaseDialog>;
};