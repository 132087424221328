import React from "react";

export interface ContractDetailItemProps {
    title: string;
    value: string;
}

export const ContractDetailItem = (props: ContractDetailItemProps) => {
    return <div className="contract-detail-item">
        <span className="item-title">{props.title}:</span>
        <span className="item-value">{props.value}</span>
    </div>;
};