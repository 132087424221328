import { Instance, types } from "mobx-state-tree";

export const GlobalStoreModel = types.model({
    lastDataUpdateTime: types.maybe(types.Date)
}).actions(self => {
    return {
        onDataUpdated() {
            self.lastDataUpdateTime = new Date();
        },
    };
});
export interface GlobalStore extends Instance<typeof GlobalStoreModel> { }
