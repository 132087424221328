import { getSnapshot } from "mobx-state-tree";
import { GetItemsResponse } from "../../common/api/items-list/get-items";
import { GroupApiService } from "../api/groups/group-api-service";
import { CreateGroupData, UpdateGroupData } from "../api/groups/group-request";
import { Group, GroupModel } from "../model/group";

class MockGroupApiService extends GroupApiService {

    private _groups: Group[] = [
        GroupModel.create({
            groupId: '1',
            name: 'Nome di test 1',
            description: 'Descrizione di test 1',
        }),
        GroupModel.create({
            groupId: '2',
            name: 'Nome di test 2',
            description: 'Descrizione di test 2',
        }),
        GroupModel.create({
            groupId: '3',
            name: 'Tariffa di test 3',
            description: 'Descrizione di test 3',
        }),
        GroupModel.create({
            groupId: '4',
            name: 'Tariffa di test 4',
            description: 'Descrizione di test 4',
        }),
        GroupModel.create({
            groupId: '5',
            name: 'Tariffa di test 5',
            description: 'Descrizione di test 5',
        }),
    ];

    private get groups() {
        return this._groups.map(elem => {
            const snapshot = getSnapshot(elem);
            return GroupModel.create({ ...snapshot });
        });
    }

    private set groups(groups: Group[]) {
        this._groups = groups;
    }

    getItems(): Promise<GetItemsResponse<Group, never>> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    totalItems: this.groups.length,
                    items: this.groups
                });
            }, 3000);
        });
    }

    deleteGroup(group: Group): Promise<void> {
        return new Promise((resolve) => setTimeout(
            () => {
                this.groups = this.groups.filter(elem => elem.groupId !== group.groupId);
                resolve();
            },
            3000
        ));
    }

    addGroup(group: CreateGroupData): Promise<Group> {
        return new Promise((resolve) => setTimeout(
            () => {
                const newGroup = GroupModel.create({
                    groupId: group.groupId,
                    name: group.name,
                    description: group.description,

                });
                this.groups = [
                    newGroup,
                    ...this.groups
                ];
                resolve(newGroup);
            },
            3000
        ));
    }

    updateGroup(oldGroupId: string, group: UpdateGroupData): Promise<void> {
        return new Promise((resolve) => setTimeout(
            () => {
                this.groups = this.groups.map((elem, index) => {
                    if (elem.groupId === oldGroupId) {
                        return GroupModel.create({
                            groupId: group.groupId,
                            name: group.name,
                            description: group.description,

                        });
                    } else {
                        return elem;
                    }
                });
                resolve();
            },
            3000
        ));
    }

}

export const mockGroupApiService = new MockGroupApiService('groups');