import { Instance, types } from "mobx-state-tree";


export enum ErrorCode {
    Generic = 0
}

export const ErrorModel = types.model({
    code: types.number,
    message: types.maybeNull(types.string)
});

export interface AppError extends Instance<typeof ErrorModel>{}