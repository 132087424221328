import { format } from "date-fns";

export const formatDate = (date: Date): string => format(date, 'dd/MM/yyyy');

export const formatDateAndTime = (date: Date): string => format(date, 'dd/MM/yyyy HH:mm');

export const formatTime = (date: Date): string => format(date, 'HH:mm');

export const formatSpacedPlate = (plate: string) => plate.length !== 7 ?
    plate :
    `${plate.substring(0, 2)} ${plate.substring(2, 5)} ${plate.substring(5, 7)}`;

export const formattedNowForReportFilename = (withSeconds = false): string => withSeconds ?
    format(Date.now(), 'yyyyMMdd_HHmmss') : format(Date.now(), 'yyyy_MM_dd');


export const formatCurrency = (currency: string, value: number | null): string =>
    value !== null ? `${value.toFixed(2)} ${currency}` : '';