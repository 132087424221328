import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import memoize from 'fast-memoize';
import { useIntl } from 'react-intl';

import { StyledInput } from '../input/styled-input';

import firstPageIcon from '../../../assets/images/icon-arrow-start.svg';
import previousPageIcon from '../../../assets/images/icon-arrow-left.svg';
import nextPageIcon from '../../../assets/images/icon-arrow-right.svg';
import lastPageIcon from '../../../assets/images/icon-arrow-end.svg';

import messages from './paged-list.messages';
import './page-selector.scss';

export const hasMorePagesIndicator = '...';

interface PageSelectorProps {

    itemsPerPage: number;
    currentPage: number;
    pagesCount: number;

    disableNavigation?: boolean;

    itemsPerPageChanged: (itemsPerPage: number) => void;
    showFirstPage: () => void;
    showPreviousPage: () => void;
    showNextPage: () => void;
    showLastPage: () => void;
    showPage: (pageNumber: number) => void;

}

export const PageSelector = (props: PageSelectorProps) => {

    const { formatMessage } = useIntl();

    const { currentPage, itemsPerPage, pagesCount, disableNavigation,
        itemsPerPageChanged, showFirstPage, showLastPage, showNextPage, showPreviousPage, showPage } = props;

    const canMoveBack = currentPage > 1 && !disableNavigation;
    const canMoveForward = currentPage < pagesCount && !disableNavigation;


    const [itemsPerPageInputValue, updateItemsPerPageInputValue] = useState(itemsPerPage);

    const handleItemsPerPageChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value, 10);
        if (!value) {
            updateItemsPerPageInputValue(0);
        } else if (!isNaN(value) && value < 100) {
            updateItemsPerPageInputValue(value);
        }
    }, []);

    const handleItemsPerPageBlur = useCallback(() => {
        if (!isNaN(itemsPerPageInputValue) && itemsPerPageInputValue > 0) {
            itemsPerPageChanged(itemsPerPageInputValue);
        } else {
            updateItemsPerPageInputValue(itemsPerPage);
        }
    }, [itemsPerPage, itemsPerPageChanged, itemsPerPageInputValue]);

    const onKeyPress = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            const target: any = e.target;
            target.blur();
        }
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handlePageSelected = useCallback(memoize((page: number) => () => showPage(page)), []);

    // Init UI page selectors array

    const [firstPage, lastPage, pages] = useMemo(
        () => calculatePageIndicators(currentPage, pagesCount),
        [currentPage, pagesCount]
    );


    return <div className='page-selector-row'>
        {pages.length > 0 && <>
            <span>{formatMessage(messages.rowsPerPageLabel)}</span>
            <StyledInput
                containerClassName='items-per-page-box'
                inputClassName='items-per-page-input'
                value={itemsPerPageInputValue}
                onChange={handleItemsPerPageChanged}
                onBlur={handleItemsPerPageBlur}
                onKeyPress={onKeyPress}
            />
            <img className={`change-page-arrow first${canMoveBack ? '' : ' disabled'}`}
                alt={formatMessage(messages.showFirstPageIconAlt)} src={firstPageIcon} onClick={canMoveBack ? showFirstPage : undefined} />
            <img className={`change-page-arrow previous'${canMoveBack ? '' : ' disabled'}`}
                alt={formatMessage(messages.showPreviousPageIconAlt)} src={previousPageIcon} onClick={canMoveBack ? showPreviousPage : undefined} />
            {firstPage > 1 && <span className='page-indicator'>{hasMorePagesIndicator}</span>}

            {pages.map(pageNumber => <span key={pageNumber} className={`page-indicator page-number${currentPage === pageNumber ? ' current' : ''}`}
                onClick={handlePageSelected(pageNumber)}>
                {pageNumber}
            </span>)}

            {lastPage < pagesCount && <span className='page-indicator'>{hasMorePagesIndicator}</span>}

            <img className={`change-page-arrow next${canMoveForward ? '' : ' disabled'}`}
                alt={formatMessage(messages.showNextPageIconAlt)} src={nextPageIcon} onClick={canMoveForward ? showNextPage : undefined} />
            <img className={`change-page-arrow last${canMoveForward ? '' : ' disabled'}`}
                alt={formatMessage(messages.showLastPageIconAlt)} src={lastPageIcon} onClick={canMoveForward ? showLastPage : undefined} />
        </>}


    </div>;
};

const calculatePageIndicators = (currentPage: number, pagesCount: number): [number, number, number[]] => {

    let firstPage: number, lastPage: number;

    if (pagesCount <= 7) {
        firstPage = 1;
        lastPage = pagesCount;
    } else {
        firstPage = currentPage - 3;
        lastPage = currentPage + 3;

        while (firstPage < 1) {
            firstPage++;
            lastPage++;
        }
        while (lastPage > pagesCount) {
            firstPage--;
            lastPage--;
        }

        if (firstPage > 1) {
            firstPage++;
        }
        if (lastPage < pagesCount) {
            lastPage--;
        }

    }
    return [
        firstPage,
        lastPage,
        pagesCount > 0 ? Array(lastPage - firstPage + 1).fill(0).map((_, index) => firstPage + index) : []
    ];
};