import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { PagedListCell, PagedListCellVariant } from '../../../../common/components/paged-list/paged-list-cell';
import { formatDate } from '../../../../common/utils/formatters';
import { Contract, ContractStatus, getContractTypes } from '../../../model/contract';

import IconDelete from '../../../../assets/images/icon-delete.svg';
import IconArrowRight from '../../../../assets/images/icon-arrow-right.svg';
import contractsMessages from '../../../contracts.messages';

interface ContractsTableItemProps {
    item: Contract;
    canUserDeleteItem: boolean;
    onItemClick?: (item: Contract) => void;
    onDeleteItemClick?: (item: Contract) => void;
}

export const ContractsTableItem = (props: ContractsTableItemProps) => {

    const intl = useIntl();
    const { formatMessage } = intl;

    const cellStyle = useMemo(() =>
        props.item.status === ContractStatus.ACTIVE ? PagedListCellVariant.Bold : undefined,
        [props.item.status]
    );

    const contractStatus = useMemo(() => {

        if(props.item.isBlocked && props.item.status !== ContractStatus.NOT_ACTIVE){
            return contractsMessages.contractBlockedState;
        }

        switch(props.item.status){
            case ContractStatus.ACTIVE:
                return contractsMessages.contractActiveState;
            case ContractStatus.NOT_ACTIVE:
                return contractsMessages.contractNotActiveState;
            case ContractStatus.EXPIRED:
                return contractsMessages.contractExpiredState;
            default:
                throw new Error('unsupported value');
        }
    }, [props.item.isBlocked, props.item.status]);

    const contractType = useMemo(() => {
        const type = getContractTypes(intl).find(elem => elem.id === props.item.type);
        if(type){
            return type.label;
        } else {
            console.error(`Unsupported value: ${props.item.type}`);
            return '-';
        }
    }, [intl, props.item.type]);

    const onDeleteItemClick: React.MouseEventHandler<HTMLImageElement> = useCallback(
        (ev) => {
            if(props.onDeleteItemClick) {
                props.onDeleteItemClick(props.item);
                ev.stopPropagation();
                return false;
            } else {
                return true;
            }
        },
        [props]
    );

    const onItemClick = useCallback(
        () => props.onItemClick && props.onItemClick(props.item),
        [props]
    );

    return <tr className={"contracts-td"} onClick={onItemClick}>
        <PagedListCell variant={cellStyle} isTextNotCapitalized={true}>{props.item.contractId}</PagedListCell>
        <PagedListCell variant={cellStyle}>{contractType}</PagedListCell>
        <PagedListCell variant={cellStyle}>{formatMessage(contractStatus)}</PagedListCell>
        <PagedListCell variant={cellStyle}>
            {`${formatDate(props.item.validityPeriod.from)} - ${formatDate(props.item.validityPeriod.to)}`}
        </PagedListCell>
        <PagedListCell variant={cellStyle}>{props.item.kwhDelivered.toFixed(2)}</PagedListCell>
        <PagedListCell variant={cellStyle}>{props.item.chargesCount.toString()}</PagedListCell>
        <PagedListCell variant={cellStyle}>{props.item.costsAmount.toFixed(2)}</PagedListCell>
        <PagedListCell variant={cellStyle}>
            {`${props.item.inflation.baseInflation.toFixed(2)}${props.item.inflation.hasFareSpecificInflation ? '*' : ''}`}
        </PagedListCell>
        <PagedListCell variant={cellStyle}>{(props.item.earningsAmount - props.item.costsAmount).toFixed(2)}</PagedListCell>
        <td className='actions'>
            {
                props.canUserDeleteItem
                    && <img src={IconDelete} alt="" onClick={onDeleteItemClick} className='remove-item-icon' />
            }
            <img src={IconArrowRight} alt="" className='arrow-icon' />
        </td>
    </tr>;
};