import React from 'react';
import { useIntl } from 'react-intl';
import { SortableTableHeader } from '../../../common/components/paged-list/table-header-sortable';
import messages from '../../../common/messages/list.messages';
import { OrderManagerProps } from '../../../common/models/order-criteria';

interface TransactionTableHeaderProps extends OrderManagerProps {
    showActions: boolean;
}

export const TransactionTableHeader = (props: TransactionTableHeaderProps) => {
    const { formatMessage } = useIntl();

    return <tr className='transaction-th'>
        <SortableTableHeader {...props} sortKey='transactionDate'
            label={formatMessage(messages.tableHeaderDateAndTime)} />
        <td>{formatMessage(messages.tableHeaderCPO)}</td>
        <SortableTableHeader {...props} sortKey='amountDue'
            label={formatMessage(messages.tableHeaderPaymentDebit, { currency: '€' })} />
        <SortableTableHeader {...props} sortKey='amountCollected'
            label={formatMessage(messages.tableHeaderPaymentCollections, { currency: '€' })} />
        <td>{formatMessage(messages.tableHeaderPaymentEarnings, { currency: '€' })}</td>
        {props.showActions && <td>{formatMessage(messages.tableHeaderActions)}</td>}
    </tr>;
};