import { Menu, MenuItem } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import iconOptions from '../../../assets/images/icon-options.svg';
import messages from '../../messages/action.messages';

import './actions-menu.scss';

export interface Action {
    label: string;
    execute: () => void;
}

interface ActionMenuProps {
    actions: Action[];
    icon?: string;
}

export const ActionsMenu = ({ actions, icon }: ActionMenuProps) => {

    const { formatMessage } = useIntl();

    const anchorRef = React.useRef(null);
    const [isOpen, setOpened] = useState(false);

    const executeAction = useCallback((action: Action) => (ev: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        setOpened(false);
        action.execute();
        ev.stopPropagation();
        return false;
    }, [setOpened]);

    const toggleMenu = useCallback(
        (ev: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
            setOpened(!isOpen);
            ev.stopPropagation();
            return false;
        },
        [isOpen]
    );

    const onClickAway = useCallback(() => setOpened(false), []);

    return <div>

        <img ref={anchorRef}
            className='actions-icon'
            aria-controls="actions-menu"
            aria-haspopup="true"
            src={icon ?? iconOptions}
            alt={formatMessage(messages.optionsIconAlt)}
            onClick={toggleMenu} />
        <Menu id="actions-menu" open={isOpen} anchorEl={anchorRef.current} onClick={onClickAway}>
            {actions.map((action, index) => <MenuItem key={index} onClick={executeAction(action)}>{action.label}</MenuItem>)}
        </Menu>
    </div>;
};