import React from 'react';

import './shadow-container.scss';

export interface ShadowContainerProps {
    children: React.ReactNode;
    className?: string;
}

export const ShadowContainer = (props: ShadowContainerProps) => {
    return <div className={`shadow-container ${props.className ?? ''}`}>
        {props.children}
    </div>;
};