import {
    Group as ApiGroup,
    CreateGroupData as ApiCreateGroupData,
    CreateGroupData as ApiUpdateGroupData,
} from "../../../_generated/api";
import { Group, GroupModel } from "../../model/group";

import { CreateGroupData, UpdateGroupData } from "./group-request";


export const parseGroupFromApiModel = (apiGroup: ApiGroup): Group => {
    return GroupModel.create({
        groupId: apiGroup.id,
        name: apiGroup.name,
        description: apiGroup.description,
    });
};


export const parseCreateGroupDataToApiModel = (group: CreateGroupData): ApiCreateGroupData => {
    return {
        name: group.name,
        description: group.description,

    };
};

export const parseUpdateGroupDataToApiModel = (group: UpdateGroupData): ApiUpdateGroupData => parseCreateGroupDataToApiModel(group);
