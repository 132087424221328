import React, { ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { IAnyType, Instance, SnapshotOrInstance } from 'mobx-state-tree';

import { AppButtonProps, AppButtonVariant } from '../../../../common/components/button/app-button';
import { PagedList } from '../../../../common/components/paged-list/paged-list';
import { ButtonRow } from '../../../../common/components/button-row/button-row';

import { UserRole } from '../../../../common/models/user';
import { OrderManagerProps } from '../../../../common/models/order-criteria';
import { useStore } from '../../../../common/stores/root-store';
import { PagedListStoreModel } from '../../../../common/stores/paged-list/paged-list-store';

import commonMessages from '../../../../common/messages/common.messages';

interface AccordionPagedListProps<ItemsType extends IAnyType, FiltersType extends IAnyType, SummaryType extends IAnyType> {
    isAdmin: boolean;

    columnsCount: number;
    store: PagedListStoreModel<ItemsType, FiltersType, SummaryType>;

    tableClass?: string;
    tableTitle?: string;

    renderTableHeader: (orderProps: OrderManagerProps) => ReactNode;
    renderItem: (item: Instance<ItemsType>, index: number) => ReactNode;

    onAddNewButtonClick: () => void;
    createButtonEnabled: boolean;

    openItemDetail?: (item: SnapshotOrInstance<ItemsType>) => void;
}

export const AccordionPagedList = observer(<ItemsType extends IAnyType, FiltersType extends IAnyType, SummaryType extends IAnyType>(
    props: AccordionPagedListProps<ItemsType, FiltersType, SummaryType>) => {

    /* ---------------- Setup ---------------- */

    const { userStore } = useStore();

    const intl = useIntl();
    const { formatMessage } = intl;

    /* ---------------- Render page elements ---------------- */

    const renderTableFooter = useCallback(() => {
        const buttons: AppButtonProps[] = [];


        if (userStore.user?.role !== UserRole.Operator) {
            buttons.push({
                label: formatMessage(commonMessages.addNew),
                variant: AppButtonVariant.Primary,
                onClick: props.onAddNewButtonClick,
                disabled: !props.createButtonEnabled
            });
        }
        return <ButtonRow buttons={buttons} />;
    }, [
        formatMessage, props.createButtonEnabled,
        props.onAddNewButtonClick,
        userStore.user?.role
    ]);

    /* ---------------- Layout ---------------- */

    return <div className='page'>

        <PagedList
            tableClass={`section-table ${props.tableClass ?? ''}`}
            columnsCount={props.columnsCount}
            store={props.store}
            renderHeader={() => <></>}
            renderTableHeader={props.renderTableHeader}
            renderTableRow={props.renderItem}
            renderTableFooter={renderTableFooter}
            openItemDetail={props.openItemDetail}
        />



    </div>;
});