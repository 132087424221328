import { defineMessages } from 'react-intl';

export default defineMessages({
    "orderByLabel": {
        "id": "containers.paged-list.orderByLabel",
        "defaultMessage": "Ordina per:",
        "description": ""
    },
    "rowsPerPageLabel": {
        "id": "containers.paged-list.rowsPerPageLabel",
        "defaultMessage": "Righe per pagina",
        "description": ""
    },
    "calendarIconAlt": {
        "id": "containers.paged-list.calendarIconAlt",
        "defaultMessage": "Icona del calendario",
        "description": ""
    },
    "orderSelectArrowIconAlt": {
        "id": "containers.paged-list.orderSelectArrowIconAlt",
        "defaultMessage": "Icona di selezione del criterio di ordinamento",
        "description": ""
    },
    "showFirstPageIconAlt": {
        "id": "containers.paged-list.showFirstPageIconAlt",
        "defaultMessage": "Mostra prima pagina",
        "description": ""
    },
    "showPreviousPageIconAlt": {
        "id": "containers.paged-list.showPreviousPageIconAlt",
        "defaultMessage": "Mostra pagina precedente",
        "description": ""
    },
    "showNextPageIconAlt": {
        "id": "containers.paged-list.showNextPageIconAlt",
        "defaultMessage": "Mostra prossima pagina",
        "description": ""
    },
    "checkIconAlt": {
        "id": "containers.paged-list.checkIconAlt",
        "defaultMessage": "Elemento selezionato",
        "description": ""
    },
    "showLastPageIconAlt": {
        "id": "containers.paged-list.showLastPageIconAlt",
        "defaultMessage": "Mostra ultima pagina",
        "description": ""
    },
    "exportIconAlt": {
        "id": "containers.paged-list.exportIconAlt",
        "defaultMessage": "Icona esportazione report",
        "description": ""
    },
    "exportButtonMessage": {
        "id": "containers.paged-list.exportButtonMessage",
        "defaultMessage": "Esporta report",
        "description": ""
    },
    "listErrorMessage": {
        "id": "containers.paged-list.listErrorMessage",
        "defaultMessage": "Errore nel caricamento dell'elenco",
        "description": ""
    },
    "listErrorReloadAlt": {
        "id": "containers.paged-list.listErrorReloadAlt",
        "defaultMessage": "Ritenta caricamento",
        "description": ""
    },
    "emptyListMessage": {
        "id": "container.paged-list.emptyListMessage",
        "defaultMessage": "Nessun risultato corrisponde ai filtri impostati",
        "description": ""
    },
    "empyListStopAlt": {
        "id": "Container.paged-listMessages.emptyListStopAlt",
        "defaultMessage": "Nessun elemento trovato",
        "description": ""
    },
    "selectIconAltText": {
        "id": "common.components.filters.selectIconAltText",
        "defaultMessage": "Icona menù a tendina",
        "description": "Testo alternativo (accessibility) della freccia mostrata all'interno del menù a tendina per nascondere il menu"
    }
});