import React from 'react';
import { AppButton, AppButtonProps } from '../button/app-button';
import './button-row.scss';

export interface ButtonRowProps {
    buttons: AppButtonProps[]
}

export const ButtonRow = (props: ButtonRowProps) => {
    return <div className='button-row'>
        {
            props.buttons.map((buttonProp, index) => <AppButton {...buttonProp} key={`button_${index}`} />)
        }
    </div>;
};