import React from 'react';
import { useIntl } from 'react-intl';
import { SortableTableHeader } from '../../../common/components/paged-list/table-header-sortable';
import messages from '../../../common/messages/list.messages';
import { OrderManagerProps } from '../../../common/models/order-criteria';

interface AdminTableHeaderProps extends OrderManagerProps {}

export const AdminTableHeader = (props: AdminTableHeaderProps) => {
    const { formatMessage } = useIntl();
    return <tr className='admin-th'>
        <SortableTableHeader {...props} sortKey='name'
            label={formatMessage(messages.tableHeaderName)} />
        <td>{formatMessage(messages.tableHeaderCountry)}</td>
        <td>{formatMessage(messages.tableHeaderRole)}</td>
        <td>{formatMessage(messages.tableHeaderEmail)}</td>
        <td>{formatMessage(messages.tableHeaderContactNumber)}</td>
        <td>{formatMessage(messages.tableHeaderActions)}</td>
    </tr>
    ;
};