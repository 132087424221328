import { MessageDescriptor } from "react-intl";
import { Currency as ApiCurrency } from "../../_generated/api";
import commonMessages from "../messages/common.messages";

export enum Currency {
    EURO = 'EUR'
}

export function fromCurrencyToMessageDescriptor(currency: Currency): MessageDescriptor {
    switch(currency){
        case Currency.EURO:
            return commonMessages.euroCurrency;
    }
}

export function fromCurrencyToSymbol(currency: Currency): string {
    switch(currency){
        case Currency.EURO:
            return '€';
    }
}

export function parseCurrencyFromApiModel(currency: ApiCurrency): Currency {
    switch(currency){
        case ApiCurrency.Eur:
            return Currency.EURO;
    }
}

export function parseCurrencyToApiModel(currency: Currency): ApiCurrency {
    switch(currency){
        case Currency.EURO:
            return ApiCurrency.Eur;
    }
}