import React, { useCallback } from 'react';

import './styled-inputs.scss';

export interface StyledTextAreaProps {
    initialValue?: string;
    onTextChange?: (text: string) => void;
    rows?: number;
    label?: string;
    readOnly?: boolean;
    placeholder?: string;
}

export const StyledTextArea = (props: StyledTextAreaProps) => {
    
    const onTextChange: React.ChangeEventHandler<HTMLTextAreaElement> = useCallback((event) => 
        props.onTextChange && props.onTextChange(event.currentTarget.value)
    , [props]);
    
    return <div className='styled-text-area'>
        {props.label && <span>{props.label}</span>}
        <textarea
            defaultValue={props.initialValue}
            onChange={onTextChange}
            rows={props.rows ?? 3}
            readOnly={props.readOnly}
            placeholder={props.placeholder}
        />
    </div>;
};