import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { OrderManagerProps, SortOrder } from '../../models/order-criteria';

import iconSortingNone from '../../../assets/images/icon-sorting-none.svg';
import iconSortingAsc from '../../../assets/images/icon-sorting-increasing.svg';
import iconSortingDesc from '../../../assets/images/icon-sorting-decreasing.svg';

import commonMessages from '../../messages/common.messages';

import './table-header-sortable.scss';

interface SortableTableHeaderProps extends OrderManagerProps {
    label: string;
    sortKey: string;
}

export const SortableTableHeader = ({ label, selectedOrder, sortKey, setOrder }: SortableTableHeaderProps) => {

    const sortOrder = selectedOrder && selectedOrder.key === sortKey ? selectedOrder.sortOrder : null;
    const { formatMessage } = useIntl();


    const onOrderArrowClick = useCallback(() => {
        setOrder(sortKey);
    }, [sortKey, setOrder]);
    return <td>
        <div className='sortable-column-header'>

            <span>
                {label}
            </span>

            <div className='sort-arrow-container' onClick={onOrderArrowClick}>
                {sortOrder == null &&
                    <img src={iconSortingNone} alt={formatMessage(commonMessages.sortableColumnNoneAlt)} />}
                {sortOrder == SortOrder.ASC &&
                    <img src={iconSortingAsc} alt={formatMessage(commonMessages.sortableColumnAscAlt)} />}
                {sortOrder == SortOrder.DESC &&
                    <img src={iconSortingDesc} alt={formatMessage(commonMessages.sortableColumnDescAlt)} />}
            </div>
        </div>

    </td>;
};