import { cast, flow, getEnv, getSnapshot, IAnyType, IMSTArray, ReferenceOptions, SnapshotOrInstance, types, } from "mobx-state-tree";
import { DEFAULT_PAGE_ITEMS } from "../../config/constants";
import { AppError, ErrorCode, ErrorModel } from "../../models/app-error";
import { nextSortOrder, OrderCriteria, OrderCriteriaModel, SortOrder } from "../../models/order-criteria";
import ApiError from "../../api/api-error";
import { ListApiService } from "../../api/items-list/list-api-service";
import { CheckedListState } from "./checked-list-state";
import { GlobalStore } from "../global-store";


export interface PagedListStoreConfig<ListItemType extends IAnyType, FilterType extends IAnyType, SummaryType extends IAnyType> {

    type: ListItemType;
    filterType?: FilterType;
    summaryType?: SummaryType;
    apiService: ListApiService<SnapshotOrInstance<ListItemType>, SnapshotOrInstance<FilterType>, SnapshotOrInstance<SummaryType>>;

    filters?: SnapshotOrInstance<FilterType>;
    orderCriteria?: any[];
    summaryData?: SnapshotOrInstance<SummaryType>;

    typeLabel: string;
    defaultOrderCriteria?: OrderCriteria;
    selectedItemReferenceOptions?: ReferenceOptions<ListItemType>;
}

export interface PagedListStoreModel<ListItemType extends IAnyType, FilterType extends IAnyType, SummaryType extends IAnyType> {

    pageNumber: number;
    totalItems: number;
    currentPageItems: IMSTArray<ListItemType>;
    pagesCount: number;
    itemsPerPage: number;

    isLoadingItems: boolean;
    error: AppError | null;

    isDownloadingReport: boolean;

    filtersCollapsed: boolean;

    orderCriteria?: OrderCriteria;

    filters?: SnapshotOrInstance<FilterType>;

    summaryData?: SnapshotOrInstance<SummaryType>;

    selectedItem: SnapshotOrInstance<ListItemType> | null;

    checkedItems: SnapshotOrInstance<ListItemType>[];

    setPage: (pageNumber: number) => void;
    firstPage: () => void;
    previousPage: () => void;
    nextPage: () => void;
    lastPage: () => void;
    updateItemsPerPage: (newValue: number) => void;

    downloadReport: () => void;

    selectOrderCriteria: (orderCriteriaId: string) => void;
    applyFilters: () => void;
    reload: () => void;
    init: () => void;
}


export const createPagedListStoreModel = function
    <ListItemType extends IAnyType, FilterType extends IAnyType, SummaryType extends IAnyType>(config: PagedListStoreConfig<ListItemType, FilterType, SummaryType>) {
    const { filterType, filters, type, apiService, summaryType } = config;
    return types
        .model({
            pageNumber: 1,
            totalItems: 0,
            orderCriteria: types.maybe(OrderCriteriaModel),
            currentPageItems: types.array(type),
            itemsPerPage: DEFAULT_PAGE_ITEMS,
            isLoadingItems: false,
            error: types.maybeNull(ErrorModel),
            filters: filterType != null && filters != null ? types.optional(filterType, filters) : types.undefined,
            summaryData: summaryType != null ? types.maybe(summaryType) : types.undefined,
            selectedItem: types.maybeNull(types.reference(type, config.selectedItemReferenceOptions)),
            filtersCollapsed: false,
            isDownloadingReport: false,
            checkedItems: types.array(types.reference(type))
        })
        .views(self => {
            const getPagesCount = () => self.itemsPerPage === 0 ? 0 : Math.ceil(self.totalItems / self.itemsPerPage);
            const isItemChecked = (item: SnapshotOrInstance<ListItemType>): boolean => {
                return self.checkedItems.indexOf(getSnapshot(item)) !== -1;
            };
            const getCheckedListState = (): CheckedListState => {
                if (self.checkedItems.length === 0) {
                    return CheckedListState.None;
                } else if (self.checkedItems.length === self.totalItems) {
                    return CheckedListState.All;
                } else {
                    return CheckedListState.Some;
                }
            };
            return {
                get pagesCount() { return getPagesCount(); },
                get checkedListState() { return getCheckedListState(); },
                isItemChecked,
                afterCreate() { return; }
            };
        }).actions(self => {

            const cache = new Map<number, ListItemType[]>();
            const clearCache = () => {
                cache.clear();
                self.checkedItems = cast([]);
            };

            const globalStore: GlobalStore = getEnv(self).globalStore;

            const loadItems = flow(function* () {
                self.error = null;
                const cachedItems = cache.get(self.pageNumber);
                if (cachedItems !== undefined) {
                    self.currentPageItems = cast(cachedItems);
                } else {
                    self.isLoadingItems = true;
                    self.currentPageItems = cast([]);
                    try {

                        const response = yield apiService.getItems(
                            self.pageNumber, self.filters, self.orderCriteria, self.itemsPerPage
                        );
                        self.currentPageItems = cast(response.items);
                        self.summaryData = cast(response.summary);

                        cache.set(self.pageNumber, response.items);

                        if (self.totalItems !== response.totalItems) {
                            self.totalItems = response.totalItems;
                            globalStore.onDataUpdated();
                        }

                    } catch (error) {
                        console.log('Error occured: ', error);
                        self.error = ErrorModel.create({
                            code: error instanceof ApiError ? error.code : ErrorCode.Generic
                        });
                    }
                    self.isLoadingItems = false;
                }

            });

            const setPage = flow(function* (pageNumber: number) {
                self.pageNumber = pageNumber;
                return yield loadItems();
            });

            return {
                init() {
                    setPage(self.pageNumber);
                },
                setPage,
                firstPage: flow(function* () {
                    return yield setPage(1);
                }),
                previousPage: flow(function* () {
                    if (self.pageNumber > 1) {
                        yield setPage(self.pageNumber - 1);
                    }
                }),
                nextPage: flow(function* () {
                    if (self.pageNumber < self.pagesCount) {
                        return yield setPage(self.pageNumber + 1);
                    }
                }),
                lastPage() {
                    this.setPage(self.pagesCount);
                },
                reload() {
                    clearCache();

                    loadItems();
                },
                updateItemsPerPage(newValue: number) {

                    if (newValue !== self.itemsPerPage) {
                        clearCache();

                        self.itemsPerPage = newValue;
                        this.firstPage();
                    }
                },
                selectOrderCriteria(key: string, sortOrder?: SortOrder) {

                    clearCache();

                    const order = sortOrder ??
                        nextSortOrder(key === self.orderCriteria?.key ? self.orderCriteria?.sortOrder : undefined);

                    self.orderCriteria = order != null
                        ? OrderCriteriaModel.create({ key, sortOrder: order })
                        : undefined;

                    this.reload();
                },
                openItemDetail(item: SnapshotOrInstance<ListItemType>) {
                    self.selectedItem = item;
                    // window.location.assign(`#/${config.typeLabel}/detail`);
                },
                clearSelectedItem() {
                    self.selectedItem = null;
                },
                downloadReport: flow(function* () {
                    self.isDownloadingReport = true;
                    try {
                        yield apiService.downloadReport(self.filters);
                        // eslint-disable-next-line no-empty
                    } catch { }
                    self.isDownloadingReport = false;
                }),
                applyFilters() {
                    clearCache();
                    self.pageNumber = 1;
                    loadItems();
                },
                toggleItemCheck(item: SnapshotOrInstance<ListItemType>) {
                    if (self.isItemChecked(item)) {
                        self.checkedItems.remove(getSnapshot(item));
                    } else {
                        self.checkedItems.push(item);
                    }
                },
                toggleCheckAll() {
                    if (self.checkedListState === CheckedListState.All) {
                        self.checkedItems = cast([]);
                    } else {
                        self.currentPageItems.forEach(item => {
                            if (!self.isItemChecked(item)) {
                                self.checkedItems.push(item);
                            }
                        });
                    }
                },
                onItemDeleted() {
                    if(self.pageNumber === self.pagesCount && self.currentPageItems.length === 1) {
                        self.pageNumber -= 1;
                    }
                    this.reload();       
                },
            };
        });
};

export const createPagedListStoreInstance = function
    <ListItemType extends IAnyType, FilterType extends IAnyType, SummaryData extends IAnyType>(
        config: PagedListStoreConfig<ListItemType, FilterType, SummaryData>): PagedListStoreModel<ListItemType, FilterType, SummaryData> {
            console.log('config.defaultOrderCriteria', config.defaultOrderCriteria);
    //@ts-ignore
    return createPagedListStoreModel(config).create({
        orderCriteria: config.defaultOrderCriteria !== undefined ? config.defaultOrderCriteria : undefined
    });
};
