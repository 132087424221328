import React from 'react';
import { Dialog } from '@material-ui/core';

import { Notification } from '../../models/notification';

import './notification-dialog.scss';

interface NotificationDialogProps {
    notification: Notification | null;
    onClose: () => void;
}

export const NotificationDialog = (props: NotificationDialogProps) => {
    const { notification } = props;
    const isNotificationAvailable = notification !== null && notification !== undefined;
    return <Dialog open={isNotificationAvailable} classes={{
        container: 'notification-dialog-container',
        paper: 'notification-dialog'
    }} onClose={props.onClose}>
        {isNotificationAvailable &&
            <>
                <img src={notification?.icon} alt='' />
                <h3>{notification?.message}</h3>
            </>
        }
    </Dialog>;
};