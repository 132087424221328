import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { onSnapshot } from 'mobx-state-tree';

import { SectionPagedList } from '../../common/section-paged-list/section-paged-list';
import { ContractDetailsSectionHeader } from './contract-details-section-header';
import { ContractDetailsTableHeader } from './contract-details-table-header';
import { ContractDetailsSummary } from './contract-details-summary/contract-details-summary';
import { ManageContractDialog } from '../../contracts/dialog/manage-contract-dialog/manage-contract-dialog';
import { ManageBlockContractDialog } from '../dialog/manage-block-contract-dialog/manage-block-contract-dialog';
import { ContractDetailsItem } from './contract-details-item';
import { DeleteFareDialog } from '../dialog/delete-fare-dialog/delete-fare-dialog';
import { ManageFeeDialog } from '../dialog/manage-fee-dialog/manage-fee-dialog';

import { OrderCriteriaModel, OrderManagerProps, SortOrder } from '../../../../common/models/order-criteria';
import { useStore } from '../../../../common/stores/root-store';
import { UserRole } from '../../../../common/models/user';
import { ContractInfo } from '../../../model/contract-info';
import { ApiRequestState } from '../../../../common/api/api-request';
import { createServerErrorNotification, createSuccessNotification } from '../../../../common/stores/notifications-store';
import { Fare, FareOrderCriteria } from '../../../model/fare';
import { ContractStatus, ContractType } from '../../../model/contract';
import { FareInfo } from '../../../model/fare-info';
import { FareError, FareListStoreModel } from '../../../store/fare-store';

import contractDetailsMessages from '../../../contract-details.messages';

import './contract-details-paged-list.scss';
import { AccordionList } from '../../common/accordion-paged-list/accordion-list';

interface ContractDetailsPagedListProps {
    isAdmin: boolean;
}

export const ContractDetailsPagedList = observer((props: ContractDetailsPagedListProps) => {

    /* ---------------- Setup ---------------- */
    const { providerStore, userStore, notificationStore } = useStore();
    const intl = useIntl();

    const { formatMessage } = intl;

    const contractsStore = providerStore.contractsStore;
    const fareStore = contractsStore?.fareStore;
    const groupStore = contractsStore?.groupStore;

    const selectedProvider = providerStore.selectedItem;
    const selectedContract = providerStore.contractsStore?.selectedItem;

    const selectedGroup = groupStore?.selectedItem;

    if (!contractsStore || !fareStore || !groupStore || !selectedProvider || !selectedContract) {
        return <div />;
    }

    useEffect(() => {
        groupStore?.initGroupStore(selectedProvider, selectedContract);
        groupStore.reload();
    }, [groupStore, selectedContract, selectedProvider]);


    if (selectedContract.type !== ContractType.HUBJECT_NGP) {

        // Use default group id
        const defaultGroup = groupStore.currentPageItems[0];
        useEffect(() => {
            fareStore?.initFareStore(selectedProvider, selectedContract, defaultGroup);
            fareStore.reload();
        }, [fareStore, selectedContract, selectedProvider, selectedGroup, defaultGroup]);
    }


    const contractInfo: ContractInfo = useMemo(() => ({
        status: selectedContract.status,
        type: selectedContract.type,
        startDate: selectedContract.validityPeriod.from,
        endDate: selectedContract.validityPeriod.to,
        baseInflation: selectedContract.inflation.baseInflation,
        operatorNotes: selectedContract.operatorNotes,
        contactPerson: selectedContract.contactPerson,
        ngpContractFile: selectedContract.ngpContractFile,
        ngpContractFilename: selectedContract.ngpContractFilename
    }), [
        selectedContract.status, selectedContract.type,
        selectedContract.validityPeriod.from, selectedContract.validityPeriod.to,
        selectedContract.inflation.baseInflation,
        selectedContract.operatorNotes,
        selectedContract.contactPerson,
        selectedContract.ngpContractFile,
        selectedContract.ngpContractFilename
    ]);


    /* ---------------- Update contract dialog  ---------------- */
    const [showUpdateContractDialog, setShowUpdateContractDialog] = useState(false);
    const onUpdateButtonClick = useCallback(() => setShowUpdateContractDialog(true), []);
    const onCloseUpdateContractDialog = useCallback(() => setShowUpdateContractDialog(false), []);
    const onConfirmUpdateContractSubmitted = useCallback((contractInfo: ContractInfo) => {
        const dispose = onSnapshot(contractsStore, (snapshot) => {
            let managed = false;

            if (snapshot?.manageContractRequestState === ApiRequestState.COMPLETED) {
                notificationStore.showNotification(createSuccessNotification(intl));
                managed = true;
                setShowUpdateContractDialog(false);

                groupStore?.initGroupStore(selectedProvider, selectedContract);
                groupStore.reload();

            } else if (snapshot?.manageContractRequestState === ApiRequestState.FAILED) {
                notificationStore.showNotification(createServerErrorNotification(
                    intl
                ));
                managed = true;
            }

            if (managed) {
                dispose();
                contractsStore.clearManageContractRequestState();
                contractsStore.clearContractError();
            }
        });
        contractsStore.updateContract(selectedContract, contractInfo);
    }, [contractsStore, selectedContract, notificationStore, intl]);

    /* ---------------- Manage block contract dialog  ---------------- */
    const [showManageBlockContractDialog, setShowManageBlockContractDialog] = useState(false);
    const onManageBlockContractButtonClick = useCallback(() => setShowManageBlockContractDialog(true), []);
    const onCloseManageBlockContractDialog = useCallback(() => setShowManageBlockContractDialog(false), []);
    const onConfirmManageBlockContractSubmitted = useCallback(() => {
        const dispose = onSnapshot(contractsStore, (snapshot) => {
            let managed = false;

            if (snapshot?.manageBlockContractRequestState === ApiRequestState.COMPLETED) {
                notificationStore.showNotification(createSuccessNotification(intl));
                managed = true;
            } else if (snapshot?.manageBlockContractRequestState === ApiRequestState.FAILED) {
                notificationStore.showNotification(createServerErrorNotification(
                    intl
                ));
                managed = true;
            }

            if (managed) {
                dispose();
                setShowManageBlockContractDialog(false);
                contractsStore.clearManageBlockContractRequestState();
                contractsStore.clearContractError();
            }
        });

        if (selectedContract.isBlocked) {
            contractsStore.unlockContract(selectedContract);
        } else {
            contractsStore.blockContract(selectedContract);
        }

    }, [contractsStore, selectedContract, notificationStore, intl]);

    /* ---------------- Delete fare dialog ---------------- */
    const [fareToDelete, setFareToDelete] = useState<Fare>();
    const [showFareToDeleteDialog, setShowFareToDeleteDialog] = useState(false);
    const onDeleteFareClick = useCallback((fare: Fare) => {
        setFareToDelete(fare);
        setShowFareToDeleteDialog(true);
    }, []);
    const onCloseDeleteFareDialog = useCallback(
        () => setShowFareToDeleteDialog(false),
        []
    );
    const onConfirmDeleteFareSubmitted = useCallback(() => {
        const dispose = onSnapshot(fareStore, (snapshot) => {
            let managed = false;

            if (snapshot?.deleteFareRequestState === ApiRequestState.COMPLETED) {
                notificationStore.showNotification(createSuccessNotification(intl));
                managed = true;
                fareStore.onItemDeleted();
            } else if (snapshot?.deleteFareRequestState === ApiRequestState.FAILED) {
                notificationStore.showNotification(createServerErrorNotification(
                    intl
                ));
                managed = true;
            }

            if (managed) {
                dispose();
                setShowFareToDeleteDialog(false);
                fareStore.clearDeleteFareRequestState();
                fareStore.clearFareError();
            }
        });
        fareToDelete && fareStore.deleteFare(fareToDelete);
    }, [fareStore, fareToDelete, notificationStore, intl]);

    /* ---------------- Manage fare dialog  ---------------- */
    const [showManageFareDialog, setShowManageFareDialog] = useState(false);
    const [fareToUpdateOrDuplicate, setFareToUpdateOrDuplicate] = useState<Fare>();
    const [isUpdatingFare, setIsUpdatingFare] = useState(false);

    const onManageFareButtonClick = useCallback(
        (hasToUpdateFare: boolean) => (fare?: Fare) => {
            setFareToUpdateOrDuplicate(fare);
            setIsUpdatingFare(hasToUpdateFare);
            setShowManageFareDialog(true);
        }
        , []);



    const onCloseManageFareDialog = useCallback(() => {
        setShowManageFareDialog(false);
        setFareToUpdateOrDuplicate(undefined);
    }, []);

    const onConfirmManageFareSubmitted = useCallback((newFare: FareInfo) => {
        const dispose = onSnapshot(fareStore, (snapshot) => {
            let managed = false;

            if (snapshot?.manageFareRequestState === ApiRequestState.COMPLETED) {
                notificationStore.showNotification(createSuccessNotification(
                    intl,
                    isUpdatingFare
                        ? contractDetailsMessages.fareUpdatedSuccessMessage
                        : contractDetailsMessages.fareCreatedSuccessMessage
                ));
                managed = true;
                fareStore.reload();
                setShowManageFareDialog(false);
            } else if (snapshot?.manageFareRequestState === ApiRequestState.FAILED) {
                notificationStore.showNotification(createServerErrorNotification(
                    intl,
                    fareStore.fareError === FareError.FARE_WITH_SAME_ID_ALREADY_EXISTS
                        ? contractDetailsMessages.fareWithSameIdAlreadyExistsMessage
                        : undefined
                ));
                managed = true;
            }

            if (managed) {
                dispose();
                fareStore.clearManageFareRequestState();
                fareStore.clearFareError();
            }
        });

        if (isUpdatingFare) {
            fareToUpdateOrDuplicate && fareStore.updateFare(fareToUpdateOrDuplicate, newFare);
        } else {
            fareStore.addFare(newFare);
        }

    }, [fareStore, isUpdatingFare, notificationStore, intl, fareToUpdateOrDuplicate]);

    /* ---------------- Render page elements ---------------- */

    const renderHeader = useCallback(() => <ContractDetailsSectionHeader />, []);

    const renderTableHeader = useCallback((orderProps: OrderManagerProps) =>
        <ContractDetailsTableHeader {...orderProps} />, []);

    const renderSummary = useCallback(() => <ContractDetailsSummary
        userRole={userStore.user?.role ?? UserRole.Operator}
        contract={selectedContract}
        onUpdateButtonClick={onUpdateButtonClick}
        onBlockButtonClick={onManageBlockContractButtonClick}
    />, [onManageBlockContractButtonClick, onUpdateButtonClick, selectedContract, userStore.user?.role]);



    const renderItem = useCallback((fare: Fare, index: number) => {
        return <ContractDetailsItem
            key={index}
            item={fare}
            userRole={userStore.user?.role ?? UserRole.Operator}
            isContractNgp={false}
            contractBaseInflation={selectedContract.inflation.baseInflation}
            isContractExpired={selectedContract.status === ContractStatus.EXPIRED}
            onDuplicateFareClick={onManageFareButtonClick(false)}
            onUpdateFareClick={onManageFareButtonClick(true)}
            onDeleteFareClick={onDeleteFareClick}
            onItemClick={onManageFareButtonClick(true)}
        />;
    }, [
        onDeleteFareClick, onManageFareButtonClick,
        selectedContract.inflation.baseInflation,
        selectedContract.status,
        userStore.user?.role
    ]);



    const renderItemNgpContract = useCallback((fare: Fare, index: number) => {
        return <ContractDetailsItem
            key={index}
            item={fare}
            userRole={userStore.user?.role ?? UserRole.Operator}
            isContractNgp={true}
            contractBaseInflation={selectedContract.inflation.baseInflation}
            isContractExpired={selectedContract.status === ContractStatus.EXPIRED}
            onDuplicateFareClick={onManageFareButtonClick(false)}
            onUpdateFareClick={onManageFareButtonClick(false)}
            onDeleteFareClick={onDeleteFareClick}
            onItemClick={onManageFareButtonClick(true)}
        />;
    }, [
        onDeleteFareClick, onManageFareButtonClick,
        selectedContract.inflation.baseInflation,
        selectedContract.status,
        userStore.user?.role
    ]);


    const renderAccordionPagedList = groupStore.currentPageItems.map(group => {
        const fareStore = FareListStoreModel.create({
            orderCriteria: OrderCriteriaModel.create({
                key: FareOrderCriteria.ID,
                sortOrder: SortOrder.DESC
            })
        });
        fareStore.initFareStore(selectedProvider, selectedContract, group);
        fareStore.reload();

        return {
            title: group.name,
            groupId: group.groupId,
            store: fareStore,
        };
    });


    /* ---------------- Layout ---------------- */

    return <>

        {contractsStore.selectedItem?.type === ContractType.HUBJECT_NGP && (
            <AccordionList
                accordions={renderAccordionPagedList}
                columnsCount={10}
                renderAccordionHeader={renderHeader}
                renderTableHeader={renderTableHeader}
                renderItem={renderItemNgpContract}
                renderSummary={renderSummary}
                selectedContract={selectedContract}
                onManageFareButtonClick={onManageFareButtonClick}

            />
        )}

        {contractsStore.selectedItem?.type !== ContractType.HUBJECT_NGP && (

            <SectionPagedList
                isAdmin={props.isAdmin}
                store={fareStore}
                tableClass='contract-details-table'
                renderSectionHeader={renderHeader}
                renderSummary={renderSummary}
                renderTableHeader={renderTableHeader}
                renderItem={renderItem}
                columnsCount={10}
                createButtonEnabled={selectedContract.isNotExpired && selectedContract.type !== ContractType.HUBJECT_DYNAMIC}
                onAddNewButtonClick={onManageFareButtonClick(false)}
                tableTitle={formatMessage(contractDetailsMessages.faresTableTitle)}
            />

        )}


        <ManageContractDialog
            open={showUpdateContractDialog}
            onClose={onCloseUpdateContractDialog}
            onPrimaryButtonClick={onConfirmUpdateContractSubmitted}
            isOperationRunning={contractsStore.manageContractRequestState === ApiRequestState.RUNNING}
            provider={selectedProvider}
            contract={contractInfo}
            isUpdating
        />

        <ManageBlockContractDialog
            open={showManageBlockContractDialog}
            onClose={onCloseManageBlockContractDialog}
            onConfirmButtonClick={onConfirmManageBlockContractSubmitted}
            isBlocking={!selectedContract.isBlocked}
            isOperationRunning={contractsStore.manageBlockContractRequestState === ApiRequestState.RUNNING}
            contractId={selectedContract.contractId}
        />

        <DeleteFareDialog
            open={showFareToDeleteDialog}
            onClose={onCloseDeleteFareDialog}
            onConfirmButtonClick={onConfirmDeleteFareSubmitted}
            isOperationRunning={fareStore.deleteFareRequestState === ApiRequestState.RUNNING}
            fareId={fareToDelete?.fareId}
        />

        <ManageFeeDialog
            open={showManageFareDialog}
            onClose={onCloseManageFareDialog}
            onConfirmButtonClick={onConfirmManageFareSubmitted}
            isOperationRunning={fareStore.manageFareRequestState === ApiRequestState.RUNNING}
            isUpdating={isUpdatingFare}
            providerName={selectedProvider.cpoName}
            contract={selectedContract}
            fare={fareToUpdateOrDuplicate}
            canUpdateFare={
                selectedContract.type !== ContractType.HUBJECT_DYNAMIC
                && selectedContract.type !== ContractType.HUBJECT_NGP
                && selectedContract.isNotExpired
                && userStore.user?.role !== UserRole.Operator
            }
        />


    </>;

});