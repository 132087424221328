import React, { ChangeEventHandler, useCallback } from "react";
import { useIntl } from "react-intl";

import { StyledInput } from "../../../../../common/components/input/styled-input";
import { StyledTextArea } from "../../../../../common/components/input/styled-text-area";

import contractsMessages from '../../../../contracts.messages';

export interface OptionalInformationContentProps {
    contactPersonaName?: string
    onContactPersonNameChange: (name: string) => void

    contactPersonEmail?: string
    onContactPersonEmailChange: (name: string) => void

    contactPersonPhoneNumber?: string
    onContactPersonPhoneNumberChange: (name: string) => void

    notesForTheOperator?: string
    onNotesForTheOperatorChange: (name: string) => void
}

export const OptionalInformationContent = (props: OptionalInformationContentProps) => {
    
    const { formatMessage } = useIntl();
    
    const onNameChange: ChangeEventHandler<HTMLInputElement> = useCallback(
        (ev) => props.onContactPersonNameChange(ev.target.value),
        [props]
    );

    const onEmailChange: ChangeEventHandler<HTMLInputElement> = useCallback(
        (ev) => props.onContactPersonEmailChange(ev.target.value),
        [props]
    );

    const onPhoneNumberChange: ChangeEventHandler<HTMLInputElement> = useCallback(
        (ev) => props.onContactPersonPhoneNumberChange(ev.target.value),
        [props]
    );

    return <div className="optional-info-content">
        <span className="section-title">
            {formatMessage(contractsMessages.contractOptionalInformationSectionTitle)}
        </span>
        <div className="input-style-row">
            <StyledInput 
                label={formatMessage(contractsMessages.representativeNameLabel)}
                value={props.contactPersonaName ?? ''}
                onChange={onNameChange}
            />
            <StyledInput 
                label={formatMessage(contractsMessages.representativeEmailLabel)}
                value={props.contactPersonEmail ?? ''}
                onChange={onEmailChange}
            />
            <StyledInput 
                label={formatMessage(contractsMessages.representativePhoneNumberLabel)}
                value={props.contactPersonPhoneNumber ?? ''}
                onChange={onPhoneNumberChange}
            />
        </div>
        <StyledTextArea
            label={formatMessage(contractsMessages.notesForTheOperatorLabel)}
            initialValue={props.notesForTheOperator ?? ''}
            onTextChange={props.onNotesForTheOperatorChange}
        />
    </div>;
};