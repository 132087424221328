import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import { FareFeeTypeOptions } from "../fare-fee-type-options/fare-fee-type-options";
import { FareFeeData } from "./fare-fee-data";

import { FareFeeInfo, OtherFareFeeInfo } from "../../../../../model/fare-info";

import contractDetailsMessages from "../../../../../contract-details.messages";
import { FareFeeType } from "../../../../../model/fare";
import { StyledSelect } from "../../../../../../common/components/input/styled-select";
import { SelectItem } from "../../../../../../common/components/input/select-item";

interface AddFareFeeProps {
    fareFeeTypeSelected?: FareFeeType;
    onFareFeeTypeChange: (fareFeeTypeSelected?: FareFeeType) => void;
    canUpdateData: boolean;
}

const clearFareFeeId = '___clearFareId___';

const AddFareFee = (props: AddFareFeeProps) => {
    
    const { formatMessage } = useIntl();

    const otherFaresItems: SelectItem[] = useMemo(() => [
        {
            id: clearFareFeeId,
            label: formatMessage(contractDetailsMessages.emptyFareFeeType)
        },
        {
            id: FareFeeType.SESSION,
            label: formatMessage(contractDetailsMessages.sessionFareFeeType)
        },
        {
            id: FareFeeType.CHARGING,
            label: formatMessage(contractDetailsMessages.chargingFareFeeType)
        },
        {
            id: FareFeeType.PARKING,
            label: formatMessage(contractDetailsMessages.parkingFareFeeType)
        }
    ], [formatMessage]);

    const onItemSelected = useCallback((itemId: string) => {
        const fareFeeType = Object.values(FareFeeType).find(elem => elem === itemId);
        props.onFareFeeTypeChange(fareFeeType);
    }, [props]);

    return <div className="add-other-fare">
        <span>{formatMessage(contractDetailsMessages.addOtherFareLabel)}</span>
        <StyledSelect 
            items={otherFaresItems}
            selectedItemId={props.fareFeeTypeSelected ?? clearFareFeeId}
            onItemSelected={onItemSelected}
            disabled={!props.canUpdateData}
        />
    </div>;
};

export interface OtherFeeDataProps {
    otherFees?: OtherFareFeeInfo[];
    onOtherFeesChange: (otherFees?: OtherFareFeeInfo[]) => void;
    canUpdateData: boolean;
}

export const OtherFeeData = (props: OtherFeeDataProps) => {
    
    const { formatMessage } = useIntl();

    const addNewFareFee = useCallback((fareFeeTypeSelected?: FareFeeType) =>
        fareFeeTypeSelected && props.onOtherFeesChange([
            ...(props.otherFees ?? []),
            {
                type: fareFeeTypeSelected,
                fareFee: {}
            }
        ])
    , [props]);

    const onFareFeeTypeChange = useCallback((feeIndex: number) => (fareFeeType?: FareFeeType) => {
        if(fareFeeType) {
            props.onOtherFeesChange(props.otherFees?.map((elem, index) => {
                if(index === feeIndex) {
                    return {
                        type: fareFeeType,
                        fareFee: {}
                    };
                } else {
                    return elem;
                }
            }));
        } else {
            props.onOtherFeesChange(props.otherFees?.filter((_, index) => index !== feeIndex));
        }
    }, [props]);

    const onFareFeeChange = useCallback((feeIndex: number) => (fareFee: FareFeeInfo) => 
        props.onOtherFeesChange(props.otherFees?.map((elem, index) => {
            if(index === feeIndex) {
                return {
                    type: elem.type,
                    fareFee: fareFee
                };
            } else {
                return elem;
            }
        }))
    , [props]);
    
    /* ------- Layout ------- */
    const renderTitle = useCallback(() => formatMessage(
        contractDetailsMessages.fareOtherCostsSectionTitle
    ),[formatMessage]);

    const renderDescription = useCallback(() => formatMessage(
        contractDetailsMessages.fareOtherCostsSectionDescription 
    ),[formatMessage]);
    
    const renderFeeOptions = useCallback(() => {
        const items = props.otherFees?.map((elem, index) => {
            return <div key={index} className='other-fee-data'>
                <AddFareFee
                    fareFeeTypeSelected={elem.type}
                    onFareFeeTypeChange={onFareFeeTypeChange(index)} 
                    canUpdateData={props.canUpdateData} 
                />
                <FareFeeTypeOptions 
                    fareFeeType={elem.type}
                    fareFee={elem.fareFee}
                    onFareFeeChange={onFareFeeChange(index)}
                    canUpdateData={props.canUpdateData}
                />
            </div>;
        }) ?? [];
        if(props.canUpdateData) {
            return [
                ...items,
                <AddFareFee
                    key={'__addFare__'}
                    onFareFeeTypeChange={addNewFareFee} 
                    canUpdateData={props.canUpdateData}
                />
            ];
        } else {
            return items;
        }
    }, [addNewFareFee, onFareFeeChange, onFareFeeTypeChange, props.canUpdateData, props.otherFees]);

    
    const onSelectFareFeeChange = useCallback((isSelected: boolean) =>
        props.onOtherFeesChange(isSelected ? [] : undefined)
    , [props]);
    
    return <FareFeeData
        renderTitle={renderTitle}
        renderDescription={renderDescription}
        renderFeeOptions={renderFeeOptions}

        showFareFeeOptions={props.otherFees !== undefined}
        onSelectFareFeeChange={onSelectFareFeeChange}
        canUpdateData={!!props.canUpdateData}
    /> ;
};