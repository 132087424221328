import React from "react";
import { useIntl } from "react-intl";
import { AppButtonVariant } from "../../../../../common/components/button/app-button";
import { BaseDialog } from "../../../../../common/components/dialogs/base-dialog";
import commonMessages from "../../../../../common/messages/common.messages";
import contractsDetailsMessages from "../../../../contract-details.messages";

export interface ConfirmFareUpdateDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirmButtonClick: () => void;
    fareId: string;
}

export const ConfirmFareUpdateDialog = (props: ConfirmFareUpdateDialogProps) => {
    const { formatMessage } = useIntl();
    
    return <BaseDialog 
        title={formatMessage(contractsDetailsMessages.confirmFareUpdateDialogTitle, {fareId: props.fareId})}
        open={props.open}
        onClose={props.onClose}
        buttons={[
            {
                variant: AppButtonVariant.Dark,
                label: formatMessage(commonMessages.cancelButton),
                onClick: props.onClose
            },
            {
                variant: AppButtonVariant.Primary,
                label: formatMessage(commonMessages.confirmButton),
                onClick: props.onConfirmButtonClick
            },
        ]}
    >
        <span>{formatMessage(contractsDetailsMessages.confirmFareUpdateDialogMessage)}</span>
    </BaseDialog>;
};
