import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { SelectItem } from "../../../../../../common/components/input/select-item";
import { CheckedState, StyledCheckBox } from "../../../../../../common/components/input/styled-checkbox";
import { StyledSelect } from "../../../../../../common/components/input/styled-select";
import { DateIntervalModel, fromValidityDayToMessageDescriptor, ValidityDay } from "../../../../../../common/models/time";
import contractDetailsMessages from "../../../../../contract-details.messages";
import { FareValidityPeriod, FareValidityPeriodModel } from "../../../../../model/fare";
import { TimeIntervalSelector } from "./time-interval-selector";

export interface ValidityPeriodsDataProps {
    validityPeriods?: FareValidityPeriod[];
    onValidityPeriodsChange: (validityPeriods?: FareValidityPeriod[]) => void;
    canUpdateData: boolean;
}

export const ValidityPeriodsData = (props: ValidityPeriodsDataProps) => {
    
    const { formatMessage } = useIntl();

    const dayIntervalItems: SelectItem[] = useMemo(() => 
        Object.values(ValidityDay).map(elem => ({
            id: elem,
            label: formatMessage(fromValidityDayToMessageDescriptor(elem))
        }))
    , [formatMessage]);

    const [isValid24h, setIsValid24h] = useState(!props.validityPeriods || props.validityPeriods.length === 0);
    const onCheckedBoxClick = useCallback(() => {
        if(isValid24h){
            props.onValidityPeriodsChange([
                FareValidityPeriodModel.create({
                    day: ValidityDay.EVERYDAY,
                    timeInterval: DateIntervalModel.create({
                        from: (new Date()).setHours(0, 0),
                        to: (new Date()).setHours(23, 59)
                    })
                })
            ]);
        } else {
            props.onValidityPeriodsChange(undefined);
        }
    }, [isValid24h, props]);
    useEffect(() => {
        setIsValid24h(!props.validityPeriods || props.validityPeriods.length === 0);
    }, [props.validityPeriods]);

    const onAddTimeIntervalClick = useCallback(() => {
        props.validityPeriods && props.onValidityPeriodsChange([
            ...props.validityPeriods,
            FareValidityPeriodModel.create({
                day: ValidityDay.EVERYDAY,
                timeInterval: DateIntervalModel.create({
                    from: (new Date()).setHours(0, 0),
                    to: (new Date()).setHours(23, 59)
                })
            })
        ]);
    }, [props]);

    const onDeleteTimeIntervalClick = useCallback(() => {
        props.validityPeriods && props.onValidityPeriodsChange(
            props.validityPeriods.slice(0, props.validityPeriods.length -  1)
        );
    }, [props]);

    const onDayIntervalChange = useCallback((index: number) => (dayIntervalId: string) => {

        const dayInterval = Object.values(ValidityDay).find(elem => elem === dayIntervalId);
        if(dayInterval && props.validityPeriods){
            props.onValidityPeriodsChange(
                props.validityPeriods.map((elem, elemIndex) => {
                    if(elemIndex === index) {
                        return FareValidityPeriodModel.create({
                            day: dayInterval,
                            timeInterval: DateIntervalModel.create({
                                ...elem.timeInterval
                            })
                        });
                    } else {
                        return elem;
                    }
                })
            );
        }

    }, [props]);

    const onStartTimeChange = useCallback((index: number) => (time: Date) => {
        
        if(props.validityPeriods){
            props.onValidityPeriodsChange(
                props.validityPeriods.map((elem, elemIndex) => {
                    if(elemIndex === index) {
                        return FareValidityPeriodModel.create({
                            day: elem.day,
                            timeInterval: DateIntervalModel.create({
                                from: time,
                                to: elem.timeInterval.to
                            })
                        });
                    } else {
                        return elem;
                    }
                })
            );
        }

    }, [props]);

    const onEndTimeChange = useCallback((index: number) => (time: Date) => {

        if(props.validityPeriods){
            props.onValidityPeriodsChange(
                props.validityPeriods.map((elem, elemIndex) => {
                    if(elemIndex === index) {
                        return FareValidityPeriodModel.create({
                            day: elem.day,
                            timeInterval: DateIntervalModel.create({
                                from: elem.timeInterval.from,
                                to: time
                            })
                        });
                    } else {
                        return elem;
                    }
                })
            );
        }

    }, [props]);

    return <div className="validity-periods-data">

        <StyledCheckBox 
            label={formatMessage(contractDetailsMessages.valid24hLabel)}
            checked={isValid24h ? CheckedState.Checked : CheckedState.Unchecked}
            onClick={onCheckedBoxClick}
            disabled={!props.canUpdateData}
        />

        {
            !isValid24h && props.validityPeriods && props.validityPeriods.length > 0 && 
                <div className="time-intervals labeled">
                    <StyledSelect
                        label={formatMessage(contractDetailsMessages.dayIntervalLabel)}
                        items={dayIntervalItems}
                        selectedItemId={props.validityPeriods[0].day}
                        onItemSelected={onDayIntervalChange(0)}
                        disabled={!props.canUpdateData}
                    />

                    <TimeIntervalSelector 
                        showLabel
                        startTime={props.validityPeriods[0].timeInterval.from}
                        endTime={props.validityPeriods[0].timeInterval.to}
                        onStartTimeChange={onStartTimeChange(0)}
                        onEndTimeChange={onEndTimeChange(0)}
                        isTimeIntervalWrong={
                            props.validityPeriods[0].timeInterval.from >= props.validityPeriods[0].timeInterval.to
                        }
                        disabled={!props.canUpdateData}

                        showAddIntervalButton={props.validityPeriods.length == 1}
                        showDeleteIntervalButton={false}
                        onAddTimeIntervalClick={onAddTimeIntervalClick}
                    />
                </div>
        }

        {
            !isValid24h && props.validityPeriods &&
                
                props.validityPeriods.slice(1).map((elem, index) =>
            
                    <div className="time-intervals" key={index}>
                        <StyledSelect
                            items={dayIntervalItems}
                            selectedItemId={elem.day}
                            onItemSelected={onDayIntervalChange(index + 1)}
                            disabled={!props.canUpdateData}
                        />

                        <TimeIntervalSelector
                            startTime={elem.timeInterval.from}
                            endTime={elem.timeInterval.to}
                            onStartTimeChange={onStartTimeChange(index + 1)}
                            onEndTimeChange={onEndTimeChange(index + 1)}
                            isTimeIntervalWrong={elem.timeInterval.from >= elem.timeInterval.to}
                            disabled={!props.canUpdateData}

                            showAddIntervalButton={(index + 2) === props.validityPeriods?.length}
                            showDeleteIntervalButton={(index + 2) === props.validityPeriods?.length}
                            onAddTimeIntervalClick={onAddTimeIntervalClick}
                            onDeleteTimeIntervalClick={onDeleteTimeIntervalClick}
                        />
                    </div>
                )
        }

        

    </div>;
};