import { ApiErrorDescription } from "../../common/api/api-error";
import apiManager from "../../common/api/api-manager";
import HttpStatusCode from "../../common/api/http-status.code";
import { User } from "../../common/models/user";
import { UserMustChangePasswordError } from "./login-api-exceptions";
import { parseApiBackofficeUser } from "./login-api-parsers";

class LoginApiService {



    login = async (email: string, password: string): Promise<User> => {
        try {
            const result = await apiManager.authApi.loginUser(
                { email, password }
            );
            // console.log('login api result', result);

            if(result.status != HttpStatusCode.OK) {
                throw new Error();
            }

            const userProfile = await apiManager.profileApi.getProfile(result.data.accessToken, result.data.idToken);

            if(result.status != HttpStatusCode.OK) {
                throw new Error();
            }

            apiManager.updateCredentials(result.data.idToken, result.data.accessToken, result.data.refreshToken);
            return Promise.resolve(parseApiBackofficeUser(userProfile.data));
        } catch (e: any) {
            console.log('login api error', e);
            if (e.response.data.errorCode === ApiErrorDescription.USER_FORCE_CHANGE_PASSWORD) {
                throw new UserMustChangePasswordError();
            }
            throw e;
        }
    };

    firstLogin = async (email: string, temporaryPassword: string, newPassword: string): Promise<User> => {
        try {
            const result = await apiManager.authApi.firstLogin(
                { email, temporaryPassword, newPassword }, {
                timeout: 20000
            });
            
            if(result.status != HttpStatusCode.OK) {
                throw new Error();
            }

            const userProfile = await apiManager.profileApi.getProfile(result.data.accessToken, result.data.idToken);

            if(result.status != HttpStatusCode.OK) {
                throw new Error();
            }

            apiManager.updateCredentials(result.data.idToken, result.data.accessToken, result.data.refreshToken);
            return Promise.resolve(parseApiBackofficeUser(userProfile.data));

        } catch (e: any) {
            console.log('ER', e.response);
            throw e;
        }
    };

    public async loadLoggedUser(): Promise<User> {
        if (process.env.NODE_ENV === 'development') {
            try {
                const rToken = sessionStorage.getItem('refresh_token');
                if (rToken) {
                    const result = await apiManager.authApi.refreshToken(rToken);
                    if (result.status === HttpStatusCode.OK) {

                        apiManager.updateCredentials(
                            result.data.idToken,
                            result.data.accessToken,
                            result.data.refreshToken
                        );

                        const userProfile = await apiManager.profileApi.getProfile(result.data.accessToken, result.data.idToken);

                        if(result.status != HttpStatusCode.OK) {
                            throw new Error();
                        }
            
                        apiManager.updateCredentials(result.data.idToken, result.data.accessToken, result.data.refreshToken);
                        return Promise.resolve(parseApiBackofficeUser(userProfile.data));

                    }
                }
            } catch (e: any) {
                console.log('Setup failed');
            }
        }
        return Promise.reject();
    }

}

const loginApiService = new LoginApiService();
export default loginApiService;