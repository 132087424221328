import { observer } from 'mobx-react-lite';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Routes } from '../../routing/routes';
import { useStore } from '../../stores/root-store';

export const PrivateRoute = observer((props: RouteProps) => {

    const userStore = useStore().userStore;
    const redirectToLogin = !userStore.isUserLogged;

    return (
        <Route {...props} >
            {!redirectToLogin && props.children
                ? props.children
                : <Redirect to={{ pathname: Routes.Login, state: { from: props.location } }} />}
        </Route>
    );
});