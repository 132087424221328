import { Instance, types } from "mobx-state-tree";
import { TimePeriodFilter } from "../../common/models/filters";
import { ContractStatus } from "./contract";

export enum ExtraContractStatusFilter {
    NO_CONTRACTS = 'noContracts',
    BLOCKED = 'blocked'
}

export const ProvidersFilterModel = types.model({
    cpoName: types.maybe(types.string),
    countryId: types.maybe(types.string),
    contractStatus: types.maybe(types.enumeration<ContractStatus | ExtraContractStatusFilter>(
        [...Object.values(ContractStatus), ...Object.values(ExtraContractStatusFilter)]
    )),
    timePeriod: types.optional(
        types.enumeration<TimePeriodFilter>(Object.values(TimePeriodFilter)),
        TimePeriodFilter.LAST_30_DAYS
    )
})
.actions((self) => ({
    
    setCPO(cpoName?: string) {
        self.cpoName = cpoName;
        return this;
    },

    setTimePeriod(timePeriod: TimePeriodFilter) {
        self.timePeriod = timePeriod;
        return this;
    },

    setCountry(countryId?: string) {
        self.countryId = countryId;
        return this;
    },

    setContractStatus(contractStatus?: ContractStatus | ExtraContractStatusFilter) {
        self.contractStatus = contractStatus;
        return this;
    },

    updateFilters(filters: ProvidersFilter) {
        this.setCPO(filters.cpoName ?? undefined);
        this.setTimePeriod(filters.timePeriod);
        this.setCountry(filters.countryId ?? undefined);
        this.setContractStatus(filters.contractStatus ?? undefined);
    },

    resetFilters() {
        self.cpoName = undefined;
        self.countryId = undefined;
        self.contractStatus = undefined;
        self.timePeriod = TimePeriodFilter.THIS_MONTH;
        return this;
    }

}));

export interface ProvidersFilter extends Instance<typeof ProvidersFilterModel> { }