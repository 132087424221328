import { AxiosPromise } from "axios";
import apiManager from "../../common/api/api-manager";
import { ListApiService } from "../../common/api/items-list/list-api-service";
import { TimePeriodFilter } from "../../common/models/filters";
import { OrderCriteria } from "../../common/models/order-criteria";
import { parseTransactionFromApiModel } from "../../transaction/api/transaction-api-parser";
import { Transaction } from "../../transaction/model/transaction";
import { apiMonthFilterFromTimePeriod } from "../../transaction/model/transaction-filter";
import { MobileUserData } from "../../_generated/api";
import { UserDetailFilter } from "../model/user-detail-filters";

class UserDetailApiService extends ListApiService<Transaction, UserDetailFilter, any> {

    parseListItem(apiResponse: any) {
        return parseTransactionFromApiModel(apiResponse);
    }

    parseSummary(apiResponse: any) {
        return {
            totalAmountCollected: apiResponse.totalAmountCollected,
            totalAmountDue: apiResponse.totalAmountDue
        };
    }

    downloadItemsList(offset: number, limit: number, filters: UserDetailFilter, orderCriteria?: OrderCriteria, accept?: string): AxiosPromise<any> {

        const config = accept === undefined ? undefined : {
            headers: {
                'Accept': accept
            }
        };

        const monthFilter = apiMonthFilterFromTimePeriod(filters?.timePeriod as TimePeriodFilter);

        return apiManager.paymentApi.searchPayments(
            apiManager.idToken,
            offset, limit,
            undefined,
            filters?.userId, undefined,
            monthFilter.monthStartIndex,
            monthFilter.monthEndIndex,
            orderCriteria ? `${orderCriteria.key}:${orderCriteria.sortOrder.toLowerCase()}` : undefined,
            config
        );
    }

    async downloadUserDetail(userId: string): Promise<MobileUserData> {
        const response = await apiManager.mobileUsersApi.getMobileUser(apiManager.idToken, userId);
        return response.data;
    }

}

export const userDetailApiService = new UserDetailApiService('userDetail');