import { Instance, types } from "mobx-state-tree";
import { TimePeriodFilter } from "../../common/models/filters";

export const UserDetailFilterModel = types.model({
    userId: types.maybe(types.string),
    timePeriod: types.enumeration<TimePeriodFilter>(Object.values(TimePeriodFilter))
})
.actions((self) => ({
    

    setTimePeriod(timePeriod: TimePeriodFilter) {
        self.timePeriod = timePeriod;
        return this;
    },

    resetFilters() {
        self.timePeriod = TimePeriodFilter.THIS_MONTH;
        return this;
    }

}));


export interface UserDetailFilter extends Instance<typeof UserDetailFilterModel> { }