import { Instance, types } from "mobx-state-tree";
import { IntlFormatters } from "react-intl";
import { DateInterval, DateIntervalModel } from "../../common/models/time";
import contractsMessages from "../contracts.messages";

export enum ContractStatus {
    ACTIVE = 'active',
    EXPIRED = 'expired',
    NOT_ACTIVE = 'notActive'
}

export enum ContractType {
    HUBJECT_STANDARD = 'standard',
    HUBJECT_PRODUCT = 'product',
    HUBJECT_DYNAMIC = 'dynamic',
    HUBJECT_NGP = 'ngp',
}

export enum ContractOrderCriteria {
    TIME_PERIOD = 'validTo'
}

export const getContractTypes = (intl: IntlFormatters) => [
    { id: ContractType.HUBJECT_STANDARD, label: intl.formatMessage(contractsMessages.contractStandardType) },
    { id: ContractType.HUBJECT_PRODUCT, label: intl.formatMessage(contractsMessages.contractProductType) },
    { id: ContractType.HUBJECT_DYNAMIC, label: intl.formatMessage(contractsMessages.contractDynamicType) },
    { id: ContractType.HUBJECT_NGP, label: intl.formatMessage(contractsMessages.contractNgpType) },
];

export const OperatorModel = types.model({
    name: types.string,
    email: types.string,
});

export const OperationInfoModel = types.model({
    date: types.Date,
    operator: OperatorModel
});

export const ContractInflationModel = types.model({
    baseInflation: types.number,
    hasFareSpecificInflation: types.boolean
});

export const ContactPersonModel = types.model({
    name: types.maybe(types.string),
    email: types.maybe(types.string),
    phoneNumber: types.maybe(types.string),
});

export const ContractModel = types.model({
    contractId: types.identifier,
    status: types.enumeration<ContractStatus>(Object.values(ContractStatus)),
    isBlocked: types.boolean,
    type: types.enumeration<ContractType>(Object.values(ContractType)),
    validityPeriod: DateIntervalModel,
    kwhDelivered: 0,
    chargesCount: 0,
    costsAmount: 0,
    earningsAmount: 0,
    inflation: ContractInflationModel,
    contactPerson: types.maybe(ContactPersonModel),
    operatorNotes: types.maybe(types.string),
    ngpContractFilename: types.maybe(types.string),
    ngpContractFile: types.maybe(types.string),
    creation: OperationInfoModel,
    lastUpdate: OperationInfoModel,
})
    .views(self => ({

        get isExpired(): boolean {
            return self.status === ContractStatus.EXPIRED;
        },

        get isNotExpired(): boolean {
            return self.status !== ContractStatus.EXPIRED;
        },

        get isActive(): boolean {
            return self.status === ContractStatus.ACTIVE && !self.isBlocked;
        },

        get canBeDeleted(): boolean {
            return self.status === ContractStatus.NOT_ACTIVE && self.costsAmount === 0 && self.earningsAmount === 0;
        },

    }))
    .actions(self => ({

        setContractType(contractType: ContractType) {
            self.type = contractType;
        },

        setValidityPeriod(validityPeriod: DateInterval) {
            self.validityPeriod = validityPeriod;
        },

        setBaseInflation(baseInflation: number) {
            self.inflation.baseInflation = baseInflation;
        },

        setContactPerson(contactPerson?: ContactPerson) {
            self.contactPerson = contactPerson;
        },

        setOperatorNotes(notes?: string) {
            self.operatorNotes = notes;
        },

        setNgpContractFilename(filename?: string) {
            self.ngpContractFilename = filename;
        },

        setNgpContractFile(file?: string) {
            self.ngpContractFile = file;
        },

        setLastUpdateOperationInfo(contractOperationInfo: OperationInfo) {
            self.lastUpdate = contractOperationInfo;
        },

        block() {
            self.isBlocked = true;
        },

        unlock() {
            self.isBlocked = false;
        }

    }));

export interface Contract extends Instance<typeof ContractModel> { }
export interface ContactPerson extends Instance<typeof ContactPersonModel> { }
export interface OperationInfo extends Instance<typeof OperationInfoModel> { }
