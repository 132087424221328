import React, { ChangeEvent, ChangeEventHandler, useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { nameFromCountryId } from "../../../../../common/models/country";
import { StyledDatePicker } from "../../../../../common/components/input/styled-date-picker";
import { StyledInput } from "../../../../../common/components/input/styled-input";
import { StyledSelect } from "../../../../../common/components/input/styled-select";
import { emptyFunction } from "../../../../../common/utils/utils";
import { ContractStatus, ContractType, getContractTypes } from "../../../../model/contract";
import { Provider } from "../../../../model/provider";

import DeleteIcon from '../../../../../assets/images/icon-delete.svg';
import UploadIcon from '../../../../../assets/images/icon-upload.svg';

import providersMessages from '../../../../providers.messages';
import contractsMessages from "../../../../contracts.messages";

export interface ContractDataContentProps {
    provider: Provider
    contractStatus: ContractStatus

    contractType?: ContractType
    onContractTypeChange: (type: ContractType) => void

    contractStartDate?: Date
    onContractStartDateChange: (date: Date) => void

    contractEndDate?: Date
    onContractEndDateChange: (date: Date) => void

    ngpContractFilename?: string
    onNgpContractFileNameChange?: (file: string) => void;
    onDeleteNgpContractFileChange?: () => void;

    npgContractFileBase64?: string
    onNgpContractFileBase64Change?: (file: string) => void;


}

export const ContractDataContent = (props: ContractDataContentProps) => {

    const [ngpContractFileName, setNgpContractFileName] = useState(props.ngpContractFilename);
    const [, setnpgContractfileBase64] = useState(props.npgContractFileBase64);
    const [, setChanged] = useState(false);

    const intl = useIntl();
    const { formatMessage } = intl;

    const todayDate = useMemo(() => new Date(), []);

    const countryItems = useMemo(() => {
        const messageDescriptor = nameFromCountryId(props.provider.country);
        return [{
            id: '0',
            label: messageDescriptor ? formatMessage(messageDescriptor) : props.provider.country
        }];
    }, [formatMessage, props.provider.country]);

    const nameItems = useMemo(() => {
        return [{
            id: '0',
            label: props.provider.cpoName
        }];
    }, [props.provider.cpoName]);


    const onDeleteNgpContractFileClick = useCallback(() => {
        if (props.onDeleteNgpContractFileChange) {
            props.onDeleteNgpContractFileChange();
        }
        setNgpContractFileName(undefined);
    }, [props]);

    const onNgpContractFilenameChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setNgpContractFileName(event.target.value);
            setChanged(true);

            if (props.contractType === ContractType.HUBJECT_NGP) {
                const filename = event.target.value;
                props.onNgpContractFileNameChange && filename && props.onNgpContractFileNameChange(filename);
            }


        },
        [props]
    );

    const onContractTypeChange = useCallback((contractTypeId: string) => {
        const selectedContractType = Object.values(ContractType).find(elem => elem === contractTypeId);
        selectedContractType && props.onContractTypeChange(selectedContractType);
    }, [props]);


    const onUploadNgpContractFileClick = useCallback(
        async () => {
            try {
                const fileInput = document.createElement('input');
                fileInput.type = 'file';
                fileInput.accept = '.xlsx';
                fileInput.onchange = async (event) => {
                    const target = event.target as HTMLInputElement;
                    const file = target.files?.[0];
                    if (file) {
                        const fileName = file.name;

                        setNgpContractFileName(fileName);
                        props.onNgpContractFileNameChange && props.onNgpContractFileNameChange(fileName);

                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = async () => {

                            const base64Data = reader.result?.toString().split(',')[1];

                            if (base64Data) {
                                setnpgContractfileBase64(base64Data);
                                props.onNgpContractFileBase64Change && props.onNgpContractFileBase64Change(base64Data);
                            }
                        };
                    }
                };
                fileInput.click();
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        },
        [props]
    );


    return <div className="contract-data-content">
        <span className="section-title">
            {formatMessage(contractsMessages.contractDataSectionTitle)}
        </span>
        <div className="provider-data">
            <StyledSelect
                label={formatMessage(providersMessages.cpoCountryLabel)}
                items={countryItems}
                onItemSelected={emptyFunction}
                selectedItemId={'0'}
                disabled
            />

            <StyledInput
                label={formatMessage(providersMessages.cpoIdLabel)}
                value={props.provider.cpoId}
                disabled
            />

            <StyledSelect
                label={formatMessage(providersMessages.cpoNameLabel)}
                items={nameItems}
                onItemSelected={emptyFunction}
                selectedItemId={'0'}
                disabled
            />
        </div>
        <div className="input-style-row">
            <StyledSelect
                label={formatMessage(contractsMessages.contractTypeLabel)}
                items={getContractTypes(intl)}
                onItemSelected={onContractTypeChange}
                selectedItemId={props.contractType}
                hideLabelItem
            />

            <StyledDatePicker
                label={formatMessage(contractsMessages.contractStartDateLabel)}
                selectedDate={props.contractStartDate}
                lastAvailableDate={props.contractEndDate}
                onDateSelected={props.onContractStartDateChange}
                disabled={props.contractStatus === ContractStatus.ACTIVE}
            />

            <StyledDatePicker
                label={formatMessage(contractsMessages.contractEndDateLabel)}
                selectedDate={props.contractEndDate}
                firstAvailableDate={
                    props.contractStatus === ContractStatus.ACTIVE
                        ? todayDate
                        : props.contractStartDate
                }
                onDateSelected={props.onContractEndDateChange}
            />
        </div>

        {props.contractType === ContractType.HUBJECT_NGP && (
            <div className="ngp-provider-data">
                <StyledInput
                    label={formatMessage(contractsMessages.contractFileNGP)}
                    onChange={onNgpContractFilenameChange}
                    disabled
                    value={ngpContractFileName || formatMessage(contractsMessages.contractFileNGPPlaceholder)}
                />
                <div className="icon-container">
                    <img src={DeleteIcon} className='styled-file-icon' onClick={onDeleteNgpContractFileClick} />
                    <img src={UploadIcon} className='styled-file-icon' onClick={onUploadNgpContractFileClick} />
                </div>
            </div>

        )}

    </div>;
};