import { Instance, types } from "mobx-state-tree";
import { ContractModel, ContractStatus } from "./contract";
import { ChargingPowerRangeModel, SocketType } from "./fare";

export enum ProviderOrderCriteria {
    ID = 'cpoId',
    NAME = 'cpoName',
    START_VALID_DATE = 'activeContractValidFrom',
    END_VALID_DATE = 'activeContractValidTo',
    KWH_DELIVERED = 'kWhDelivered',
    CHARGES_COUNT = 'chargesCount',
    COSTS_AMOUNT = 'costsAmount',
    EARNINGS_AMOUNT = 'earningsAmount',
}

export enum ProviderValueStatus {
    MAX = 'max', 
    MIN = 'min', 
    NONE = 'none'
}

export const SocketTypePowerRangeModel = types.model({
    socketType: types.enumeration(Object.values(SocketType)),
    newSocketType: types.enumeration(Object.values(SocketType)),
    powerRange: ChargingPowerRangeModel,
});

export const ProviderModel = types.model({
    cpoId: types.identifier,
    cpoName: types.string,
    country: types.string,
    kwhDelivered: 0,
    chargesCount: 0,
    costsAmount: 0,
    earningsAmount: 0,
    activeContract: types.maybe(ContractModel),
    deliveredKwhStatus: ProviderValueStatus.NONE,
    chargesCountStatus: ProviderValueStatus.NONE,
    earningsAmountStatus: ProviderValueStatus.NONE,
    socketTypePowerRanges: types.array(SocketTypePowerRangeModel)
})
.views(self => {
    return {
        get hasContracts(): boolean {
            return self.activeContract !== undefined;
        },
        get hasNoContracts(): boolean {
            return self.activeContract === undefined;
        },
        get canIdBeUpdated(): boolean {
            return (self.activeContract === undefined || self.activeContract.status !== ContractStatus.ACTIVE)
                && self.earningsAmount === 0
                && self.costsAmount === 0;
        },
        get canBeDeleted(): boolean {
            return (self.activeContract === undefined || self.activeContract.status !== ContractStatus.ACTIVE)
                && self.earningsAmount === 0
                && self.costsAmount === 0;
        },
    };
});

export interface Provider extends Instance<typeof ProviderModel> {}
export interface SocketTypePowerRange extends Instance<typeof SocketTypePowerRangeModel> {}