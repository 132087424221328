import { flow, Instance, types } from "mobx-state-tree";
import { IntlFormatters, MessageDescriptor } from "react-intl";
import { NOTIFICATION_TIME_MILLISECONDS } from "../config/constants";
import { NotificationModel, Notification } from "../models/notification";

import checkIcon from '../../assets/images/icon-ok.svg';
import stopIcon from '../../assets/images/icon-stop.svg';
import commonMessages from "../messages/common.messages";
import { CancelablePromise, makeCancelable } from "../utils/cancelable-promise";

export const NotificationsStoreModel = types.model({
    activeNotification: types.maybeNull(NotificationModel)
}).actions(self => {
    let timeout: NodeJS.Timeout | null = null;
    let cancelablePromise: CancelablePromise<void> | null = null;

    return {
        showNotification: flow(function* (notification: Notification, duration?: number) {
            self.activeNotification = notification;
            
            try {
            cancelablePromise = makeCancelable(new Promise<void>((resolve) => {
                timeout = setTimeout(() => resolve(), duration || NOTIFICATION_TIME_MILLISECONDS);
            }));
            yield cancelablePromise.promise;
            // eslint-disable-next-line no-empty
            } catch{}

            self.activeNotification = null;
        }),
        clearNotification() {
            if (timeout !== null) {
                clearTimeout(timeout);
            }
            if(cancelablePromise !== null) {
                cancelablePromise.cancel();
            }
        }
    };
});

export interface NotificationStore extends Instance<typeof NotificationsStoreModel> { }

export const createServerErrorNotification = (intl: IntlFormatters, message?: MessageDescriptor): Notification => NotificationModel.create({
    icon: stopIcon,
    message: intl.formatMessage(message ?? commonMessages.serverErrorNotificationMessage)
});

export const createSuccessNotification = (intl: IntlFormatters, message?: MessageDescriptor): Notification => NotificationModel.create({
    icon: checkIcon,
    message: intl.formatMessage(message ?? commonMessages.successNotificationMessage)
});