import React, { useCallback, useMemo } from 'react';
import { useIntl } from "react-intl";

import { StyledSelect } from '../../../common/components/input/styled-select';

import { Provider } from '../../../providers/model/provider';
import { getTimeFilters, TimePeriodFilter } from '../../../common/models/filters';
import { nameFromCountryId } from '../../../common/models/country';

import iconCreditCard from '../../../assets/images/icon-credit-card.svg';

import messages from '../../transaction.messages';

import './transaction-section-header.scss';
import filterMessages from '../../../common/messages/filter.messages';

interface TransactionSectionHeaderProps {
    totalItems: number;
    providers: Provider[];
    availableCountries: string[];

    selectedTimePeriodFilter: TimePeriodFilter;
    selectedProviderFilter?: string;
    selectedCountryFilter?: string;

    onSelectedProvider: (provider?: string) => void;
    onSelectedCountry: (country?: string) => void;
    onSelectedTimePeriod: (timePeriod: TimePeriodFilter) => void;

    canUserChangeCountryFilter: boolean;
}

const noCountry = 'noCountry';

export const TransactionSectionHeader = (props: TransactionSectionHeaderProps) => {
    const intl = useIntl();
    const { formatMessage } = intl;

    const timeFilters = useMemo(() => getTimeFilters(intl), [intl]);
    const noCPOId = '  ';

    const providers = useMemo(() => [
        { id: noCPOId, label: formatMessage(messages.allCPO) },
        ...props.providers.map((elem) => ({ id: elem.cpoId, label: elem.cpoName }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [formatMessage, props.providers.length]);

    const onTimeFilterSelected = useCallback((newItem: string) => {
        if (newItem != props.selectedTimePeriodFilter) {
            const timeFiltered = Object.values(TimePeriodFilter).find((val) => val == newItem);
            if (timeFiltered != null) {
                props.onSelectedTimePeriod(timeFiltered);
            }
        }
    }, [props]);

    const onProviderFilterSelected = useCallback((provider: string) => {
        if (provider != props.selectedProviderFilter && provider !== noCPOId)
            props.onSelectedProvider(provider);
        else if (provider != props.selectedProviderFilter)
            props.onSelectedProvider(undefined);
    }, [props]);

    const availableCountries = useMemo(() => [
            {id: noCountry, label: formatMessage(filterMessages.anyCountryFilter)},
            ...props.availableCountries.map(elem => {
            const nameDescriptor = nameFromCountryId(elem);
            return {
                id: elem, 
                label: nameDescriptor ? formatMessage(nameDescriptor) : elem
            };
        })
        .sort((a,b) => a.label.localeCompare(b.label))
    ]
    , [formatMessage, props.availableCountries]);
    
    const onCountrySelected = useCallback((countryId: string) => {
        if (countryId == noCountry) {
            props.onSelectedCountry(undefined);
        }
        else {
            props.onSelectedCountry(countryId);
        }
    }, [props]);


    return <div className='transaction-section-header section-header'>
        <img className='card-icon' src={iconCreditCard} alt='' />
        <h2> {formatMessage(messages.sectionTitle)} </h2>
        
        <StyledSelect
            containerClassName='country-search-select'
            items={availableCountries}
            selectedItemId={props.selectedCountryFilter ?? noCountry}
            onItemSelected={onCountrySelected}
            disabled={!props.canUserChangeCountryFilter}
        />

        <StyledSelect
            items={providers.sort(function (a,b) {
                if(a.label.localeCompare(formatMessage(messages.allCPO)) == 0) return -1;
                if(b.label.localeCompare(formatMessage(messages.allCPO)) == 0) return 1;
                return a.label.localeCompare(b.label);
            }
                )}
            onItemSelected={onProviderFilterSelected}
            selectedItemId={props.selectedProviderFilter ?? noCPOId} />
        <StyledSelect
            items={timeFilters}
            onItemSelected={onTimeFilterSelected}
            selectedItemId={props.selectedTimePeriodFilter} />
        <span className='items-count'>({props.totalItems})</span>
    </div>;
};