import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';

import ExpandIcon from '../../../assets/images/icon-arrow-down.svg';

import './app-accordion.scss';

export interface AppAccordionProps {
    title: string | (() => React.ReactNode);
    children: React.ReactNode;

    bodyClassName?: string;
    defaultExpanded?: boolean;
    onChange?: (event: React.ChangeEvent<any>, isExpanded: boolean) => void;
}

export const AppAccordion = (props: AppAccordionProps) => {
    return <Accordion className='app-accordion' elevation={0} onChange={props.onChange} defaultExpanded={props.defaultExpanded}>
        <AccordionSummary expandIcon={<img src={ExpandIcon} className='app-accordion-arrow' />} >
            {
                typeof props.title === 'string'
                    ? <span className='app-accordion-title'>{props.title}</span>
                    : props.title()
            }
        </AccordionSummary>
        <AccordionDetails className={`app-accordion-body ${props.bodyClassName ?? ''}`}>
            {props.children}
        </AccordionDetails>
    </Accordion>;
};