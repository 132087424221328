import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { BaseDialog } from '../../../../../common/components/dialogs/base-dialog';
import { ContractDataContent } from './contract-data-content';
import { OptionalInformationContent } from './optional-information-content';
import { AppButtonVariant } from '../../../../../common/components/button/app-button';
import { ManageContractDialogTitle } from './manage-contract-dialog-title';
import { LowFeeConfirmationDialog } from '../low-fee-confirmation-dialog/low-fee-confirmation-dialog';

import { Provider } from '../../../../model/provider';
import { ContractStatus, ContractType } from '../../../../model/contract';
import { ContractInfo } from '../../../../model/contract-info';

import commonMessages from '../../../../../common/messages/common.messages';

import './manage-contract-dialog.scss';


export interface ManageContractDialogProps {
    open: boolean
    isOperationRunning: boolean
    onClose: () => void
    onPrimaryButtonClick: (contractInfo: ContractInfo) => void

    provider: Provider
    contract?: ContractInfo
    isUpdating?: boolean
    clearContractEndDateField?: boolean
}


export const ManageContractDialog = (props: ManageContractDialogProps) => {

    const { formatMessage } = useIntl();

    /* ----------- State ----------- */

    const [baseInflation, setBaseInflation] = useState<string>();
    const onBaseInflationChange = useCallback((fee?: string) => setBaseInflation(fee), []);
    const baseInflationValue = useMemo(() => Number(baseInflation), [baseInflation]);

    const [contractType, setContractType] = useState(ContractType.HUBJECT_STANDARD);
    const onContractTypeChange = useCallback((type: ContractType) => setContractType(type), []);

    const [contractStartDate, setContractStartDate] = useState<Date>();
    const onContractStartDateChange = useCallback((date: Date) => setContractStartDate(date), []);

    const [contractEndDate, setContractEndDate] = useState<Date>();
    const onContractEndDateChange = useCallback((date: Date) => setContractEndDate(date), []);

    const [contactPersonName, setContactPersonName] = useState<string>();
    const onContactPersonNameChange = useCallback((name: string) => setContactPersonName(name), []);

    const [contactPersonEmail, setContactPersonEmail] = useState<string>();
    const onContactPersonEmailChange = useCallback((email: string) => setContactPersonEmail(email), []);

    const [contactPersonPhoneNumber, setContactPersonPhoneNumber] = useState<string>();
    const onContactPersonPhoneNumberChange = useCallback((phoneNumber: string) => setContactPersonPhoneNumber(phoneNumber), []);

    const [notesForTheOperator, setNotesForTheOperator] = useState<string>();
    const onNotesForTheOperatorChange = useCallback((notes: string) =>
        setNotesForTheOperator(notes.trim().length > 0 ? notes : undefined)
        , []);


    const [ngpFilename, setNgpFilename] = useState<string>();
    const onNgpContractFileNameChange = useCallback((filename: string) =>
        setNgpFilename(filename.trim().length > 0 ? filename : undefined)
        , []);

    const [ngpFileBase64, setNgpFileBase64] = useState<string>();
    const onNgpContractFileBase64Change = useCallback((file: string) =>
        setNgpFileBase64(file.trim().length > 0 ? file : undefined)
        , []);

    const isPrimaryButtonEnabled = useMemo(() => {
        let isHubjectNgpValid = true;
        if (contractType === ContractType.HUBJECT_NGP) {
            isHubjectNgpValid = (ngpFilename !== null);
        }

        return contractType !== undefined
            && contractStartDate !== undefined
            && contractEndDate !== undefined
            && contractStartDate <= contractEndDate
            && !isNaN(baseInflationValue)
            && isHubjectNgpValid;
    }, [baseInflationValue, contractEndDate, contractStartDate, contractType, ngpFilename]);

    const manageContract = useCallback(() => {
        let contactPerson = undefined;
        if (contactPersonName || contactPersonEmail || contactPersonPhoneNumber) {
            contactPerson = {
                name: contactPersonName,
                email: contactPersonEmail,
                phoneNumber: contactPersonPhoneNumber,
            };
        }

        props.onPrimaryButtonClick({
            type: contractType,
            startDate: contractStartDate ?? new Date(),
            endDate: contractEndDate ?? new Date(),
            baseInflation: baseInflationValue,
            contactPerson,
            status: ContractStatus.NOT_ACTIVE,
            operatorNotes: notesForTheOperator,
            ngpContractFilename: (ngpFilename && ngpFileBase64) ? ngpFilename : undefined,
            ngpContractFile: (ngpFilename && ngpFileBase64) ? ngpFileBase64 : undefined,
        });
    }, [
        baseInflationValue, contractEndDate, contractStartDate, contractType,
        props, contactPersonEmail, contactPersonName,
        contactPersonPhoneNumber, notesForTheOperator, ngpFilename, ngpFileBase64
    ]);

    /* ----------- Low fee dialog ----------- */
    const [showLowFeeDialog, setShowLowFeeDialog] = useState(false);

    const onCloseLowFeeDialog = useCallback(() => setShowLowFeeDialog(false), []);
    const onConfirmLowFeeDialog = useCallback(() => {
        setShowLowFeeDialog(false);
        manageContract();
    }, [manageContract]);

    /* ----------- Dialog management ----------- */

    const renderTitle = useCallback(() => <ManageContractDialogTitle
        baseInflation={baseInflation}
        onBaseInflationChange={onBaseInflationChange}
    />, [baseInflation, onBaseInflationChange]);

    const onClose = useCallback(() => {
        if (!props.isOperationRunning)
            props.onClose();
    }, [props]);

    const onPrimaryButtonClick = useCallback(() => {
        if (!isPrimaryButtonEnabled || props.isOperationRunning) {
            return;
        }

        if (baseInflationValue <= 0) {
            setShowLowFeeDialog(true);
        } else {
            manageContract();
        }
    }, [baseInflationValue, isPrimaryButtonEnabled, manageContract, props.isOperationRunning]);

    useEffect(() => {
        setBaseInflation(props.contract?.baseInflation.toFixed(2) ?? '0.00');
        setContractType(props.contract?.type ?? ContractType.HUBJECT_STANDARD);
        setContractStartDate(props.contract?.startDate);
        setContractEndDate(props.clearContractEndDateField ? undefined : props.contract?.endDate);
        setContactPersonName(props.contract?.contactPerson?.name);
        setContactPersonEmail(props.contract?.contactPerson?.email);
        setContactPersonPhoneNumber(props.contract?.contactPerson?.phoneNumber);
        setNotesForTheOperator(props.contract?.operatorNotes);
        setNgpFilename(props.contract?.ngpContractFilename);
        setNgpFileBase64(props.contract?.ngpContractFile);
    }, [props.clearContractEndDateField, props.contract, props.open]);

    /* ----------- Layout ----------- */

    return <>

        <BaseDialog
            open={props.open}
            title={renderTitle}
            buttons={[
                {
                    label: formatMessage(commonMessages.cancelButton),
                    disabled: props.isOperationRunning || showLowFeeDialog,
                    buttonClasses: AppButtonVariant.Dark,
                    onClick: onClose
                },
                {
                    label: formatMessage(props.isUpdating ? commonMessages.saveButton : commonMessages.addButton),
                    disabled: props.isOperationRunning || !isPrimaryButtonEnabled || showLowFeeDialog,
                    buttonClasses: AppButtonVariant.Primary,
                    onClick: onPrimaryButtonClick
                },
            ]}
            onClose={props.onClose}
            containerClassName='manage-contract-dialog'
        >

            <form>

                <ContractDataContent
                    provider={props.provider}
                    contractStatus={props.contract?.status ?? ContractStatus.NOT_ACTIVE}
                    contractType={contractType}
                    contractStartDate={contractStartDate}
                    contractEndDate={contractEndDate}
                    ngpContractFilename={ngpFilename}
                    npgContractFileBase64={ngpFileBase64}
                    onContractTypeChange={onContractTypeChange}
                    onContractStartDateChange={onContractStartDateChange}
                    onContractEndDateChange={onContractEndDateChange}
                    onNgpContractFileNameChange={onNgpContractFileNameChange}
                    onNgpContractFileBase64Change={onNgpContractFileBase64Change}

                />

                <OptionalInformationContent
                    contactPersonEmail={contactPersonEmail}
                    contactPersonaName={contactPersonName}
                    contactPersonPhoneNumber={contactPersonPhoneNumber}
                    notesForTheOperator={notesForTheOperator}
                    onContactPersonEmailChange={onContactPersonEmailChange}
                    onContactPersonNameChange={onContactPersonNameChange}
                    onContactPersonPhoneNumberChange={onContactPersonPhoneNumberChange}
                    onNotesForTheOperatorChange={onNotesForTheOperatorChange}
                />

            </form>

        </BaseDialog>

        <LowFeeConfirmationDialog
            open={showLowFeeDialog}
            onConfirmButtonClick={onConfirmLowFeeDialog}
            onClose={onCloseLowFeeDialog}
        />

    </>;
};