import React, { useCallback, useMemo, useRef, useState } from 'react';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { createMuiTheme, Theme, ThemeProvider } from '@material-ui/core';

import { StyledInput } from '../input/styled-input';

import { formatTime } from '../../utils/formatters';

interface StyledTimePickerProps {
    selectedTime?: Date;
    onTimeSelected?: (date: Date) => void;
    label?: string;
    dialogTheme?: Theme;
    disabled?: boolean;
    errorLabel?: string;
}

const inputStyle: React.CSSProperties = {
    padding: 8
};

export const StyledTimePicker = (props: StyledTimePickerProps) => {

    const inputRef = useRef<HTMLInputElement>(null);
    
    const [opened, setOpened] = useState(false);

    const openMenu = useCallback(() => setOpened(true), []);
    const closeMenu = useCallback(() => {
        setOpened(false);
        inputRef.current?.blur();
    }, []);

    const updateValue = useCallback((date: Date | null) => 
        date && props.onTimeSelected && props.onTimeSelected(date),
    [props]);

    const muiTheme = useMemo(() => props.dialogTheme ?? createMuiTheme({
        palette: {
            primary: {
                main: '#333',
            }
        },
    }), [props.dialogTheme]);

    const renderInput = useCallback(() => {
        const value = props.selectedTime ? formatTime(props.selectedTime) : undefined;
        return <StyledInput 
            readOnly
            inputRef={inputRef}
            label={props.label} 
            value={value}
            onClick={openMenu}
            className='styled-time-picker'
            disabled={props.disabled}
            errorLabel={props.errorLabel}
        />;
    }, [openMenu, props.disabled, props.label, props.selectedTime, props.errorLabel]);

    return <ThemeProvider theme={muiTheme}>
        <KeyboardTimePicker
            disabled={props.disabled}
            placeholder={props.label}
            TextFieldComponent={renderInput}
            onClick={openMenu}
            onClose={closeMenu}
            open={opened}
            
            value={props.selectedTime}
            onChange={updateValue}
            
            keyboardIcon={null}
            variant='dialog'
            inputVariant='outlined'
            ampm={false}
            
            
            inputProps={{
                style: inputStyle
            }}
        />
    </ThemeProvider>;
};