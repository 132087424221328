import { defineMessages } from 'react-intl';

export default defineMessages({
    contractActiveState: {
        id: "containers.contracts.activeState",
        defaultMessage: "Attivo",
        description: ""
    },
    contractNotActiveState: {
        id: "containers.contracts.notActiveState",
        defaultMessage: "Non attivo",
        description: ""
    },
    contractExpiredState: {
        id: "containers.contracts.contractExpiredState",
        defaultMessage: "Terminato",
        description: ""
    },
    contractBlockedState: {
        id: "containers.contracts.contractBlockedState",
        defaultMessage: "Bloccato",
        description: ""
    },
    noContracts: {
        id: "containers.contracts.noContracts",
        defaultMessage: "Nessuno",
        description: ""
    },
    goBackToProvidersPage: {
        id: "containers.contracts.goBackToProvidersPage",
        defaultMessage: "Torna a CPO",
        description: "Messaggio del link usato per tornare alla pagina dei CPO",
    },
    contractStandardType: {
        id: "containers.contracts.contractStandardType",
        defaultMessage: "Standard",
        description: "",
    },
    contractProductType: {
        id: "containers.contracts.contractProductType",
        defaultMessage: "Product",
        description: "",
    },
    contractDynamicType: {
        id: "containers.contracts.contractDynamicType",
        defaultMessage: "Dynamic",
        description: "",
    },
    contractNgpType: {
        id: "containers.contracts.contractNgpType",
        defaultMessage: "Ngp",
        description: "",
    },
    deleteContractDialogTitle: {
        id: "containers.contracts.deleteContractDialogTitle",
        defaultMessage: "Rimuovere il contratto {contractId}?",
        description: "Titolo della dialog di rimozione di un contratto"
    },
    manageContractDialogTitle: {
        id: "containers.contracts.manageContractDialogTitle",
        defaultMessage: "Contratto",
        description: "Titolo della dialog di gestione di un contratto"
    },
    contractBaseFeeLabel: {
        id: "containers.contracts.contractBaseFeeLabel",
        defaultMessage: "Rincaro base ({currency})",
        description: "Label del campo di testo che permette di modificare il rincaro base di un contratto"
    },
    contractDataSectionTitle: {
        id: "containers.contracts.contractDataSection",
        defaultMessage: "Dati relativi al contratto stipulato",
        description: "Titolo della sezione contenente i dati specifici del contratto"
    },
    contractOptionalInformationSectionTitle: {
        id: "containers.contracts.contractOptionalInformationSectionTitle",
        defaultMessage: "Informazioni opzionali",
        description: "Titolo della sezione contenente le informazioni opzionali del contratto"
    },
    contractTypeLabel: {
        id: "containers.contracts.contractTypeLabel",
        defaultMessage: "Tipo di contratto",
        description: "Label per la select di scelta del tipo di contratto"
    },
    contractStartDateLabel: {
        id: "containers.contracts.contractStartDateLabel",
        defaultMessage: "Valido da",
        description: "Label per il campo di testo che permette di selezionare la data di inizio validità di un contratto"
    },
    contractEndDateLabel: {
        id: "containers.contracts.contractEndDateLabel",
        defaultMessage: "Valido a",
        description: "Label per il campo di testo che permette di selezionare la data di fine validità di un contratto"
    },
    contractFileNGP: {
        id: "containers.contracts.contractFileNGP",
        defaultMessage: "Contratto NGP",
        description: "Label per il campo di testo che permette di selezionare il file di un contratto NGP"
    },
    contractFileNGPPlaceholder: {
        id: "containers.contracts.contractFileNGPPlaceholder",
        defaultMessage: "denominazione_file_contratto.xlsx",
        description: "Messaggio che viene mostrato quando non è stato selezionato nessun file di contratto NGP"
    },
    representativeNameLabel: {
        id: "containers.contracts.representativeNameLabel",
        defaultMessage: "Nome referente",
        description: "Label per il campo di testo che permette di inserire il nome del referente di un contratto"
    },
    representativeEmailLabel: {
        id: "containers.contracts.representativeEmailLabel",
        defaultMessage: "Email referente",
        description: "Label per il campo di testo che permette di inserire l'email del referente di un contratto"
    },
    representativePhoneNumberLabel: {
        id: "containers.contracts.representativePhoneNumberLabel",
        defaultMessage: "Tel referente",
        description: "Label per il campo di testo che permette di inserire il numero di telefono del referente di un contratto"
    },
    notesForTheOperatorLabel: {
        id: "containers.contracts.notesForTheOperatorLabel",
        defaultMessage: "Note per l'operatore",
        description: "Label per il campo di testo che permette di inserire delle note per un operatore"
    },
    lowFeeConfirmationDialogTitle: {
        id: "containers.contracts.lowFeeConfirmationDialogTitle",
        defaultMessage: "Rincaro pari o inferiore a 0",
        description: "Titolo della dialog che informa l'utente che si sta creando un contratto con un rincaro basso"
    },
    lowFeeConfirmationDialogMessage: {
        id: "containers.contracts.lowFeeConfirmationDialogMessage",
        defaultMessage: "Il rincaro impostato è pari o inferiore a 0, si desidera procedere comunque? Questo non influirà sulle tariffe preesistenti.",
        description: "Body della dialog che informa l'utente che si sta creando un contratto con un rincaro basso"
    },
    blockContractDialogTitle: {
        id: "containers.contracts.blockContractDialogTitle",
        defaultMessage: "Bloccare il contratto {contractId}?",
        description: "Titolo della dialog che permette di bloccare un contratto"
    },
    blockContractDialogMessage: {
        id: "containers.contracts.blockContractDialogMessage",
        defaultMessage: "\
            Il contratto e tutte le sue tariffe non saranno più visibili in app anche se ricadono nel periodo di validità specificato. \
            Il contratto potrà essere modificato e sbloccato in qualsiasi momento entro la data di scadenza. \
            NOTA: L'aggiornamento in app potrebbe richiedere qualche minuto. Ricariche già eseguite o in corso non saranno influenzate dal blocco.\
        ",
        description: "Messaggio mostrato nella dialog che permette di bloccare un contratto"
    },
    unlockContractDialogTitle: {
        id: "containers.contracts.unlockContractDialogTitle",
        defaultMessage: "Sbloccare il contratto {contractId}?",
        description: "Titolo della dialog che permette di sbloccare un contratto"
    },
    unlockContractDialogMessage: {
        id: "containers.contracts.unlockContractDialogMessage",
        defaultMessage: "\
            Il contratto e tutte le sue tariffe saranno nuovamente abilitati e torneranno visibili in app. \
            L'effetto potrebbe richiedere qualche minuto.\
        ",
        description: "Messaggio mostrato nella dialog che permette di sbloccare un contratto"
    },
});