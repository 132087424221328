import { defineMessages } from 'react-intl';

export default defineMessages({
    "admin": {
        "id": "roles.admin",
        "defaultMessage": "Amministratore",
        "description": ""
    },
    "operator": {
        "id": "roles.operator",
        "defaultMessage": "Operatore",
        "description": ""
    },
    "manager": {
        "id": "roles.manager",
        "defaultMessage": "Gestore",
        "description": ""
    },
});