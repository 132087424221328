import { AxiosPromise } from "axios";

import { ListApiService } from "../../../common/api/items-list/list-api-service";
import { parseContractAnagraphicFromApiModel, parseContractFromApiModel, parseContractTypeToApiModel, parseDateTimeToApiModel } from "./contract-api-parser";
import { OrderCriteria } from "../../../common/models/order-criteria";
import { Contract } from "../../model/contract";
import apiManager from "../../../common/api/api-manager";
import HttpStatusCode from "../../../common/api/http-status.code";
import { GetItemsResponse } from "../../../common/api/items-list/get-items";
import { DEFAULT_PAGE_ITEMS } from "../../../common/config/constants";
import { AddContractData, UpdateContractData } from "./contract-request";
import { Provider } from "../../model/provider";
import { ProviderContractsSearchResultItem } from "../../../_generated/api";

export class ContractApiService extends ListApiService<Contract, never, never> {

    private provider?: Provider;

    parseListItem = (apiResponse: any) => parseContractFromApiModel(apiResponse);

    parseSummary = () => undefined;

    async getItems(pageNumber: number, filters: never, orderCriteria?: OrderCriteria, itemPerPage?: number): Promise<GetItemsResponse<Contract, never>> {

        const offset = (itemPerPage || 0) * (pageNumber - 1);
        const limit = itemPerPage || DEFAULT_PAGE_ITEMS;

        const result = await this.downloadItemsList(offset, limit, filters, orderCriteria);

        if (result.status !== HttpStatusCode.OK) {
            throw new Error();
        }

        return Promise.resolve({
            totalItems: result.data.length,
            items: result.data.slice(offset, limit).map(this.parseListItem)
        });
    }

    downloadItemsList(offset: number, limit: number, filters: never, orderCriteria?: OrderCriteria, accept?: string): AxiosPromise<ProviderContractsSearchResultItem[]> {
        if (!this.provider) {
            throw new Error('provider id not specified');
        }

        const config = accept === undefined ? undefined : {
            headers: {
                'Accept': accept
            }
        };

        return apiManager.providerApi.getContracts(
            this.provider.cpoId,
            apiManager.idToken,
            orderCriteria ? `${orderCriteria.key}:${orderCriteria.sortOrder.toLowerCase()}` : undefined,
            config
        );
    }

    async deleteContract(contract: Contract): Promise<void> {
        if (!this.provider) {
            throw new Error('provider id not specified');
        }

        const result = await apiManager.providerApi.deleteContract(
            this.provider.cpoId,
            apiManager.idToken,
            contract.contractId
        );

        if (result.status === HttpStatusCode.NO_CONTENT) {
            return Promise.resolve();
        }
        else {
            throw new Error();
        }
    }

    async addContract(contract: AddContractData): Promise<Contract> {
        if (!this.provider) {
            throw new Error('provider id not specified');
        }

        contract.startDate.setHours(0, 0, 0);
        contract.endDate.setHours(23, 59, 59);

        const result = await apiManager.providerApi.createContract(
            apiManager.idToken,
            this.provider.cpoId,
            {
                inflation: contract.baseInflation,
                type: parseContractTypeToApiModel(contract.type),
                validityPeriod: {
                    from: parseDateTimeToApiModel(contract.startDate, this.provider.country),
                    to: parseDateTimeToApiModel(contract.endDate, this.provider.country)
                },
                contactPerson: contract.contactPerson,
                operatorNotes: contract.operatorNotes,
                ngpContractFile: contract.ngpContractFile,
                ngpContractFilename: contract.ngpContractFilename,
            }
        );

        if (result.status === HttpStatusCode.CREATED) {
            return Promise.resolve(
                parseContractAnagraphicFromApiModel(result.data)
            );
        }
        else {
            throw new Error();
        }
    }

    async updateContract(contractId: string, data: UpdateContractData): Promise<Contract> {
        if (!this.provider) {
            throw new Error('provider id not specified');
        }

        data.startDate.setHours(0, 0, 0);
        data.endDate.setHours(23, 59, 59);

        const result = await apiManager.providerApi.updateContract(
            apiManager.idToken,
            this.provider.cpoId,
            contractId,
            {
                inflation: data.baseInflation,
                type: parseContractTypeToApiModel(data.type),
                validityPeriod: {
                    from: parseDateTimeToApiModel(data.startDate, this.provider.country),
                    to: parseDateTimeToApiModel(data.endDate, this.provider.country)
                },
                contactPerson: data.contactPerson ?? {},
                operatorNotes: data.operatorNotes ?? '',
                ngpContractFilename: data.ngpContractFilename,
                ngpContractFile: data.ngpContractFile

            }
        );

        if (result.status === HttpStatusCode.OK) {
            return Promise.resolve(
                parseContractAnagraphicFromApiModel(result.data)
            );
        }
        else {
            throw new Error();
        }
    }

    blockContract(contract: Contract): Promise<void> {
        return this.manageBlockContract(contract, true);
    }

    unlockContract(contract: Contract): Promise<void> {
        return this.manageBlockContract(contract, false);
    }

    setProvider(provider: Provider) {
        this.provider = provider;
    }

    private async manageBlockContract(contract: Contract, isBlocked: boolean): Promise<void> {
        if (!this.provider) {
            throw new Error('provider id not specified');
        }

        contract.validityPeriod.from.setHours(0, 0, 0);
        contract.validityPeriod.to.setHours(23, 59, 59);

        const result = await apiManager.providerApi.updateContract(
            apiManager.idToken,
            this.provider.cpoId,
            contract.contractId,
            {
                inflation: contract.inflation.baseInflation,
                isBlocked: isBlocked,
                type: parseContractTypeToApiModel(contract.type),
                validityPeriod: {
                    from: parseDateTimeToApiModel(contract.validityPeriod.from, this.provider.country),
                    to: parseDateTimeToApiModel(contract.validityPeriod.to, this.provider.country)
                }
            }
        );

        if (result.status === HttpStatusCode.OK) {
            return Promise.resolve();
        }
        else {
            throw new Error();
        }
    }

}

export const contractApiService = new ContractApiService('contracts');
