import React from 'react';

import { Action, ActionsMenu } from '../actions/actions-menu';

interface ListActionsItemProps {
    actions: Action[];
}

export const ListActionsItem = ({actions}: ListActionsItemProps) => {

    return <td >
        <ActionsMenu actions={actions}/>
    </td>;
};