import { defineMessages } from 'react-intl';

export default defineMessages({
    "sectionTitle": {
        "id": "containers.transactions.sectionTitle",
        "defaultMessage": "Transazioni",
        "description": ""
    },
    "allCPO": {
        "id": "containers.transactions.allCPO",
        "defaultMessage": "Tutti i CPO",
        "description": ""
    },
});