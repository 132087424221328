import { defineMessages } from "react-intl";

export default defineMessages({
  sectionTitle: {
    id: "containers.providers.sectionTitle",
    defaultMessage: "Contratti",
    description: "",
  },
  providersPageTitle: {
    id: "containers.providers.providersPageTitle",
    defaultMessage: "CPO",
    description: "Titolo della pagina dei CPO",
  },
  providerContractsPageTitle: {
    id: "containers.providers.providerContractsPageTitle",
    defaultMessage: "Contratti {provider}",
    description: "Titolo della pagina dei contratti di un CPO",
  },
  providerContractDetailsPageTitle: {
    id: "containers.providers.providerContractDetailsPageTitle",
    defaultMessage: "Contratto {contract}",
    description: "Titolo della pagina dei dettagli di un contratto",
  },
  dataFrom: {
    id: "containers.providers.dataFrom",
    defaultMessage: "Dati",
    description:
      "Label usata per indicare a quale periodo di tempo si riferiscono i dati degli operatori",
  },
  anyContractFilter: {
    id: "containers.providers.anyContractFilter",
    defaultMessage: "Tutti",
    description: "",
  },
  onlyActiveContractsFilter: {
    id: "containers.providers.onlyActiveContractsFilter",
    defaultMessage: "Attivi",
    description: "",
  },
  onlyNotActiveContractsFilter: {
    id: "containers.providers.onlyNotActiveContractsFilter",
    defaultMessage: "Non attivi",
    description: "",
  },
  onlyExpiredContractsFilter: {
    id: "containers.providers.onlyExpiredContractsFilter",
    defaultMessage: "Terminati",
    description: "",
  },
  onlyBlockedContractsFilter: {
    id: "containers.providers.onlyExpiredContractsFilter",
    defaultMessage: "Bloccati",
    description: "",
  },
  onlyNoContractsFilter: {
    id: "containers.providers.onlyNoContractsFilter",
    defaultMessage: "Nessuno",
    description: "",
  },
  deleteProviderOperatorDialogTitle: {
    id: "containers.providers.deleteProviderOperatorDialogTitle",
    defaultMessage: "Rimuovere {providerName} dalla lista dei provider?",
    description: "Titolo della dialog di rimozione di un provider",
  },
  deleteItemOperatorDialogBody: {
    id: "containers.providers.deleteItemOperatorDialogBody",
    defaultMessage: "L'operazione è irreversibile.",
    description:
      "Body della dialog di rimozione di un provider o di un contratto",
  },
  addProviderOperatorDialogTitle: {
    id: "containers.providers.addProviderOperatorDialogTitle",
    defaultMessage: "Provider",
    description: "Titolo della dialog di aggiunta di un provider",
  },
  addProviderOperatorDialogBody: {
    id: "containers.providers.addProviderOperatorDialogBody",
    defaultMessage:
      "Inserisci i dati del provider e conferma. Verrai reindirizzato alla pagina di generazione del contratto.",
    description: "Body della dialog di aggiunta di un provider",
  },
  cpoCountryLabel: {
    id: "containers.providers.cpoCountryLabel",
    defaultMessage: "Paese",
    description:
      "Label della select che permette di scegliere il paese del CPO che si sta creando",
  },
  cpoIdLabel: {
    id: "containers.providers.cpoIdLabel",
    defaultMessage: "ID Provider",
    description:
      "Label delil campo di testo che permette di inserire l'id del CPO che si sta creando",
  },
  cpoNameLabel: {
    id: "containers.providers.cpoNameLabel",
    defaultMessage: "Nome Provider",
    description:
      "Label delil campo di testo che permette di inserire il nome del CPO che si sta creando",
  },
  addProviderDialogSuccessMessage: {
    id: "containers.providers.addProviderDialogSuccessMessage",
    defaultMessage: "Il provider è stato aggiunto",
    description:
      "Messaggio mostrato in caso di avvenuta aggiunta di un provider",
  },
  providerWithSameIdAlreadyExistsMessage: {
    id: "containers.providers.addProviderDialogSuccessMessage",
    defaultMessage: "Nel sistema è già presente un provider con lo stesso ID",
    description:
      "Messaggio di errore mostrato quando si prova ad inserire un id di un provider che già esiste",
  },
  enableHpcCheckboxLabel: {
    id: "containers.providers.enableHpcCheckboxLabel",
    defaultMessage: "Ricarica HPC",
    description: "Label della checkbox che permette di abilitare la edit text mostrato quando si prova ad inserire un id di un provider che già esiste",
  },
  hpcLabel: {
    id: "containers.providers.hpcLabel",
    defaultMessage: "Considera HPC a partire da (Kwh)",
    description: "Messaggio di errore mostrato quando si prova ad inserire un id di un provider che già esiste",
  }
});
