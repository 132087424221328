import apiManager from "../../common/api/api-manager";
import HttpStatusCode from "../../common/api/http-status.code";

class ChangePasswordApiService {

    public async changePassword(oldPassword: string, newPassword: string): Promise<void> {
        const result = await apiManager.profileApi.updateUserCredentials(
            apiManager.accessToken,
            apiManager.idToken,
            {
                newPassword, oldPassword
            }
        );
        
        if(result.status !== HttpStatusCode.OK){
            throw new Error();
        }
    }

}

const changePasswordApiService = new ChangePasswordApiService();
export default changePasswordApiService;