import { AxiosPromise } from "axios";
import { DEFAULT_PAGE_ITEMS, HEADER_ACCEPT_XLSX } from "../../config/constants";
import { OrderCriteria } from "../../models/order-criteria";
import { formattedNowForReportFilename } from "../../utils/formatters";
import { base64toBlob } from "../../utils/utils";
import ApiError from "../api-error";
import { ApiService } from "../api-service";
import HttpStatusCode from "../http-status.code";
import { GetItemsResponse } from "./get-items";

export abstract class ListApiService<ListResponseType, FiltersType, SummaryType> extends ApiService {

    private reportTypeLabel: string;
    constructor(reportTypeLabel: string) {
        super();
        this.reportTypeLabel = reportTypeLabel;
    }

    abstract parseListItem(apiResponse: any): ListResponseType;
    abstract parseSummary(apiResponse: any): SummaryType | undefined;

    async getItems(pageNumber: number, filters?: FiltersType, orderCriteria?: OrderCriteria, itemPerPage?: number): Promise<GetItemsResponse<ListResponseType, SummaryType>> {

        const offset = (itemPerPage || 0) * (pageNumber - 1);
        const limit = itemPerPage || DEFAULT_PAGE_ITEMS;

        const result = await this.downloadItemsList(offset, limit, filters, orderCriteria);

        if (result.status !== HttpStatusCode.OK) {
            throw new Error();
        }
        return Promise.resolve({
            totalItems: result.data.totalNumberOfItems,
            items: result.data.items.map(this.parseListItem),
            summary: this.parseSummary(result.data)
        });
    }

    abstract downloadItemsList(offset: number | undefined, limit: number | undefined, filters?: FiltersType, orderCriteria?: OrderCriteria, accept?: string): AxiosPromise<any>;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // abstract downloadReport(country: Country, filters: any, orderCriteria?: OrderCriteria): Promise<void>;

    downloadReport = async (filters: any, orderCriteria?: OrderCriteria) => {
        return this.downloadItemsList(undefined, undefined, filters, orderCriteria, HEADER_ACCEPT_XLSX).then(result => {
            if (result.status === HttpStatusCode.OK) {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    base64toBlob(result.data, HEADER_ACCEPT_XLSX)
                    //new Blob([result.data]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download', `${this.reportTypeLabel}_report_${formattedNowForReportFilename(true)}.xlsx`
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();
                return Promise.resolve();
            }
            else {
                throw new ApiError(result.status);
            }
        });
    };
}