import { AxiosPromise } from "axios";
import apiManager from "../../common/api/api-manager";
import HttpStatusCode from "../../common/api/http-status.code";
import { ListApiService } from "../../common/api/items-list/list-api-service";
import { TimePeriodFilter } from "../../common/models/filters";
import { OrderCriteria, SortOrder } from "../../common/models/order-criteria";
import { parseProviderFromApiModel } from "../../providers/api/providers/provider-api-parser";
import { Provider, ProviderOrderCriteria } from "../../providers/model/provider";
import { ProvidersSearchResult } from "../../_generated/api";
import { Transaction, TransactionsSummary } from "../model/transaction";
import { apiMonthFilterFromTimePeriod, TransactionsFilter } from "../model/transaction-filter";
import { parseTransactionFromApiModel, parseTransactionsSummaryFromApiModel } from "./transaction-api-parser";

class TransactionsApiService extends ListApiService<Transaction, TransactionsFilter, TransactionsSummary> {
    
    parseListItem(apiResponse: any): Transaction {
        return parseTransactionFromApiModel(apiResponse);
    }

    parseSummary(apiResponse: any): TransactionsSummary {
        return parseTransactionsSummaryFromApiModel(apiResponse);
    }
    
    downloadItemsList(offset: number, limit: number, filters: TransactionsFilter, orderCriteria?: OrderCriteria, accept?: string): AxiosPromise<any> {
        

        const config = accept === undefined ? undefined : {
            headers: {
                'Accept': accept
            }
        };
        const monthFilter = apiMonthFilterFromTimePeriod(filters?.timePeriod as TimePeriodFilter);
        
        return apiManager.paymentApi.searchPayments(
            apiManager.idToken,
            offset,
            limit,
            filters.countryId?.toUpperCase(),
            undefined,
            filters?.cpoId ?? undefined,
            monthFilter.monthStartIndex,
            monthFilter.monthEndIndex,
            orderCriteria ? `${orderCriteria.key}:${orderCriteria.sortOrder.toLowerCase()}` : undefined,
            config
        );
    }
  
    async getListOfCPO(country?: string): Promise<Provider[]> {   
        
        const numberMaxNumberOfReturnableCPO = 50; //the Max is 100

        const result = await apiManager.providerApi.searchProviders(
            apiManager.idToken,
            numberMaxNumberOfReturnableCPO,
            undefined,
            country ? country.toUpperCase() : undefined,
            undefined,
            undefined,
            undefined,
            `${ProviderOrderCriteria.NAME}:${SortOrder.ASC.toLowerCase()}`
        );

        if (result.status !== HttpStatusCode.OK) {
            throw Error();
        }

        const providerFoundInTheFirstCall = result.data.items.map(parseProviderFromApiModel);
        const totalNumberOfProvider = result.data.totalNumberOfItems;
        let numberOfProviderFound = providerFoundInTheFirstCall.length;

        const getProvidersPromises : Promise<ProvidersSearchResult>[] = [];

        while(numberOfProviderFound < totalNumberOfProvider) {
            getProvidersPromises.push(
                apiManager.providerApi.searchProviders(
                    apiManager.idToken,
                    numberMaxNumberOfReturnableCPO,
                    numberOfProviderFound,
                    country ? country.toUpperCase() : undefined,
                    undefined,
                    undefined,
                    undefined,
                    `${ProviderOrderCriteria.NAME}:${SortOrder.ASC.toLowerCase()}`
                ).then(res => {
                    if(res.status !== HttpStatusCode.OK) throw Error();
                    else return res.data;
                })
            );
            numberOfProviderFound += numberMaxNumberOfReturnableCPO;
        }
        
        const providers = await Promise.all(getProvidersPromises);

        return providers.reduce((acc, curr) => acc.concat(curr.items.map(parseProviderFromApiModel)), providerFoundInTheFirstCall);

    }

}


export const transactionApiService = new TransactionsApiService('transactions');