import { User, UserModel, UserRole } from "../../common/models/user";
import { BackofficeUser } from "../../_generated/api";

export const parseApiBackofficeUser = (backofficeUser: BackofficeUser): User => {
    return UserModel.create({
        id: backofficeUser.id,
        email: backofficeUser.email,
        country: backofficeUser.country.toUpperCase(),
        locale: backofficeUser.locale,
        name: backofficeUser.name,
        contactDetails: backofficeUser.telephone,
        role: backofficeUser.role.toString() as UserRole

    });
};