import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import { PagedListCell, PagedListCellVariant } from "../../../../common/components/paged-list/paged-list-cell";
import { Action, ActionsMenu } from "../../../../common/components/actions/actions-menu";

import { Fare, FareFee, FareFeeType, SocketType } from "../../../model/fare";
import { UserRole } from "../../../../common/models/user";
import { fromCurrencyToMessageDescriptor, fromCurrencyToSymbol } from "../../../../common/models/currency";
import { fromPricingRefernceUnitToMessageDescriptor } from "../../../../common/models/pricing-reference-unit";

import IconArrowRight from '../../../../assets/images/icon-arrow-right.svg';

import actionMessages from "../../../../common/messages/action.messages";
import contractDetailsMessages from "../../../contract-details.messages";

export interface ContractDetailsItemProps {
    item: Fare;
    contractBaseInflation: number;
    isContractExpired: boolean;
    isContractNgp: boolean;
    userRole: UserRole;

    onUpdateFareClick: (fare: Fare) => void;
    onDuplicateFareClick: (fare: Fare) => void;
    onDeleteFareClick: (fare: Fare) => void;
    onItemClick?: (fare: Fare) => void;
}

export const ContractDetailsItem = (props: ContractDetailsItemProps) => {

    const { formatMessage } = useIntl();

    const socketType = useMemo(() => {
        if (!props.item.socketType) {
            return contractDetailsMessages.allSockets;
        }
        switch (props.item.socketType) {
            case SocketType.AC:
                return contractDetailsMessages.acSocketType;
            case SocketType.DC:
                return contractDetailsMessages.dcSocketType;
            case SocketType.HPC_UFC:
                return contractDetailsMessages.hpcUfcSocketType;
        }
    }, [props.item.socketType]);

    const powerRange = useMemo(() => {
        if (!props.item.chargingPowerRange) {
            return formatMessage(contractDetailsMessages.allPowerCharges);
        }

        if (props.item.chargingPowerRange.min && props.item.chargingPowerRange.max) {

            if (props.item.chargingPowerRange.min.value == props.item.chargingPowerRange.max.value) {
                return `${props.item.chargingPowerRange.min.value} Kwh`;
            } else {
                return `${props.item.chargingPowerRange.min.value} - ${props.item.chargingPowerRange.max.value} Kwh`;
            }
        }

        if (props.item.chargingPowerRange.min) {
            return formatMessage(contractDetailsMessages.powerRangeFromValueMessage, {
                value: props.item.chargingPowerRange.min.value
            });
        }

        if (props.item.chargingPowerRange.max) {
            return formatMessage(contractDetailsMessages.powerRangeToValueMessage, {
                value: props.item.chargingPowerRange.max.value
            });
        }

        return formatMessage(contractDetailsMessages.allPowerCharges);
    }, [formatMessage, props.item.chargingPowerRange]);

    const inflationStyle = useMemo(() => {
        const baseInflation = props.contractBaseInflation.toFixed(2);
        const fareInflation = props.item.inflation?.toFixed(2);

        if (baseInflation == fareInflation || props.item.inflation === undefined) {
            return undefined;
        } else if (props.contractBaseInflation > props.item.inflation) {
            return PagedListCellVariant.Success;
        } else if (props.contractBaseInflation < props.item.inflation) {
            return PagedListCellVariant.Warning;
        }

    }, [props.contractBaseInflation, props.item.inflation]);

    const evseIds = useMemo(() => {
        if (props.item.plugIds.length == 0) {
            return formatMessage(contractDetailsMessages.allEvseIds);
        }

        return props.item.plugIds.join('; ');
    }, [formatMessage, props.item.plugIds]);

    const actions: Action[] = useMemo(() => {
        if (!props.isContractNgp) {
          
            return [
                {
                    label: formatMessage(actionMessages.updateFareActionLabel),
                    execute: () => props.onUpdateFareClick(props.item)
                },
                {
                    label: formatMessage(actionMessages.duplicateActionLabel),
                    execute: () => props.onDuplicateFareClick(props.item)
                },
                {
                    label: formatMessage(actionMessages.deleteFareActionLabel),
                    execute: () => props.onDeleteFareClick(props.item)
                }
            ];
        }else{
            return [];
        }
    }, [props, formatMessage]);




    const costsSummary = useMemo(() => {
        const costs: string[] = [];
        const otherFees: string[] = [];

        const hasMoreConditions = (fareFee: FareFee): boolean => {
            return fareFee.gracePeriod !== undefined
                || (fareFee.minimumReferenceUnit ?? 0) > 0;
        };

        const price = (fareFee: FareFee, type: FareFeeType): string => {
            return `${fareFee.price.toFixed(2)}${(fareFee.priceReferenceUnit
                ? formatMessage(
                    fromPricingRefernceUnitToMessageDescriptor(fareFee.priceReferenceUnit),
                    { currency: fromCurrencyToSymbol(props.item.currency) }
                )
                : fromCurrencyToSymbol(props.item.currency)
            )}${hasMoreConditions(fareFee) || props.item.otherFees.find(elem => elem.type === type) ? '*' : ''}`;
        };

        if (props.item.sessionFee) {
            costs.push(`${formatMessage(contractDetailsMessages.sessionFareFeeType)} ${price(props.item.sessionFee, FareFeeType.SESSION)}`);
        }

        if (props.item.chargingFee) {
            costs.push(`${formatMessage(contractDetailsMessages.chargingFareFeeType)} ${price(props.item.chargingFee, FareFeeType.CHARGING)}`);
        }

        if (props.item.parkingFee) {
            costs.push(`${formatMessage(contractDetailsMessages.parkingFareFeeType)} ${price(props.item.parkingFee, FareFeeType.PARKING)}`);
        }

        if (props.item.otherFees.length > 0) {
            props.item.otherFees.forEach((otherFee) => {
                let label = '';
                switch (otherFee.type) {
                    case FareFeeType.SESSION:
                        label = formatMessage(contractDetailsMessages.sessionFareFeeType);
                        break;
                    case FareFeeType.CHARGING:
                        label = formatMessage(contractDetailsMessages.chargingFareFeeType);
                        break;
                    case FareFeeType.PARKING:
                        label = formatMessage(contractDetailsMessages.parkingFareFeeType);
                        break;
                    default:
                        break;
                }
                otherFees.push(`${label} ${price(otherFee.fee, otherFee.type)}`);
            });
        }

        return [costs.join(' - '), otherFees.join(' - ')];
    }, [
        formatMessage, props.item.sessionFee,
        props.item.chargingFee, props.item.currency,
        props.item.otherFees, props.item.parkingFee,
    ]);

    const onItemClick = useCallback(
        () => props.onItemClick && props.onItemClick(props.item),
        [props]
    );

    const [fees, otherFees] = costsSummary;

    return <>


        <tr className="contract-details-td" onClick={onItemClick}>
            <PagedListCell variant={PagedListCellVariant.Bold} isTextNotCapitalized={true}>{props.item.fareId}</PagedListCell>
            <PagedListCell>{props.item.fareName}</PagedListCell>
            <PagedListCell>{formatMessage(fromCurrencyToMessageDescriptor(props.item.currency))}</PagedListCell>
            <PagedListCell>{formatMessage(socketType)}</PagedListCell>
            <PagedListCell isTextNotCapitalized={true} className="power-range">{powerRange}</PagedListCell>
            <PagedListCell variant={inflationStyle}>{(props.item.inflation ?? props.contractBaseInflation).toFixed(2) + '%'}</PagedListCell>
            <PagedListCell>
                {formatMessage(props.item.isValid24h ? contractDetailsMessages.fareIsNotInSpecificTimeSlot : contractDetailsMessages.fareIsInSpecificTimeSlot)}
            </PagedListCell>
            <PagedListCell className="evse-id">{evseIds}</PagedListCell>
            <PagedListCell className="operator-notes" isTextNotCapitalized={true}>{props.item.operatorNotes ?? ''}</PagedListCell>
            <td className='actions'>
                {!props.isContractExpired && props.userRole !== UserRole.Operator && <ActionsMenu actions={actions} />}
                <img src={IconArrowRight} alt="" className='arrow-icon' />
            </td>
        </tr>
        <tr className="item-costs-summary">
            <td colSpan={10}>
                <div className="item-costs-row">
                    <div>
                        <span className="summary-costs-label">{formatMessage(contractDetailsMessages.costsSummaryText)}</span> {fees}
                        { /* whitespace */}
                        <span className="summary-costs-label"></span>
                        <span className="summary-costs-label"></span>
                        {
                            otherFees && (<>
                                <span className="summary-costs-label">{formatMessage(contractDetailsMessages.otherFeeText)}</span> {otherFees}
                            </>)
                        }
                    </div>
                    <span>{formatMessage(contractDetailsMessages.fareInflationValue, {
                        fareBaseInflation: (props.item.inflation ?? props.contractBaseInflation).toFixed(2),
                        currency: '%'
                    })}</span>
                </div>

            </td>
        </tr>
    </>;
};