import { Instance, types } from "mobx-state-tree";
import { MessageDescriptor } from "react-intl";
import { ValidityDay as ApiValidityDay } from "../../_generated/api";
import commonMessages from "../messages/common.messages";

export enum ValidityDay {
    EVERYDAY = 'everyday',
    WORKDAYS = 'workdays',
    WEEKENDS = 'weekends',
    MONDAY = 'monday',
    TUESDAY = 'tuesday',
    WEDNESDAY = 'wednesday',
    THURSDAY = 'thursday',
    FRIDAY = 'friday',
    SATURDAY = 'saturday',
    SUNDAY = 'sunday'
}

export function parseValidityDayFromApiModel(apiValidityDay: ApiValidityDay): ValidityDay {
    switch(apiValidityDay){
        case ApiValidityDay.Everyday:
            return ValidityDay.EVERYDAY;
        case ApiValidityDay.Workdays:
            return ValidityDay.WORKDAYS;
        case ApiValidityDay.Weekend:
            return ValidityDay.WEEKENDS;
        case ApiValidityDay.Monday:
            return ValidityDay.MONDAY;
        case ApiValidityDay.Tuesday:
            return ValidityDay.TUESDAY;
        case ApiValidityDay.Wednesday:
            return ValidityDay.WEDNESDAY;
        case ApiValidityDay.Thursday:
            return ValidityDay.THURSDAY;
        case ApiValidityDay.Friday:
            return ValidityDay.FRIDAY;
        case ApiValidityDay.Saturday:
            return ValidityDay.SATURDAY;
        case ApiValidityDay.Sunday:
            return ValidityDay.SUNDAY;
    }
}

export function parseValidityDayToApiModel(validityDay: ValidityDay): ApiValidityDay {
    switch(validityDay){
        case ValidityDay.EVERYDAY:
            return ApiValidityDay.Everyday;
        case ValidityDay.WORKDAYS:
            return ApiValidityDay.Workdays;
        case ValidityDay.WEEKENDS:
            return ApiValidityDay.Weekend;
        case ValidityDay.MONDAY:
            return ApiValidityDay.Monday;
        case ValidityDay.TUESDAY:
            return ApiValidityDay.Tuesday;
        case ValidityDay.WEDNESDAY:
            return ApiValidityDay.Wednesday;
        case ValidityDay.THURSDAY:
            return ApiValidityDay.Thursday;
        case ValidityDay.FRIDAY:
            return ApiValidityDay.Friday;
        case ValidityDay.SATURDAY:
            return ApiValidityDay.Saturday;
        case ValidityDay.SUNDAY:
            return ApiValidityDay.Sunday;
    }
}

export function fromValidityDayToMessageDescriptor(validityDay: ValidityDay): MessageDescriptor {
    switch(validityDay){
        case ValidityDay.EVERYDAY:
            return commonMessages.everydayValidityPeriod;
        case ValidityDay.WORKDAYS:
            return commonMessages.workdaysValidityPeriod;
        case ValidityDay.WEEKENDS:
            return commonMessages.weekendsValidityPeriod;
        case ValidityDay.MONDAY:
            return commonMessages.mondaysValidityPeriod;
        case ValidityDay.TUESDAY:
            return commonMessages.tuesdaysValidityPeriod;
        case ValidityDay.WEDNESDAY:
            return commonMessages.wednesdaysValidityPeriod;
        case ValidityDay.THURSDAY:
            return commonMessages.thursdaysValidityPeriod;
        case ValidityDay.FRIDAY:
            return commonMessages.fridaysValidityPeriod;
        case ValidityDay.SATURDAY:
            return commonMessages.saturdaysValidityPeriod;
        case ValidityDay.SUNDAY:
            return commonMessages.sundaysValidityPeriod;
    }
}

export const DateIntervalModel = types.model({
    from: types.Date,
    to: types.Date
});

export interface DateInterval extends Instance<typeof DateIntervalModel> { }