import React from "react";
import { observer } from "mobx-react-lite";

import { ShadowContainer } from "../../../../../common/components/container/shadow-container";
import { ContractDetailsSummaryTitle } from "./contract-details-summary-title";
import { ContractDetailsSummaryData } from "./contract-details-summary-data";

import { Contract } from "../../../../model/contract";
import { UserRole } from "../../../../../common/models/user";

import './contract-details-summary.scss';

export interface ContractDetailsSummaryProps {
    contract: Contract;
    onUpdateButtonClick: () => void;
    onBlockButtonClick: () => void;
    userRole: UserRole;
}

export const ContractDetailsSummary = observer((props: ContractDetailsSummaryProps) => {

    return <ShadowContainer className="contract-details-summary">

        <ContractDetailsSummaryTitle
            contractStartDate={props.contract.validityPeriod.from}
            contractEndDate={props.contract.validityPeriod.to}
            baseFee={props.contract.inflation.baseInflation}
            showUpdateButton={props.userRole !== UserRole.Operator}
            onUpdateButtonClick={props.onUpdateButtonClick}
            canUpdateContract={props.contract.isNotExpired}
        />

        <ContractDetailsSummaryData
            contract={props.contract}
            showBlockButton={props.userRole !== UserRole.Operator && props.contract.isNotExpired}
            onBlockButtonClick={props.onBlockButtonClick}
        />

    </ShadowContainer>;
});