export interface CancelablePromise<T> {
    cancel: () => void;
    promise: Promise<T>;
}

export function makeCancelable<T>(promise: Promise<T>): CancelablePromise<T> {
    let isCanceled = false;
    let promiseReject: () => void | null;
    const wrappedPromise =
        new Promise<T>((resolve, reject) => {
            promiseReject = reject;
            promise
                .then((val) => (isCanceled ? reject({ isCanceled }) : resolve(val)))
                .catch((error) => (isCanceled ? reject({ isCanceled }) : reject(error)));
        });
    return {
        promise: wrappedPromise,
        cancel() {
            isCanceled = true;
            promiseReject();
        },
    };
}

