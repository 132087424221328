import { Instance, types } from "mobx-state-tree";
import { createContext, useContext } from "react";
import { ProviderListStoreModel } from "../../providers/store/provider-store";
import { TransactionListStoreModel } from "../../transaction/store/transaction-store";
import { NotificationsStoreModel } from './notifications-store';
import { UserStoreModel } from "./user-store";
import { AppUsersListStoreModel } from "../../users/store/users-store";
import { GlobalStoreModel } from "./global-store";
import { AdministrationListStoreModel } from "../../administration/store/admin-store";
import { OrderCriteriaModel, SortOrder } from "../models/order-criteria";

export const RootStore =
    types.model("RootStore", {
        globalStore: GlobalStoreModel,
        notificationStore: NotificationsStoreModel,
        providerStore: ProviderListStoreModel,
        appUsersStore: AppUsersListStoreModel,
        userStore: UserStoreModel,
        transactionStore: TransactionListStoreModel,
        administrationStore: AdministrationListStoreModel
    });

export interface RootStoreModel extends Instance<typeof RootStore> { }

export const createStore = (): RootStoreModel => {

    const env: any = {
    };

    const globalStore = GlobalStoreModel.create({});
    env['globalStore'] = globalStore;

    const notificationStore = NotificationsStoreModel.create();
    env['notificationStore'] = notificationStore;
    
    const appUsersStore = AppUsersListStoreModel.create({
        orderCriteria: OrderCriteriaModel.create({
            key: 'lastChargeDate',
            sortOrder: SortOrder.DESC
        })
    }, env);
    const providerStore = ProviderListStoreModel.create({}, env);
    env['providerStore'] = providerStore;
    const transactionStore = TransactionListStoreModel.create({}, env);
    const administrationStore = AdministrationListStoreModel.create({
        orderCriteria: OrderCriteriaModel.create({
            key: 'name',
            sortOrder: SortOrder.ASC
        })
    }, env);

    env['listStores'] = [appUsersStore, providerStore, transactionStore, administrationStore];

    const userStore = UserStoreModel.create(undefined, env);

    //@ts-ignore
    return RootStore.create({ globalStore, appUsersStore, notificationStore, providerStore, userStore, transactionStore, administrationStore }, env);
};



const StoreContext = createContext<RootStoreModel>({} as RootStoreModel);
export const useStore = () => useContext(StoreContext);
export const StoreProvider = StoreContext.Provider;
