import React, { ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { IAnyType, Instance, SnapshotOrInstance } from 'mobx-state-tree';

import { AppButtonProps, AppButtonVariant } from '../../../../common/components/button/app-button';
import { TopMenu } from '../../../../common/components/menu/top-menu';
import { Footer } from '../../../../common/components/footer/footer';
import { PagedList } from '../../../../common/components/paged-list/paged-list';
import { ButtonRow } from '../../../../common/components/button-row/button-row';
import { SectionPagedListHeader } from './section-paged-list-header';

import { UserRole } from '../../../../common/models/user';
import { OrderManagerProps } from '../../../../common/models/order-criteria';
import { useStore } from '../../../../common/stores/root-store';
import { PagedListStoreModel } from '../../../../common/stores/paged-list/paged-list-store';

import commonMessages from '../../../../common/messages/common.messages';

interface SectionPagedListProps<ItemsType extends IAnyType, FiltersType extends IAnyType, SummaryType extends IAnyType> {
    isAdmin: boolean;

    columnsCount: number;
    store: PagedListStoreModel<ItemsType, FiltersType, SummaryType>;

    tableClass?: string;
    tableTitle?: string;


    renderSectionHeader: (isLoadingElements: boolean) => ReactNode;
    renderTableHeader: (orderProps: OrderManagerProps) => ReactNode;
    renderItem: (item: Instance<ItemsType>, index: number) => ReactNode;
    renderSummary?: () => ReactNode;

    onAddNewButtonClick: () => void;
    createButtonEnabled: boolean;

    openItemDetail?: (item: SnapshotOrInstance<ItemsType>) => void;
}

export const SectionPagedList = observer(<ItemsType extends IAnyType, FiltersType extends IAnyType, SummaryType extends IAnyType>(
    props: SectionPagedListProps<ItemsType, FiltersType, SummaryType>) => {

    /* ---------------- Setup ---------------- */

    const { userStore } = useStore();

    const intl = useIntl();
    const { formatMessage } = intl;

    /* ---------------- Render page elements ---------------- */

    const downloadXLS = useCallback(() => {
        props.store.downloadReport();
    }, [props.store]);

    const renderAdditionalHeaderComponents = useCallback(() => {
        return props.renderSectionHeader(props.store.isLoadingItems);
    }, [props]);

    const renderHeader = useCallback(() => {

        return <SectionPagedListHeader
            renderAdditionalHeaderComponents={renderAdditionalHeaderComponents}
        />;
    }, [renderAdditionalHeaderComponents]);

    const renderTableFooter = useCallback((isTableEmpty: boolean) => {
        const buttons: AppButtonProps[] = [
            {
                label: formatMessage(commonMessages.downloadXLS),
                variant: AppButtonVariant.Dark,
                onClick: downloadXLS,
                disabled: props.store.isDownloadingReport || isTableEmpty
            }
        ];

        if (userStore.user?.role !== UserRole.Operator) {
            buttons.push({
                label: formatMessage(commonMessages.addNew),
                variant: AppButtonVariant.Primary,
                onClick: props.onAddNewButtonClick,
                disabled: !props.createButtonEnabled
            });
        }
        return <ButtonRow buttons={buttons} />;
    }, [
        downloadXLS, formatMessage, props.createButtonEnabled,
        props.onAddNewButtonClick, props.store.isDownloadingReport,
        userStore.user?.role
    ]);

    /* ---------------- Layout ---------------- */

    return <div className='page'>

        <TopMenu isAdmin={userStore.user?.role === UserRole.Admin} />

        <PagedList
            tableTitle={props.tableTitle}
            tableClass={`section-table ${props.tableClass ?? ''}`}
            renderHeader={renderHeader}
            renderTableFooter={renderTableFooter}
            store={props.store}
            columnsCount={props.columnsCount}
            renderSummary={props.renderSummary}
            renderTableHeader={props.renderTableHeader}
            renderTableRow={props.renderItem}
            openItemDetail={props.openItemDetail}
        />

        <Footer />

    </div>;
});