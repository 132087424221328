import { BackofficeUser as ApiBackofficeUser } from "../../_generated/api";
import { User, UserModel, UserRole } from "../../common/models/user";

export const parseBackofficeUserFromApiModel = (apiProvider: ApiBackofficeUser): User => {
    return UserModel.create({
        email: apiProvider.email,
        id: apiProvider.id,
        name: apiProvider.name,
        contactDetails: apiProvider.contact_details,
        country: apiProvider.country,
        role: Object.values(UserRole).find(elem => elem.toString() == apiProvider.role.toString()) ?? UserRole.Operator,
        locale: apiProvider.locale
    });
};