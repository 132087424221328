import { defineMessages } from 'react-intl';

export default defineMessages({
    "boxTitle": {
        "id": "containers.changePassword.changePasswordBoxTitle",
        "defaultMessage": "Cambia password"
    },
    "oldPasswordSectionTitle": {
        "id": "containers.changePassword.oldPasswordSectionTitle",
        "defaultMessage": "Vecchia password"
    },
    "newPasswordSectionTitle": {
        "id": "containers.changePassword.newPasswordSectionTitle",
        "defaultMessage": "Nuova password"
    },
    "confirmPasswordPlaceholder": {
        "id": "containers.changePassword.confirmPasswordPlaceholder",
        "defaultMessage": "Ripeti password"
    },
    "passwordValidationFailed":{
        "id": "containers.changePassword.passwordValidationFailed",
        "defaultMessage": "La password deve essere di almeno 8 caratteri e contenere lettere (maiuscole e minuscole), numeri e simboli speciali"
    },
    "notMatchingPasswords": {
        "id": "containers.changePassword.notMatchingPasswords",
        "defaultMessage": "Le password non coincidono"
   
    },
    "passwordUpdated": {
        "id": "containers.changePassword.passwordUpdated",
        "defaultMessage": "La password è stata aggiornata"
    }
});