import { SnapshotOrInstance, types } from "mobx-state-tree";

export enum SortOrder {
    ASC = 'ASC', DESC = 'DESC'
}
export const OrderCriteriaModel = types.model({
    key: types.string,
    sortOrder: types.enumeration<SortOrder>(Object.values(SortOrder))
}).views(self => {
    return {
        get queryParamValue(): string { 
            return `${self.key}:${self.sortOrder.toLowerCase()}`;
        },
    };
});


export const nextSortOrder = (sortOrder?: SortOrder): SortOrder | null => {
    switch (sortOrder) {
        case SortOrder.ASC:
            return SortOrder.DESC;
        case SortOrder.DESC:
            return null;
        default: return SortOrder.ASC;
    }
};

//export interface OrderCriteria extends SnapshotOrInstance<typeof OrderCriteriaModel>{}
export type OrderCriteria = SnapshotOrInstance<typeof OrderCriteriaModel>;

export interface OrderManagerProps {
    selectedOrder?: OrderCriteria;
    setOrder: (key: string, order?: SortOrder) => void;
}