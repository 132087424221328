import React, { ChangeEventHandler, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { StyledInput } from '../../../../../../common/components/input/styled-input';
import { StyledSelect } from '../../../../../../common/components/input/styled-select';
import { isNumber } from '../../../../../../common/utils/validators';
import contractDetailsMessages from '../../../../../contract-details.messages';
import { RangeValueCondition } from '../../../../../model/fare';

interface PowerRangeSelectorProps {
    isMin: boolean;
    value: string;
    rangeValueCondition: RangeValueCondition;

    onRangeValueConditionChange: (condition: RangeValueCondition) => void;
    onValueChange: (value: string) => void;
    
    disabled: boolean;
}

export const PowerRangeSelector = (props: PowerRangeSelectorProps) => {
    
    const { formatMessage } = useIntl();

    const rangeValueConditionItems = useMemo(() => [
        {
            id: RangeValueCondition.INCLUSIVE,
            label: props.isMin ? '\u2265' : '\u2264'
        },
        {
            id: RangeValueCondition.EXCLUSIVE,
            label: props.isMin ? '>' : '<'
        },
    ], [props.isMin]);

    const onRangeValueConditionChange = useCallback((conditionId: string) => {
        const rangeValueCondition = Object.values(RangeValueCondition).find(elem => elem === conditionId);
        rangeValueCondition && props.onRangeValueConditionChange(rangeValueCondition);
    }, [props]);

    const onValueChange: ChangeEventHandler<HTMLInputElement> = useCallback((ev) => {
        const value = ev.target.value;
        if (isNumber(value)) {
            if (value.endsWith('.')) {
                props.onValueChange(value);
                return;
            } else {
                if (value !== undefined && value !== null) {
                    props.onValueChange(value);
                    return;
                }
            }
        }
    }, [props]);
    
    return <div className="cc-input-box power-range-selector">
        <span>{formatMessage(props.isMin 
            ? contractDetailsMessages.minPowerRangeLabel 
            : contractDetailsMessages.maxPowerRangeLabel
        )}*
        </span>

        <div  className='input-fields-row'>
            <StyledSelect 
                selectedItemId={props.rangeValueCondition}
                items={rangeValueConditionItems}
                onItemSelected={onRangeValueConditionChange}
                disabled={props.disabled}
            />
            <StyledInput 
                value={props.value}
                onChange={onValueChange}
                disabled={props.disabled}
            />
        </div>

    </div>;
};