import React, { ChangeEventHandler, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { StyledInput } from "../../../../../common/components/input/styled-input";
import { StyledSelect } from "../../../../../common/components/input/styled-select";
import { StyledTextArea } from "../../../../../common/components/input/styled-text-area";
import { Currency, fromCurrencyToMessageDescriptor } from "../../../../../common/models/currency";
import { isNumber } from "../../../../../common/utils/validators";
import { FareInflationType } from '../../../../model/fare';
import contractDetailsMessages from "../../../../contract-details.messages";

import './fare-data-content.scss';

export interface FareDataContentProps {
    inflation: string;
    onInflationChange: (inflation?: string) => void;
    fareInflationTypes: Array<{id: FareInflationType, label: string}>;
    fareInflationType: string;
    onFareInflationTypeChange: (fareInflationTypeId: string) => void;
    contractBaseInflation: string;

    fareId: string;
    onFareIdChange: (fareId: string) => void;
    
    fareName: string;
    onFareNameChange: (fareName: string) => void;

    currency: Currency;
    onCurrencyChange: (currency: Currency) => void;

    operatorNotes: string;
    onOperatorNotesChange: (notes: string) => void;

    evseIdList: string[];

    canUpdateData: boolean;
}

export const FareDataContent = (props: FareDataContentProps) => {
    
    const { formatMessage } = useIntl();

    const onInflationChange: ChangeEventHandler<HTMLInputElement> = useCallback((ev) => {
        const value = ev.target.value;
        if (isNumber(value) || value.startsWith('-')) {
            if (value.endsWith('.')) {
                props.onInflationChange(value);
                return;
            } else {
                if (value !== undefined && value !== null && value.trim().length > 0) {
                    props.onInflationChange(value);
                    return;
                }
            }
        }
        props.onInflationChange(undefined);
    }, [props]);

    const onIdChange: ChangeEventHandler<HTMLInputElement> = useCallback(
        (ev) => props.onFareIdChange(ev.target.value),
        [props]
    );

    const onNameChange: ChangeEventHandler<HTMLInputElement> = useCallback(
        (ev) => props.onFareNameChange(ev.target.value),
        [props]
    );

    const currencyItems = useMemo(() => Object.values(Currency).map(elem =>({
        id: elem,
        label: formatMessage(fromCurrencyToMessageDescriptor(elem))
    })), [formatMessage]);

    const onCurrencyChange = useCallback((currencyId: string) => {
        const currency = Object.values(Currency).find(elem => elem === currencyId);
        currency && props.onCurrencyChange(currency);
    }, [props]);
    
    return <div className="fare-data-content bordered">

        <div className="section-title">
            {formatMessage(contractDetailsMessages.fareDataSectionTitle)}

            <div className='inflation'>
                {formatMessage(contractDetailsMessages.fareInflationLabel, {currency: '%'})}

                <div className='column'>
                    <StyledSelect
                        items={props.fareInflationTypes}
                        selectedItemId={props.fareInflationType}
                        onItemSelected={props.onFareInflationTypeChange}
                        disabled={!props.canUpdateData}
                        hideLabelItem
                    />
                    <div className="spacing"></div>
                    <StyledInput 
                        value={props.fareInflationType === FareInflationType.CONTRACT ? props.contractBaseInflation : props.inflation}
                        onChange={onInflationChange}
                        disabled={!props.canUpdateData || props.fareInflationType == FareInflationType.CONTRACT}
                    />
                </div>
            </div>

        </div>

        <div className="main-data">
            <StyledInput 
                className="fare-id"
                label={formatMessage(contractDetailsMessages.fareIdLabel)}
                onChange={onIdChange}
                value={props.fareId}
                disabled={!props.canUpdateData}
            />
            <StyledInput 
                className="fare-name"
                label={formatMessage(contractDetailsMessages.fareNameLabel)}
                onChange={onNameChange}
                value={props.fareName}
                disabled={!props.canUpdateData}
            />
            <StyledSelect
                containerClassName="currency"
                label={formatMessage(contractDetailsMessages.fareCurrencyLabel)}
                items={currencyItems}
                selectedItemId={props.currency}
                onItemSelected={onCurrencyChange}
                disabled={!props.canUpdateData}
                hideLabelItem
            />
        </div>

        <div className="input-style-row">

            <StyledTextArea
                label={formatMessage(contractDetailsMessages.operatorNotesLabel)}
                initialValue={props.operatorNotes}
                onTextChange={props.onOperatorNotesChange}
                placeholder={formatMessage(contractDetailsMessages.operatorNotesPlaceholder)}
                readOnly={!props.canUpdateData}
            />

            <StyledTextArea 
                label={formatMessage(contractDetailsMessages.evseIdListLabel)}
                initialValue={props.evseIdList.length > 0 ? props.evseIdList.join('; ') : formatMessage(contractDetailsMessages.allEvseIds)}
                readOnly
            />

        </div>

    </div>;
};