import React, { useCallback } from "react";
import { useIntl } from "react-intl";

import { FareFeeTypeOptions } from "../fare-fee-type-options/fare-fee-type-options";
import { FareFeeData } from "./fare-fee-data";

import { FareFeeInfo } from "../../../../../model/fare-info";

import contractDetailsMessages from "../../../../../contract-details.messages";
import { FareFeeType } from "../../../../../model/fare";

export interface ChargingFeeDataProps {
    chargingFee?: FareFeeInfo;
    onChargingFeeChange: (chargingFee?: FareFeeInfo) => void;
    canUpdateData: boolean;
}

export const ChargingFeeData = (props: ChargingFeeDataProps) => {
    
    const { formatMessage } = useIntl();

    const renderTitle = useCallback((value: string, unitOfMeasure: string) => formatMessage(
        contractDetailsMessages.fareChargingCostsSectionTitle, 
        {
            value,
            unitOfMeasure
        }
    ),[formatMessage]);

    const renderDescription = useCallback(() => formatMessage(
        contractDetailsMessages.fareChargingCostsSectionDescription 
    ),[formatMessage]);
    
    const renderFeeOptions = useCallback(() => props.chargingFee && 
        <FareFeeTypeOptions 
            fareFeeType={FareFeeType.CHARGING}
            fareFee={props.chargingFee}
            onFareFeeChange={props.onChargingFeeChange}
            canUpdateData={props.canUpdateData}
        />
    , [props.canUpdateData, props.onChargingFeeChange, props.chargingFee]);

    const onSelectFareFeeChange = useCallback((isSelected: boolean) =>
        props.onChargingFeeChange(isSelected ? {} : undefined)
    , [props]);
    
    return <FareFeeData     
        
        renderTitle={renderTitle}
        renderDescription={renderDescription}
        renderFeeOptions={renderFeeOptions}

        showFareFeeOptions={!!props.chargingFee}
        fareFee={props.chargingFee}
        onSelectFareFeeChange={onSelectFareFeeChange}
        canUpdateData={!!props.canUpdateData}
    /> ;
};