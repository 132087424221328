import { Instance, types } from "mobx-state-tree";
import { Currency } from "../../common/models/currency";
import { PricingReferenceUnit } from "../../common/models/pricing-reference-unit";
import { DateIntervalModel, ValidityDay } from "../../common/models/time";
import { MessageDescriptor } from 'react-intl';
import contractDetailsMessages from "../contract-details.messages";

export enum FareOrderCriteria {
    ID = 'fareId',
    NAME = 'fareName',
    POWER = 'maxChargingPower',
}

export enum SocketType {
    AC = 'ac',
    DC = 'dc',
    HPC_UFC = 'hpc-ufc'
}

export enum SessionMoment {
    SESSION_START = 'sessionStart',
    SESSION_END = 'sessionEnd',
    CHARGING_START = 'chargingStart',
    CHARGING_END = 'chargingEnd',
}

export enum FareFeeType {
    PARKING = 'parking',
    CHARGING = 'charging',
    SESSION = 'session',
    START = 'start',
    GENERIC = 'generic',
}

export enum RangeValueCondition {
    INCLUSIVE = 'inclusive',
    EXCLUSIVE = 'exclusive'
}

export enum FareInflationType {
    CONTRACT = 'contract',
    CUSTOM = 'custom',
}

export function fromFareInflationTypeToMessageDescriptor(fareInflationType: FareInflationType): MessageDescriptor {
    switch(fareInflationType){
        case FareInflationType.CONTRACT:
            return contractDetailsMessages.contractFareInflationType;
        case FareInflationType.CUSTOM:
            return contractDetailsMessages.customFareInflationType;
    }
}

export const FareValidityPeriodModel = types.model({
    timeInterval: DateIntervalModel,
    day: types.enumeration(Object.values(ValidityDay))
});

export const GracePeriodModel = types.model({
    priceReferenceUnit: types.enumeration(Object.values(PricingReferenceUnit)),
    minimumValue: types.number
});

export const FareFeeModel = types.model({
    price: types.number,
    // if it's undefined then it's a fare fee with a fixed price
    priceReferenceUnit: types.maybe(
        types.enumeration(Object.values(PricingReferenceUnit))
    ),
    /* 
        it indicates if there is a minimum amount that will always be considered
        for example
        - minimumReferenceUnit = 3 and priceReferenceUnit = minutes
            under 3 minutes the system will always credit 
            a session of 3 minutes 
    */
    minimumReferenceUnit: types.maybe(types.number),
    startingFrom: types.maybe(
        types.enumeration(Object.values(SessionMoment))
    ),
    endingTo: types.maybe(
        types.enumeration(Object.values(SessionMoment))
    ),
    // period where the cost won't increase
    gracePeriod: types.maybe(GracePeriodModel)
});

export const OtherFeeModel = types.model({
    type: types.enumeration(Object.values(FareFeeType)),
    fee: FareFeeModel
});

export const RangeValueModel = types.model({
    value: types.number,
    condition: types.enumeration(Object.values(RangeValueCondition))
});

export const ChargingPowerRangeModel = types.model({
    min: types.maybe(RangeValueModel),
    max: types.maybe(RangeValueModel)
});

export const FareModel = types.model({
    fareId: types.string,
    fareName: types.string,
    currency: types.enumeration(Object.values(Currency)),
    isValid24h: types.boolean,
    validityPeriods: types.array(FareValidityPeriodModel),
    socketType: types.maybe(types.enumeration(Object.values(SocketType))),
    chargingPowerRange: types.maybe(ChargingPowerRangeModel),
    inflation: types.maybe(types.number),
    plugIds: types.array(types.string),
    minimumPrice: types.maybe(types.number),
    maximumPrice: types.maybe(types.number),
    fixedPrice: types.maybe(types.number),
    subCpoId: types.maybe(types.string),
    operatorNotes: types.maybe(types.string),
    sessionFee: types.maybe(FareFeeModel),
    chargingFee: types.maybe(FareFeeModel),
    parkingFee: types.maybe(FareFeeModel),
    otherFees: types.array(OtherFeeModel),
});

export interface Fare extends Instance<typeof FareModel> { }
export interface FareValidityPeriod extends Instance<typeof FareValidityPeriodModel> { }
export interface FareFee extends Instance<typeof FareFeeModel> { }
export interface OtherFee extends Instance<typeof OtherFeeModel> { }
export interface ChargingPowerRange extends Instance<typeof ChargingPowerRangeModel> { }
export interface RangeValue extends Instance<typeof RangeValueModel> { }
export interface GracePeriod extends Instance<typeof GracePeriodModel> { }
