import React, { useCallback, useState } from 'react';
import { MenuItem, Select } from '@material-ui/core';

import { SelectItem } from './select-item';

import './styled-inputs.scss';

interface StyledSelectProps {
    label?: string;
    disabled?: boolean;

    clearItemConfig?:
    {
        label: string;
        value: string;
        onSelected: () => void;
    }

    items: SelectItem[];
    selectedItemId?: string;
    onItemSelected: (itemId: string) => void;

    containerClassName?: string;
    hideLabelItem?: boolean;
}

export const StyledSelect = (props: StyledSelectProps) => {
    const { label, items, selectedItemId, clearItemConfig, containerClassName, disabled,
        onItemSelected, hideLabelItem } = props;


    const handleChange= useCallback( (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {

        const value = event.target.value;

        if (clearItemConfig && value === clearItemConfig?.value) {
            clearItemConfig.onSelected();
        } else {
            value && typeof value === 'string' && onItemSelected(value);
        }
    },[clearItemConfig, onItemSelected]);

    const [opened, setOpened] = useState(false);
    const onOpened = useCallback(() => setOpened(true), [setOpened]);
    const onClosed = useCallback(() => setOpened(false), [setOpened]);


    return (
        <div className={`cc-input-box${containerClassName ? ' ' + containerClassName : ''}`}>
            {label && <span>{label}</span>}
            <Select
                disabled={props.disabled !== undefined ? props.disabled : false}
                className={`custom-select${opened ? ' opened' : ''}${disabled ? ' disabled' : ''}`}
                onChange={handleChange}
                value={selectedItemId || ""}
                classes={{
                    select: `custom-select-select${opened ? ' opened' : ''}`,
                    icon: 'custom-select-icon',
                    disabled: 'custom-select-disabled'
                }}
                onOpen={onOpened}
                onClose={onClosed}
            >
                {label && !hideLabelItem && <MenuItem value="" disabled>{label}</MenuItem>}
                {clearItemConfig && <MenuItem className='custom-select-clear-item' value={clearItemConfig.value}>{clearItemConfig.label}</MenuItem>}
                {items.map((item, index) =>
                    <MenuItem key={index} value={item.id}>{item.label}</MenuItem>)}
            </Select>
        </div>
    );
};