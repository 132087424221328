import React from 'react';
import { useIntl } from 'react-intl';

import stopIcon from '../../../assets/images/icon-stop.svg';
import messages from './paged-list.messages';
import './empty-list-box.scss';

export const EmptyListBox = () => {
    const { formatMessage } = useIntl();
    return <div className='empty-list-box'>
        <span className='text-standard-24'>{formatMessage(messages.emptyListMessage)}</span>
        <img src={stopIcon} alt={formatMessage(messages.empyListStopAlt)}></img>
    </div>;
};