import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { ButtonRow } from '../../../common/components/button-row/button-row';
import { BaseDialog } from '../../../common/components/dialogs/base-dialog';
import { Footer } from '../../../common/components/footer/footer';
import { TopMenu } from '../../../common/components/menu/top-menu';
import { PagedList } from '../../../common/components/paged-list/paged-list';
import { OrderManagerProps } from '../../../common/models/order-criteria';
import { useStore } from '../../../common/stores/root-store';
import { AppUser } from '../../model/app-user';
import { UsersSectionHeader } from './users-section-header';
import { UsersTableHeader } from './users-table-header';
import { UsersTableItem } from './users-table-item';

import messages from '../../users.messages';
import commonMessages from '../../../common/messages/common.messages';
import { AppButtonVariant } from '../../../common/components/button/app-button';
import { onSnapshot } from 'mobx-state-tree';
import { ApiRequestState } from '../../../common/api/api-request';
import { createServerErrorNotification, createSuccessNotification } from '../../../common/stores/notifications-store';
import { observer } from 'mobx-react-lite';
import { UserDetailPage } from '../detail/user-detail-page';

interface UsersPagedListProps {
    isAdmin: boolean;
}

export const UsersPagedList = observer(({ isAdmin }: UsersPagedListProps) => {

    const intl = useIntl();
    const { formatMessage } = intl;

    const { appUsersStore, notificationStore } = useStore();

    const { blockUserRequestState, restoreUserRequestState, deleteUserRequestState } = appUsersStore;


    const [userToBlock, setUserToBlock] = useState<AppUser | null>(null);
    const [userToRestore, setUserToRestore] = useState<AppUser | null>(null);
    const [userToDelete, setUserToDelete] = useState<AppUser | null>(null);

    const onBlockUserClick = useCallback((user: AppUser) => setUserToBlock(user), [setUserToBlock]);
    const clearUserToBlock = useCallback(() => setUserToBlock(null), [setUserToBlock]);
    const blockUser = useCallback(() => {
        if (userToBlock !== null) {
            const dispose = onSnapshot(appUsersStore, (snapshot) => {
                // Indicates if the state change has been consumed and is no more necessary to listen to it
                let managed = false;

                if (snapshot.blockUserRequestState === ApiRequestState.COMPLETED) {
                    notificationStore.showNotification(createSuccessNotification(intl));
                    appUsersStore.reload();
                    managed = true;
                } else if (snapshot.blockUserRequestState === ApiRequestState.FAILED) {
                    notificationStore.showNotification(createServerErrorNotification(intl));
                    managed = true;
                }

                if (managed) {
                    dispose();
                    setUserToBlock(null);
                    appUsersStore.clearBlockUserState();
                }
            });
            appUsersStore.blockUser(userToBlock);
        }
    }, [appUsersStore, notificationStore, userToBlock, intl]);

    const onRestoreUserClick = useCallback((user: AppUser) => setUserToRestore(user), [setUserToRestore]);
    const clearUserToRestore = useCallback(() => setUserToRestore(null), [setUserToRestore]);
    const restoreUser = useCallback(() => {
        if (userToRestore !== null) {
            const dispose = onSnapshot(appUsersStore, (snapshot) => {
                // Indicates if the state change has been consumed and is no more necessary to listen to it
                let managed = false;

                if (snapshot.restoreUserRequestState === ApiRequestState.COMPLETED) {
                    notificationStore.showNotification(createSuccessNotification(intl));
                    appUsersStore.reload();
                    managed = true;
                } else if (snapshot.restoreUserRequestState === ApiRequestState.FAILED) {
                    notificationStore.showNotification(createServerErrorNotification(intl));
                    managed = true;
                }

                if (managed) {
                    dispose();
                    setUserToRestore(null);
                    appUsersStore.clearRestoreUserState();
                }
            });
            appUsersStore.restoreUser(userToRestore);
        }
    }, [appUsersStore, notificationStore, userToRestore, intl]);

    const onDeleteUserClick = useCallback((user: AppUser) => setUserToDelete(user), [setUserToDelete]);
    const clearUserToDelete = useCallback(() => setUserToDelete(null), [setUserToDelete]);
    const deleteUser = useCallback(() => {
        if (userToDelete !== null) {
            const dispose = onSnapshot(appUsersStore, (snapshot) => {
                // Indicates if the state change has been consumed and is no more necessary to listen to it
                let managed = false;

                if (snapshot.deleteUserRequestState === ApiRequestState.COMPLETED) {
                    notificationStore.showNotification(createSuccessNotification(intl));
                    appUsersStore.onItemDeleted();
                    managed = true;
                } else if (snapshot.deleteUserRequestState === ApiRequestState.FAILED) {
                    notificationStore.showNotification(createServerErrorNotification(intl));
                    managed = true;
                }

                if (managed) {
                    dispose();
                    setUserToDelete(null);
                    appUsersStore.clearDeleteUserState();
                }
            });
            appUsersStore.deleteUser(userToDelete);
        }
    }, [appUsersStore, notificationStore, userToDelete, intl]);


    const downloadXLS = useCallback(() => {
        appUsersStore.downloadReport();
    }, [appUsersStore]);
    
    // Item detail

    const onOpenItemDetail = useCallback((user: AppUser) => {
        appUsersStore.openItemDetail(user);
    }, [appUsersStore]);


    
    // Render page elements

    const renderHeader = useCallback(() => <UsersSectionHeader />, []);

    const renderTableHeader = useCallback((orderProps: OrderManagerProps) =>
        <UsersTableHeader {...orderProps} />, []);

    const renderTableFooter = useCallback((isTableEmpty: boolean) =>
        <ButtonRow buttons={[
            {
                label: formatMessage(commonMessages.downloadXLS),
                variant: AppButtonVariant.Dark,
                onClick: downloadXLS,
                disabled: appUsersStore.isDownloadingReport || isTableEmpty
            }]} />, [appUsersStore.isDownloadingReport, downloadXLS, formatMessage]);

    const renderItem = useCallback((item: AppUser) => {
        return <UsersTableItem
            key={item.id}
            item={item}
            onOpenUserDetail={onOpenItemDetail}
            onBlockUserClick={onBlockUserClick}
            onRestoreUserClick={onRestoreUserClick}
            onDeleteUserClick={onDeleteUserClick}
        />;
    }, [onOpenItemDetail, onBlockUserClick, onDeleteUserClick, onRestoreUserClick]);

    return <div className='page'>

        <TopMenu isAdmin={isAdmin} />
        <PagedList
            renderTableFooter={renderTableFooter}
            tableClass='users-table'
            store={appUsersStore}
            columnsCount={6}
            renderTableHeader={renderTableHeader}
            renderTableRow={renderItem}
            renderHeader={renderHeader}
            openItemDetail={appUsersStore.openItemDetail} />
        <Footer />

        <BaseDialog
            open={userToBlock !== null}
            onClose={clearUserToBlock}
            title={formatMessage(messages.blockUserDialogTitle, { email: userToBlock?.email })}
            buttons={[{
                label: formatMessage(commonMessages.cancelButton),
                variant: AppButtonVariant.Dark,
                onClick: clearUserToBlock,
                disabled: blockUserRequestState === ApiRequestState.RUNNING
            }, {
                label: formatMessage(messages.blockButton),
                onClick: blockUser,
                variant: AppButtonVariant.Tertiary,
                disabled: blockUserRequestState === ApiRequestState.RUNNING

            }]}
        >
            <span>{formatMessage(messages.blockUserDialogMessage)}</span>
        </BaseDialog>

        <BaseDialog
            open={userToRestore !== null}
            onClose={clearUserToRestore}
            title={formatMessage(messages.restoreUserDialogTitle, { email: userToRestore?.email })}
            buttons={[{
                label: formatMessage(commonMessages.cancelButton),
                variant: AppButtonVariant.Dark,
                onClick: clearUserToRestore,
                disabled: restoreUserRequestState === ApiRequestState.RUNNING
            }, {
                label: formatMessage(messages.restoreButton),
                onClick: restoreUser,
                disabled: restoreUserRequestState === ApiRequestState.RUNNING
            }]}
        >
            <span>{formatMessage(messages.restoreUserDialogMessage)}</span>
        </BaseDialog>

        <BaseDialog
            open={userToDelete !== null}
            onClose={clearUserToDelete}
            title={formatMessage(messages.deleteUserDialogTitle, { email: userToDelete?.email })}
            buttons={[{
                label: formatMessage(commonMessages.cancelButton),
                variant: AppButtonVariant.Dark,
                onClick: clearUserToDelete,
                disabled: deleteUserRequestState === ApiRequestState.RUNNING
            }, {
                label: formatMessage(messages.deleteUserButton),
                onClick: deleteUser,
                disabled: deleteUserRequestState === ApiRequestState.RUNNING,
                variant: AppButtonVariant.Tertiary
            }]}
        >
            <span>{formatMessage(messages.deleteUserDialogMessage, { email: userToDelete?.email })}</span>
        </BaseDialog>

        <UserDetailPage user={appUsersStore.selectedItem} />

    </div>;
});