import { Instance, types } from "mobx-state-tree";
import { MobileUserStatus } from "../../_generated/api";

export const UsersFilterModel = types.model({
    email: '',
    privateUser: true,
    businessUser: true,
    userStatus: types.maybe(types.enumeration<MobileUserStatus>(Object.values(MobileUserStatus)))
})
.actions((self) => ({
    
    setEmail(email: string) {
        self.email = email;
        return this;
    },
    setPrivateUserEnabled(enabled: boolean) {
        self.privateUser = enabled;
        return this;
    },
    setBusinessUserEnabled(enabled: boolean) {
        self.businessUser = enabled;
        return this;
    },
    setUserStatus(status?: MobileUserStatus) {
        self.userStatus = status;
        return this;
    },
    reset() {
        self.businessUser = true;
        self.privateUser = true;
        self.email = '';
        self.userStatus = undefined;
        return this;
    },

}));


export interface UsersFilter extends Instance<typeof UsersFilterModel> { }