import React, { ChangeEventHandler, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CheckedState, StyledCheckBox } from "../../../../../../common/components/input/styled-checkbox";
import { StyledInput } from "../../../../../../common/components/input/styled-input";
import contractDetailsMessages from "../../../../../contract-details.messages";

export interface SubCpoDataProps {
    subCpo?: string;
    onSubCpoChange: (subCpo?: string) => void;
    canUpdateData: boolean;
}

export const SubCpoData = (props: SubCpoDataProps) => {
    
    const { formatMessage } = useIntl();

    const [isSettingSubCpo, setCanSetSubCpo] = useState(false);
    const onCheckedBoxClick = useCallback(() => {
        if(isSettingSubCpo){
            props.onSubCpoChange(undefined);
        } else {
            props.onSubCpoChange('');
        }
        setCanSetSubCpo((prevState) => !prevState);
    }, [isSettingSubCpo, props]);

    const onSubCpoChange: ChangeEventHandler<HTMLInputElement> = useCallback(
        (ev) => props.onSubCpoChange(ev.target.value),
        [props]
    );

    useEffect(() => {
        setCanSetSubCpo(props.subCpo !== undefined);
    }, [props.subCpo]);

    return <div className="sub-cpo">

        <StyledCheckBox 
            label={formatMessage(contractDetailsMessages.setSubCpoLabel)}
            checked={isSettingSubCpo ? CheckedState.Checked : CheckedState.Unchecked}
            onClick={onCheckedBoxClick}
            disabled={!props.canUpdateData}
        />

        <StyledInput
            label={formatMessage(contractDetailsMessages.subCpoLabel)}
            value={props.subCpo ?? ''}
            onChange={onSubCpoChange}
            disabled={!isSettingSubCpo || !props.canUpdateData}
        />

    </div>;
};