import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { createServerErrorNotification, createSuccessNotification } from '../../common/stores/notifications-store';
import { useStore } from '../../common/stores/root-store';
import changePasswordApiService from '../api/change-password-api-service';
import { ChangePasswordSection } from './components/change-password';

import messages from '../change-password-messages';
import { useHistory } from 'react-router';

export const ChangePasswordPage = () => {
    
    const { notificationStore } = useStore();
    const history = useHistory();
    const intl = useIntl();

    const [ isSaving, setIsSaving ] = useState(false);
    const [ isSaveFailed, setSaveFailed ] = useState(false);

    const clearSaveError = useCallback(() => {
        setSaveFailed(false);
    }, []);

    const onSave = useCallback(async (oldPassword: string, newPassword: string) => {
        if(!isSaving){
            setIsSaving(true);
            try {
                await changePasswordApiService.changePassword(oldPassword, newPassword);
                notificationStore.showNotification(createSuccessNotification(intl, messages.passwordUpdated));
                history.goBack();
            } catch(e) {
                setSaveFailed(true);
                notificationStore.showNotification(createServerErrorNotification(intl));
            } finally {
                setIsSaving(false);
            }
        }
    }, [history, intl, isSaving, notificationStore]);
    
    return <ChangePasswordSection
        isCancelAvailable={true}
        isSaveFailed={isSaveFailed}
        isSaving={isSaving}
        clearSaveError={clearSaveError}
        onSave={onSave}
    />;
};