import { defineMessages } from 'react-intl';

export default defineMessages({
    "loginBoxTitle": {
        "id": "containers.login.loginBoxTitle",
        "defaultMessage": "Accedi per continuare"
    },
    "loginButton": {
        "id": "containers.login.loginButton",
        "defaultMessage": "LOGIN"
    },
    "loginErrorMessage": {
        "id": "containers.login.loginErrorMessage",
        "defaultMessage": "email o password errati"
    },
    "invalidEmailError": {
        "id": "containers.login.invalidEmailError",
        "defaultMessage": "Email non valida"
    }
});