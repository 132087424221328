import { getSnapshot } from "mobx-state-tree";
import { GetItemsResponse } from "../../common/api/items-list/get-items";
import { Currency } from "../../common/models/currency";
import { PricingReferenceUnit } from "../../common/models/pricing-reference-unit";
import { DateIntervalModel, ValidityDay } from "../../common/models/time";
import { FareApiService } from "../api/fares/fare-api-service";
import { CreateFareData, UpdateFareData } from "../api/fares/fare-request";
import { ChargingPowerRangeModel, Fare, FareFeeModel, FareModel, FareValidityPeriodModel, GracePeriodModel, OtherFeeModel, RangeValueCondition, RangeValueModel, SocketType } from "../model/fare";

class MockFareApiService extends FareApiService {
    
    private _fares: Fare[] = [
        FareModel.create({
            fareId: '1',
            fareName: 'Tariffa di test',
            currency: Currency.EURO,
            isValid24h: false,
            validityPeriods: [
                FareValidityPeriodModel.create({
                    day: ValidityDay.MONDAY,
                    timeInterval: DateIntervalModel.create({
                        from: (new Date()).setHours(10, 30),
                        to: (new Date()).setHours(15, 45)
                    })
                })
            ],
            inflation: 10,
            sessionFee: FareFeeModel.create({
                price: 1,
            }),
            chargingFee: FareFeeModel.create({
                price: 0.35,
                priceReferenceUnit: PricingReferenceUnit.KWH,
            }),
            parkingFee: FareFeeModel.create({
                price: 0.02,
                priceReferenceUnit: PricingReferenceUnit.PER_MINUTE,
            }),
            operatorNotes: 'Note di test',
            socketType: SocketType.DC,
            chargingPowerRange: ChargingPowerRangeModel.create({
                min: RangeValueModel.create({
                    value: 50,
                    condition: RangeValueCondition.INCLUSIVE
                }),
                max: RangeValueModel.create({
                    value: 100,
                    condition: RangeValueCondition.INCLUSIVE
                }),
            })
        }),
        FareModel.create({
            fareId: '2',
            fareName: 'Tariffa di test 2',
            currency: Currency.EURO,
            isValid24h: true,
            inflation: 20,
            sessionFee: FareFeeModel.create({
                price: 1,
                gracePeriod: GracePeriodModel.create({
                    minimumValue: 10,
                    priceReferenceUnit: PricingReferenceUnit.KWH
                })
            }),
            chargingFee: FareFeeModel.create({
                price: 0.35,
                priceReferenceUnit: PricingReferenceUnit.KWH,
                minimumReferenceUnit: 2
            }),
            parkingFee: FareFeeModel.create({
                price: 0.02,
                priceReferenceUnit: PricingReferenceUnit.PER_MINUTE,
            }),
            chargingPowerRange: ChargingPowerRangeModel.create({
                min: RangeValueModel.create({
                    value: 50,
                    condition: RangeValueCondition.INCLUSIVE
                }),
            }),
            plugIds: ['1234', '4567'], 
            operatorNotes: 'Note molto lunghe che non dovrebbero comparire nella totalità proprio perchè troppo lunghe, sono lunghe, molto molto molto molto molto molto lunghe'
        }),
        FareModel.create({
            fareId: '3',
            fareName: 'Tariffa di test 3',
            currency: Currency.EURO,
            isValid24h: true,
            inflation: 4,
            sessionFee: FareFeeModel.create({
                price: 1,
            }),
            chargingFee: FareFeeModel.create({
                price: 0.35,
                priceReferenceUnit: PricingReferenceUnit.KWH,
            }),
            parkingFee: FareFeeModel.create({
                price: 0.02,
                priceReferenceUnit: PricingReferenceUnit.PER_MINUTE,
            }),
            chargingPowerRange: ChargingPowerRangeModel.create({
                max: RangeValueModel.create({
                    value: 200,
                    condition: RangeValueCondition.INCLUSIVE
                }),
            }),
            socketType: SocketType.HPC_UFC
        }),
        FareModel.create({
            fareId: '4',
            fareName: 'Tariffa di test 4',
            currency: Currency.EURO,
            isValid24h: true,
            inflation: 10,
            sessionFee: FareFeeModel.create({
                price: 1,
            }),
            chargingFee: FareFeeModel.create({
                price: 0.35,
                priceReferenceUnit: PricingReferenceUnit.KWH,
            }),
            parkingFee: FareFeeModel.create({
                price: 0.02,
                priceReferenceUnit: PricingReferenceUnit.PER_MINUTE,
            }),
            chargingPowerRange: ChargingPowerRangeModel.create({
                min: RangeValueModel.create({
                    value: 20,
                    condition: RangeValueCondition.INCLUSIVE
                }),
                max: RangeValueModel.create({
                    value: 20,
                    condition: RangeValueCondition.INCLUSIVE
                }),
            }),
        }),
        FareModel.create({
            fareId: '5',
            fareName: 'Tariffa di test 5',
            currency: Currency.EURO,
            isValid24h: true,
            inflation: 10,
            sessionFee: FareFeeModel.create({
                price: 1,
            }),
            chargingFee: FareFeeModel.create({
                price: 0.35,
                priceReferenceUnit: PricingReferenceUnit.KWH,
            }),
            parkingFee: FareFeeModel.create({
                price: 0.02,
                priceReferenceUnit: PricingReferenceUnit.PER_MINUTE,
            }),
        }),
    ];

    private get fares() {    
        return this._fares.map(elem => {
            const snapshot = getSnapshot(elem);
            return FareModel.create({...snapshot});
        });
    }

    private set fares(fares: Fare[]) {
        this._fares = fares;
    }

    getItems(): Promise<GetItemsResponse<Fare, never>> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    totalItems: this.fares.length,
                    items: this.fares
                });
            }, 3000);
        });
    }

    deleteFare(fare: Fare): Promise<void> {
        return new Promise((resolve) => setTimeout(
            () => {
                this.fares = this.fares.filter(elem => elem.fareId !== fare.fareId);
                resolve();
            },
            3000
        ));
    }

    addFare(fare: CreateFareData): Promise<Fare> {
        return new Promise((resolve) => setTimeout(
            () => {
                const newFare = FareModel.create({
                    fareId: fare.fareId ?? `555${this._fares.length}`,
                    fareName: fare.fareName,
                    currency: fare.currency,
                    isValid24h: fare.isValid24Hours,
                    inflation: fare.inflation,
                    plugIds: [],
                    operatorNotes: fare.operatorNotes,
                    subCpoId: fare.subCpo,
                    socketType: fare.socketType,
                    chargingPowerRange: fare.chargingPowerRange && ChargingPowerRangeModel.create({
                        min: fare.chargingPowerRange.min && RangeValueModel.create({
                            value: fare.chargingPowerRange.min.value,
                            condition: fare.chargingPowerRange.min.condition
                        }),
                        max: fare.chargingPowerRange.max && RangeValueModel.create({
                            value: fare.chargingPowerRange.max.value,
                            condition: fare.chargingPowerRange.max.condition
                        })
                    }),
                    validityPeriods: fare.validityPeriods.map(elem => FareValidityPeriodModel.create({
                        day: elem.day,
                        timeInterval: DateIntervalModel.create({
                            from: elem.timeInterval.from,
                            to: elem.timeInterval.to
                        })
                    })),
                    sessionFee: fare.sessionFee && FareFeeModel.create({
                        price: fare.sessionFee.price,
                        priceReferenceUnit: fare.sessionFee.priceReferenceUnit,
                        startingFrom: fare.sessionFee.startingFrom,
                        endingTo: fare.sessionFee.endingTo,
                        minimumReferenceUnit: fare.sessionFee.minimumReferenceUnit,
                        gracePeriod: fare.sessionFee.gracePeriod && GracePeriodModel.create({
                            minimumValue: fare.sessionFee.gracePeriod.minimumValue,
                            priceReferenceUnit: fare.sessionFee.gracePeriod.priceReferenceUnit
                        })
                    }),
                    chargingFee: fare.chargingFee && FareFeeModel.create({
                        price: fare.chargingFee.price,
                        priceReferenceUnit: fare.chargingFee.priceReferenceUnit,
                        startingFrom: fare.chargingFee.startingFrom,
                        endingTo: fare.chargingFee.endingTo,
                        minimumReferenceUnit: fare.chargingFee.minimumReferenceUnit,
                        gracePeriod: fare.chargingFee.gracePeriod && GracePeriodModel.create({
                            minimumValue: fare.chargingFee.gracePeriod.minimumValue,
                            priceReferenceUnit: fare.chargingFee.gracePeriod.priceReferenceUnit
                        })
                    }),
                    parkingFee: fare.parkingFee && FareFeeModel.create({
                        price: fare.parkingFee.price,
                        priceReferenceUnit: fare.parkingFee.priceReferenceUnit,
                        startingFrom: fare.parkingFee.startingFrom,
                        endingTo: fare.parkingFee.endingTo,
                        minimumReferenceUnit: fare.parkingFee.minimumReferenceUnit,
                        gracePeriod: fare.parkingFee.gracePeriod && GracePeriodModel.create({
                            minimumValue: fare.parkingFee.gracePeriod.minimumValue,
                            priceReferenceUnit: fare.parkingFee.gracePeriod.priceReferenceUnit
                        })
                    }),
                    otherFees: fare.otherFees.map(elem => OtherFeeModel.create({
                        type: elem.type,
                        fee: FareFeeModel.create({
                            price: elem.fee.price,
                            priceReferenceUnit: elem.fee.priceReferenceUnit,
                            startingFrom: elem.fee.startingFrom,
                            endingTo: elem.fee.endingTo,
                            minimumReferenceUnit: elem.fee.minimumReferenceUnit,
                            gracePeriod: elem.fee.gracePeriod && GracePeriodModel.create({
                                minimumValue: elem.fee.gracePeriod.minimumValue,
                                priceReferenceUnit: elem.fee.gracePeriod.priceReferenceUnit
                            })
                        }),
                    })),
                    maximumPrice: fare.maximumPrice,
                    fixedPrice: fare.fixedPrice,
                    minimumPrice: fare.minimumPrice
                });
                this.fares = [
                    newFare,
                    ...this.fares
                ];
                resolve(newFare);
            },
            3000
        )); 
    }

    updateFare(oldFareId: string, fare: UpdateFareData): Promise<void> {
        return new Promise((resolve) => setTimeout(
            () => {
                this.fares = this.fares.map((elem, index) => {
                    if(elem.fareId === oldFareId) {
                        return FareModel.create({
                            fareId: fare.fareId ?? `555${index}`,
                            fareName: fare.fareName,
                            currency: fare.currency,
                            isValid24h: fare.isValid24Hours,
                            inflation: fare.inflation,
                            plugIds: [],
                            operatorNotes: fare.operatorNotes,
                            subCpoId: fare.subCpo,
                            socketType: fare.socketType,
                            chargingPowerRange: fare.chargingPowerRange && ChargingPowerRangeModel.create({
                                min: fare.chargingPowerRange.min && RangeValueModel.create({
                                    value: fare.chargingPowerRange.min.value,
                                    condition: fare.chargingPowerRange.min.condition
                                }),
                                max: fare.chargingPowerRange.max && RangeValueModel.create({
                                    value: fare.chargingPowerRange.max.value,
                                    condition: fare.chargingPowerRange.max.condition
                                })
                            }),
                            validityPeriods: fare.validityPeriods.map(elem => FareValidityPeriodModel.create({
                                day: elem.day,
                                timeInterval: DateIntervalModel.create({
                                    from: elem.timeInterval.from,
                                    to: elem.timeInterval.to
                                })
                            })),
                            sessionFee: fare.sessionFee && FareFeeModel.create({
                                price: fare.sessionFee.price,
                                priceReferenceUnit: fare.sessionFee.priceReferenceUnit,
                                startingFrom: fare.sessionFee.startingFrom,
                                endingTo: fare.sessionFee.endingTo,
                                minimumReferenceUnit: fare.sessionFee.minimumReferenceUnit,
                                gracePeriod: fare.sessionFee.gracePeriod && GracePeriodModel.create({
                                    minimumValue: fare.sessionFee.gracePeriod.minimumValue,
                                    priceReferenceUnit: fare.sessionFee.gracePeriod.priceReferenceUnit
                                })
                            }),
                            chargingFee: fare.chargingFee && FareFeeModel.create({
                                price: fare.chargingFee.price,
                                priceReferenceUnit: fare.chargingFee.priceReferenceUnit,
                                startingFrom: fare.chargingFee.startingFrom,
                                endingTo: fare.chargingFee.endingTo,
                                minimumReferenceUnit: fare.chargingFee.minimumReferenceUnit,
                                gracePeriod: fare.chargingFee.gracePeriod && GracePeriodModel.create({
                                    minimumValue: fare.chargingFee.gracePeriod.minimumValue,
                                    priceReferenceUnit: fare.chargingFee.gracePeriod.priceReferenceUnit
                                })
                            }),
                            parkingFee: fare.parkingFee && FareFeeModel.create({
                                price: fare.parkingFee.price,
                                priceReferenceUnit: fare.parkingFee.priceReferenceUnit,
                                startingFrom: fare.parkingFee.startingFrom,
                                endingTo: fare.parkingFee.endingTo,
                                minimumReferenceUnit: fare.parkingFee.minimumReferenceUnit,
                                gracePeriod: fare.parkingFee.gracePeriod && GracePeriodModel.create({
                                    minimumValue: fare.parkingFee.gracePeriod.minimumValue,
                                    priceReferenceUnit: fare.parkingFee.gracePeriod.priceReferenceUnit
                                })
                            }),
                            otherFees: fare.otherFees.map(elem => OtherFeeModel.create({
                                type: elem.type,
                                fee: FareFeeModel.create({
                                    price: elem.fee.price,
                                    priceReferenceUnit: elem.fee.priceReferenceUnit,
                                    startingFrom: elem.fee.startingFrom,
                                    endingTo: elem.fee.endingTo,
                                    minimumReferenceUnit: elem.fee.minimumReferenceUnit,
                                    gracePeriod: elem.fee.gracePeriod && GracePeriodModel.create({
                                        minimumValue: elem.fee.gracePeriod.minimumValue,
                                        priceReferenceUnit: elem.fee.gracePeriod.priceReferenceUnit
                                    })
                                }),
                            })),
                            maximumPrice: fare.maximumPrice,
                            fixedPrice: fare.fixedPrice,
                            minimumPrice: fare.minimumPrice
                        });
                    } else {
                        return elem;
                    }
                });
                resolve();
            },
            3000
        ));
    }

}

export const mockFareApiService = new MockFareApiService('fares');