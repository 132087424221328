import { Instance, types } from "mobx-state-tree";
import logoutManager from "../api/account/logout-manager";
import apiManager from "../api/api-manager";
import { User, UserModel, UserRole } from "../models/user";

export const UserStoreModel = types.model({
    firstLoginEmail: types.maybeNull(types.string),
    user: types.maybeNull(UserModel),
    loginChecked: false
}).views(self => {
    return {
        get isUserLogged(): boolean {
            return self.user !== null;
        },
        get isAdminLogged(): boolean {
            return self.user !== null && self.user.role === UserRole.Admin;
        },
    };
}).actions((self) => {

    return {
        setLoginChecked(checked: boolean) {
            self.loginChecked = checked;
        },
        setFirstLoginEmail(email: string) {
            self.firstLoginEmail = email;
        },
        clearFirstLoginEmail() {
            self.firstLoginEmail = null;
        },
        setUser(user: User) {
            self.user = user;
        },
        logout(){
            logoutManager.logout();
            apiManager.logout();
            self.user = null;
            self.loginChecked = false;
        }
    };
});

export interface UserStore extends Instance<typeof UserStoreModel> { }