import { flow, SnapshotOrInstance, types, getEnv } from "mobx-state-tree";
import { ApiRequestState } from "../../common/api/api-request";
import { ResourceType } from "../../common/config/constants";
import { Env } from "../../common/config/env";
import { OrderCriteriaModel, SortOrder } from "../../common/models/order-criteria";
import { DateIntervalModel } from "../../common/models/time";
import { createPagedListStoreModel } from "../../common/stores/paged-list/paged-list-store";
import { emptyFunction } from "../../common/utils/utils";
import {
    ContractApiService,
    contractApiService as contractApiServ
} from "../api/contracts/contract-api-service";
import { mockContractApiService } from "../mock/mock-contract-api-service";
import { ContactPersonModel, Contract, ContractModel, OperationInfoModel, OperatorModel } from "../model/contract";
import { ContractInfo } from "../model/contract-info";
import { FareOrderCriteria } from "../model/fare";
import { GroupOrderCriteria } from "../model/group";
import { Provider } from "../model/provider";
import { FareListStoreModel } from "./fare-store";
import { GroupListStoreModel } from "./group-store";

export enum ContractError {
    CONTRACT_WITH_SAME_ID_ALREADY_EXISTS = '1',
}

const contractApiService: ContractApiService = Env.useMocks
    ? mockContractApiService
    : contractApiServ;

const AdditionalContractModel = types.model({
    contractError: types.maybe(
        types.enumeration<ContractError>(Object.values(ContractError))
    ),
    manageContractRequestState: types.maybe(
        types.enumeration<ApiRequestState>(Object.values(ApiRequestState))
    ),
    deleteContractRequestState: types.maybe(
        types.enumeration<ApiRequestState>(Object.values(ApiRequestState))
    ),
    manageBlockContractRequestState: types.maybe(
        types.enumeration<ApiRequestState>(Object.values(ApiRequestState))
    ),
    lastNewCreatedContract: types.maybe(ContractModel),
    fareStore: types.maybe(FareListStoreModel),
    groupStore: types.maybe(GroupListStoreModel)
});

export const ContractListStoreModel = types.compose(createPagedListStoreModel({
    type: ContractModel,
    apiService: contractApiService,
    typeLabel: ResourceType.Contracts,
    selectedItemReferenceOptions: {
        get(identifier: string, parent: any) {
            const contract = parent.currentPageItems.find((elem: any) => elem.contractId === identifier);
            return contract ?? parent.lastNewCreatedContract;
        },
        set(elem: Contract) {
            return elem.contractId;
        }
    }
}), AdditionalContractModel)
    .actions(self => {

        const parentSelectItem = self.openItemDetail;

        const openItemDetail = (item: SnapshotOrInstance<Contract>) => {
            parentSelectItem(item);
            self.fareStore = FareListStoreModel.create({
                orderCriteria: OrderCriteriaModel.create({
                    key: FareOrderCriteria.ID,
                    sortOrder: SortOrder.DESC
                })
            }, getEnv(self));
            self.groupStore = GroupListStoreModel.create({
                orderCriteria: OrderCriteriaModel.create({
                    key: GroupOrderCriteria.ID,
                    sortOrder: SortOrder.DESC
                })
            }, getEnv(self));

        };

        const deselectItem = () => {
            self.selectedItem = null;
            self.lastNewCreatedContract = undefined;
        };

        const addContract = flow(function* (contract: ContractInfo) {
            self.manageContractRequestState = ApiRequestState.RUNNING;
            try {
                const newContract = yield contractApiService.addContract(contract);
                self.lastNewCreatedContract = newContract;
                self.manageContractRequestState = ApiRequestState.COMPLETED;
            } catch (e: any) {
                self.manageContractRequestState = ApiRequestState.FAILED;
            }
        });

        const updateContract = flow(function* (oldContract: Contract, newContractInfo: ContractInfo) {
            self.manageContractRequestState = ApiRequestState.RUNNING;
            try {
                const updatedContract: Contract = yield contractApiService.updateContract(
                    oldContract.contractId,
                    newContractInfo
                );

                oldContract.setContractType(updatedContract.type);
                oldContract.setValidityPeriod(DateIntervalModel.create({ ...updatedContract.validityPeriod }));
                oldContract.setBaseInflation(updatedContract.inflation.baseInflation);
                oldContract.setContactPerson(ContactPersonModel.create({ ...updatedContract.contactPerson }));
                oldContract.setOperatorNotes(updatedContract.operatorNotes);
                oldContract.setLastUpdateOperationInfo(OperationInfoModel.create({
                    date: updatedContract.lastUpdate.date,
                    operator: OperatorModel.create({ ...updatedContract.lastUpdate.operator })
                }));
                oldContract.setNgpContractFilename(updatedContract.ngpContractFilename);
                oldContract.setNgpContractFile(updatedContract.ngpContractFile);

                self.manageContractRequestState = ApiRequestState.COMPLETED;
            } catch (e: any) {
                self.manageContractRequestState = ApiRequestState.FAILED;
            }
        });

        const deleteContract = flow(function* (contract: Contract) {
            self.deleteContractRequestState = ApiRequestState.RUNNING;
            try {
                yield contractApiService.deleteContract(contract);
                self.deleteContractRequestState = ApiRequestState.COMPLETED;
            } catch (e: any) {
                self.deleteContractRequestState = ApiRequestState.FAILED;
            }
        });

        const blockContract = flow(function* (contract: Contract) {
            self.manageBlockContractRequestState = ApiRequestState.RUNNING;
            try {
                yield contractApiService.blockContract(contract);
                contract.block();
                self.manageBlockContractRequestState = ApiRequestState.COMPLETED;
            } catch (e: any) {
                self.manageBlockContractRequestState = ApiRequestState.FAILED;
            }
        });

        const unlockContract = flow(function* (contract: Contract) {
            self.manageBlockContractRequestState = ApiRequestState.RUNNING;
            try {
                yield contractApiService.unlockContract(contract);
                contract.unlock();
                self.manageBlockContractRequestState = ApiRequestState.COMPLETED;
            } catch (e: any) {
                self.manageBlockContractRequestState = ApiRequestState.FAILED;
            }
        });

        const clearManageContractRequestState = () => {
            self.manageContractRequestState = undefined;
        };

        const clearDeleteContractRequestState = () => {
            self.deleteContractRequestState = undefined;
        };

        const clearManageBlockContractRequestState = () => {
            self.manageBlockContractRequestState = undefined;
        };

        const clearContractError = () => {
            self.contractError = undefined;
        };

        const init = emptyFunction;

        const initContractsStore = (provider: Provider) => {
            contractApiService.setProvider(provider);
        };

        return {
            init, addContract, deleteContract,
            updateContract,
            clearDeleteContractRequestState,
            clearManageContractRequestState,
            clearContractError,
            openItemDetail, deselectItem,
            blockContract, unlockContract,
            clearManageBlockContractRequestState,
            initContractsStore
        };
    })
    .named('ContractListStoreModel');
