import React from 'react';
import { useIntl } from 'react-intl';

import reloadIcon from '../../../assets/images/reload.svg';
import messages from './paged-list.messages';

import './list-error-box.scss';

interface ListErrorBoxProps {
    onClick: () => void;
}

export const ListErrorBox = (props: ListErrorBoxProps) => {
    const { formatMessage } = useIntl();
    return <div className='list-error-box' onClick={props.onClick}>
        <span className='text-standard-24'>{formatMessage(messages.listErrorMessage)}</span>
        <img src={reloadIcon} alt={formatMessage(messages.listErrorReloadAlt)}/>
    </div>;
};