import { AxiosPromise } from "axios";
import apiManager from "../../common/api/api-manager";
import HttpStatusCode from "../../common/api/http-status.code";
import { ListApiService } from "../../common/api/items-list/list-api-service";
import { OrderCriteria } from "../../common/models/order-criteria";
import { fromRoleToBackofficeRole, User } from "../../common/models/user";
import { ErrorCode, Locale } from "../../_generated/api";
import { AdministrationFilter } from "../model/admin-filter";
import { BackofficeUserInfo } from "../model/backoffice-user-info";
import { UserAlreadyExistsError, UserCannotDeleteHimselfError, UserCannotUpdateHisRoleError } from "./admin-api-exceptions";
import { parseBackofficeUserFromApiModel } from "./admin-api-parser";

class AdministrationApiService extends ListApiService<User, AdministrationFilter, any> {

    parseListItem(apiResponse: any): User {
        return parseBackofficeUserFromApiModel(apiResponse);
    }

    parseSummary(): any {
        return undefined;
    }

    downloadItemsList(offset: number, limit: number, filters: AdministrationFilter, orderCriteria?: OrderCriteria, accept?: string): AxiosPromise<any> {
        
        const config = accept === undefined ? undefined : {
            headers: {
                'Accept': accept
            }
        };

        return apiManager.adminApi.getBackofficeUsersData(
            apiManager.idToken,
            offset,
            limit,
            orderCriteria ? `${orderCriteria.key}:${orderCriteria.sortOrder.toLowerCase()}` : undefined,
            filters.backendUserSearched ?? undefined,
            filters.role ? fromRoleToBackofficeRole(filters.role) : undefined,
            filters.country?.toUpperCase() ?? undefined,
            config
        );
    }

    async createNewBackofficeUser(userInfo: BackofficeUserInfo, locale: string): Promise<void> {
        try {
            const result = await apiManager.adminApi.addBackofficeUser(
                apiManager.idToken,
                {
                    contact_details: userInfo.contact_details ?? '',
                    country: userInfo.country.toUpperCase(),
                    email: userInfo.email,
                    name: userInfo.name,
                    role: fromRoleToBackofficeRole(userInfo.role),
                    locale: Object.values(Locale).find(elem => elem == locale) ?? Locale.ItIt
                }
            );

            if (result.status !== HttpStatusCode.CREATED) {
                throw new Error();
            }
        } catch (e: any) {
            if (e.response?.data?.errorCode === ErrorCode._4xxUserAlreadyExists) {
                throw new UserAlreadyExistsError();
            } else {
                throw e;
            }
        }
    }

    async updateBackofficeUserInfo(userId: string, userInfo: BackofficeUserInfo): Promise<void> {
        try {
            const result = await apiManager.adminApi.adminUpdateBackofficeUser(
                userId,
                apiManager.idToken,
                {
                    country: userInfo.country.toUpperCase(),
                    name: userInfo.name,
                    contact_details: userInfo.contact_details,
                    role: fromRoleToBackofficeRole(userInfo.role)
                }
            );

            if (result.status !== HttpStatusCode.NO_CONTENT && result.status !== HttpStatusCode.OK) {
                throw new Error();
            }
        } catch (e: any) {
            console.log('ER: ', e.response);
            if (e.response?.data?.errorCode === ErrorCode._4xxUserCannotUpdateItsRole) {
                throw new UserCannotUpdateHisRoleError();
            } else {
                throw e;
            }
        }
    }

    async deleteBackofficeUser(user: User): Promise<void> {
        try {
            const result = await apiManager.adminApi.deleteBackofficeUser(
                user.id,
                apiManager.idToken
            );

            if (result.status !== HttpStatusCode.NO_CONTENT && result.status !== HttpStatusCode.OK) {
                throw new Error();
            }
        } catch (e: any) {
            console.log('ER: ', e.response);
            if (e.response?.data?.errorCode === ErrorCode._4xxUserCannotDeleteItself) {
                throw new UserCannotDeleteHimselfError();
            } else {
                throw e;
            }
        }
    }

}


export const administrationApiService = new AdministrationApiService('administration');