import { defineMessages } from 'react-intl';

export default defineMessages({
    "perHour": {
        "id": "pricing.perHour",
        "defaultMessage": "{currency} H",
        "description": ""
    },
    "perMinute": {
        "id": "pricing.perMinute",
        "defaultMessage": "{currency} Min",
        "description": ""
    },
    "kilowattPerHour": {
        "id": "pricing.kilowattPerHour",
        "defaultMessage": "{currency} Kwh",
        "description": ""
    },
});