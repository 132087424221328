import { defineMessages } from 'react-intl';

export default defineMessages({
    "thisMonthFilter": {
        "id": "commons.filters.thisMonthFilter",
        "defaultMessage": "Questo mese",
        "description": ""
    },
    "lastMonthFilter": {
        "id": "commons.filters.lastMonthFilter",
        "defaultMessage": "Mese scorso",
        "description": ""
    },
    "lastThreeMonthsFilter": {
        "id": "commons.filters.lastThreeMonthsFilter",
        "defaultMessage": "Ultimi 3 mesi",
        "description": ""
    },
    "lastSixMonthsFilter": {
        "id": "commons.filters.lastSixMonthsFilter",
        "defaultMessage": "Ultimi 6 mesi",
        "description": ""
    },
    "lastYearFilter": {
        "id": "commons.filters.lastYearFilter",
        "defaultMessage": "Ultimo anno",
        "description": ""
    },
    "last30DaysFilter": {
        "id": "commons.filters.last30DaysFilter",
        "defaultMessage": "Ultimi 30gg",
        "description": ""
    },
    "anyCountryFilter": {
        "id": "commons.filters.anyCountryFilter",
        "defaultMessage": "Qualsiasi paese",
        "description": "",
    },
});