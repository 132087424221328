import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { AppUser, messageDescriptorForStatus } from '../../model/app-user';
import { formatDate } from '../../../common/utils/formatters';
import { PagedListCell, PagedListCellVariant } from '../../../common/components/paged-list/paged-list-cell';
import { ListActionsItem } from '../../../common/components/paged-list/list-actions-item';

import messages from '../../users.messages';
import { useStore } from '../../../common/stores/root-store';
import { UserRole } from '../../../common/models/user';
import { MobileUserStatus } from '../../../_generated/api';

interface UsersTableItemProps {
    item: AppUser;
    onOpenUserDetail: (item: AppUser) => void;
    onRestoreUserClick: (item: AppUser) => void;
    onBlockUserClick: (item: AppUser) => void;
    onDeleteUserClick: (item: AppUser) => void;
}

export const UsersTableItem = observer(({ item, onOpenUserDetail, onBlockUserClick, onDeleteUserClick, onRestoreUserClick }: UsersTableItemProps) => {

    const { formatMessage } = useIntl();
    const { userStore } = useStore();

    const openChargesDetail = useCallback(() => onOpenUserDetail(item), [item, onOpenUserDetail]);
    const restoreAccount = useCallback(() => onRestoreUserClick(item), [item, onRestoreUserClick]);
    const blockAccount = useCallback(() => onBlockUserClick(item), [item, onBlockUserClick]);
    const deleteAccount = useCallback(() => onDeleteUserClick(item), [item, onDeleteUserClick]);

    const actions = useMemo(() => {
        const actionsList = [{
            label: formatMessage(messages.actionChargesDetail),
            execute: openChargesDetail
        }];
        if (userStore.user && userStore.user.role !== UserRole.Operator) {

            if (item.status === MobileUserStatus.Blocked || item.status === MobileUserStatus.Suspended) {
                actionsList.push({
                    label: formatMessage(messages.actionRestoreAccount),
                    execute: restoreAccount
                });
            }
            if (item.status !== MobileUserStatus.Blocked) {
                actionsList.push({
                    label: formatMessage(messages.actionBlockAccount),
                    execute: blockAccount
                });
            } else {
                actionsList.push({
                    label: formatMessage(messages.actionDeleteAccount),
                    execute: deleteAccount
                });
            }
        }
        return actionsList;
    }, [userStore.user, item.status, formatMessage, openChargesDetail, restoreAccount, blockAccount, deleteAccount]);

    return <tr className='users-tr'>
        <td>{item.email}</td>
        <td>{item.registrationDate ? formatDate(item.registrationDate) : ''}</td>
        <PagedListCell variant={PagedListCellVariant.Bold}>{
            item.status ? formatMessage(messageDescriptorForStatus(item.status)) : ''}
        </PagedListCell>
        <PagedListCell variant={PagedListCellVariant.Bold}>{
            item.lastChargeDate ? formatDate(item.lastChargeDate) : ''}
        </PagedListCell>
        <PagedListCell variant={PagedListCellVariant.Bold}>{item.amountPaid.toFixed(2)}</PagedListCell>
        <PagedListCell variant={item.amountDue > 0 ? PagedListCellVariant.Warning : PagedListCellVariant.Bold}>
            {item.amountDue.toFixed(2)}
        </PagedListCell>
        <ListActionsItem actions={actions} />
    </tr>;
});