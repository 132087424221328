import { Instance, types } from "mobx-state-tree";

export const TransactionModel = types.model({
    date: types.Date,
    cpoName: types.string,
    userId: types.string,
    amountDue: types.number,
    amountCollected: types.number
})
.views((self) => ({
    get amountEarned() {
        return self.amountCollected - self.amountDue;
    }
}));

export const TransactionsSummaryModel = types.model({
    totalAmountDue: types.number,
    totalAmountCollected: types.number,
})
.views((self) => ({
    get totalAmountEarned() {
        return self.totalAmountCollected - self.totalAmountDue;
    }
}));

export const TransactionsModel = types.model({
    totalNumberOfItems: types.integer,
    summary: TransactionsSummaryModel,
    items: types.array(TransactionModel)
});

export interface Transaction extends Instance<typeof TransactionModel> { }
export interface Transactions extends Instance<typeof TransactionsModel> { }
export interface TransactionsSummary extends Instance<typeof TransactionsSummaryModel> { }