import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { BaseDialog } from "../../../../../common/components/dialogs/base-dialog";
import { AppButtonVariant } from "../../../../../common/components/button/app-button";
import { FareDataContent } from "./fare-data-content";
import { FareConditionsContent } from "./fare-conditions-content/fare-conditions-content";
import { SessionFeeData } from "./fare-fee-data/session-fee-data";
import { ChargingFeeData } from "./fare-fee-data/charging-fee-data";
import { ParkingFeeData } from "./fare-fee-data/parking-fee-data";
import { OtherFeeData } from "./fare-fee-data/other-fee-data";

import { 
    ChargingPowerRange,
    Fare,
    FareFee,
    FareFeeModel,
    FareValidityPeriod,
    GracePeriodModel,
    OtherFeeModel,
    RangeValueCondition,
    SocketType 
} from "../../../../model/fare";
import { Currency } from "../../../../../common/models/currency";
import { FareFeeInfo, FareInfo, OtherFareFeeInfo } from "../../../../model/fare-info";

import contractDetailsMessages from "../../../../contract-details.messages";
import commonMessages from "../../../../../common/messages/common.messages";

import './manage-fee-dialog.scss';
import { ConfirmFareUpdateDialog } from "../confirm-fare-update-dialog/confirm-fare-update-dialog";
import { Contract } from "../../../../model/contract";
import { fromFareInflationTypeToMessageDescriptor, FareInflationType } from '../../../../model/fare';

export interface ManageFeeDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirmButtonClick: (fare: FareInfo) => void;
    isOperationRunning: boolean;
    providerName: string;
    contract: Contract;
    isUpdating: boolean;
    
    fare?: Fare;
    canUpdateFare?: boolean;
}

export const ManageFeeDialog = observer((props: ManageFeeDialogProps) => {
    
    const { formatMessage } = useIntl();

    /* ----------- Inflation ----------- */
    const [inflation, setInflation] = useState<string>();

    const inflationValue = useMemo(() => Number(inflation), [inflation]);

    const onInflationChange = useCallback((newValue?: string) => setInflation(newValue), []);

    const fareInflationTypes = useMemo(() => Object.values(FareInflationType).map(elem =>({
        id: elem,
        label: formatMessage(fromFareInflationTypeToMessageDescriptor(elem)),
    })), [formatMessage]);

    const [fareInflationType, setInflationManageType] = useState<string>(fareInflationTypes[0].id);
    
    const onFareInflationTypeChange = useCallback((fareInflationTypeId: string) => {
        const fareInflationType = Object.values(FareInflationType).find(elem => elem === fareInflationTypeId);
        fareInflationType && setInflationManageType(fareInflationType);
        if (fareInflationType && fareInflationType == FareInflationType.CONTRACT) {
            onInflationChange(undefined);
        }
    }, [onInflationChange]);

    /* ----------- Fare id ----------- */
    const [fareId, setFareId] = useState<string>();

    const onFareIdChange = useCallback((newValue: string) => 
        setFareId(newValue.trim().length > 0 ? newValue : undefined), 
        []
    );

    /* ----------- Fare name ----------- */
    const [fareName, setFareName] = useState('');

    const onFareNameChange = useCallback((newValue: string) => setFareName(newValue), []);

    /* ----------- Currency ----------- */
    const [currency, setCurrency] = useState(Currency.EURO);

    const onCurrencyChange = useCallback((newValue: Currency) => setCurrency(newValue), []);

    /* ----------- Operator notes ----------- */
    const [operatorNotes, setOperatorNotes] = useState<string>();

    const onOperatorNotesChange = useCallback((newValue: string) =>    
        setOperatorNotes(newValue.trim().length > 0 ? newValue : undefined), 
        []
    );

    /* ----------- Evse id list ----------- */
    const [evseIdList, setEvseIdList] = useState<string[]>();

    /* ----------- Sub CPO ----------- */
    const [subCpo, setSubCpo] = useState<string>();

    const onSubCpoChange = useCallback((subCpo?: string) => {
        setSubCpo(subCpo);   
    }, []);

    /* ----------- Socket type ----------- */
    const [socketType, setSocketType] = useState<SocketType>();

    const onSocketTyoeChange = useCallback((socketType?: SocketType) => {
        setSocketType(socketType);   
    }, []);

    /* ----------- Charging power range ----------- */
    const [chargingPowerRange, setChargingPowerRange] = useState<ChargingPowerRange>();

    const onChargingPowerRangeChange = useCallback((chargingPowerRange?: ChargingPowerRange) => {
        setChargingPowerRange(chargingPowerRange);   
    }, []);

    const isChargingPowerRangeValid = useMemo(() => { 
        if(!chargingPowerRange) {
            return true;
        } else if (chargingPowerRange.min && chargingPowerRange.max) { 
            if(chargingPowerRange.min.condition === RangeValueCondition.INCLUSIVE || chargingPowerRange.max.condition === RangeValueCondition.INCLUSIVE) {
                return chargingPowerRange.min.value <= chargingPowerRange.max.value;
            } else {
                return chargingPowerRange.min.value < chargingPowerRange.max.value;
            }
        } else {
            return (!!chargingPowerRange.min && !chargingPowerRange.max)
                || (!!chargingPowerRange.max && !chargingPowerRange.min);
        }
    }, [chargingPowerRange]);

    /* ----------- Validity periods ----------- */
    const [validityPeriods, setValidityPeriods] = useState<FareValidityPeriod[]>();

    const onValidityPeriodsChange = useCallback((validityPeriods?: FareValidityPeriod[]) => {
        setValidityPeriods(validityPeriods);
    }, []);

    /* ----------- Session fee ----------- */
    const [sessionFee, setSessionFee] = useState<FareFeeInfo>();

    const onSessionFeeChange = useCallback((sessionFee?: FareFeeInfo) => {
        setSessionFee(sessionFee);
    }, []);

    const sessionFeeValue = useMemo(() => sessionFee && FareFeeModel.create({
        price: Number(sessionFee.price),
        priceReferenceUnit: sessionFee.priceReferenceUnit,
        minimumReferenceUnit: sessionFee.minimumReferenceUnit ? Number(sessionFee.minimumReferenceUnit) : undefined,
        startingFrom: sessionFee.startingFrom,
        endingTo: sessionFee.endingTo,
        gracePeriod: sessionFee.gracePeriod && GracePeriodModel.create({
            priceReferenceUnit: sessionFee.gracePeriod.priceReferenceUnit,
            minimumValue: Number(sessionFee.gracePeriod.minimumValue)
        })
    }), [sessionFee]);

    /* ----------- Charging fee ----------- */
    const [chargingFee, setChargingFee] = useState<FareFeeInfo>();

    const onChargingFeeChange = useCallback((chargingFee?: FareFeeInfo) => {
        setChargingFee(chargingFee);
    }, []);

    const chargingFeeValue = useMemo(() => chargingFee && FareFeeModel.create({
        price: Number(chargingFee.price),
        priceReferenceUnit: chargingFee.priceReferenceUnit,
        minimumReferenceUnit: chargingFee.minimumReferenceUnit ? Number(chargingFee.minimumReferenceUnit) : undefined,
        startingFrom: chargingFee.startingFrom,
        endingTo: chargingFee.endingTo,
        gracePeriod: chargingFee.gracePeriod && GracePeriodModel.create({
            priceReferenceUnit: chargingFee.gracePeriod.priceReferenceUnit,
            minimumValue: Number(chargingFee.gracePeriod.minimumValue)
        })
    }), [chargingFee]);

    /* ----------- Parking fee ----------- */
    const [parkingFee, setParkingFee] = useState<FareFeeInfo>();

    const onParkingFeeChange = useCallback((parkingFee?: FareFeeInfo) => {
        setParkingFee(parkingFee);
    }, []);

    const parkingFeeValue = useMemo(() => parkingFee && FareFeeModel.create({
        price: Number(parkingFee.price),
        priceReferenceUnit: parkingFee.priceReferenceUnit,
        minimumReferenceUnit: parkingFee.minimumReferenceUnit ? Number(parkingFee.minimumReferenceUnit) : undefined,
        startingFrom: parkingFee.startingFrom,
        endingTo: parkingFee.endingTo,
        gracePeriod: parkingFee.gracePeriod && GracePeriodModel.create({
            priceReferenceUnit: parkingFee.gracePeriod.priceReferenceUnit,
            minimumValue: Number(parkingFee.gracePeriod.minimumValue)
        })
    }), [parkingFee]);

    /* ----------- Parking fee ----------- */
    const [otherFees, setOtherFees] = useState<OtherFareFeeInfo[]>();

    const onOtherFeesChange = useCallback((otherFees?: OtherFareFeeInfo[]) => {
        setOtherFees(otherFees);
    }, []);

    const otherFeesValue = useMemo(() => otherFees?.map(elem => OtherFeeModel.create({
        type: elem.type,
        fee: FareFeeModel.create({
            price: Number(elem.fareFee.price),
            priceReferenceUnit: elem.fareFee.priceReferenceUnit,
            minimumReferenceUnit: elem.fareFee.minimumReferenceUnit ? Number(elem.fareFee.minimumReferenceUnit) : undefined,
            startingFrom: elem.fareFee.startingFrom,
            endingTo: elem.fareFee.endingTo,
            gracePeriod: elem.fareFee.gracePeriod && GracePeriodModel.create({
                priceReferenceUnit: elem.fareFee.gracePeriod.priceReferenceUnit,
                minimumValue: Number(elem.fareFee.gracePeriod.minimumValue)
            })
        })
    })), [otherFees]);

    const isFareFeeValid = useCallback((fareFeeValue?: FareFee, fareFee?: FareFeeInfo) => {    
        return !fareFeeValue
            || (
                !isNaN(fareFeeValue.price) && (fareFee?.price?.trim().length ?? 0) > 0
                && (
                    fareFeeValue.minimumReferenceUnit === undefined || (
                        !isNaN(fareFeeValue.minimumReferenceUnit) 
                        && (fareFee?.minimumReferenceUnit?.trim().length ?? 0) > 0
                    )
                )
                && (
                    fareFeeValue.gracePeriod === undefined || (
                        !isNaN(fareFeeValue.gracePeriod.minimumValue)
                        && (fareFee?.gracePeriod?.minimumValue?.trim().length ?? 0) > 0
                    )
                )
            );
    }, []);

    /* ----------- Primary button enabled ----------- */
    const isPrimaryButtonEnabled = useMemo(() => {
        return fareName.trim().length > 0 
            &&  (
                    (fareInflationType === FareInflationType.CONTRACT)
                        ? true
                        : !isNaN(inflationValue)
                )
            && (subCpo === undefined || subCpo.trim().length > 0)
            && isChargingPowerRangeValid
            && (!validityPeriods || validityPeriods.every(elem => elem.timeInterval.from < elem.timeInterval.to))
            && isFareFeeValid(sessionFeeValue, sessionFee)
            && isFareFeeValid(chargingFeeValue, chargingFee)
            && isFareFeeValid(parkingFeeValue, parkingFee)
            && ( otherFeesValue === undefined
                || otherFeesValue.length === 0
                || otherFeesValue.every((elem, index) => isFareFeeValid(elem.fee, otherFees && otherFees[index]?.fareFee))
            )
            // at least one fee has to be set
            && (!!sessionFeeValue || !!chargingFeeValue || !!parkingFeeValue);
    }, [
        fareName, inflationValue, fareInflationType, subCpo, isChargingPowerRangeValid, 
        validityPeriods, isFareFeeValid, sessionFeeValue, 
        sessionFee, chargingFeeValue, chargingFee,
        parkingFeeValue, parkingFee, otherFeesValue, otherFees
    ]);

    /* ----------- Dialog management ----------- */
    useEffect(() => {
        setInflation(props.fare?.inflation?.toString() ?? '0.00');
        setInflationManageType(props.fare?.inflation ? FareInflationType.CUSTOM : FareInflationType.CONTRACT);
        setFareId(props.isUpdating ? props.fare?.fareId : undefined);
        setFareName(props.isUpdating && props.fare?.fareName ? props.fare.fareName : '');
        setCurrency(props.fare?.currency ?? Currency.EURO);
        setOperatorNotes(props.fare?.operatorNotes);
        setEvseIdList(props.fare?.plugIds);
        setSubCpo(props.fare?.subCpoId);
        setSocketType(props.fare?.socketType);
        setChargingPowerRange(props.fare?.chargingPowerRange);
        setValidityPeriods(props.fare?.validityPeriods);
        setSessionFee(props.fare?.sessionFee && {
            price: props.fare.sessionFee.price.toString(),
            priceReferenceUnit: props.fare.sessionFee.priceReferenceUnit,
            minimumReferenceUnit: props.fare.sessionFee.minimumReferenceUnit?.toString(),
            startingFrom: props.fare.sessionFee.startingFrom,
            endingTo: props.fare.sessionFee.endingTo,
            gracePeriod: props.fare.sessionFee.gracePeriod && {
                priceReferenceUnit: props.fare.sessionFee.gracePeriod.priceReferenceUnit,
                minimumValue: props.fare.sessionFee.gracePeriod.minimumValue.toString(),
            }
        });
        setChargingFee(props.fare?.chargingFee && {
            price: props.fare.chargingFee.price.toString(),
            priceReferenceUnit: props.fare.chargingFee.priceReferenceUnit,
            minimumReferenceUnit: props.fare.chargingFee.minimumReferenceUnit?.toString(),
            startingFrom: props.fare.chargingFee.startingFrom,
            endingTo: props.fare.chargingFee.endingTo,
            gracePeriod: props.fare.chargingFee.gracePeriod && {
                priceReferenceUnit: props.fare.chargingFee.gracePeriod.priceReferenceUnit,
                minimumValue: props.fare.chargingFee.gracePeriod.minimumValue.toString(),
            }
        });
        setParkingFee(props.fare?.parkingFee && {
            price: props.fare.parkingFee.price.toString(),
            priceReferenceUnit: props.fare.parkingFee.priceReferenceUnit,
            minimumReferenceUnit: props.fare.parkingFee.minimumReferenceUnit?.toString(),
            startingFrom: props.fare.parkingFee.startingFrom,
            endingTo: props.fare.parkingFee.endingTo,
            gracePeriod: props.fare.parkingFee.gracePeriod && {
                priceReferenceUnit: props.fare.parkingFee.gracePeriod.priceReferenceUnit,
                minimumValue: props.fare.parkingFee.gracePeriod.minimumValue.toString(),
            }
        });
        setOtherFees(props.fare?.otherFees?.map(elem => ({
            type: elem.type,
            fareFee: {
                price: elem.fee.price.toString(),
                priceReferenceUnit: elem.fee.priceReferenceUnit,
                minimumReferenceUnit: elem.fee.minimumReferenceUnit?.toString(),
                startingFrom: elem.fee.startingFrom,
                endingTo: elem.fee.endingTo,
                gracePeriod: elem.fee.gracePeriod && {
                    priceReferenceUnit: elem.fee.gracePeriod.priceReferenceUnit,
                    minimumValue: elem.fee.gracePeriod.minimumValue.toString(),
                }
            }
        })));
    }, [
        props.fare?.currency, props.fare?.fareId, props.fare?.fareName,
        props.fare?.inflation, props.fare?.operatorNotes, props.fare?.plugIds,
        props.fare?.subCpoId, props.fare?.socketType, props.fare?.chargingPowerRange, 
        props.fare?.validityPeriods, props.fare?.sessionFee, props.fare?.chargingFee,
        props.fare?.parkingFee, props.fare?.otherFees, props.isUpdating
    ]);

    useEffect(() => {
        if(!props.open) {
            setInflation('0.00');
            setFareId(undefined);
            setFareName('');
            setOperatorNotes(undefined);
            setEvseIdList(undefined);
            setSubCpo(undefined);
            setSocketType(undefined);
            setChargingPowerRange(undefined);
            setValidityPeriods(undefined);
            setSessionFee(undefined);
            setChargingFee(undefined);
            setParkingFee(undefined);
            setOtherFees(undefined);
        }
    }, [props.open]);
    
    const onClose = useCallback(() => {
        if(!props.isOperationRunning)
            props.onClose();
    }, [props]);

    const [showConfirmFareUpdateDialog, setShowConfirmFareUpdateDialog] = useState(false);
    const onCloseShowConfirmUpdateDialog = useCallback(() => 
        setShowConfirmFareUpdateDialog(false)
    , []);

    const onConfirmButtonClick = useCallback(() => {
        if(isPrimaryButtonEnabled && !props.isOperationRunning){
            setShowConfirmFareUpdateDialog(false);
            props.onConfirmButtonClick({
                fareId,
                fareName,
                currency,
                operatorNotes,
                isValid24Hours: !validityPeriods || validityPeriods.length === 0,
                validityPeriods: validityPeriods ?? [],
                socketType,
                chargingPowerRange,
                inflation: fareInflationType === FareInflationType.CONTRACT ? undefined : inflationValue,
                subCpo,
                sessionFee: sessionFeeValue,
                chargingFee: chargingFeeValue,
                parkingFee: parkingFeeValue,
                otherFees: otherFeesValue ?? []
            });
        }
    }, [
        chargingFeeValue, chargingPowerRange, fareInflationType, currency, fareId, 
        fareName, inflationValue, isPrimaryButtonEnabled, 
        operatorNotes, otherFeesValue, parkingFeeValue, 
        props, sessionFeeValue, socketType, subCpo, validityPeriods
    ]);

    const onPrimaryButtonClick = useCallback(() => {
        if(isPrimaryButtonEnabled && !props.isOperationRunning){
            if(props.isUpdating && props.contract.isActive) {
                setShowConfirmFareUpdateDialog(true);
            } else {
                onConfirmButtonClick();
            }
        }
    }, [
        isPrimaryButtonEnabled, onConfirmButtonClick,
        props.contract.isActive, props.isOperationRunning,
        props.isUpdating
    ]);

    const dialogButtons = useMemo(() => {
        const buttonActions = [{
            label: formatMessage(props.canUpdateFare ? commonMessages.cancelButton : commonMessages.closeButton),
            disabled: props.isOperationRunning,
            buttonClasses: AppButtonVariant.Dark,
            onClick: onClose
        }];

        if(props.canUpdateFare){
            return [
                ...buttonActions,
                {
                    label: formatMessage(props.isUpdating ? commonMessages.saveButton : commonMessages.addButton),
                    disabled: props.isOperationRunning || !isPrimaryButtonEnabled,
                    buttonClasses: AppButtonVariant.Primary,
                    onClick: onPrimaryButtonClick
                },
            ];
        } else {
            return buttonActions;
        }
    }, [
        formatMessage, isPrimaryButtonEnabled, onClose, onPrimaryButtonClick, 
        props.canUpdateFare, props.isOperationRunning, props.isUpdating
    ]);
    
    return <BaseDialog
        containerClassName="manage-fee-dialog" 
        open={props.open}
        onClose={props.onClose}
        buttons={dialogButtons}
        title={formatMessage(
            props.isUpdating 
                ? contractDetailsMessages.manageFareDialogTitle
                : contractDetailsMessages.addFareDialogTitle,
            {
                providerName: props.providerName,
                contractId: props.contract.contractId,
                fareId: props.fare?.fareId ?? ''
            }
        )}
    >

        <FareDataContent
            inflation={inflation ?? ''} 
            onInflationChange={onInflationChange}
            fareInflationTypes={fareInflationTypes}
            fareInflationType={fareInflationType}
            onFareInflationTypeChange={onFareInflationTypeChange}
            contractBaseInflation={props.contract.inflation.baseInflation.toString()}
            fareId={fareId ?? ''}
            onFareIdChange={onFareIdChange}
            fareName={fareName}
            onFareNameChange={onFareNameChange}
            currency={currency}
            onCurrencyChange={onCurrencyChange}
            operatorNotes={operatorNotes ?? ''}
            onOperatorNotesChange={onOperatorNotesChange}
            evseIdList={evseIdList ?? []}
            canUpdateData={!!props.canUpdateFare}
        />

        <FareConditionsContent
            subCpo={subCpo}
            onSubCpoChange={onSubCpoChange}
            socketType={socketType}
            onSocketTypeChange={onSocketTyoeChange}
            chargingPowerRange={chargingPowerRange}
            onChargingPowerRangeChange={onChargingPowerRangeChange}
            validityPeriods={validityPeriods}
            onValidityPeriodsChange={onValidityPeriodsChange}
            canUpdateData={!!props.canUpdateFare}
        />

        <SessionFeeData 
            sessionFee={sessionFee}
            onSessionFeeChange={onSessionFeeChange}
            canUpdateData={!!props.canUpdateFare}
        />

         <ChargingFeeData 
            chargingFee={chargingFee}
            onChargingFeeChange={onChargingFeeChange}
            canUpdateData={!!props.canUpdateFare}
        />

        <ParkingFeeData 
            parkingFee={parkingFee}
            onParkingFeeChange={onParkingFeeChange}
            canUpdateData={!!props.canUpdateFare}
        />

        <OtherFeeData 
            otherFees={otherFees}
            onOtherFeesChange={onOtherFeesChange}
            canUpdateData={!!props.canUpdateFare}
        />

        <ConfirmFareUpdateDialog
            open={showConfirmFareUpdateDialog}
            onConfirmButtonClick={onConfirmButtonClick}
            onClose={onCloseShowConfirmUpdateDialog}
            fareId={props.fare?.fareId ?? ''}
        />

    </BaseDialog>;
});