import { Instance, types } from "mobx-state-tree";
import messages from '../users.messages';

export enum MobileUserStatus {
    Active = 'ACTIVE',
    Suspended = 'SUSPENDED',
    Blocked = 'BLOCKED',
    Unconfirmed = 'UNCONFIRMED'
}

export const messageDescriptorForStatus = (status: MobileUserStatus) => {
    switch (status) {
        case MobileUserStatus.Active:
            return messages.userStatusActive;
        case MobileUserStatus.Blocked:
            return messages.userStatusBlocked;
        case MobileUserStatus.Suspended:
            return messages.userStatusSuspended;
        default:
            return messages.userStatusUnconfirmed;
    }
};


export const AppUserModel = types.model({
    id: types.identifier,
    email: types.string,
    registrationDate: types.maybe(types.Date),
    status: types.maybe(types.enumeration<MobileUserStatus>(
        Object.values(MobileUserStatus)
    )),
    lastChargeDate:  types.maybe(types.Date),
    amountPaid: 0,
    amountDue: 0,
    businessUser: false
});


export interface AppUser extends Instance<typeof AppUserModel> { }