import React, { InputHTMLAttributes, useMemo } from 'react';
import './styled-inputs.scss';

interface StyledInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    label?: string;
    errorLabel?: string;
    containerClassName?: string;
    inputClassName?: string;
    icon?: string;
    onIconClick?: () => void;
    inputRef?: React.LegacyRef<HTMLInputElement>;
    type?: InputHTMLAttributes<HTMLInputElement>["type"];
}

export const StyledInput = (props: StyledInputProps) => {

    return useMemo(() => {
        const inputProps = { ...props };
        delete inputProps.inputClassName;
        delete inputProps.containerClassName;
        delete inputProps.errorLabel;
        delete inputProps.onIconClick;
        delete inputProps.inputRef;

        return <div className={`cc-input-box ${props.containerClassName || ''} ${props.icon ? 'padded' : ''}`}>
            {props.label && <span>{props.label}</span>}
            <input ref={props.inputRef} type={props.type} className={props.inputClassName || ''} {...inputProps} />
            {props.icon &&
                <img className={`input-icon${props.onIconClick ? ' clickable' : ''}`} src={props.icon} alt='' onClick={props.disabled ? undefined : props.onIconClick}/>
            }
            {props.errorLabel && <span className='error-label'>{props.errorLabel}</span>}
        </div>;
    }, [props]);

};