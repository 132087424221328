import { flow, types } from "mobx-state-tree";
import { ApiRequestState } from "../../common/api/api-request";
import { ResourceType } from "../../common/config/constants";
import { Env } from "../../common/config/env";
import { createPagedListStoreModel } from "../../common/stores/paged-list/paged-list-store";
import { emptyFunction } from "../../common/utils/utils";
import { FareWithSameIdAlreadyExists } from "../api/fares/fare-api-exception";
import {
    FareApiService,
    fareApiService as fareApiServ
} from "../api/fares/fare-api-service";
import { mockFareApiService } from "../mock/mock-fare-api-service";
import { Contract } from "../model/contract";
import { Fare, FareModel } from "../model/fare";
import { FareInfo } from "../model/fare-info";
import { Provider } from "../model/provider";
import { Group } from "../model/group";

export enum FareError {
    FARE_WITH_SAME_ID_ALREADY_EXISTS = '1',
}

const fareApiService: FareApiService = Env.useMocks
    ? mockFareApiService
    : fareApiServ;

const AdditionalFareModel = types.model({
    fareError: types.maybe(
        types.enumeration<FareError>(Object.values(FareError))
    ),
    manageFareRequestState: types.maybe(
        types.enumeration<ApiRequestState>(Object.values(ApiRequestState))
    ),
    deleteFareRequestState: types.maybe(
        types.enumeration<ApiRequestState>(Object.values(ApiRequestState))
    )
});

export const FareListStoreModel = types.compose(createPagedListStoreModel({
    type: FareModel,
    apiService: fareApiService,
    typeLabel: ResourceType.Fares
}), AdditionalFareModel)
    .actions(self => {

        const clearManageFareRequestState = () => {
            self.manageFareRequestState = undefined;
        };

        const clearDeleteFareRequestState = () => {
            self.deleteFareRequestState = undefined;
        };

        const clearFareError = () => {
            self.fareError = undefined;
        };

        const addFare = flow(function* (fare: FareInfo) {
            self.manageFareRequestState = ApiRequestState.RUNNING;
            try {
                yield fareApiService.addFare(fare);
                self.manageFareRequestState = ApiRequestState.COMPLETED;
            } catch (e: any) {
                if (e instanceof FareWithSameIdAlreadyExists) {
                    self.fareError = FareError.FARE_WITH_SAME_ID_ALREADY_EXISTS;
                }
                self.manageFareRequestState = ApiRequestState.FAILED;
            }
        });

        const updateFare = flow(function* (oldFare: Fare, fare: FareInfo) {
            self.manageFareRequestState = ApiRequestState.RUNNING;
            try {
                yield fareApiService.updateFare(oldFare.fareId, fare);
                self.manageFareRequestState = ApiRequestState.COMPLETED;
            } catch (e: any) {
                if (e instanceof FareWithSameIdAlreadyExists) {
                    self.fareError = FareError.FARE_WITH_SAME_ID_ALREADY_EXISTS;
                }
                self.manageFareRequestState = ApiRequestState.FAILED;
            }
        });

        const deleteFare = flow(function* (fare: Fare) {
            self.deleteFareRequestState = ApiRequestState.RUNNING;
            try {
                yield fareApiService.deleteFare(fare);
                self.deleteFareRequestState = ApiRequestState.COMPLETED;
            } catch (e: any) {
                self.deleteFareRequestState = ApiRequestState.FAILED;
            }
        });

        const init = emptyFunction;


        const initFareStore = (provider: Provider, contract: Contract, group: Group | undefined | null) => {
            fareApiService.setProviderId(provider.cpoId);
            fareApiService.setContractId(contract.contractId);

            if (group != null) {
                fareApiService.setGroupId(group?.groupId);
            }

        };

        return {
            init, clearManageFareRequestState, clearFareError,
            clearDeleteFareRequestState,
            addFare, updateFare, deleteFare,
            initFareStore
        };
    })
    .named('FareListStoreModel');
