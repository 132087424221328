const passwordRegexp = '^(?=.*[A-Z])(?=.*[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';
const emailRegexp = "^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9]+\\.[a-zA-Z]+";
const phoneNumberRegexp = '^(\\+[0-9][0-9]\\s?)?([0-9]+)$'; // +39(optional with optional whitespace)11111111
const numberRegexp = /^[0-9]*\.?[0-9]*$/;

export const isPasswordValid = (password: string)  => {
    return password.match(passwordRegexp);
};

export const isEmailValid = (email: string) => {
    return email.match(emailRegexp) != null;
};

export const isPhoneNumberValid = (phoneNumber: string) => {
    return phoneNumber.match(phoneNumberRegexp) != null;
};

export const isNumber = (value: string) => {
    return numberRegexp.test(value);
};
export const isFeeInputValid = (inputValue: string) => {
    const value = Number(inputValue);
    return inputValue.length > 0 && !isNaN(value) && value !== null && value !== undefined;
};