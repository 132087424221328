import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { observer } from "mobx-react-lite";

import { ContractDetailItem } from "./contract-detail-item";

import commonMessages from "../../../../../common/messages/common.messages";
import { formatDate, formatTime } from "../../../../../common/utils/formatters";
import { Contract, getContractTypes } from "../../../../model/contract";

import contractDetailsMessages from "../../../../contract-details.messages";

export interface ContractDetailsSummaryDataProps {
    contract: Contract;
    onBlockButtonClick: () => void;
    showBlockButton?: boolean;
}

export const ContractDetailsSummaryData = observer((props: ContractDetailsSummaryDataProps) => {

    const intl = useIntl();
    const { formatMessage } = intl;

    const contactPersonInfo = useMemo(() => {
        const info: string[] = [];
        const contactPerson = props.contract.contactPerson;
        if (contactPerson?.name && contactPerson.name.trim().length > 0) {
            info.push(contactPerson.name);
        }
        if (contactPerson?.email && contactPerson.email.trim().length > 0) {
            info.push(contactPerson.email);
        }
        if (contactPerson?.phoneNumber && contactPerson.phoneNumber.trim().length > 0) {
            info.push(contactPerson.phoneNumber);
        }

        return info.length > 0 ? info.join('; ') : '...';
    }, [props.contract.contactPerson]);

    const contractType = useMemo(() => {
        const type = getContractTypes(intl).find(elem => elem.id === props.contract.type);
        if (type) {
            return type.label;
        } else {
            console.error(`Unsupported value: ${props.contract.type}`);
            return '-';
        }
    }, [intl, props.contract.type]);

    return <div className="summary-data" >

        <ContractDetailItem
            title={formatMessage(contractDetailsMessages.contractCreatedOnLabel)}
            value={formatMessage(contractDetailsMessages.contractOperationInfo, {
                date: formatDate(props.contract.creation.date),
                time: formatTime(props.contract.creation.date),
                operatorName: props.contract.creation.operator.name,
                operatorEmail: props.contract.creation.operator.email,
            })}
        />

        <ContractDetailItem
            title={formatMessage(contractDetailsMessages.contractLastUpdateOnLabel)}
            value={formatMessage(contractDetailsMessages.contractOperationInfo, {
                date: formatDate(props.contract.lastUpdate.date),
                time: formatTime(props.contract.lastUpdate.date),
                operatorName: props.contract.lastUpdate.operator.name,
                operatorEmail: props.contract.lastUpdate.operator.email,
            })}
        />

        <ContractDetailItem
            title={formatMessage(contractDetailsMessages.contractTypeLabel)}
            value={contractType}
        />

        <ContractDetailItem
            title={formatMessage(contractDetailsMessages.contractRepresentativeLabel)}
            value={contactPersonInfo}
        />

        <div>
            <ContractDetailItem
                title={formatMessage(contractDetailsMessages.contractNotesForTheOperatorLabel)}
                value={props.contract.operatorNotes ?? '...'}
            />

            {
                props.showBlockButton &&
                <span onClick={props.onBlockButtonClick} className={`${props.contract.isBlocked ? 'unlock' : 'block'}-button`}>
                    {
                        formatMessage(
                            props.contract.isBlocked
                                ? commonMessages.unlockButton
                                : commonMessages.blockButton
                        )
                    }
                </span>
            }
        </div>

    </div>;
});