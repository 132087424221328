import React, { ReactNode } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { AppButton, AppButtonProps } from '../button/app-button';

import './base-dialog.scss';


interface BaseDialogProps {
    title: string | (() => ReactNode);
    children: ReactNode;
    buttons: AppButtonProps[];
    open: boolean;
    onClose: () => void;
    containerClassName?: string;
}

export const BaseDialog = (props: BaseDialogProps) => {
    return <Dialog open={props.open}
        onClose={props.onClose}
        classes={{
            container: `dialog-container${props.containerClassName ? ' ' + props.containerClassName : ''}`,
            paper: 'dialog-paper'
        }}>
        <DialogTitle className='dialog-title'>
            {
                typeof props.title === 'string' 
                    ? props.title 
                    : props.title()
            }
        </DialogTitle>
        <DialogContent className='dialog-content'>
            {props.children}
        </DialogContent>
        <DialogActions className='dialog-actions'>
            <>
                {props.buttons.map((buttonProps, index) => <AppButton key={index} {...buttonProps} />)}
            </>
        </DialogActions>
    </Dialog>;
};