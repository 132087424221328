import React, { ButtonHTMLAttributes, MouseEvent } from 'react';
import { emptyFunction } from '../../utils/utils';
import './app-button.scss';

export enum AppButtonVariant {
    Primary = 'primary', Tertiary = 'tertiary', Dark = 'dark'
}

export interface AppButtonProps {
    label: string;
    buttonClasses?: string;
    type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];

    disabled?: boolean;
    variant?: AppButtonVariant;

    onClick?: (a: MouseEvent<HTMLButtonElement>) => void;
}

export const AppButton = (props: AppButtonProps) => {

    const variant = props.variant || AppButtonVariant.Primary;
    return <button
        className={`primary-button ${variant} ${props.buttonClasses || ''}`}
        onClick={!props.disabled ? props.onClick : emptyFunction}
        disabled={props.disabled}
        type={props.type || "button"}>
        {props.label}
    </button>;
};