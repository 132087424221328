import { defineMessages } from 'react-intl';

export default defineMessages({
    "goBackToBackoffice": {
        "id": "containers.pageNotFound.goBackToBackoffice",
        "defaultMessage": "Torna al sito",
        "description": ""
    },
    "pageNotFoundTitle": {
        "id": "containers.pageNotFound.pageNotFoundTitle",
        "defaultMessage": "Oops, si è verificato un problema",
        "description": ""
    },
    "pageNotFoundBody": {
        "id": "containers.pageNotFound.pageNotFoundBody",
        "defaultMessage": "La pagina che stai cercando non è al momento raggiungibile",
        "description": ""
    },
});