import { parseCurrencyFromApiModel, parseCurrencyToApiModel } from "../../../common/models/currency";
import { parsePricingReferenceUnitFromApiModel, parsePricingReferenceUnitToApiModel } from "../../../common/models/pricing-reference-unit";
import { DateIntervalModel, parseValidityDayFromApiModel, parseValidityDayToApiModel } from "../../../common/models/time";
import {
    Fare as ApiFare,
    SocketType as ApiSocketType,
    FareFee as ApiFareFee,
    SessionMoment as ApiSessionModel,
    OtherFee as ApiOtherFee,
    FareFeeType as ApiFareFeeType,
    FareValidityPeriod as ApiFareValidityPeriod,
    CreateFareData as ApiCreateFareData,
    CreateFareData as ApiUpdateFareData,
    ChargingPowerRange as ApiChargingPowerRange,
    RangeValueCondition as ApiRangeValueCondition,
} from "../../../_generated/api";
import {
    ChargingPowerRange,
    ChargingPowerRangeModel,
    Fare, FareFee, FareFeeModel, FareFeeType,
    FareModel, FareValidityPeriod, FareValidityPeriodModel, GracePeriodModel, OtherFee, OtherFeeModel, RangeValueCondition, RangeValueModel, SessionMoment,
    SocketType
} from "../../model/fare";
import { CreateFareData, UpdateFareData } from "./fare-request";

const parseFareFeeTypeFromApiModel = (apiFareFeeType: ApiFareFeeType): FareFeeType => {
    switch (apiFareFeeType) {
        case ApiFareFeeType.Charging:
            return FareFeeType.CHARGING;
        case ApiFareFeeType.Generic:
            return FareFeeType.GENERIC;
        case ApiFareFeeType.Parking:
            return FareFeeType.PARKING;
        case ApiFareFeeType.Session:
            return FareFeeType.SESSION;
        case ApiFareFeeType.Start:
            return FareFeeType.START;
    }
};

export const parseSocketTypeFromApiModel = (apiSocketType: ApiSocketType): SocketType => {
    switch (apiSocketType) {
        case ApiSocketType.Ac:
            return SocketType.AC;
        case ApiSocketType.Dc:
            return SocketType.DC;
        case ApiSocketType.HpcUfc:
            return SocketType.HPC_UFC;
    }
};

export const parseRangeValueConditionFromApiModel = (apiRangeValueCondition: ApiRangeValueCondition): RangeValueCondition => {
    switch (apiRangeValueCondition) {
        case ApiRangeValueCondition.Exclusive:
            return RangeValueCondition.EXCLUSIVE;
        case ApiRangeValueCondition.Inclusive:
            return RangeValueCondition.INCLUSIVE;
    }
};

const parseSessionMomentFromApiModel = (apiSessionModel: ApiSessionModel): SessionMoment => {
    switch (apiSessionModel) {
        case ApiSessionModel.SessionStart:
            return SessionMoment.SESSION_START;
        case ApiSessionModel.SessionEnd:
            return SessionMoment.SESSION_END;
        case ApiSessionModel.ChargingStart:
            return SessionMoment.CHARGING_START;
        case ApiSessionModel.ChargingEnd:
            return SessionMoment.CHARGING_END;
    }
};

const parseFareFeeFromApiModel = (apiFareFee: ApiFareFee): FareFee => {
    return FareFeeModel.create({
        price: apiFareFee.price,
        priceReferenceUnit: apiFareFee.priceReferenceUnit ? parsePricingReferenceUnitFromApiModel(apiFareFee.priceReferenceUnit) : undefined,
        minimumReferenceUnit: apiFareFee.minimumReferenceUnit,
        startingFrom: apiFareFee.startingFrom ? parseSessionMomentFromApiModel(apiFareFee.startingFrom) : undefined,
        endingTo: apiFareFee.endingTo ? parseSessionMomentFromApiModel(apiFareFee.endingTo) : undefined,
        gracePeriod: apiFareFee.gracePeriod ? GracePeriodModel.create({
            minimumValue: apiFareFee.gracePeriod.minimumValue,
            priceReferenceUnit: parsePricingReferenceUnitFromApiModel(apiFareFee.gracePeriod.priceReferenceUnit)
        }) : undefined,
    });
};

const parseOtherFeeFromApiModel = (apiOtherFee: ApiOtherFee): OtherFee => {
    return OtherFeeModel.create({
        fee: parseFareFeeFromApiModel(apiOtherFee.fee),
        type: parseFareFeeTypeFromApiModel(apiOtherFee.type)
    });
};

const parseFareValidityPeriodFromApiModel = (apiFareValidity: ApiFareValidityPeriod): FareValidityPeriod => {
    const [fromHours, fromMinutes] = apiFareValidity.timeInterval.from.split(':');
    const [toHours, toMinutes] = apiFareValidity.timeInterval.to.split(':');

    return FareValidityPeriodModel.create({
        day: parseValidityDayFromApiModel(apiFareValidity.day),
        timeInterval: DateIntervalModel.create({
            from: (new Date()).setHours(Number(fromHours), Number(fromMinutes)),
            to: (new Date()).setHours(Number(toHours), Number(toMinutes)),
        })
    });
};

const parseChargingPowerRangeFromApiModel = (apiChargingPower: ApiChargingPowerRange): ChargingPowerRange => {
    return ChargingPowerRangeModel.create({
        min: apiChargingPower.min && RangeValueModel.create({
            value: apiChargingPower.min.value,
            condition: parseRangeValueConditionFromApiModel(apiChargingPower.min.condition)
        }),
        max: apiChargingPower.max && RangeValueModel.create({
            value: apiChargingPower.max.value,
            condition: parseRangeValueConditionFromApiModel(apiChargingPower.max.condition)
        }),
    });
};

export const parseFareFromApiModel = (apiFare: ApiFare): Fare => {
    return FareModel.create({
        fareId: apiFare.fareId,
        fareName: apiFare.fareName,
        currency: parseCurrencyFromApiModel(apiFare.currency),
        chargingPowerRange: apiFare.chargingPowerRange && parseChargingPowerRangeFromApiModel(apiFare.chargingPowerRange),
        isValid24h: apiFare.isValid24Hours,
        socketType: apiFare.socketType && parseSocketTypeFromApiModel(apiFare.socketType),
        inflation: apiFare.inflation,
        operatorNotes: apiFare.operatorNotes,
        subCpoId: apiFare.subCpo,
        maximumPrice: apiFare.maximumPrice,
        minimumPrice: apiFare.minimumPrice,
        fixedPrice: apiFare.fixedPrice,
        plugIds: apiFare.plugIds,
        chargingFee: apiFare.chargingFee && parseFareFeeFromApiModel(apiFare.chargingFee),
        parkingFee: (apiFare.parkingFee && parseFareFeeFromApiModel(apiFare.parkingFee)) ?? undefined,
        sessionFee: (apiFare.sessionFee && parseFareFeeFromApiModel(apiFare.sessionFee)) ?? undefined,
        otherFees: apiFare.otherFees?.map(parseOtherFeeFromApiModel),
        validityPeriods: apiFare.validityPeriods?.map(parseFareValidityPeriodFromApiModel),
    });
};

export const parseFareFeeTypeToApiModel = (fareFeeType: FareFeeType): ApiFareFeeType => {
    switch (fareFeeType) {
        case FareFeeType.CHARGING:
            return ApiFareFeeType.Charging;
        case FareFeeType.GENERIC:
            return ApiFareFeeType.Generic;
        case FareFeeType.PARKING:
            return ApiFareFeeType.Parking;
        case FareFeeType.SESSION:
            return ApiFareFeeType.Session;
        case FareFeeType.START:
            return ApiFareFeeType.Start;
    }
};

export const parseSocketTypeToApiModel = (socketType: SocketType): ApiSocketType => {
    switch (socketType) {
        case SocketType.AC:
            return ApiSocketType.Ac;
        case SocketType.DC:
            return ApiSocketType.Dc;
        case SocketType.HPC_UFC:
            return ApiSocketType.HpcUfc;
    }
};

export const parseSessionMomentToApiModel = (sessionModel: SessionMoment): ApiSessionModel => {
    switch (sessionModel) {
        case SessionMoment.SESSION_START:
            return ApiSessionModel.SessionStart;
        case SessionMoment.SESSION_END:
            return ApiSessionModel.SessionEnd;
        case SessionMoment.CHARGING_START:
            return ApiSessionModel.ChargingStart;
        case SessionMoment.CHARGING_END:
            return ApiSessionModel.ChargingEnd;
    }
};

export const parseRangeValueConditionToApiModel = (rangeValueCondition: RangeValueCondition): ApiRangeValueCondition => {
    switch (rangeValueCondition) {
        case RangeValueCondition.EXCLUSIVE:
            return ApiRangeValueCondition.Exclusive;
        case RangeValueCondition.INCLUSIVE:
            return ApiRangeValueCondition.Inclusive;
    }
};

export const parseFareFeeToApiModel = (fareFee: FareFee): ApiFareFee => {
    return {
        price: fareFee.price,
        priceReferenceUnit: fareFee.priceReferenceUnit && parsePricingReferenceUnitToApiModel(fareFee.priceReferenceUnit),
        minimumReferenceUnit: fareFee.minimumReferenceUnit,
        startingFrom: fareFee.startingFrom && parseSessionMomentToApiModel(fareFee.startingFrom),
        endingTo: fareFee.endingTo && parseSessionMomentToApiModel(fareFee.endingTo),
        gracePeriod: fareFee.gracePeriod && {
            minimumValue: fareFee.gracePeriod.minimumValue,
            priceReferenceUnit: parsePricingReferenceUnitToApiModel(fareFee.gracePeriod.priceReferenceUnit)
        }
    };
};

export const parseOtherFeeToApiModel = (otherFee: OtherFee): ApiOtherFee => {
    return {
        fee: parseFareFeeToApiModel(otherFee.fee),
        type: parseFareFeeTypeToApiModel(otherFee.type)
    };
};

export const parseFareValidityPeriodToApiModel = (fareValidity: FareValidityPeriod): ApiFareValidityPeriod => {

    const formatNumber = (num: number) => num.toString().padStart(2, '0');
    const formatDate = (date: Date) => `${formatNumber(date.getHours())}:${formatNumber(date.getMinutes())}`;

    return {
        day: parseValidityDayToApiModel(fareValidity.day),
        timeInterval: {
            from: formatDate(fareValidity.timeInterval.from),
            to: formatDate(fareValidity.timeInterval.to),
        }
    };
};

export const parseCreateFareDataToApiModel = (fare: CreateFareData): ApiCreateFareData => {
    return {
        fareId: fare.fareId,
        fareName: fare.fareName,
        currency: parseCurrencyToApiModel(fare.currency),
        isValid24Hours: fare.isValid24Hours,
        subCpo: fare.subCpo,
        maximumPrice: fare.maximumPrice,
        minimumPrice: fare.minimumPrice,
        fixedPrice: fare.fixedPrice,
        inflation: fare.inflation,
        sessionFee: fare.sessionFee && parseFareFeeToApiModel(fare.sessionFee),
        chargingFee: fare.chargingFee && parseFareFeeToApiModel(fare.chargingFee),
        parkingFee: fare.parkingFee && parseFareFeeToApiModel(fare.parkingFee),
        otherFees: fare.otherFees.map(parseOtherFeeToApiModel),
        operatorNotes: fare.operatorNotes,
        socketType: fare.socketType && parseSocketTypeToApiModel(fare.socketType),
        chargingPowerRange: fare.chargingPowerRange && {
            min: fare.chargingPowerRange.min && {
                value: fare.chargingPowerRange.min.value,
                condition: parseRangeValueConditionToApiModel(fare.chargingPowerRange.min.condition)
            },
            max: fare.chargingPowerRange.max && {
                value: fare.chargingPowerRange.max.value,
                condition: parseRangeValueConditionToApiModel(fare.chargingPowerRange.max.condition)
            },
        },
        validityPeriods: fare.validityPeriods.map(parseFareValidityPeriodToApiModel),
    };
};

export const parseUpdateFareDataToApiModel = (fare: UpdateFareData): ApiUpdateFareData => parseCreateFareDataToApiModel(fare);
