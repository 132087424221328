import { Configuration } from "../../_generated/api";

export class Env {
    
    static get baseUrl(): string {
        return process.env.REACT_APP_BASE_URL || '';
    }

    static get apiKey(): string {
        return process.env.REACT_APP_BE_API_KEY || '';
    }

    static get keepSession(): boolean {
        return process.env.REACT_APP_KEEP_SESSION === 'true' || false;
    }

    static get useMocks(): boolean {
        return process.env.REACT_APP_USE_MOCKS === 'true';
    }

    static get apiConfig(): Configuration {
        return {
            basePath: Env.baseUrl,
            apiKey: Env.apiKey,
            isJsonMime: () => true
        };
    }
}