import { flow, getEnv, SnapshotOrInstance, types } from "mobx-state-tree";
import { ApiRequestState } from "../../common/api/api-request";
import { ResourceType } from "../../common/config/constants";
import { Env } from "../../common/config/env";
import { OrderCriteriaModel, SortOrder } from "../../common/models/order-criteria";
import { createPagedListStoreModel } from "../../common/stores/paged-list/paged-list-store";
import { 
    ProviderApiService,
    providerApiService as providerApiServ
} from "../api/providers/provider-api-service";
import { CannotUpdateProviderId, ProviderWithSameIdAlreadyExists } from "../api/providers/provider-api-exception";
import { mockProviderApiService } from "../mock/mock-provider-api-service";
import { ContractOrderCriteria } from "../model/contract";
import { Provider, ProviderModel } from "../model/provider";
import { ProvidersFilterModel } from "../model/providers-filter";
import { ContractListStoreModel } from "./contract-store";

export enum ProviderError {
    PROVIDER_WITH_SAME_ID_ALREADY_EXISTS = '1',
    CANNOT_UPDATE_PROVIDER_ID = '2',
}

const providerApiService: ProviderApiService = Env.useMocks 
    ? mockProviderApiService 
    : providerApiServ;

const AdditionalProviderModel = types.model({
    contractsStore: types.maybeNull(ContractListStoreModel),
    providerError: types.maybe(
        types.enumeration<ProviderError>(Object.values(ProviderError))
    ),
    manageProviderRequestState: types.maybe(
        types.enumeration<ApiRequestState>(Object.values(ApiRequestState))
    ),
    deleteProviderRequestState: types.maybe(
        types.enumeration<ApiRequestState>(Object.values(ApiRequestState))
    ),
});

export const ProviderListStoreModel = types.compose(createPagedListStoreModel({
    type: ProviderModel,
    filterType: ProvidersFilterModel,
    filters: () => ProvidersFilterModel.create({}),
    apiService: providerApiService,
    typeLabel: ResourceType.Providers,
    selectedItemReferenceOptions: {
        get(identifier: string, parent: any) {            
            return parent.currentPageItems.find((elem: any) => elem.cpoId === identifier);
        },
        set(elem: Provider) {
            return elem.cpoId;
        }
    }
}), AdditionalProviderModel)
.actions(self => {

    const parentSelectItem = self.openItemDetail;
    const parentInit = self.init;

    const openItemDetail = (item: SnapshotOrInstance<Provider>) => {
        // needed for selecting a provider after creating it
        if(!self.currentPageItems.find(elem => elem.cpoId === item.cpoId)){
            self.currentPageItems.push(item);
        }
        parentSelectItem(item);
        self.contractsStore = ContractListStoreModel.create({
            orderCriteria: OrderCriteriaModel.create({
                key: ContractOrderCriteria.TIME_PERIOD,
                sortOrder: SortOrder.DESC
            })
        }, getEnv(self));
    };

    const deselectItem = () => {
        self.selectedItem = null;
        self.contractsStore = null;
    };

    const addProvider = flow(function* (provider: Provider) {
        self.manageProviderRequestState = ApiRequestState.RUNNING;
        try {
            yield providerApiService.addProvider(provider);
            self.manageProviderRequestState = ApiRequestState.COMPLETED;
        } catch (e: any) {
            if(e instanceof ProviderWithSameIdAlreadyExists) {
                self.providerError = ProviderError.PROVIDER_WITH_SAME_ID_ALREADY_EXISTS;
            }
            self.manageProviderRequestState = ApiRequestState.FAILED;
        }
    });

    const updateProvider = flow(function* (oldProvider: Provider, provider: Provider) {
        self.manageProviderRequestState = ApiRequestState.RUNNING;
        try {
            yield providerApiService.updateProvider(oldProvider, provider);
            self.manageProviderRequestState = ApiRequestState.COMPLETED;
        } catch (e: any) {
            if(e instanceof CannotUpdateProviderId) {
                self.providerError = ProviderError.CANNOT_UPDATE_PROVIDER_ID;
            } else if(e instanceof ProviderWithSameIdAlreadyExists) {
                self.providerError = ProviderError.PROVIDER_WITH_SAME_ID_ALREADY_EXISTS;
            }
            self.manageProviderRequestState = ApiRequestState.FAILED;
        }
    });

    const deleteProvider = flow(function* (provider: Provider) {
        self.deleteProviderRequestState = ApiRequestState.RUNNING;
        try {
            yield providerApiService.deleteProvider(provider);
            self.deleteProviderRequestState = ApiRequestState.COMPLETED;
        } catch (e: any) {
            self.deleteProviderRequestState = ApiRequestState.FAILED;
        }
    });

    const clearManageProviderRequestState = () => {
        self.manageProviderRequestState = undefined;
    };

    const clearDeleteProviderRequestState = () => {
        self.deleteProviderRequestState = undefined;
    };
    
    const clearProviderError = () => {
        self.providerError = undefined;
    };

    const init = () => {
        if(self.filters?.countryId) {
            parentInit();
        }
    };

    return {
        init,
        openItemDetail, deselectItem,
        addProvider, deleteProvider,
        updateProvider,
        clearDeleteProviderRequestState,
        clearManageProviderRequestState,
        clearProviderError
    };
})
.named('ProviderListStoreModel');
